import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { CSSProperties, useEffect, useState } from "react";
import Builder from "./Builder";
import Editor from "./Editor";

export const newObjForm = () => {
  return {
    compId: StringUtils.getUuid(),
    key: "",
    value: "",
    type: JSON_DATA_TYPE.STRING,
  };
};

export const newArrForm = (index) => {
  return {
    compId: StringUtils.getUuid(),
    key: index,
    // value: [{ ...newObjForm() }],
    value: "",
    subType: JSON_DATA_TYPE.ARRAY_INDEX,
    type: JSON_DATA_TYPE.STRING,
  };
};

export const JSON_DATA_TYPE = {
  STRING: "string",
  ARRAY: "array",
  ARRAY_INDEX: "arrayIndex",
  OBJECT: "object",
  DATE_TIME: "dateTime",
};

/**
 * @param {Object} props
 * @param {"builder" | "editor" } props.viewMode
 * @param {Object} props.keyLabel : JSON key의 라벨
 * @param {Object} props.valueLabel : JSON value 라벨
 * @param {Object} props.addButtonLabel : 필드 추가 버튼 라벨 (default 필드 추가)
 * @param {Boolean} props.isAddFieldButton : 필드 추가 버튼 여부
 * @param {Boolean} props.isViewModeChangeButton : viewMode 전환 버튼 여부
 * @param {CSSProperties} props.style : builder Wrapper 스타일
 * @param {Funtion} props.onChange : return value
 * @param {String} props.emptyMessage : JSON 데이터가 없을 때 Builder에서 보여지는 메세지
 * @param {Object} value
 * @param {Object} editorConfig
 * @returns
 */
function JSONBuilder({
  viewMode = "builder",
  isViewModeChangeButton = true,
  ...props
}) {
  const [_viewMode, setViewMode] = useState(viewMode);
  const [builderData, setBuilderData] = useState([]);
  const [editorData, setEditorData] = useState("");

  /**
   * 뷰모드가 바뀔때
   */
  useEffect(() => {
    setViewMode(viewMode);
    if (StringUtils.equalsIgnoreCase(viewMode, "builder")) {
      const _builderlist = JSONToBuilderArray(props.value);
      setBuilderData(_builderlist);
    } else if (StringUtils.equalsIgnoreCase(viewMode, "editor")) {
      setEditorData(JSON.stringify(props.value, null, 2));
    }
  }, [viewMode]);

  /**
   * 빌더 데이터가 바뀌게 되면 외부에 데이터에 적용한다.
   */
  useEffect(() => {
    if (builderData.length > 0) {
      try {
        const _data = BuilderArrayToJSON(builderData);
        props.onChange(_data);
      } catch (error) {
        console.error("BuilderArray To JSON Error");
      }
    }
  }, [builderData]);

  /**
   * JSON 에서 빌더에서 사용되는 컴포넌트 형태로 변경
   * @param {*} obj
   * @param {*} list
   */
  const JSONToBuilderArray = (obj) => {
    const list = [];

    const _getType = (value) => {
      let type = "";
      if (value === null || value === undefined) {
        type = JSON_DATA_TYPE.STRING;
      } else if (typeof value === JSON_DATA_TYPE.STRING) {
        if (
          String(value).length === 24 &&
          StringUtils.indexOf(value, "T") === 10 &&
          StringUtils.indexOf(value, "Z") === 23
        ) {
          type = JSON_DATA_TYPE.DATE_TIME;
        } else {
          type = JSON_DATA_TYPE.STRING;
        }
      } else if (ArrayUtils.isArray(value)) {
        type = JSON_DATA_TYPE.ARRAY;
      } else if (ObjectUtils.isObject(value)) {
        type = JSON_DATA_TYPE.OBJECT;
      } else {
        type = JSON_DATA_TYPE.STRING;
      }
      return type;
    };

    const _classifyType = (key, value, _list) => {
      const body = {
        compId: StringUtils.getUuid(),
        key: key,
        value: null,
        type: _getType(value),
      };
      if (value === null) {
        body.value = value;
      } else if (typeof value === JSON_DATA_TYPE.STRING) {
        body.value = value;
      } else if (ArrayUtils.isArray(value)) {
        body.value = setArrChild(value);
      } else if (ObjectUtils.isObject(value)) {
        body.value = setObjChild(value);
      } else {
        body.value = value;
      }
      _list.push(body);
    };

    /**
     * Arr 타입 변환
     * @param {*} objValue
     * @param {*} _childList
     * @returns
     */
    const setArrChild = (objValue, _childList = []) => {
      for (const [index, item] of objValue.entries()) {
        let arrList = [];
        let type = JSON_DATA_TYPE.STRING;
        if (typeof item === "string") {
          arrList = item;
        } else if (ArrayUtils.isArray(item)) {
          setArrChild(item, arrList);
          type = JSON_DATA_TYPE.ARRAY;
        } else if (ObjectUtils.isObject(item)) {
          setObjChild(item, arrList);
          type = JSON_DATA_TYPE.OBJECT;
        }
        _childList.push({
          compId: StringUtils.getUuid(),
          key: index,
          value: arrList,
          type: type,
          subType: JSON_DATA_TYPE.ARRAY_INDEX,
        });
      }

      return _childList;
    };

    /**
     * object 타입 변환
     * @param {*} objValue
     * @param {*} _childList
     * @returns
     */
    const setObjChild = (objValue, _childList = []) => {
      for (const cKey in objValue) {
        _classifyType(cKey, objValue[cKey], _childList);
      }
      return _childList;
    };

    if (!ObjectUtils.isEmpty(obj)) {
      for (const key in obj) {
        _classifyType(key, obj[key], list);
      }
    }
    return list;
  };

  /**
   * 시간 데이터의 오차를 일치 시키도록 하는 함수
   * BuilderArrayToJSON에서 사용
   * @param {*} type
   * @param {*} value
   * @returns
   */
  const _stringOrDateTime = (type, value) => {
    if (type === JSON_DATA_TYPE.STRING) {
      return value;
    } else if (type === JSON_DATA_TYPE.DATE_TIME) {
      //년 월 일  시간 : 분 : 초 로 쪼개기 .000Z로 데이트타임 구분할것
      if (!StringUtils.isEmpty(value)) {
        const _date = new Date(value);
        const _Y = _date.getFullYear();
        const _M = _date.getMonth();
        const _D = _date.getDate();
        const _H = _date.getHours();
        const _MI = _date.getMinutes();
        const _S = _date.getSeconds();

        const _Ad = new Date();
        _Ad.setFullYear(_Y);
        _Ad.setMonth(_M);
        _Ad.setDate(_D);
        _Ad.setHours(_H);
        _Ad.setMinutes(_MI);
        _Ad.setSeconds(_S);
        _Ad.setMilliseconds(0);
        return _Ad.toISOString();
      } else {
        return "";
      }
    }
  };

  /**
   * 빌더에서 사용되는 컴포넌트 (Array)를 JSON 데이터로 변경
   * @param {*} objects
   * @returns
   */
  const BuilderArrayToJSON = (objects, isArrayChild = false) => {
    let data = {};

    if (!ObjectUtils.isObject(objects)) return false;

    if (isArrayChild) {
      data = [];

      for (const obj of objects) {
        if (
          obj.type === JSON_DATA_TYPE.STRING ||
          obj.type === JSON_DATA_TYPE.DATE_TIME
        ) {
          data.push(_stringOrDateTime(obj.type, obj.value));
        } else if (obj.type === JSON_DATA_TYPE.ARRAY) {
          data.push(BuilderArrayToJSON(obj.value, true));
        } else if (obj.type === JSON_DATA_TYPE.OBJECT) {
          data.push(BuilderArrayToJSON(obj.value));
        }
      }
    } else {
      for (const obj of objects) {
        if (
          obj.type === JSON_DATA_TYPE.STRING ||
          obj.type === JSON_DATA_TYPE.DATE_TIME
        ) {
          data[obj.key] = _stringOrDateTime(obj.type, obj.value);
        } else if (obj.type === JSON_DATA_TYPE.ARRAY) {
          data[obj.key] = obj.value.map((item) => {
            if (
              StringUtils.includesIgnoreCase(item.type, [
                JSON_DATA_TYPE.STRING,
                JSON_DATA_TYPE.DATE_TIME,
              ])
            ) {
              return item.value;
            } else if (item.type === JSON_DATA_TYPE.ARRAY) {
              debugger;
              return BuilderArrayToJSON(item.value, true);
            } else if (item.type === JSON_DATA_TYPE.OBJECT) {
              return BuilderArrayToJSON(item.value);
            } else {
              return item;
            }
          });
        } else if (obj.type === JSON_DATA_TYPE.OBJECT) {
          const pkey = StringUtils.isEmpty(obj.key) ? "_tempKey" : obj.key;
          if (!data[pkey]) data[pkey] = {};
          for (const k in obj.value) {
            const { value, type } = obj.value[k];
            const ckey = StringUtils.isEmpty(obj.value[k].key)
              ? "_tempKey"
              : obj.value[k].key;
            if (
              type === JSON_DATA_TYPE.STRING ||
              type === JSON_DATA_TYPE.DATE_TIME
            ) {
              data[pkey][ckey] = _stringOrDateTime(type, value);
            } else if (
              StringUtils.equalsIgnoreCase(type, JSON_DATA_TYPE.ARRAY)
            ) {
              data[pkey][ckey] = BuilderArrayToJSON(value, true);
            } else if (
              StringUtils.equalsIgnoreCase(type, JSON_DATA_TYPE.OBJECT)
            ) {
              data[pkey][ckey] = BuilderArrayToJSON(value);
            }
          }
        }
      }
    }
    return data;
  };

  if (props.children) {
    return <>{props.children}</>;
  } else {
    return (
      <>
        <Builder
          {...props}
          show={StringUtils.equalsIgnoreCase(_viewMode, "Builder")}
          builderData={builderData}
          setBuilderData={setBuilderData}
          setViewMode={setViewMode}
          isViewModeChangeButton={isViewModeChangeButton}
        />
        <Editor
          {...props}
          editorData={editorData}
          show={StringUtils.equalsIgnoreCase(_viewMode, "Editor")}
          setViewMode={setViewMode}
          isViewModeChangeButton={isViewModeChangeButton}
        />
      </>
    );
  }
}

export default Object.assign(JSONBuilder, {
  Builder,
  Editor,
});
