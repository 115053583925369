import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import { useRef } from "react";
import { BsPencilSquare } from "react-icons/bs";
import styled from "styled-components";

import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import UModalMiscCodeEditor from "components/common/code/UModalMiscCodeEditor";
import UModalStringEditor from "components/common/code/UModalStringEditor";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";

const ExtendPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * ExtendPopup : textarea을 확장해서 입력할 Popup
 *
 * 2022.03.11 : init: songe.park
 * 2022.03.18 : json, java등 다양한 언어 사용 가능하도록 수정
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @param {*} props
 * @returns value
 */
const ExtendPopup = (props) => {
  const {
    title,
    defaultValue,
    callbackFnc,
    setValue,
    fieldType,
    jsonValidation,
    isEventBuilder,
  } = props;
  // const [popVal, setPopVal] = useState(defaultValue);
  const popValRef = useRef(defaultValue || "");

  /**
   * closePopup: value값을 전달하고 popup 닫기
   * @param {Event} e
   * @returns
   */
  const closePopup = (e) => {
    if (
      !StringUtils.isEmpty(popValRef.current) &&
      fieldType === "json" &&
      jsonValidation === true
    ) {
      try {
        JSON.parse(popValRef.current);
      } catch (e) {
        Message.alert(
          "It does not conform to the JSON format. " + e,
          Enums.MessageType.ERROR
        );
        return false;
      }
    }

    let val = StringUtils.isEmpty(popValRef.current) ? "" : popValRef.current;
    if (!ObjectUtils.isEmpty(setValue)) {
      setValue(val);
    }
    if (!ObjectUtils.isEmpty(callbackFnc)) {
      callbackFnc.call(this, val);
    }
    Popup.close();
  };

  /**
   * Editor change event
   * @param {String} value
   * @param {*} viewUpdate
   */
  const onEditorChange = (value, viewUpdate) => {
    // setPopVal(value);
    popValRef.current = value;
  };
  return (
    <ExtendPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="event-editor-body">
          {StringUtils.isEmpty(fieldType) ? (
            <UModalStringEditor {...props} onChange={onEditorChange} />
          ) : fieldType !== "json" && isEventBuilder ? (
            <UModalJavascriptEditor {...props} onChange={onEditorChange} />
          ) : (
            <UModalMiscCodeEditor {...props} onChange={onEditorChange} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={closePopup}>
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </ExtendPopupStyle>
  );
};

export default ExtendPopup;
