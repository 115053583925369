/*!
 * Builder Builder output Reducer for react v.17
 *
 *  현재 작업중인 Builder output에 대한 Reducer
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import ActionType from "components/builder/BuilderActionType";
import { Enums } from "components/builder/BuilderEnum";
import produce from "immer";

const initialState = {
  output: {},
  mobileOutput: {},
  webOutput: {},
  memo: [],
};

/**
 * UI Builder output을 reducer state로 관리
 * action type subfix는 UI (API Builder : API, Report Builder : RPT)
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function UIBuilderOutputReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.UI.CREATE_PAGE: {
      return produce(state, (draft) => {
        draft.output = action.output;
        draft.output.page.child = [];
      });
    }
    case ActionType.UI.UPDATE_OUTPUT: {
      //filter output 일 경우
      if (action.output.page.pageType === Enums.ComponentType.FILTER) {
        return produce(state, (draft) => {
          draft.output.page = draft.output.page || {};
          draft.output.page.filter = action.output;
        });
      } else {
        return produce(state, (draft) => {
          draft.output = action.output;
        });
      }
    }
    case ActionType.UI.UPDATE_PROGRAM_INFORMATION: {
      return produce(state, (draft) => {
        draft.information = action.information;
      });
    }
    case ActionType.UI.INIT_OUTPUT: {
      return initialState;
    }

    case ActionType.UI.SET_PROGRAM_MEMO: {
      return produce(state, (draft) => {
        draft.memo = action.payload;
      });
    }

    case ActionType.UI.ADD_PROGRAM_MEMO: {
      return produce(state, (draft) => {
        draft.memo.push(action.payload);
      });
    }
    case ActionType.UI.UPDATE_PROGRAM_MEMO: {
      return produce(state, (draft) => {
        draft.memo[action.payload.index] = { ...action.payload };
      });
    }
    case ActionType.UI.DELETE_PROGRAM_MEMO: {
      return produce(state, (draft) => {
        draft.memo.splice(action.payload.index, 1);
      });
    }

    case ActionType.UI.GET_UI_OUTPUT: {
      return produce(state, (draft) => {
        draft.output = action.output;
      });
    }

    case ActionType.MOBILE.GET_MOBILE_OUTPUT: {
      return produce(state, (draft) => {
        draft.output = action.output;
      });
    }

    case ActionType.UI.SET_UI_OUTPUT: {
      return produce(state, (draft) => {
        draft.webOutput = action.output;
      });
    }

    case ActionType.MOBILE.SET_MOBILE_OUTPUT: {
      return produce(state, (draft) => {
        draft.mobileOutput = action.output;
      });
    }

    default:
      return state;
  }
}
