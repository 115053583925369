import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaBook, FaCoins, FaLayerGroup } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import loadable from "@loadable/component";
import { Enums } from "components/builder/BuilderEnum";
import BuilderControlSideBar from "components/builder/BuilderControlSideBar";
import { setIsMobileEditor } from "components/builder/mobile/reducers/MobileAction";
import BasicComponentsTab from "components/builder/ui/BasicComponentsTab";
import UICustomDragLayer from "components/builder/ui/editor/UICustomDragLayer";
import ElementComponentsTab from "components/builder/ui/ElementComponentsTab";
import ServiceComponentsTab from "components/builder/ui/ServiceComponentsTab";
import UITree from "components/builder/ui/tree/UITree";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import "css/builder/main.css";
import "css/builder/runtime.css";
import "css/builder/settings.css";
import "css/builder/sirius/main.css";

import MobileFloatingTab from "components/builder/mobile/MobileFloatingTab";
import { useRef } from "react";
import FloatingPropertyTab from "components/builder/ui/properiesTab/FloatingPropertyTab";

// 이미 로드된 CSS 파일들을 추적하기 위한 Set
const loadedCSSFiles = new Set();

const UIBuilderMain = (props) => {
  const dispatch = useDispatch();
  const output = useSelector((state) => state.outputUI.output);
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const workspace = useSelector((state) => state.workspace);
  const { treeOpen } = useSelector((state) => state.activedUIComponent);

  const [activedTabKey, setActivedTabKey] = useState("BasicComponents");

  const activateComponentRef = useRef(activedComponent);
  const editorComponentRef = useRef();
  let theme = CommonUtils.getTheme();

  if (!editorComponentRef.current) {
    editorComponentRef.current = loadable(() =>
      import(`components/builder/ui/editor/theme/${theme}/UIEditor`)
    );
  }

  useEffect(() => {
    if (
      !ObjectUtils.isEmpty(activateComponentRef.current) &&
      activateComponentRef.current.compId === activedComponent.compId
    ) {
      return false;
    } else if (!ObjectUtils.isEmpty(activedComponent)) {
      activateComponentRef.current = activedComponent;
    }
  }, [activedComponent]);

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  useEffect(() => {
    dispatch(setIsMobileEditor(false));
    importDynamicStyle();
  }, []);

  /**
   * 테마별 css 임포트
   */
  const importDynamicStyle = () => {
    let theme = CommonUtils.getTheme();
    // 아직 로드되지 않은 파일들만 필터링
    if (theme !== "common") {
      const requireCSS = require.context("css/builder/sirius", false, /\.css$/);
      const cssFileNameSet = new Set();
      requireCSS.keys().forEach((file) => {
        cssFileNameSet.add(file.replace("./", "").split("/").pop());
      });
      const cssFileNameList = Array.from(cssFileNameSet);
      const filesToLoad = cssFileNameList.filter(
        (fileName) => !loadedCSSFiles.has(fileName)
      );
      if (!ArrayUtils.isEmpty(filesToLoad)) {
        Promise.all(
          filesToLoad.map((fileName) =>
            import(`css/builder/sirius/${fileName}`).then(() => {
              loadedCSSFiles.add(fileName); // 로드 완료된 파일은 Set에 추가
            })
          )
        )
          .then(() => {
            console.log(`Additional CSS for ${theme} loaded`);
          })
          .catch((err) => {
            console.error(`Failed to load ${theme} CSS`, err);
          });
      }
    }
  };

  console.log("[Check re-rendering] ", "UIBuilderMain has been re-rendered!!!");

  return (
    <div className={`builder-main-wrapper ${treeOpen ? "tree-view" : ""}`}>
      <DndProvider backend={HTML5Backend}>
        <UICustomDragLayer />
        <BuilderControlSideBar className="ui">
          <div className="component-tab">
            <Tabs
              fill
              activeKey={activedTabKey}
              id="controlled-tab"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="ServiceComponents"
                title={
                  <span>
                    <FaCoins size="14" />
                    <span className="tab-name">Service</span>
                  </span>
                }
              >
                <ServiceComponentsTab
                  trdUseYn={CommonUtils.getAppConfig(workspace, "trdUseYn")}
                />
              </Tab>
              <Tab
                eventKey="BasicComponents"
                title={
                  <span>
                    <FaLayerGroup size="14" />
                    <span className="tab-name">Components</span>
                  </span>
                }
              >
                <BasicComponentsTab
                  trdUseYn={CommonUtils.getAppConfig(workspace, "trdUseYn")}
                />
              </Tab>
              {/* 랜더링 할때 사후렌더링이 되는 경우 removeEventListener로 인한 오류가 발생함 */}
              {StringUtils.equalsIgnoreCase(
                CommonUtils.getAppConfig(workspace, "trdUseYn"),
                "Y"
              ) && (
                <Tab
                  eventKey="DataElement"
                  title={
                    <span>
                      <FaBook size="14" />
                      <span className="tab-name" style={{ paddingLeft: "2px" }}>
                        Model
                      </span>
                    </span>
                  }
                >
                  <ElementComponentsTab
                    trdUseYn={CommonUtils.getAppConfig(workspace, "trdUseYn")}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
          <div className="ui-tree-tab">
            {StringUtils.equalsIgnoreCase(
              window.location.pathname,
              Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
            ) &&
              output.page && <UITree />}
          </div>
        </BuilderControlSideBar>
        <div className="editor-wrapper">
          <div id="editPanel" className={`edit-panel ${theme} `}>
            {!ObjectUtils.isEmpty(output) ? (
              <editorComponentRef.current tabType="E" />
            ) : (
              ""
            )}
          </div>
        </div>
        <FloatingPropertyTab />
      </DndProvider>
    </div>
  );
};
export default UIBuilderMain;
