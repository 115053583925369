import React, { createRef } from "react";
import GridLayoutTemplate from "components/builder/ui/editor/theme/common/template/GridLayoutTemplate";

import {
  StringUtils,
  ObjectUtils,
  ArrayUtils,
} from "components/common/utils/CommonUtils";
import { Button } from "react-bootstrap";
import { FaMinus } from "react-icons/fa";

import Modal from "components/common/modal/UModal";
import { TemplateCheckbox } from "components/builder/ui/editor/theme/common/UIComponentStyle";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import Popup from "components/common/Popup";

class MobileGridLayoutTemplate extends GridLayoutTemplate {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: {
        gridId: "",
        // dataModelId: this.defaultValues.dataModelId || "",
        dataModelId: this.props.dataModelId || "",
        headerTitle: this.defaultValues.headerTitle || false,
        gridDisplayMode: "D",
        containButtons: !ArrayUtils.isEmpty(this.defaultValues.buttons),
      },
      fieldCol: [
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          headerAlign: "center",
        },
        // { field: "compId", headerName: "compId", hide: true },
      ],
      gridCol: [
        {
          field: "remove",
          headerName: "삭제",
          width: 50,
          sortable: false,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <Button
                size="sm"
                onClick={(e) => this.onDelete(e, params)}
                className="btn btn-outline-secondary btn-sm btn-remove"
                style={{
                  padding: "0px 3px 0px 3px",
                }}
              >
                <FaMinus />
              </Button>
            );
          },
        },
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          sortable: false,
          headerAlign: "center",
        },
        // { field: "compId", headerName: "compId", hide: true },
      ],
      checkedField: [],
      fieldList: [],
      gridCells: [],
    };

    this.createLayout = this.createLayout.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getfieldList = this.getfieldList.bind(this);
    this.onDelete = (e, params) => {
      let compId = params.compId;
      let gridCells = [...this.state.gridCells];
      if (!ArrayUtils.isEmpty(gridCells)) {
        let sameIndex;
        gridCells.map((item, index) => {
          if (compId === item.compId) {
            sameIndex = index;
          }
        });

        if (!ObjectUtils.isEmpty(sameIndex)) {
          gridCells.splice(sameIndex, 1);
          this.setState({ gridCells: gridCells });
        }
      }
    };
    this.onChangeDisplayMode = this.onChangeDisplayMode.bind(this);
    this.buttonCheckBox = createRef();
  }

  /**
   * Create Layout
   * @param {Event} event
   * @returns
   */
  createLayout = (event) => {
    /* dataModel validation */

    if (!this.validation()) return false;

    //grid
    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);

    /**
     *  모바일 GridOptions 셋팅
     *  하단 Grid에서 변경시 즉시반영되지 않기에 여기서 셋팅
     *  grid/grid db 옵션 확인
     */
    newGrid.propertyValue.gridOptions = newGrid.propertyValue.mobileGridOptions;
    // let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    delete newGrid.propertyValue.gridColumns; //default column option 삭제

    newGrid.propertyValue.gridOptions.gridId = this.state.inputValue.gridId;
    newGrid.propertyValue.gridOptions.dataModelEntityId =
      this.state.inputValue.dataModelEntityId;
    newGrid.propertyValue.gridOptions.dataModelInfo =
      this.state.inputValue.dataModelInfo;

    //엔티티타입, 파라미터 리스트 추가
    if (this.state.inputValue.entityType)
      newGrid.propertyValue.gridOptions.entityType =
        this.state.inputValue.entityType;
    if (this.state.inputValue.procedureName)
      newGrid.propertyValue.gridOptions.procedureName =
        this.state.inputValue.procedureName;
    if (this.state.inputValue.insertOption)
      newGrid.propertyValue.gridOptions.insertOption =
        this.state.inputValue.insertOption;

    //Grid 유형
    newGrid.propertyValue.gridOptions.displayMode =
      this.state.inputValue.gridDisplayMode;
    //title 추가
    if (this.state.inputValue.headerTitle === true) {
      newGrid.propertyValue.gridOptions.title = "Grid Title";
    }
    //버튼 영역포함
    let defaultAdditionalOptions =
      newGrid.propertyValue.gridOptions.additionalOptions || {};

    defaultAdditionalOptions.toolbarOptions = {
      ...(defaultAdditionalOptions.toolbarOptions || {}),
      ...{
        buttons: this.state.inputValue.containButtons
          ? this.defaultValues.buttons
          : [],
      },
    };
    newGrid.propertyValue.gridOptions.additionalOptions =
      defaultAdditionalOptions;

    // let _this = this;
    let gridCells = [...this.state.gridCells];
    if (ArrayUtils.isEmpty(gridCells)) {
      const compId = StringUtils.getUuid();

      let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
      let gridColumOption = { ...newGrid.propertyValue.gridColumns };
      let newCol = Object.assign(gridColumOption, {
        compId: compId,
        name: "New-" + compId,
        gridId: this.state.inputValue.gridId,
        componentClass: "grid/GridHeader",
      });

      gridCells = new Array(newCol);
    } else {
      gridCells.map((item, index) => {
        item.gridId = this.state.inputValue.gridId;
      });
    }

    newGrid.propertyValue.gridOptions.columns = gridCells;
    this.callbackFnc.call(this, newGrid);
    Popup.close();
  };

  /**
   * Title, button 등이 포함된 Grid layout을 생성
   * @returns
   */
  renderLayoutTemplatePop = () => {
    return (
      <Modal>
        <Modal.Header title="Grid Layout Settings" />
        <Modal.Body>
          {this.renderSelectDataModel()}
          {/* <div className="row p-10">
            <div className="col2">
              <Form.Label>Grid 유형</Form.Label>
              <InputGroup>
                <USelectbox
                  type="common"
                  id="displayMode"
                  items={[
                    { id: "D", text: "조회용 Grid" },
                    { id: "E", text: "입력용 Grid" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.inputValue.gridDisplayMode,
                    "D"
                  )}
                  onChange={(e) => this.onChangeDisplayMode(e)}
                />
              </InputGroup>
            </div>
          </div> */}
          <div className="row p-10">
            <div className="col2">
              <TemplateCheckbox
                type="checkbox"
                name="headerTitle"
                className="xmall-input"
                defaultChecked={this.state.inputValue.headerTitle}
                onChange={this.onChangeCheckbox}
              />
              Include Grid title
            </div>
          </div>
          {/* {!ObjectUtils.isEmpty(this.defaultValues.buttons) && !ObjectUtils.isEmpty(this.defaultValues.buttons.length > 0) ? (
            <div className="row p-10">
              <div className="col2">
                <TemplateCheckbox
                  ref={this.buttonCheckBox}
                  type="checkbox"
                  name="containButtons"
                  className="xmall-input"
                  defaultChecked={this.state.inputValue.containButtons}
                  onChange={this.onChangeCheckbox}
                />
                Grid Button 영역 포함
              </div>
            </div>
          ) : (
            ""
          )} */}
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  };
}
export default MobileGridLayoutTemplate;
