import ActionType from "components/builder/BuilderActionType";

/**
 * 브레이크 포인트 초기화
 * @param {*} breakpoint
 * @returns
 */
export const initBreakpoint = (breakpoint) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.INIT,
  };
};

/**
 * 브레이크 포인트 추가
 * @param {{serviceId:Number,processName:String,compId:String,type:String, processType:String}} payload
 * @returns
 */
export const addBreakpoint = (payload) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.ADD,
    payload: payload,
  };
};

/**
 * 싱글 브레이크 포인트 상태 업데이트
 * @param {*} breakpoint
 * @returns
 */
export const updateBreakpoint = (breakpoint) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.UPDATE,
    payload: breakpoint,
  };
};

/**
 * 브레이크 포인트 삭제
 * @param {*} breakpoint
 * @returns
 */
export const deleteBreakpoint = (breakpoint) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.DELETE,
    payload: breakpoint,
  };
};

/**
 * 브레이크 포인트 상태 업데이트
 * @param {Array} breakpointList
 * @returns
 */
export const setBreakpoint = (breakpointList) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.SET,
    payload: breakpointList,
  };
};
/**
 * 디버스 브레이크 포인트 타입 설정
 * @param {*} data
 * @returns
 */
export const setWFBreakpointType = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.BREAK_POINT.SET_TYPE,
    payload: data,
  };
};

/**
 * 디버깅 초기화
 * @returns
 */
export const initDebug = () => {
  return {
    type: ActionType.WORKFLOW.DEBUG.INIT,
  };
};

/**
 * 통신 중 여부
 * @param {*} data
 * @returns
 */
export const setWFInCommunication = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.SET_IN_COMMUNICATION,
    payload: data,
  };
};

/**
 * 디버그 트레이스 설정
 * @param {*} data
 * @returns
 */
export const setWFTrace = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.SET_TRACE,
    payload: data,
  };
};

/**
 * 디버그 프로세스( 현 위치 ) 설정
 * @param {*} data
 * @returns
 */
export const setWFProcess = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.SET_PROCESS,
    payload: data,
  };
};
/**
 * 현재 디버깅 중인지 설정
 * @returns
 */
export const setWFIsDebugging = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.SET_IS_DEBUGGING,
    payload: data,
  };
};
/**
 * 현재 디버깅 중인지 설정
 * @returns
 */
export const setWFErrType = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.SET_ERROR_TYPE,
    payload: data,
  };
};

/**
 * 백엔드 작업중인 트레이스 업데이트
 * @returns
 */
export const updateTrace = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.UPDATE_TRACE,
    payload: data,
  };
};
/**
 * 백엔드 작업중인 트레이스 초기화
 * @returns
 */
export const initTrace = (data) => {
  return {
    type: ActionType.WORKFLOW.DEBUG.INIT_TRACE,
    payload: data,
  };
};
