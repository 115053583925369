import { useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";

// import PropertiesTab from "components/builder/ui/PropertiesTab";
import BuilderLeftMenuPanel from "components/builder/mobile/BuilderLeftMenuPanel";
import MobileEditor from "components/builder/mobile/editor/MobileEditor";
import MobileFloatingTab from "components/builder/mobile/MobileFloatingTab";
import {
  setIsMobileEditor,
  setMobileApp,
} from "components/builder/mobile/reducers/MobileAction";
import UICustomDragLayer from "components/builder/ui/editor/UICustomDragLayer";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import "css/builder/main.css";
import "css/builder/runtime.css";
import "css/builder/settings.css";
import "css/builder/sirius/main.css";
import "css/mobile/mobile.css";
import { useState } from "react";
import MobileAppService from "services/mobile/MobileAppService";
import { MobileAppContext } from ".";

const loadedCSSFiles = new Set();

const MobileBuilderMain = (props) => {
  const dispatch = useDispatch();
  const output = useSelector((state) => state.outputUI.output);
  let theme = CommonUtils.getTheme();
  const workspace = useSelector((state) => state.workspace);
  const mobile = useSelector((state) => state.mobile);
  const { checkMobileWorkspace } = useContext(MobileAppContext);
  const [appLayout, setAppLayout] = useState(null);

  useEffect(() => {
    // checkMobileWorkspace()
    //   .then(checkMobileLayout)
    //   .then((flag) => {
    //     if (flag) {
    //       //레이아웃이 확인됨
    //       dispatch(setIsMobileEditor(true));
    //       importDynamicStyle();
    //     }
    //   });

    dispatch(setIsMobileEditor(true));
    importDynamicStyle();
  }, []);

  const checkMobileLayout = () => {
    return new Promise(async (resolve, reject) => {
      const result = await MobileAppService.getMobilApp(mobile.workspace);
      if (result.isError) {
        reject(result.message);
      } else if (result.data) {
        setAppLayout(result.data.layout);
        dispatch(setMobileApp(result.data));
        resolve(true);
      }
    });
  };

  /**
   * 테마별 css 임포트
   */
  const importDynamicStyle = () => {
    let theme = CommonUtils.getTheme();
    // 아직 로드되지 않은 파일들만 필터링
    if (theme !== "common") {
      const requireCSS = require.context("css/builder/sirius", false, /\.css$/);
      const cssFileNameSet = new Set();
      requireCSS.keys().forEach((file) => {
        cssFileNameSet.add(file.replace("./", "").split("/").pop());
      });
      const cssFileNameList = Array.from(cssFileNameSet);
      const filesToLoad = cssFileNameList.filter(
        (fileName) => !loadedCSSFiles.has(fileName)
      );
      if (!ArrayUtils.isEmpty(filesToLoad)) {
        Promise.all(
          filesToLoad.map((fileName) =>
            import(`css/builder/sirius/${fileName}`).then(() => {
              loadedCSSFiles.add(fileName); // 로드 완료된 파일은 Set에 추가
            })
          )
        )
          .then(() => {
            console.log(`Additional CSS for ${theme} loaded`);
          })
          .catch((err) => {
            console.error(`Failed to load ${theme} CSS`, err);
          });
      }
    }
  };

  // console.log("[Check re-rendering] ", "MobileBuilderMain has been re-rendered!!!");
  return (
    <div className="mobile-builder">
      <DndProvider backend={HTML5Backend}>
        <BuilderLeftMenuPanel />
        <div id="editPanel" className={`edit-panel mobile ${theme}`}>
          {!ObjectUtils.isEmpty(output) ? (
            <MobileEditor appLayout={appLayout} tabType="E" />
          ) : (
            ""
          )}
        </div>
        <UICustomDragLayer />
        <MobileFloatingTab />
      </DndProvider>
    </div>
  );
};
export default MobileBuilderMain;
