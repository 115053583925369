import Popup from "components/common/Popup";
import ViewportEditPopup from "page/popup/mobile/ViewportEditPopup";
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Enums from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";

import ViewportService from "services/mobile/ViewportService";

import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import UIEditorSource from "components/builder/ui/editor/UIEditorSource";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";

import classNames from "classnames";
import MobileCommandButton from "components/builder/mobile/MobileCommandButton";
import Page from "components/builder/mobile/mobileComponents/layout/Page";
import { MobileEditorPanel } from "components/builder/mobile/mobileComponents/MobileComponentStyle";
import {
  setActiveFilter,
  setAppLayoutList,
  setDeviceOs,
  setMobileScreenHeight,
  setMobileScreenWidth,
  setViewportDirection,
} from "components/builder/mobile/reducers/MobileAction";
import Message from "components/common/Message";
import produce from "immer";
import MobileBuilderPreview from "page/builder/mobile/MobileBuilderPreview";
import { useEffect, useRef } from "react";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import MobileDevice from "./MobileDevice";
import MobileEditorNodeRender from "./MobileEditorNodeRender";
import MobileFilterPopup from "./MobileFilterPopup";
import MobileRemoteControl from "./MobileRemoteControl";
import MobileEditorFooter from "./render/MobileEditorFooter";

const MobileEditor = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const { showPropTab, viewportDirection, buttonShow, filterProgram } =
    useSelector((state) => state.mobile);
  const [tabType, setTabType] = useState(
    StringUtils.defaultString(props.tabType, "E")
  );

  const [pageDragOver, setPageDragOver] = useState(false);
  const [viewportList, setViewportList] = useState([]);
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [selectViewportId, setSelectViewportId] = useState();
  // const output = useSelector((state) => state.outputUI);
  const statOutput = useSelector((state) => state.outputUI.output);
  const mobileInfo = useSelector((state) => state.mobile);
  let theme = CommonUtils.getTheme();

  const footerChildCnt =
    ObjectUtils.isEmpty(statOutput["page"]["footer"]) ||
    ArrayUtils.isEmpty(statOutput["page"]["footer"]["child"])
      ? 0
      : statOutput["page"]["footer"]["child"].length;

  useEffect(() => {
    getViewportList();
  }, []);

  useEffect(() => {
    if (
      statOutput.page.propertyValue?.programType ===
      Enums.MobileProgramType.TOP_NAV
    ) {
      setScreenHeight(mobileInfo.screenHeight);
      // } else {
      //   selectViewPort([]);
    }
  }, [mobileInfo, statOutput]);

  const getViewportList = () => {
    let params = { appId: workspace.appId };
    ViewportService.getViewportList(params, (res) => {
      setViewportList(res.data);
      selectViewPort(res.data);
    });
  };
  /**
   * 선택된 viewport 찾기
   * 뷰포트에서 수정된 값을 바로 반영하기 위한 로직
   * @param {*} paramList
   */
  const selectViewPort = (paramList) => {
    let vpList = [...paramList];
    if (ArrayUtils.isEmpty(vpList) && !ArrayUtils.isEmpty(viewportList)) {
      vpList = viewportList;
    }
    for (const viewport of vpList) {
      if (
        ObjectUtils.isEmpty(selectViewportId) ||
        StringUtils.equalsIgnoreType(viewport.viewportId, selectViewportId)
      ) {
        setSelectViewportId(viewport.viewportId);
        setScreenWidth(viewport.screenWidth ? viewport.screenWidth : 0);
        dispatch(
          setMobileScreenWidth(viewport.screenWidth ? viewport.screenWidth : 0)
        );

        setScreenHeight(viewport.screenHeight ? viewport.screenHeight : 0);
        dispatch(
          setMobileScreenHeight(
            viewport.screenHeight ? viewport.screenHeight : 0
          )
        );
        break;
      }
    }
  };

  const [localSettings, setLocalSettings] = useState({
    lineDisplay: true,
    dropZoneDisplay: true,
    titleDisplay: true,
    toolbarDisplay: true,
    watermarkDisplay: true,
  });

  const componentContext = useContext(AppContext).component;

  /* template 용 표준 base components - e.g) page, form, row, col... */
  const templateComponents =
    UITemplateHelper.getTemplateComponents(componentContext);
  /*template 용 표준 base components - Page component */
  const pageTemplate = UITemplateHelper.getTemplate(
    templateComponents,
    Enums.ComponentType.PAGE
  );

  const pageProgramId =
    ObjectUtils.isEmpty(statOutput["page"]) ||
    ObjectUtils.isEmpty(statOutput["page"]["propertyValue"])
      ? ""
      : statOutput["page"]["propertyValue"]["programId"];

  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );

  const components = useContext(AppContext).component.getComponentList("B");
  const pageCompnent = useContext(AppContext).component.getPageComponent();

  const editorPanelRef = useRef();

  //ESC 및 DELETE 키다운 이벤트 적용
  useEffect(() => {
    // editorPanelRef.current
    document.addEventListener("keydown", onKeyDownEsc);
    return () => {
      document.removeEventListener("keydown", onKeyDownEsc);
    };
  }, [activedComponent]);

  /**
   * change editor local settings
   * @param {*} key
   * @param {*} value
   */
  const changeSettings = (key, value) => {
    setLocalSettings({ ...localSettings, ...{ [key]: value } });
  };

  /**
   * output을 update 한다.
   * @param {*} changedOutput
   */
  const updateOutputSource = (changedOutput) => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      const activedComponentNode = JsonUtils.findNode(
        changedOutput,
        "compId",
        activedComponent.compId
      );
      dispatch(actions.updateActivateProps(activedComponentNode.propertyValue));
      dispatch(actions.updateOutput(changedOutput));
    }
  };

  /**
   * Drag & drap handle
   */
  const handleDrop = useCallback(
    (dropZone, item, templateComponents, output) => {
      dropZone.workspace = workspace;
      // 모바일 default옵션 분기처리
      // react 불변성 때문에 produce 사용
      const newItem = produce(item, (draft) => {
        draft.isMobile = true;
        // draft.programId = pageProgramId;
      });
      //mobile filter
      if (dropZone.rootLocation === Enums.ComponentType.FILTER) {
        //filter영역 drop시 상위의 programId가 없어서 넣어줌
        //formId 자동 생성을 위한 로직
        const filter = output.page.filter;
        const updatedFilter = produce(filter, (draftFilter) => {
          draftFilter.page.propertyValue = draftFilter.propertyValue || {};
          draftFilter.page.propertyValue.programId = pageProgramId;
        });

        Event.handleDrop(
          dropZone,
          newItem,
          templateComponents,
          updatedFilter,
          dispatch
        );
      } else {
        Event.handleDrop(
          dropZone,
          newItem,
          templateComponents,
          output,
          dispatch
        );
      }
    },
    [dispatch]
  );

  /**
   * Page click
   * @param {*} e
   */
  const onClick = (e) => {
    Event.handlePageClick(
      e,
      dispatch,
      activedComponent,
      statOutput,
      pageTemplate
    );
  };

  /**
   * Page Editor class
   * @returns
   */
  const getPageEditorClass = () => {
    let className = "page";
    Object.keys(localSettings).forEach(function (option) {
      if (localSettings[option] === false) {
        className += " none-" + option;
      }
    });
    if (
      statOutput.page.propertyValue?.programType ===
      Enums.MobileProgramType.POPUP_PROGRAM
    ) {
      className += " popup-page";
    }

    return className;
  };

  /**
   * Page Penal Class
   * @returns
   */
  const getPagePanelClass = () => {
    let pageClass = "page-container";

    if (activedComponent.componentType === Enums.ComponentType.PAGE)
      pageClass += " active";

    if (!ObjectUtils.isEmpty(statOutput.page.propertyValue)) {
      /* popup */
      if (
        statOutput.page.propertyValue.programType ===
        Enums.MobileProgramType.POPUP_PROGRAM
      ) {
        const size = ObjectUtils.isEmpty(
          statOutput.page.propertyValue.popupOptions
        )
          ? "xl"
          : StringUtils.defaultString(
              statOutput.page.propertyValue.popupOptions.size,
              "xl"
            );
        pageClass +=
          " popup-container " + (size !== "fl" ? " modal-" + size : "");
        /* dashboard */
      } else {
        pageClass +=
          " page-type-" +
          StringUtils.defaultString(
            statOutput.page.propertyValue.programType,
            Enums.ProgramType.MENU_PROGRAM
          );
      }
    }

    if (StringUtils.isEmpty(filterProgram) && pageDragOver === true) {
      pageClass += " dragover";
    }
    return pageClass;
  };

  const pagePropertyValue = statOutput.page.propertyValue || {};
  const programType = StringUtils.defaultString(
    pagePropertyValue.programType,
    "M"
  );

  /**
   * 키이벤트에서 사용하는,
   * 선택된 노드보다 상위로 포커싱 이동하는 로직
   * @param {*} e
   * @param {*} component
   */
  const _moveToParentsNode = (e, component) => {
    const compId = component?.compId;

    let outputPage = !StringUtils.isEmpty(filterProgram)
      ? statOutput.page.filter.page
      : statOutput.page;

    if (compId) {
      const parentNode = JsonUtils.findParentNodeByOnlyCompId(
        outputPage,
        compId
      );
      if (parentNode)
        Event.clickComponent(
          e,
          parentNode,
          components,
          activedComponent,
          dispatch
        );
    }
  };

  /**
   * 키다운 이벤트 전체
   * ESC 눌렀을 때 상위노드로 이동하는 로직
   * DELETE 눌렀을때 해당 노드 삭제하는 로직
   * @param {*} e
   */
  const onKeyDownEsc = (e) => {
    if (
      (editorPanelRef.current &&
        editorPanelRef.current.contains(document.activeElement)) ||
      document.activeElement === document.body
    ) {
      if (e && e.keyCode === 27) {
        if (!ObjectUtils.isEmpty(activedComponent)) {
          const { type } = activedComponent;
          let compId = activedComponent.compId;
          if (
            type &&
            StringUtils.includes(type, [
              Enums.ComponentType.GRID_CELL,
              Enums.ComponentType.GRID_HEADER,
              Enums.ComponentType.LIST_CELL,
              Enums.ComponentType.LIST_HEADER,
            ])
          ) {
            //그리드 부모 찾아 주기
            compId = compId.split("-")[1];
          }
          _moveToParentsNode(e, { compId });
        }
      } else if (StringUtils.equalsIgnoreCase(e.key, "delete")) {
        Event.removeNode(
          e,
          activedComponent,
          statOutput,
          components,
          pageCompnent,
          dispatch
        );
        _moveToParentsNode(e, activedComponent);
      }
    }
  };

  /**
   * 뷰포트 설정하는 함수
   * @param {*} e
   */
  const onChangeView = (e) => {
    const viewport = viewportList.find((v) =>
      StringUtils.equalsIgnoreType(v.viewportId, e.target.value)
    );
    setSelectViewportId(viewport.viewportId);
    setScreenWidth(viewport.screenWidth);
    setScreenHeight(viewport.screenHeight);
    dispatch(setMobileScreenWidth(viewport.screenWidth));
    dispatch(setMobileScreenHeight(viewport.screenHeight));
    //잠깐 String으로 OS 타입 고정 -> 향후 수정예정
    const deviceNm = String(viewport.deviceNm).toLowerCase();
    let os = Enums.MobileOsType.ANDROID;
    if (
      deviceNm.indexOf("iphone") > -1 ||
      deviceNm.indexOf("ipad") > -1 ||
      deviceNm.indexOf("아이폰") > -1 ||
      deviceNm.indexOf("아이패드") > -1
    ) {
      os = Enums.MobileOsType.IOS;
    }
    dispatch(setDeviceOs(os));
  };

  /**
   * 뷰포트 회전하는 로직
   * @returns
   */
  const onRotation = () => {
    if (
      !StringUtils.isEmpty(programType) &&
      programType === Enums.MobileProgramType.TOP_NAV
    ) {
      Message.alert(
        "상단 네비게이션은 돌릴 수 없습니다.",
        Enums.MessageType.WARN
      );
      return;
    }
    let direction = "";
    if (
      StringUtils.equalsIgnoreCase(
        viewportDirection,
        Enums.MobileDirection.VERTICAL
      )
    ) {
      direction = Enums.MobileDirection.HORIZONTAL;
    } else {
      direction = Enums.MobileDirection.VERTICAL;
    }
    //방향만 설정하고 상세는 조건에 따라 분기로 처리한다.
    dispatch(setViewportDirection(direction));
  };

  const onClosePopup = () => {
    getViewportList();
  };

  const openViewportEditPopup = () => {
    Popup.open(
      <ViewportEditPopup
        appId={workspace.appId}
        callbackFnc={(e, d) => {
          Popup.close();
        }}
      />,
      {
        onCloseCallback: onClosePopup,
        style: { content: { width: "700px" } },
      }
    );
  };

  /**
   * close filter popup
   */
  const closeFilterPopup = () => {
    dispatch(setActiveFilter(null));
  };
  /**
   * 뷰포트 스타일
   * 뷰포트 방향에 따라 다르게 설정
   */
  const viewportStyle = StringUtils.equalsIgnoreCase(
    Enums.MobileDirection.VERTICAL,
    viewportDirection
  )
    ? { width: screenWidth, height: screenHeight }
    : { width: screenHeight, height: screenWidth };

  /**
   * page에 drag over 되었을 경우 맨위와 맨 마지막 drop-zone을 활성화 시킨다.
   * @param {*} e
   */
  const onPageDragOver = (e) => {
    setPageDragOver(true);
  };

  /**
   * page에 drag leave 되었을 경우 맨위와 맨 마지막 drop-zone을 원복 시킨다.
   * @param {*} e
   */
  const onPageDragLeave = (e) => {
    setPageDragOver(false);
  };

  /**
   * page에 drop 되었을 경우 맨위와 맨 마지막 drop-zone을 원복 시킨다.
   * @param {*} e
   */
  const onPageDrop = (e) => {
    e.preventDefault();
    setPageDragOver(false); // 드롭 후 색상 복원
  };

  return (
    <React.Fragment>
      <MobileCommandButton
        setTabType={setTabType}
        tabType={tabType}
        onChangeView={onChangeView}
        viewportList={viewportList}
        onRotation={onRotation}
        openViewportEditPopup={openViewportEditPopup}
      />
      {tabType === "E" ? (
        <React.Fragment>
          {viewportStyle.width && viewportStyle.height ? (
            <MobileDevice style={viewportStyle}>
              <MobileEditorPanel
                className={classNames(
                  "mobile-edit-panel-area",
                  getPagePanelClass()
                )}
                id="editPage"
                onClick={onClick}
                // onKeyDown={onKeyDownEsc}
                // tabIndex={"0"}
                // style={{ outline: "none" }}
                ref={editorPanelRef}
                onDragOver={onPageDragOver}
                onDragLeave={onPageDragLeave}
                onDrop={onPageDrop}
              >
                <Page
                  event="renderEditor"
                  componentInfo={statOutput["page"]}
                  filterProgram={filterProgram}
                >
                  <div
                    id="editCanvas"
                    className={getPageEditorClass()}
                    onMouseOver={Event.handlePageMouseOver}
                    onMouseOut={Event.handlePageMouseOut}
                    style={{
                      maxHeight:
                        pagePropertyValue?.popupOptions?.displayFooter === false
                          ? "calc(100vh - 365px)"
                          : "calc(100vh - 415px)",
                    }}
                  >
                    <MobileEditorNodeRender
                      pageChild={statOutput["page"]["child"]}
                      handleDrop={handleDrop}
                      templateComponents={templateComponents}
                      mobileInfo={mobileInfo}
                    />
                    {!StringUtils.isEmpty(filterProgram) && (
                      <MobileFilterPopup
                        isOpen={true}
                        onClose={closeFilterPopup}
                      >
                        <MobileEditorNodeRender
                          pageChild={
                            statOutput["page"]["filter"]["page"]["child"]
                          }
                          handleDrop={handleDrop}
                          templateComponents={templateComponents}
                          mobileInfo={mobileInfo}
                          isMobileFilter={true}
                        />
                      </MobileFilterPopup>
                    )}
                  </div>
                </Page>
                {footerChildCnt > 0 ? (
                  <MobileEditorFooter
                    onDrop={handleDrop}
                    pagePropertyValue={pagePropertyValue}
                    templateComponents={templateComponents}
                    child={statOutput["page"]["footer"]["child"]}
                  />
                ) : (
                  ""
                )}
              </MobileEditorPanel>
            </MobileDevice>
          ) : (
            <></>
          )}

          <MobileRemoteControl />
        </React.Fragment>
      ) : tabType === "S" ? (
        <UIEditorSource
          output={statOutput}
          updateOutputSource={updateOutputSource}
        />
      ) : (
        <MobileBuilderPreview
          output={statOutput}
          style={viewportStyle}
          updateOutputSource={updateOutputSource}
        />
      )}
    </React.Fragment>
  );
};
export default React.memo(MobileEditor);
