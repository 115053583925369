import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { BiHide, BiShow } from "react-icons/bi";
import { IoDocumentsOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowDeviceButton,
  setShowPropertiesTab,
  setViewportScale,
} from "../reducers/MobileAction";
import { FiMaximize } from "react-icons/fi";

function MobileRemoteControl() {
  const { showPropTab, viewportDirection, buttonShow, scale } = useSelector(
    (state) => state.mobile
  );
  const dispatch = useDispatch();

  const changeScale = (_scale) => {
    dispatch(setViewportScale(_scale));
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 30,
        right: 30,
        border: "1px solid lightgray",
        background: "white",
        borderRadius: "5px",
        padding: "15px 15px",
        display: "grid",
        gap: "3px",
        boxShadow: "1px 1px 3px lightgray",
      }}
    >
      <InputGroup>
        <Button size="sm" variant="secondary">
          Scale
        </Button>
        <Form.Control
          type="number"
          style={{ width: "90px" }}
          value={scale}
          min={1}
          max={200}
          onChange={(e) => changeScale(Number(e.target.value))}
        />
      </InputGroup>

      <ButtonGroup style={{ display: "flex", gap: "3px" }}>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={(e) => changeScale(scale - 10)}
        >
          <AiOutlineZoomOut size={20} />
        </Button>
        <Button size="sm" variant="warning" onClick={(e) => changeScale(100)}>
          <FiMaximize size={20} />
        </Button>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={(e) => changeScale(scale + 10)}
        >
          <AiOutlineZoomIn size={20} />
        </Button>
      </ButtonGroup>
      <Button
        variant="success"
        size="sm"
        onClick={(e) => {
          dispatch(setShowPropertiesTab(!showPropTab));
        }}
        style={{ fontSize: "smaller" }}
      >
        <IoDocumentsOutline size={14} /> Property Tab
      </Button>
      <Button
        variant="secondary"
        size="sm"
        onClick={(e) => dispatch(setShowDeviceButton(!buttonShow))}
        style={{ fontSize: "smaller" }}
      >
        {buttonShow ? <BiHide size={14} /> : <BiShow size={14} />}
        Device Button
      </Button>
    </div>
  );
}

export default MobileRemoteControl;
