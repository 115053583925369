import produce from "immer";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";

import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";

import loadable from "@loadable/component";
import * as Enums from "components/builder/BuilderEnum";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UIEditorDNDContainer, {
  UIViewerDNDContainer,
} from "components/builder/ui/editor/UIEditorDNDContainer";

const style = {};
const UIEditorStep = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const [activedStep, setActivedStep] = React.useState("");

  const ref = useRef(null);
  const componentRef = useRef();
  let theme = CommonUtils.getTheme();

  if (!componentRef.current) {
    componentRef.current = loadable(() =>
      import(
        `components/builder/ui/editor/theme/${theme}/layout/StepWizardContainer`
      )
    );
  }

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    componentType: item.componentType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const onStepChange = (event, stepProps) => {
    setActivedStep(stepProps.id);
  };

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  //default active step 지정
  let defaultActiveKey;
  if (!StringUtils.isEmpty(activedStep)) {
    defaultActiveKey = activedStep;
  } else if (!ArrayUtils.isEmpty(item.child)) {
    let activeStepIndex = 0;
    if (
      !ObjectUtils.isEmpty(item.propertyValue) &&
      !isNaN(item.propertyValue.activeStep)
    ) {
      activeStepIndex = item.propertyValue.activeStep - 1;
    }
    if (activeStepIndex < 0 || activeStepIndex > item.child.length) {
      activeStepIndex = 0;
    }
    defaultActiveKey = JsonUtils.defaultString(
      item.child[activeStepIndex].propertyValue,
      "id"
    );
  }
  const DNDContainer =
    JsonUtils.defaultString(item.propertyValue, "stepWizardType") === "S"
      ? UIViewerDNDContainer
      : props.dndContainer
      ? props.dndContainer
      : UIEditorDNDContainer;

  return (
    <div ref={ref} className="whole-wrap">
      <componentRef.current
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
        onStepChange={onStepChange}
        activedStep={defaultActiveKey}
      >
        {!ArrayUtils.isEmpty(item.child)
          ? item.child.map((step, stepindex) => {
              let stepProps = step.propertyValue || {};
              let stepId = StringUtils.defaultString(stepProps.id, step.compId);
              return (
                <StepPanel
                  id={stepId}
                  key={stepId}
                  active={stepId === defaultActiveKey && true}
                >
                  {!ArrayUtils.isEmpty(step.child)
                    ? step.child.map((child, index) => {
                        const currentPath = `${path}-${stepindex}-${index}`;
                        return (
                          <React.Fragment key={index}>
                            <DNDContainer
                              data={{
                                location: Enums.ComponentType.CONTAINER,
                                rootLocation: rootLocation,
                                path: currentPath,
                                childrenCount: step.child.length,
                                propertyValue: data.propertyValue,
                                compId: data.compId,
                              }}
                              onDrop={handleDrop}
                              templateComponents={templateComponents}
                            />
                            <UIEditorChildRender
                              key={child.compId}
                              data={child}
                              handleDrop={handleDrop}
                              templateComponents={templateComponents}
                              path={currentPath}
                              dndContainer={DNDContainer}
                              rootLocation={rootLocation}
                            />
                          </React.Fragment>
                        );
                      })
                    : ""}
                  <DNDContainer
                    data={{
                      location: Enums.ComponentType.CONTAINER,
                      rootLocation: rootLocation,
                      path: `${path}-${stepindex}-${
                        ArrayUtils.isEmpty(step.child) ? 0 : step.child.length
                      }`,
                      childrenCount: ArrayUtils.isEmpty(step.child)
                        ? 0
                        : step.child.length,
                      propertyValue: data.propertyValue,
                      compId: data.compId,
                    }}
                    onDrop={handleDrop}
                    templateComponents={templateComponents}
                    className={`${
                      ArrayUtils.isEmpty(step.child)
                        ? "horizontal-drag-full"
                        : ""
                    }`}
                  />
                  {JsonUtils.defaultString(
                    item.propertyValue,
                    "stepWizardType"
                  ) === "S" ? (
                    <div className="editor-empty-steppanel">
                      {StringUtils.defaultString(
                        stepProps.programId,
                        stepProps.url
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </StepPanel>
              );
            })
          : ""}
      </componentRef.current>
    </div>
  );
};

export default React.memo(UIEditorStep);

const StepPanel = (props) => {
  const { children, id, active, ...other } = props;

  return (
    <div
      className="editor-step-panel"
      hidden={active !== true}
      id={id}
      {...other}
    >
      {active && children}
    </div>
  );
};
