import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ObjectUtils from "components/common/utils/ObjectUtils";
import "css/mobile/layout-setting.css";
import produce from "immer";
import { MobileAppContext } from "page/builder/mobile";
import PageTemplate from "page/common/PageTemplate";
import MobileLayoutPreview from "page/mobile/appConfiguration/MobileLayoutPreview";
import MobileTopNavSetting from "page/mobile/appConfiguration/MobileTopNavSetting";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as Fa from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import MobileAppService from "services/mobile/MobileAppService";
import InitialAppConfiguraion from "./InitialAppConfiguraion";
import MobileBottomNavSetting from "./MobileBottomNavSetting";
import MobileMenuTypeSetting from "./MobileMenuTypeSetting";
import MobileThemeSetting from "./MobileThemeSetting";
import { cloneDeep } from "lodash";
import { setMobileApp } from "components/builder/mobile/reducers/MobileAction";
import ArrayUtils from "components/common/utils/ArrayUtils";

export const MobileLayoutContext = createContext({
  tnb: {},
  setTnb: () => {},
  renderTopNav: () => {},
  bnbTmpl: [],
  setBnbTmpl: () => {},
  applyColor: () => {},
  mobileAppMst: {},
  setMobileAppMst: () => {},
  saveLayout: () => {},
  tnbTmplList: [],
  mnuTmplList: [],
  mnu: {},
  setMnu: () => {},
  bnb: {},
  setBnb: () => {},
  theme: {},
  setTheme: () => {},
  themeTmpl: [],
  setThemeTmpl: () => {},
});

const AppConfiguration = () => {
  const breadcrum = [
    {
      name: "App Configuration",
      url: Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.LIST,
      active: true,
    },
  ];
  const mobile = useSelector((state) => state.mobile);
  const [bnbTmpl, setBnbTmpl] = useState([]); //하단 네비게이션 리스트
  const [tnb, setTnb] = useState({}); //선택된 top Nav
  const [mnu, setMnu] = useState({}); //선택된 메뉴타입
  const [theme, setTheme] = useState({}); //선택된 Theme
  const [bnb, setBnb] = useState({}); // 레이아웃 BNB
  const [mobileAppMst, setMobileAppMst] = useState({});
  const [tnbTmplList, setTnbTmplList] = useState([]);
  const [mnuTmplList, setMnuTmplList] = useState([]);
  const [themeTmpl, setThemeTmpl] = useState([]);

  const { checkMobileWorkspace } = useContext(MobileAppContext);
  const { initial } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * 초기 데이터 불러오기
   */
  useEffect(() => {
    checkMobileWorkspace();
    getTmplList();
  }, []);

  useEffect(() => {
    if (
      (ObjectUtils.isEmpty(mobileAppMst) ||
        mobileAppMst.appMstId !== mobile.workspace.appMstId) &&
      !ObjectUtils.isEmpty(mobile.workspace)
    ) {
      getAppLayout();
    }
  }, [mobile]);

  const getAppLayout = async () => {
    const result = await MobileAppService.getMobilApp({ ...mobile.workspace });
    if (!result.isError && result.data) {
      //최초 생성시 layout이 없기 때문에 분류로직을 태우지 않음
      if (
        ArrayUtils.isArray(result.data.layout) &&
        !ArrayUtils.isEmpty(result.data.layout)
      ) {
        const mMst = produce(result.data, (draft) => {
          draft.layout = result.data.layout.map((layout) => {
            if (layout.customContent) {
              layout.customContent = JSON.parse(layout.customContent);
            }
            return layout;
          });
        });
        setMobileAppMst(mMst);
        dispatch(setMobileApp(mMst));
        const layoutList = cloneDeep(mMst.layout);
        const top = layoutList.find((lay) => lay.layoutType === "T");
        const menu = layoutList.find((lay) => lay.layoutType === "M");
        const bottom = layoutList.find((lay) => lay.layoutType === "B");
        const theme = layoutList.find((lay) => lay.layoutType === "C");

        setTnb(top);

        if (bottom) {
          setBnb(bottom);
          if (bottom.customContent) {
            setBnbTmpl(bottom.customContent);
          }
        }
        setMnu(menu);
        setTheme(theme);
      } else {
        navigate(
          Enums.BuilderPath.MOBILE.MAIN +
            "/" +
            Enums.BuilderPath.MOBILE.INITIAL_APP_CONFIGURAION
        );
      }
    }
  };

  const getTmplList = () => {
    return new Promise((resolve, reject) => {
      LayoutSettingService.getTmplList({ layoutType: "T" }, (res) => {
        const result = res.data.map((obj) => {
          obj.presetContent = JSON.parse(obj.presetContent);
          return obj;
        });
        const tnbPrestTmpl = result.filter((tmp) => tmp.layoutType === "T");
        const menuTmpl = result.filter((tmp) => tmp.layoutType === "M");
        const themeTmpl = result.filter((tmp) => tmp.layoutType === "C");

        setTnbTmplList(tnbPrestTmpl);
        setMnuTmplList(menuTmpl);
        setThemeTmpl(themeTmpl);
      });
    });
  };

  const renderTopNav = (structure) => {
    return (
      <div className={"top-nav-template"}>
        {structure.map((item, index) => {
          const width = `${item.width}${item.unit}`;
          const Icon =
            item.buttonType !== "logo" && item.icon ? Fa[item.icon] : null;
          const isTitle = item.areaType === "title";

          const LogoUrl =
            item.areaType === "logo" && !StringUtils.isEmpty(item.logo)
              ? item.logo
              : "";
          let align = "";
          let logoStyle = {};
          if (item.areaType === "logo") {
            align = "left";
            switch (item.align) {
              case "left":
                align = "flex-start";
                break;
              case "center":
                align = "center";
                break;
              case "right":
                align = "flex-end";
                break;
              default:
                break;
            }
            logoStyle.justifyContent = align;
            logoStyle.display = "flex";
          }
          return (
            <div
              key={index}
              className="top-nav-content"
              style={{ width: width, ...logoStyle }}
            >
              {isTitle ? (
                <span>Application Title</span>
              ) : Icon ? (
                <Icon />
              ) : LogoUrl ? (
                <img
                  src={LogoUrl}
                  alt="company logo"
                  style={{ maxHeight: "45px" }}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const saveLayout = (layout) => {
    let body = {
      ...layout,
      customContent:
        (layout.customContent && typeof layout.customContent !== "string"
          ? JSON.stringify(layout.customContent)
          : layout.customContent) || null,
      appMstId: mobileAppMst.appMstId,
      tenantId: "*",
      coCd: "*",
    };
    LayoutSettingService.saveAppLayout(body, (res) => {
      //파싱
      const result = produce(res.data, (draft) => {
        draft.customContent = JSON.parse(draft.customContent);
      });
      if (layout.layoutType === "T") {
        setTnb(result);
      }
      if (layout.layoutType === "M") {
        setMnu(result);
      }
      if (layout.layoutType === "C") {
        setTheme(result);
      }
      //레이아웃에서 바꿔줘야함
      const layoutIndex = mobileAppMst.layout.findIndex(
        (lay) => lay.layoutType === res.data.layoutType
      );
      let newMApp = null;
      if (layoutIndex > -1) {
        newMApp = produce(mobileAppMst, (draft) => {
          draft.layout[layoutIndex] = result;
        });
      } else {
        newMApp = produce(mobileAppMst, (draft) => {
          draft.layout.push(result);
        });
      }
      setMobileAppMst(newMApp);
      if (!res.isError) {
        Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
      }
    });
  };

  const contextValue = {
    tnb,
    setTnb,
    renderTopNav,
    saveLayout,
    bnbTmpl,
    setBnbTmpl,
    mobileAppMst,
    setMobileAppMst,
    tnbTmplList,
    setTnbTmplList,
    mnuTmplList,
    mnu,
    setMnu,
    bnb,
    setBnb,
    theme,
    setTheme,
    themeTmpl,
    setThemeTmpl,
  };

  return (
    <MobileLayoutContext.Provider value={contextValue}>
      <React.Fragment>
        <DndProvider backend={HTML5Backend}>
          <div>
            <PageTemplate breadcrum={breadcrum}>
              <PageTemplate.Box boxClass="mb-0">
                <div className="layout-container">
                  <Row style={{ width: "100%", height: "100%" }}>
                    <Col
                      xs={9}
                      style={{
                        height: "100%",
                        overflowY: "auto",
                        padding: "24px",
                      }}
                    >
                      {StringUtils.equalsIgnoreCase(initial, "initial") ? (
                        <InitialAppConfiguraion />
                      ) : (
                        <>
                          <Stack gap={3}>
                            {/* 상단 네비게이션 설정 */}
                            <MobileTopNavSetting />

                            <MobileMenuTypeSetting />

                            <MobileBottomNavSetting />

                            <MobileThemeSetting />

                            {/* <LoginSetting />
                        
                        <MainBoardSetting /> */}
                          </Stack>
                        </>
                      )}
                    </Col>
                    <Col xs={3}>
                      {/* 우측 미리보기 화면 */}
                      <MobileLayoutPreview />
                    </Col>
                  </Row>
                </div>
              </PageTemplate.Box>
            </PageTemplate>
          </div>
        </DndProvider>
      </React.Fragment>
    </MobileLayoutContext.Provider>
  );
};

export default AppConfiguration;
