import loadable from "@loadable/component";
import { Tooltip } from "@mui/material";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import { useEffect, useRef, useState } from "react";
import {
  FaRegWindowMaximize,
  FaRegWindowMinimize,
  FaList,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { RiPushpin2Fill, RiPushpinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowPropertiesTab } from "../reducers/UIBuilderAction";
import { Tab, Tabs } from "react-bootstrap";
import { TbMathFunction } from "react-icons/tb";
import EventTab from "components/builder/ui/properiesTab/EventTab";

/**
 * UI Builder용 Prop 둥둥 패널
 * @returns
 */
const FloatingPropertyTab = () => {
  const navigate = useNavigate();
  const tabRef = useRef();
  const tabHeaderRef = useRef();
  const positionRef = useRef();
  const propertyTabWidth = useRef(360);
  const dragStartRef = useRef();
  const dispatch = useDispatch();
  const [isPinTab, setIsPinTab] = useState(false);
  const [isMinSize, setIsMinSize] = useState(false);
  const [activedTabKey, setActivedTabKey] = useState("propertyTab");

  const {
    showPropTab,
    component: activedComponent,
    treeOpen,
  } = useSelector((state) => state.activedUIComponent);

  let minTop = 60;
  let minLeft = treeOpen ? 50 : 50;
  let maxTop = 850;
  let maxLeft = window.innerWidth - 360;

  const propertyTabComponentRef = useRef();

  const activateComponentRef = useRef();

  const workspace = useSelector((state) => state.workspace);

  if (!propertyTabComponentRef.current) {
    let theme = CommonUtils.getTheme();
    propertyTabComponentRef.current = loadable(() =>
      import(`components/builder/ui/properiesTab/${theme}/PropertiesTab`)
    );
  }

  useEffect(() => {
    if (
      !ObjectUtils.isEmpty(activateComponentRef.current) &&
      activateComponentRef.current.compId === activedComponent.compId
    ) {
      return false;
    } else if (!ObjectUtils.isEmpty(activedComponent)) {
      if (!isPinTab && !isMinSize) {
        activateComponentRef.current = activedComponent;
        const targetComponent = document.querySelector(
          `div[data-id='${activedComponent.compId}']`
        );
        if (targetComponent) {
          const targetPosition = targetComponent.getBoundingClientRect();
          if (!positionRef.current) {
            positionRef.current = {
              top: targetPosition.top,
              left: targetPosition.left + propertyTabWidth.current + 30, //보정치
            };
          }
          const isOverflowToRight =
            targetPosition.left +
              targetPosition.width / 2 +
              propertyTabWidth.current >
            window.innerWidth;
          if (isOverflowToRight) {
            positionRef.current.top = targetPosition.top;
            positionRef.current.left =
              targetPosition.left - propertyTabWidth.current - 30;
          }

          // tabRef.current.style = `top:${positionRef.current.top}px;left:${positionRef.current.left}px`;
          if (positionRef.current.left < minLeft) {
            positionRef.current.left = minLeft;
          } else if (positionRef.current.left > maxLeft) {
            positionRef.current.left = maxLeft;
          }
          //top 위치 고정
          tabRef.current.style = `top:80px;left:${positionRef.current.left}px`;
        }
      }

      if (!showPropTab) {
        dispatch(setShowPropertiesTab(true));
      }
    }
  }, [activedComponent]);

  /**
   * 드래그 이벤트
   * 드래그 결과 위치에서 시작위치를 뺀다.
   * @param {*} e
   * @returns
   */
  const onDragComponent = (e) => {
    e.stopPropagation();

    //드래그 결과 포지션
    const newPosition = {
      top: e.pageY,
      left: e.pageX,
    };
    if (!positionRef.current) {
      positionRef.current = newPosition;
    }
    if (newPosition.left === 0 && newPosition.top === 0) {
      return false;
    }
    if (
      positionRef.current.top === newPosition.top &&
      positionRef.current.left === newPosition.left
    ) {
      return false;
    } else {
      positionRef.current = newPosition;
      if (isMinSize) {
        if (!ObjectUtils.isEmpty(dragStartRef.current)) {
          tabRef.current.style = `top:${
            newPosition.top - dragStartRef.current.top
          }px; left:${newPosition.left - dragStartRef.current.left}px`;
        }
      } else {
        if (!ObjectUtils.isEmpty(dragStartRef.current)) {
          tabRef.current.style = `top:80px; left:${
            newPosition.left - dragStartRef.current.left
          }px`;
        }
      }
    }
  };

  /**
   * 드래그 시작할 때 포지션을 알아서 드래그시 이질감이 없도록 한다.
   *
   * @param {*} event
   * @returns
   */
  const onSetDragStartPosition = (event) => {
    event.stopPropagation();
    const element = tabHeaderRef.current;
    if (!element) return;
    const img = new Image();
    img.src = "";
    event.dataTransfer.setDragImage(img, 0, 0);
    const rect = element.getBoundingClientRect();
    const offsetX = event.clientX - rect.left; // 클릭한 X 위치
    const offsetY = event.clientY - rect.top; // 클릭한 Y 위치
    dragStartRef.current = {
      top: offsetY,
      left: offsetX,
    };
  };

  const handleDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let top = e.clientY - dragStartRef.current.top;
    let left = e.clientX - dragStartRef.current.left;

    if (top < minTop) {
      top = minTop;
    } else if (top > maxTop) {
      top = maxTop;
    }

    if (isMinSize) {
      if (left < minLeft) {
        left = minLeft;
      } else if (left > maxLeft + 200) {
        left = maxLeft + 200;
      }
    } else {
      if (left < minLeft) {
        left = minLeft;
      } else if (left > maxLeft) {
        left = maxLeft;
      }
    }

    if (isMinSize) {
      tabRef.current.style = `top:${top}px; left:${left}px`;
    } else {
      tabRef.current.style = `top:80px; left:${left}px`;
    }
  };

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  return (
    <>
      <div
        className={`floating-property-tab ${showPropTab ? "show" : "hidden"} ${
          isMinSize ? "min" : "max"
        }`}
        ref={tabRef}
      >
        <div
          className="floating-tab-header"
          ref={tabHeaderRef}
          draggable
          onDragStart={onSetDragStartPosition}
          onDrag={onDragComponent}
          onDragEnd={handleDragEnd}
        >
          <div
            onClick={(e) => {
              setIsMinSize(!isMinSize);
            }}
          >
            {isMinSize ? <FaRegWindowMaximize /> : <FaRegWindowMinimize />}
          </div>
          <Tooltip
            title="Fix the tab position regardless of the selected component's location"
            placement="top"
          >
            <div
              onClick={(e) => {
                setIsPinTab(!isPinTab);
              }}
            >
              {isPinTab ? <RiPushpin2Fill /> : <RiPushpinLine />}
            </div>
          </Tooltip>

          <div onClick={(e) => dispatch(setShowPropertiesTab(false))}>
            <MdClose />
          </div>
        </div>
        <div className="floating-tab-body control-sidebar ">
        <propertyTabComponentRef.current
                navigate={navigate}
                trdUseYn={CommonUtils.getAppConfig(workspace, "trdUseYn")}
              />
          {/* <Tabs fill activeKey={activedTabKey} onSelect={handleTabSelect}>
            <Tab
              eventKey="propertyTab"
              title={
                <span>
                  <FaList size="14" />
                  <span className="tab-name">Properties</span>
                </span>
              }
            >
              <propertyTabComponentRef.current
                navigate={navigate}
                trdUseYn={CommonUtils.getAppConfig(workspace, "trdUseYn")}
              />
            </Tab>
            <Tab
              eventKey="eventTab"
              title={
                <span>
                  <TbMathFunction size="14" />
                  <span className="tab-name">Event</span>
                </span>
              }
            >
              <EventTab />
            </Tab>
          </Tabs> */}
        </div>
      </div>
    </>
  );
};

export default FloatingPropertyTab;
