const { BaseService } = require("@alpha/com.bizentro.daaf.front.framework");

class MobileAppService extends BaseService {
  static api = new BaseService("/mobile");

  /**
   * 모바일 App 목록 호출
   * @param {*} workspace
   * @returns
   */
  static getMobilAppList = (workspace) => {
    return this.api.sendPost("/getMAppList", workspace);
  };

  /**
   * 모바일 App 단일 호출
   * @param {*} data - {appMstId}
   * @returns
   */
  static getMobilApp = (data) => {
    return this.api.sendPost("/getMAppInfo", data);
  };

  /**
   * 모바일 App 저장
   * @param {*} data
   * @returns
   */
  static saveMobileApp = (data) => {
    return this.api.sendPost("/saveMAppInfo", data);
  };
}

export default MobileAppService;
