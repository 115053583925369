import { Button, Col, Form, Row } from "react-bootstrap";
import USelectbox from "components/common/element/USelectbox";
import { MdLibraryAdd } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import ArrayUtils from "components/common/utils/ArrayUtils";
import UInputPopup from "components/common/element/UInputPopup";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import React, { useState, useEffect, useRef } from "react";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import Popup from "components/common/Popup";
import ProgramListPopup from "./ProgramListPopup";
import { ObjectUtils } from "@alpha/com.bizentro.daaf.front.framework";
import DataModelService from "services/datamodel/DataModelService";
import * as Enums from "components/builder/BuilderEnum";

const PopupHandleComponent = (props) => {
  const {
    callbackFnc,
    item,
    entityId,
    data,
    setRetItems,
    workspace,
    onClickEventBuilder,
  } = props;

  const [programId, setProgramId] = useState("");
  const [url, setUrl] = useState("");
  const [size, setSize] = useState("");
  const [position, setPosition] = useState("");
  const [animInClass, setAnimInClass] = useState("");
  const [animOutClass, setAnimOutClass] = useState("");
  const [background, setBackground] = useState("");
  const beforeSubmitRef = useRef("");
  const [params, setParams] = useState([]);
  const [dataBindingList, setDataBindingList] = useState([]);

  useEffect(() => {
    let initItem = { ...(item || {}) };

    if (ObjectUtils.isEmpty(initItem.params)) {
      initItem.params = [{}];
    }
    setProgramId(initItem.programId);
    setUrl(initItem.url);
    setSize(initItem.size);
    setPosition(initItem.position);
    beforeSubmitRef.current = initItem.beforeSubmit;
    // setBeforeSubmit(initItem.beforeSubmit);
    setParams(initItem.params);
    selectDataBindingList(entityId);
    setAnimInClass(initItem.animInClass);
    setAnimOutClass(initItem.animOutClass);
    setBackground(initItem.background);
  }, [entityId, item]);

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  /**
   * setRetItems : popupHandleConfigPopup에서 재 items 설정을 위해
   * @param {*} e
   * @param {*} pId
   * @param {*} pItem
   */
  const onChangeSetRetItems = (e, pId, pItem) => {
    if (setRetItems) {
      if (!StringUtils.isEmpty(pId)) {
        setRetItems.call(this, pId, pItem);
      } else {
        setRetItems.call(this, e.target.id, e.target.value);
      }
    }
  };

  const onChangeProgramId = (e) => {
    setProgramId(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeUrl = (e) => {
    setUrl(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeSize = (e) => {
    setSize(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangePosition = (e) => {
    setPosition(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeEffectIn = (e) => {
    setAnimInClass(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeEffectOut = (e) => {
    setAnimOutClass(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeBackground = (e) => {
    setBackground(e.target.value);
    onChangeSetRetItems(e);
  };
  const onChangeBeforeSubmit = (e) => {
    beforeSubmitRef.current = e.target.value;
    onChangeSetRetItems(e);
  };

  const onInsertClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  const onDeleteClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  const openProgramPopup = (e) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "60%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <ProgramListPopup
        workspace={workspace.Info ? workspace.Info : workspace}
        title="Load Popup Program"
        isSharedCall={"Y"}
        params={{
          builderType: data.builderType
            ? data.builderType
            : Enums.BuilderType.UI,
          programType: {
            readonly: true,
            value: "P",
          },
        }}
        callbackFnc={(data) => {
          setProgramId(data.programId);
          let el = document.getElementById("programId");
          if (el) {
            el.value = data.programId;
            el.dispatchEvent(new Event("input", { target: el, bubbles: true }));
          }

          onChangeSetRetItems(null, "programId", data.programId);
          Popup.close();
        }}
      />,
      options
    );
  };

  const onChangeDataBinding = (e, param, index) => {
    let newParam = { ...param };
    //id를 setting해준다.
    newParam.id = StringUtils.substringAfter(e.target.value, ".");
    changeParam(e, newParam, index);
  };

  const onChangeParam = (e, param, index) => {
    changeParam(e, param, index);
  };

  const changeParam = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft[index] = { ...param, [e.target.id]: e.target.value };
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Please enter Program ID or Call URL</Form.Label>
      </Form.Group>
      <Row>
        <Col xl={4}>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Program ID</Form.Label>
            <UInputPopup
              id="programId"
              placeholder="Please select Popup Program ID"
              size="default"
              defaultValue={StringUtils.defaultString(programId)}
              onClick={openProgramPopup}
              onBlur={onChangeProgramId}
              onChange={(event) => {
                event.target.value = event.target.value.toUpperCase();
              }}
            />
          </Form.Group>
        </Col>
        <Col xl={4}>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Call URL</Form.Label>
            <input
              type="text"
              id="url"
              name="url"
              placeholder="Please enter Popup URL"
              className="form-control"
              value={StringUtils.defaultString(url)}
              onChange={onChangeUrl}
            />
          </Form.Group>
        </Col>
        <Col xl={4}>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Size</Form.Label>
            <USelectbox
              id="size"
              name="size"
              size="default"
              onChange={(e) => onChangeSize(e)}
              value={StringUtils.defaultString(size, "xl")}
              items={data.size || []}
              options={{ matchCd: "codeDtlCd", matchNm: "codeDtlNm" }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl={3}>
          <Form.Group as={Col}>
            <Form.Label>Location</Form.Label>
            <USelectbox
              id="position"
              name="position"
              size="default"
              onChange={(e) => onChangePosition(e)}
              value={StringUtils.defaultString(position, "center")}
              items={data.position || []}
              options={{ matchCd: "codeDtlCd", matchNm: "codeDtlNm" }}
            />
          </Form.Group>
        </Col>
        <Col xl={3}>
          <Form.Group as={Col}>
            <Form.Label>Effect (In)</Form.Label>
            <USelectbox
              id="animInClass"
              onChange={(e) => onChangeEffectIn(e)}
              value={StringUtils.defaultString(animInClass, "")}
              items={[
                { id: "", text: "None" },
                { id: "bounceIn", text: "[Bouncing] -bounceIn" },
                { id: "bounceInDown", text: "[Bouncing] -bounceInDown" },
                { id: "bounceInLeft", text: "[Bouncing] -bounceInLeft" },
                { id: "bounceInRight", text: "[Bouncing] -bounceInRight" },
                { id: "bounceInUp", text: "[Bouncing] -bounceInUp" },
                { id: "fadeIn", text: "[Fading] - fadeIn" },
                { id: "fadeInDown", text: "[Fading] - fadeInDown" },
                { id: "fadeInDownBig", text: "[Fading] - fadeInDownBig" },
                { id: "fadeInLeft", text: "[Fading] - fadeInLeft" },
                { id: "fadeInLeftBig", text: "[Fading] - fadeInLeftBig" },
                { id: "fadeInRight", text: "[Fading] - fadeInRight" },
                { id: "fadeInRightBig", text: "[Fading] - fadeInRightBig" },
                { id: "fadeInUp", text: "[Fading] - fadeInUp" },
                { id: "fadeInUpBig", text: "[Fading] - fadeInUpBig" },
                { id: "rotateIn", text: "[Rotating] - rotateIn" },
                {
                  id: "rotateInDownLeft",
                  text: "[Rotating] - rotateInDownLeft",
                },
                {
                  id: "rotateInDownRight",
                  text: "[Rotating] - rotateInDownRight",
                },
                { id: "rotateInUpLeft", text: "[Rotating] - rotateInUpLeft" },
                { id: "rotateInUpRight", text: "[Rotating] - rotateInUpRight" },
                { id: "zoomIn", text: "[Zooming] - zoomIn" },
                { id: "zoomInDown", text: "[Zooming] - zoomInDown" },
                { id: "zoomInLeft", text: "[Zooming] - zoomInLeft" },
                { id: "zoomInRight", text: "[Zooming] - zoomInRight" },
                { id: "zoomInUp", text: "[Zooming] - zoomInUp" },
                { id: "slideInDown", text: "[Sliding] -slideInDown" },
                { id: "slideInLeft", text: "[Sliding] -slideInLeft" },
                { id: "slideInRight", text: "[Sliding] -slideInRight" },
                { id: "slideInUp", text: "[Sliding] -slideInUp" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </Form.Group>
        </Col>
        <Col xl={3}>
          <Form.Group as={Col}>
            <Form.Label>Effect (Out)</Form.Label>
            <USelectbox
              id="animOutClass"
              onChange={(e) => onChangeEffectOut(e)}
              value={StringUtils.defaultString(animOutClass, "")}
              items={[
                { id: "", text: "None" },
                { id: "bounceOut", text: "[Bouncing] -bounceOut" },
                { id: "bounceOutDown", text: "[Bouncing] -bounceOutDown" },
                { id: "bounceOutLeft", text: "[Bouncing] -bounceOutLeft" },
                { id: "bounceOutRight", text: "[Bouncing] -bounceOutRight" },
                { id: "bounceOutUp", text: "[Bouncing] -bounceOutUp" },
                { id: "fadeOut", text: "[Fading] - fadeOut" },
                { id: "fadeOutDown", text: "[Fading] - fadeOutDown" },
                { id: "fadeOutDownBig", text: "[Fading] - fadeOutDownBig" },
                { id: "fadeOutLeft", text: "[Fading] - fadeOutLeft" },
                { id: "fadeOutLeftBig", text: "[Fading] - fadeOutLeftBig" },
                { id: "fadeOutRight", text: "[Fading] - fadeOutRight" },
                { id: "fadeOutRightBig", text: "[Fading] - fadeOutRightBig" },
                { id: "fadeOutUp", text: "[Fading] - fadeOutUp" },
                { id: "fadeOutUpBig", text: "[Fading] - fadeOutUpBig" },
                { id: "rotateOut", text: "[Rotating] - rotateOut" },
                {
                  id: "rotateOutDownLeft",
                  text: "[Rotating] - rotateOutDownLeft",
                },
                {
                  id: "rotateOutDownRight",
                  text: "[Rotating] - rotateOutDownRight",
                },
                { id: "rotateOutUpLeft", text: "[Rotating] - rotateOutUpLeft" },
                {
                  id: "rotateOutUpRight",
                  text: "[Rotating] - rotateOutUpRight",
                },
                { id: "zoomOut", text: "[Zooming] - zoomOut" },
                { id: "zoomOutDown", text: "[Zooming] - zoomOutDown" },
                { id: "zoomOutLeft", text: "[Zooming] - zoomOutLeft" },
                { id: "zoomOutRight", text: "[Zooming] - zoomOutRight" },
                { id: "zoomOutUp", text: "[Zooming] - zoomOutUp" },
                { id: "slideOutDown", text: "[Sliding] -slideOutDown" },
                { id: "slideOutLeft", text: "[Sliding] -slideOutLeft" },
                { id: "slideOutRight", text: "[Sliding] -slideOutRight" },
                { id: "slideOutUp", text: "[Sliding] -slideOutUp" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </Form.Group>
        </Col>
        <Col xl={3}>
          <Form.Group as={Col}>
            <Form.Label>Background</Form.Label>
            <USelectbox
              id="backdrop"
              onChange={(e) => onChangeBackground(e)}
              value={StringUtils.defaultString(background, "true")}
              items={[
                { id: "true", text: "Dark Transparent Background" },
                { id: "false", text: "No Background" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </Form.Group>
        </Col>
      </Row>
      {!StringUtils.equalsIgnoreType(item.eventType, "BTN_OPEN_POPUP") ? (
        <Form.Group className="mb-3">
          <Form.Label>Input Mapping</Form.Label>
          <Col>
            <Row className="mb-2">
              <Col>Data Binding (From)</Col>
              <Col>ID (To)</Col>
              <Col>Fixed Value</Col>
              <Col></Col>
            </Row>
            {!ArrayUtils.isEmpty(params)
              ? params.map((param, index) => {
                  return (
                    <Row
                      key={index}
                      className="pb-2"
                      style={{ alignItems: "center" }}
                    >
                      <Col>
                        <Form.Select
                          size="sm"
                          id="dataBinding"
                          onChange={(e) => onChangeDataBinding(e, param, index)}
                          value={StringUtils.defaultString(param.dataBinding)}
                        >
                          <option value={""}>Select</option>
                          {!ArrayUtils.isEmpty(dataBindingList)
                            ? dataBindingList.map((option, optionIdx) => (
                                <option key={optionIdx} value={option.id}>
                                  {option.text}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </Col>
                      <Col>
                        <input
                          type="text"
                          id="id"
                          className="form-control form-select-sm"
                          defaultValue={param.id}
                          onChange={(e) => onChangeParam(e, param, index)}
                        />
                      </Col>
                      <Col>
                        <input
                          type="text"
                          id="default"
                          className="form-control form-select-sm"
                          defaultValue={param.default}
                          onChange={(e) => onChangeParam(e, param, index)}
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={(e) => onInsertClick(e, param, index)}
                          size="sm"
                          className="mr-5"
                          variant={"outline-primary"}
                        >
                          <MdLibraryAdd size="16" />
                        </Button>
                        <Button
                          onClick={(e) => onDeleteClick(e, param, index)}
                          size="sm"
                          variant={"outline-danger"}
                        >
                          <FaTrash size="16" />
                        </Button>
                      </Col>
                    </Row>
                  );
                })
              : ""}
          </Col>
        </Form.Group>
      ) : (
        ""
      )}
      {!StringUtils.equalsIgnoreType(item.eventType, "BTN_OPEN_POPUP") ? (
        <Form.Group className="mb-3">
          <Form.Label>User Pre-processing Function</Form.Label>
          <UModalJavascriptEditor
            height="200px"
            defaultValue={StringUtils.defaultString(beforeSubmitRef.current)}
            eventInfo={props.getEventInfo("beforeSubmit")}
            onClickEventBuilder={(e) => onClickEventBuilder("beforeSubmit")}
            onChange={(value, viewUpdate) => {
              onChangeBeforeSubmit({
                target: { id: "beforeSubmit", value: value },
              });
            }}
          ></UModalJavascriptEditor>
        </Form.Group>
      ) : (
        ""
      )}
    </div>
  );
};

export default PopupHandleComponent;
