import axios from "axios";
import Api from "components/common/Api";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
/**
<<<<<<< HEAD
 * 연결 설정 저장 및 연결
 * **/
class ConnectionService {
  static save(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/saveConnection", data, callbackFnc, errCallbackFnc);
  }
  static getConnectionList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getConnectionList", data, callbackFnc, errCallbackFnc);
  }
  static getAllConnectionList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getAllConnectionList", data, callbackFnc, errCallbackFnc);
  }
  static test(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/connectionTest", data, callbackFnc, errCallbackFnc);
  }
  static connect(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/connect", data, callbackFnc, errCallbackFnc);
  }
  static getConnection(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getConnection", data, callbackFnc, errCallbackFnc);
  }
  static delete(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/deleteConnection", data, callbackFnc, errCallbackFnc);
  }
  static patchSoftware(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/patchSoftware", data, callbackFnc, errCallbackFnc);
  }
  static getDefaultCompList(data, callbackFnc, errCallbackFnc) {
    Api.post("/common/getDefaultCompList", data, callbackFnc, errCallbackFnc);
  }

  static directConnect(data, callbackFnc, errCallbackFnc) {
    const { headers, url } = ConnectionService.setDirectDeployParam(data);
    const body = {
      userId: data.systemUserId,
      password: data.systemUserPw,
      tenantId: data.tenantId,
      coCd: data.coCd,
    };

    headers.moduleCd = "*";
    axios
      .post(`${url}api/daaf/builder/Authentication`, body, {
        headers,
      })
      .then((res) => {
        callbackFnc(res);
      })
      .catch((err) => {
        errCallbackFnc(err);
      });
  }

  static directConnectTest(data, callbackFnc, errCallbackFnc) {
    const { headers, url } = ConnectionService.setDirectDeployParam(data);
    const body = {
      userId: data.systemUserId,
      password: data.systemUserPw,
      tenantId: data.tenantId,
      coCd: data.coCd,
    };

    headers.moduleCd = "*";
    axios
      .post(`${url}api/daaf/builder/all/ConnectTest`, body, {
        headers,
      })
      .then((res) => {
        callbackFnc(res.data);
      })
      .catch((err) => {
        errCallbackFnc(err);
      });
  }

  /**
   *
   * @param {Object} response
   * @returns {boolean} message
   */
  static reponseValidator(response, tenantMstId) {
    const { data, isError, message, errType } = response;
    let text = "";
    let flag = false;
    if (!isError) {
      if (data) {
        flag = true;
        text = "Connect Successfully";
      } else if (data === null || data === undefined) {
        text =
          "Data is not found. Connection is not valid, or please check the Input value.";
        flag = false;
      }
    } else if (message === "ConnectException: Connection refused: connect") {
      text =
        "The connection to the authentication server could not be confirmed.";
      if (tenantMstId) {
        const con = { ...User.getConnection(tenantMstId) };
        con.expired = true;
        User.setConnection(tenantMstId, con);
      }
    } else if (errType === "Invalid") {
      //토큰이 유효하지 않을때
      text =
        message ||
        "Token has expired or the connection has terminated. Please reconnect the server.";
      flag = false;
    } else if (errType === "Unauthorized") {
      //토큰이 유효하지 않을때
      text =
        message ||
        "Token has expired or the connection has terminated. Please reconnect the server.";
      flag = false;
      if (tenantMstId) {
        const con = { ...User.getConnection(tenantMstId) };
        con.expired = true;
        User.setConnection(tenantMstId, con);
      }
    } else {
      flag = false;
      if (data) {
        switch (data.step) {
          case 1:
            text = "Unable to find User Info.";
            break;
          case 2:
            text = "Password is not set.";
            break;
          case 3:
            text = message;
            break;
          case 4:
            text = "Expired Account.";
            break;
          case 5:
            text =
              "Password has been expired. <br/> Please change the system password.";
            break;
          case 6:
            text =
              "This is the initially logged-in account.<br/> Please re-register your password before using it.";
            break;
          case 7:
            text =
              "Login has locked. Please reset & re-register password before use.";
            break;
          case 19:
            text = "This system account requires the issuance of an OTP key.";
            break;
          case 17:
            text = "Unable to find user info.";
            break;
          case 0:
            text = "User Info is not found.";
            break;
          default:
            text = message;
            break;
        }
      } else {
        text = message;
      }
    }
    return { flag, text };
  }

  /**
   * 배포 서버 직접 연결 config 생성
   * @param {*} connectionInfo
   * @returns
   */
  static setDirectDeployParam(connectionInfo) {
    try {
      const { protocol, host, runtimeProtocol, runtimeHost } = connectionInfo;

      let url = protocol + "://" + host;
      if (process.env.NODE_ENV === "development") {
        // url = "/";
      }
      if (!url.endsWith("/")) {
        url += "/";
      }
      const headers = {};
      headers.language = "ko";
      if (connectionInfo.token) {
        headers.Authorization = "Bearer " + connectionInfo.token;
      }

      return { headers, url };
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * URL 주소에 Port 번호 적용
   * @param {*} urlPath
   */
  static setPortNumberToURL(urlPath) {
    let url = new URL(urlPath);
    if (!url.port) {
      if (StringUtils.equalsIgnoreCase(url.protocol, "http")) {
        url.port = 80;
      } else if (StringUtils.equalsIgnoreCase(url.protocol, "https")) {
        url.port = 443;
      }
    }

    return url.toString();
  }

  /**
   * 현재 사용 중인 커넥션의 유효성 검증
   * @param {*} workspace
   * @returns {{ message: string, isConnected: boolean, connection: Object|null }} 검증 결과 객체
   */
  static validConnection = (workspace) => {
    let message = "";
    let isConnected = true;
    let connection = null;
    if (!workspace) return false;
    connection = User.getConnection(workspace.tenantMstId);
    if (!connection) {
      message = "Need to Server connection";
      isConnected = false;
    }
    if (connection?.expired) {
      message = "Token Expired";
      isConnected = false;
    }
    if (!connection?.token) {
      message =
        "Authentication server token is not validated.\n Please reconnect.";
      isConnected = false;
    }
    return { message, isConnected, connection };
  };
}
export default ConnectionService;
