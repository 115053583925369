import { CircularProgress } from "@mui/material";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import {
  AppConfig,
  AppConfigDesc,
  AppConfigName,
  AppConfigSwitch,
  AppDesc,
} from "components/setting/SettingMenu";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import AppService from "services/common/AppService";
import ConnectionService from "services/common/ConnectionService";
import AppGloabalVariableManage from "./AppGloabalVariableManage";
import CodeService from "services/common/CodeService";

function AppGeneral() {
  const [themeList, setThemeList] = useState([]);
  const [mainTheme, setMainTheme] = useState({
    configValue: null,
  });
  const [defaultValueUseYn, setDefaultValueUseYn] = useState({
    configValue: "N",
  });
  const [defaultValueWorkflow, setDefaultValueWorkflow] = useState({});
  const [globalVariableUseYn, setGlobalVariableUseYn] = useState({
    configValue: "N",
  });
  const {
    workspace: { Info: workspace, openPopup: workspaceOpen },
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAppConfig();
  }, []);

  const getAppConfig = () => {
    CodeService.getCodeCombo({ codeMstCd: "Z0055" }, (res) => {
      setThemeList(res.data);
    });

    let theme = CommonUtils.getAppConfig(workspace, "theme", true);

    let defaultValuConfig = CommonUtils.getAppConfig(
      workspace,
      "defaultValueUseYn",
      true
    );
    let globaclVariableConfig = CommonUtils.getAppConfig(
      workspace,
      "globalVariableUseYn",
      true
    );
    if (theme) {
      setMainTheme(theme);
    }
    if (defaultValuConfig) {
      setDefaultValueUseYn(defaultValuConfig);
      setDefaultValueWorkflow(JSON.parse(defaultValuConfig.remark));
    }
    if (globaclVariableConfig) {
      setGlobalVariableUseYn(globaclVariableConfig);
    }
  };

  const onClickWfSearch = () => {
    const wfCallback = async (wf) => {
      //Runtime 정보 업데이트
      const result = await applyRuntime({
        dtlCd: "WORKFLOW_FOR_DEFAULT_VALUE",
        useYn: "Y",
        reference: wf.serviceUid,
        dtlNm: "Workflow For Default Input Value",
        dtlNmDesc: "Workflow For Default Input Value",
      });
      if (result) {
        setDefaultValueWorkflow(wf);
        //builder 정보 업데이트
        AppService.setAppConfig(
          {
            ...defaultValueUseYn,
            appMst: workspace,
            remark: JSON.stringify(wf),
          },
          (res) => {
            setDefaultValueUseYn(res.data);
          }
        );
        Popup.close();
      }
    };

    Popup.open(
      <WorkflowListPopup workspace={workspace} callbackFnc={wfCallback} />,
      {
        style: { content: { width: "1200px" } },
      }
    );
  };

  /**
   * 테마 변경
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  const onChangeTheme = async (e) => {
    const themeValue = e.target.value === "default" ? null : e.target.value;
    const result = await applyRuntime({
      dtlCd: "THEME",
      useYn: "Y",
      reference: themeValue,
      dtlNm: "Runtime APP Theme",
      dtlNmDesc: "Runtime APP Theme",
    });
    if (result) {
      AppService.setAppConfig(
        {
          ...mainTheme,
          appMst: workspace,
          configCd: "theme",
          configValue: themeValue,
        },
        (res) => {
          setIsLoading(false);
          if (!themeValue) res.data.configValue = "default";
          setMainTheme(res.data);

          Message.confirm({
            title: "Notice for theme changed",
            desc: "The main theme has been changed. All programs deployed with the previous design theme must be redeployed.",
          });
        },
        (err) => {
          Message.alert(err.message, Enums.MessageType.ERROR);
          setIsLoading(false);
        }
      );
    }
  };

  const onChangeDefaultValue = async (flag) => {
    let useYn = flag ? "Y" : "N";

    const result = await applyRuntime({
      dtlCd: "USING_DEFAULT_INPUT_VALUE",
      useYn: "Y",
      reference: useYn,
      dtlNm: "Use of default user input values",
      dtlNmDesc: "Use of default user input values",
    });
    if (result) {
      AppService.setAppConfig(
        {
          ...defaultValueUseYn,
          appMst: workspace,
          configCd: "defaultValueUseYn",
          configValue: useYn,
        },
        (res) => {
          setDefaultValueUseYn(res.data);
        }
      );
    }
  };

  const onChangeGlobalVariable = async (flag) => {
    let useYn = flag ? "Y" : "N";

    const result = await applyRuntime({
      dtlCd: "USING_GLOBAL_VARIABLES",
      useYn: "Y",
      reference: useYn,
      dtlNm: "Use of Global Variable Workflow",
      dtlNmDesc: "Use of Global Variable Workflow",
    });
    if (result) {
      AppService.setAppConfig(
        {
          ...globalVariableUseYn,
          appMst: workspace,
          configCd: "globalVariableUseYn",
          configValue: useYn,
        },
        (res) => {
          setGlobalVariableUseYn(res.data);
        }
      );
    }
  };

  const applyRuntime = ({ dtlCd, useYn, reference, dtlNm, dtlNmDesc }) => {
    const { connection, message, isConnected } =
      ConnectionService.validConnection(workspace);

    return new Promise((resolve, reject) => {
      if (!isConnected) {
        Message.alert(message, Enums.MessageType.INFO);
        resolve(false);
      } else {
        setIsLoading(true);
        //Runtime 정보 업데이트
        AppService.setConfigForRuntime(
          {
            ...connection,
            dtlCd: dtlCd,
            mstId: "DF0001",
            dtlNm: dtlNm,
            dtlNmDesc: dtlNmDesc,
            useYn: useYn,
            reference: reference,
            accessToken: connection.token,
          },
          (res) => {
            resolve(true);
            setIsLoading(false);
          },
          (err) => {
            Message.alert(err.message, Enums.MessageType.ERROR);
            resolve(false);
            setIsLoading(false);
          }
        );
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div className="data-load-wrapper">
          <div className="data-load-box">
            <CircularProgress color="inherit" size={13} />
            &nbsp;&nbsp;&nbsp; Applying...
          </div>
        </div>
      )}
      <div className="setting-header">Application Genaral Setting</div>
      <div
        className="setting-container"
        style={{ height: "100%", padding: "0 25px" }}
      >
        {ObjectUtils.isEmpty(workspace) ? (
          <div className="workspace-empty-alert">
            <div className="alert-msg">Workspace setting Required </div>
            <Button onClick={workspaceOpen}>Open Popup</Button>
          </div>
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <AppConfig>
                <AppConfigName>Select APP Theme</AppConfigName>
                <AppConfigDesc>
                  <AppDesc>
                    Select the design theme to be used in the Daaf Builder and
                    Runtime App.
                  </AppDesc>
                  <AppConfigSwitch>
                    <Form.Select
                      value={mainTheme.configValue}
                      onChange={onChangeTheme}
                    >
                      <option value={null}>default</option>
                      {themeList.map((theme) => {
                        return (
                          <option key={theme.id} value={theme.id}>
                            {theme.text}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </AppConfigSwitch>
                </AppConfigDesc>
              </AppConfig>

              {!ConnectionService.validConnection(workspace).isConnected && (
                <AppConfigName
                  style={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    height: "100%",
                    background: "rgba(169,169,169,0.48)",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "3px",
                  }}
                >
                  <span className="blink" style={{ color: "tomato" }}>
                    Connect Server berfore set Configuration
                  </span>
                </AppConfigName>
              )}
              <AppConfig>
                <AppConfigName>Use App default input value</AppConfigName>
                <AppConfigDesc>
                  <AppDesc>
                    It determines whether to use the default input value
                    settings for the runtime application.
                  </AppDesc>
                  <AppConfigSwitch>
                    <BootstrapSwitchButton
                      onlabel="Yes"
                      offlabel="No"
                      offstyle="dark"
                      disabled={
                        !ConnectionService.validConnection(workspace)
                          .isConnected
                      }
                      checked={
                        defaultValueUseYn.configValue === "Y" ? true : false
                      }
                      onChange={(value) => {
                        onChangeDefaultValue(value);
                      }}
                    />
                  </AppConfigSwitch>
                </AppConfigDesc>
                {defaultValueUseYn.configValue === "Y" && (
                  <div
                    style={{
                      paddingLeft: "5%",
                      display: "flex",
                      gap: "15px",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <span>Workflow for default value</span>
                    <div>
                      <InputGroup>
                        <Form.Control
                          style={{ minWidth: "300px" }}
                          value={
                            !ObjectUtils.isEmpty(defaultValueWorkflow)
                              ? `${defaultValueWorkflow.serviceName} ( ${defaultValueWorkflow.serviceId} ) `
                              : ""
                          }
                          readOnly
                        />
                        <Button
                          size="sm"
                          onClick={onClickWfSearch}
                          disabled={
                            !ConnectionService.validConnection(workspace)
                              .isConnected
                          }
                        >
                          <MdSearch />
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
                )}
              </AppConfig>
              <AppConfig>
                <AppConfigName>Use App Global Variable</AppConfigName>
                <AppConfigDesc>
                  <AppDesc>
                    It determines whether to use custom global variables in the
                    runtime application.
                  </AppDesc>
                  <AppConfigSwitch>
                    <BootstrapSwitchButton
                      onlabel="Yes"
                      offlabel="No"
                      offstyle="dark"
                      disabled={
                        !ConnectionService.validConnection(workspace)
                          .isConnected
                      }
                      checked={
                        globalVariableUseYn.configValue === "Y" ? true : false
                      }
                      onChange={onChangeGlobalVariable}
                    />
                  </AppConfigSwitch>
                </AppConfigDesc>
                {globalVariableUseYn.configValue === "Y" && (
                  <div style={{ paddingLeft: "0 5%" }}>
                    <AppGloabalVariableManage />
                  </div>
                )}
              </AppConfig>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AppGeneral;
