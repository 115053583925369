import { StringUtils } from "@alpha/com.bizentro.daaf.front.framework";
import UModal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import produce from "immer";
import { useEffect, useState } from "react";
import {
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Row,
  ToggleButton,
} from "react-bootstrap";
import MobileAppService from "services/mobile/MobileAppService";

function MobileWorkspacePopup({ workspace, ...props }) {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [selectedMobileWorkspace, setSelectedMobileWorkspace] = useState({});
  const [mobileAppList, setMobileAppList] = useState([]);
  const [mobileAppInfo, setMobileAppInfo] = useState({
    appMstId: null,
    appId: workspace.appId,
    mobileAppNm: "",
  });

  useEffect(() => {
    getMobileAppList();
  }, []);

  const getMobileAppList = async () => {
    const result = await MobileAppService.getMobilAppList(workspace);
    if (result.data) {
      setMobileAppList(result.data);
    }
  };

  /**
   * 신규앱 생성 모드
   */
  const onClickAppCreateMode = () => {
    setIsCreateMode(true);
    setSelectedMobileWorkspace(null);
  };

  /**
   *
   * @param {*} e
   */
  const onChangeNewAppNm = (e) => {
    setMobileAppInfo(
      produce(mobileAppInfo, (draft) => {
        draft.mobileAppNm = e.target.value;
      })
    );
  };

  /**
   * 저장 및 진행
   * @param {*} e
   */
  const onClickConfirm = async (e) => {
    if (props.callback) {
      if (isCreateMode) {
        const result = await MobileAppService.saveMobileApp(mobileAppInfo);
        if (!result.isError) {
          props.callback(result.data, true);
        }
      } else {
        props.callback(selectedMobileWorkspace);
      }
    }
  };

  return (
    <UModal>
      <UModal.Header title={"Set Mobile Workspace"} />
      <UModal.Body>
        <UModalTemplate>
          <FormGroup>
            <Form.Label>Current Workspace</Form.Label>
            <Form.Control value={workspace.appNm} readOnly className="mb-3" />
            {isCreateMode ? (
              <>
                <Form.Label className="required">
                  Create New Mobile App
                </Form.Label>
                <Form.Control
                  value={mobileAppInfo.mobileAppNm}
                  placeholder="Input new Mobile App name"
                  className="mb-3"
                  onChange={onChangeNewAppNm}
                />
              </>
            ) : (
              <>
                {" "}
                <Form.Label>Select Mobile App</Form.Label>
                <Form.Select
                  className="mb-3"
                  value={selectedMobileWorkspace.appMstId}
                  onChange={(e) => {
                    const selected = mobileAppList.find((mapp) =>
                      StringUtils.equalsIgnoreType(
                        mapp.appMstId,
                        e.target.value
                      )
                    );
                    if (selected) {
                      setSelectedMobileWorkspace(selected);
                    }
                  }}
                >
                  <option value="">Select</option>
                  {mobileAppList.map((mapp) => {
                    return (
                      <option value={mapp.appMstId} key={mapp.appMstId}>
                        {mapp.mobileAppNm}
                      </option>
                    );
                  })}
                </Form.Select>
              </>
            )}
          </FormGroup>

          <Row className="mb-3 gy-1">
            <Col xs={12} className="history-list">
              <ButtonGroup>
                <ToggleButton
                  id="historyTab1"
                  name="historyTab1"
                  type="radio"
                  variant="outline-dark"
                  value="recent"
                  size="sm"
                >
                  Recently Used
                </ToggleButton>
                <ToggleButton
                  id="historyTab2"
                  name="historyTab2"
                  type="radio"
                  variant="outline-dark"
                  value="prefer"
                  size="sm"
                >
                  Favorites
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Row>
        </UModalTemplate>
      </UModal.Body>
      <UModal.Footer>
        <UModal.Footer.Button
          side="left"
          variant="success"
          onClick={onClickAppCreateMode}
        >
          Create App
        </UModal.Footer.Button>
        <UModal.Footer.Button onClick={onClickConfirm}>
          Confirm
        </UModal.Footer.Button>
      </UModal.Footer>
    </UModal>
  );
}

export default MobileWorkspacePopup;
