/*!
 * Builder EventHandler for react v.17
 *
 * Builder에서 발생되는 Event 및 Event 관련 로직
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import produce from "immer";

import * as Enums from "components/builder/BuilderEnum";
import UITemplateHandler from "components/builder/ui/editor/handler/UITemplateHandler";
import UIDndHelper from "components/builder/ui/editor/helper/UIDndHelper";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import NamingUtils from "components/common/utils/NamingUtils";
import cloneDeep from "lodash/cloneDeep";
import FormTypeSelectPopup from "page/popup/FormTypeSelectPopup";
import { setClipboard } from "../../reducers/CommandAction";
import {
  setBuilderTreeNodeIds,
  updateActivateProps,
} from "../../reducers/UIBuilderAction";
import CardLayoutSettingPopup from "page/popup/mobile/CardLayoutSettingPopup";

/**
 * get root of the output
 * @param {*} rootLocation
 * @param {*} output
 * @returns
 */
export const getRoot = (rootLocation, output) => {
  if (rootLocation === Enums.ComponentType.FOOTER) {
    return output.page.footer;
  } else {
    return output.page;
  }

  // return rootLocation === Enums.ComponentType.FILTER
  //   ? output.page.filter
  //   : output.page;
};

/**
 * Event 중단
 * @param {Event} e
 */
export const stopEvent = (e) => {
  if (e) {
    e.preventDefault(); //event 수행을 중단함.
    e.stopPropagation(); //상위 엘리먼트에게 event가 전달되지 않토록함
  }
};

/**
 * Drag & drap handle
 * @param {*} dropZone
 * @param {*} item
 * @param {*} templateComponents
 * @param {*} output
 * @param {*} dispatch
 * @returns
 */
export const handleDrop = (
  dropZone,
  item,
  templateComponents,
  output,
  dispatch,
  componentContext
) => {
  const splitDropZonePath = dropZone.path.split("-");
  const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

  let newItem = {
    compId: item.compId,
    type: item.type,
  };

  const component = item.component;

  /***************************************************
   * 1.drag & drop From sidebar
   * *************************************************/
  if (item.componentType === Enums.ComponentType.SIDEBAR_ITEM) {
    /******** 1-1.Template drag & droop ***************/
    if (component.componentType === Enums.ComponentType.TEMPLATE) {
      //footer영역에 template을 넣지 못하게한다.
      if (dropZone.rootLocation === Enums.ComponentType.FOOTER) {
        //Footer에 컴포넌트를 추가하는 경우
        debugger;
        return false;
      }
      let preHandleOptions =
        (typeof component.preHandleOptions === "string"
          ? JsonUtils.parseJson(component.preHandleOptions)
          : component.preHandleOptions) || {};

      UITemplateHandler.call(
        this,
        dropZone,
        output,
        item,
        preHandleOptions,
        templateComponents,
        (newItem) => {
          let newChildren = !ArrayUtils.isArray(newItem) ? [newItem] : newItem;
          let updatedChild = [...output.page.child];

          //child path때문에 reverse한다.

          newChildren.reverse().map((newChild, index) => {
            updatedChild = UIDndHelper.addComponentIntoEditor(
              dropZone.location,
              updatedChild,
              splitDropZonePath,
              newChild,
              templateComponents
            );
          });

          /**
           * ElementTab의 Workflow Grid에서
           */
          let newActiveFormPropertyValue = null;
          if (
            item.component.viewerAttr?.isDataStudioElement &&
            item.component.editorAttr?.fromCompId
          ) {
            const targetData = {
              type: "grid",
              useYn: true,
              target: newItem.propertyValue.gridOptions.gridId,
              entityVariable: newItem.editorAttr?.entityVariable,
            };
            const targetCompId = item.component.editorAttr.fromCompId;

            // 깊은 복사
            const updateData = CommonUtils.deepCopy(updatedChild);

            const targetDataUpdate = (obj) => {
              for (let key in obj) {
                if (obj[key] === targetCompId) {
                  if (
                    obj.propertyValue.form.length === 1 &&
                    ObjectUtils.isEmpty(obj.propertyValue.form[0])
                  ) {
                    obj.propertyValue.form.splice(0, 1);
                  }
                  obj.propertyValue = {
                    ...obj.propertyValue,
                    form: [...obj.propertyValue.form, targetData],
                  };
                  newActiveFormPropertyValue = obj.propertyValue;
                }
                if (typeof obj[key] === "object" && obj[key] != null) {
                  targetDataUpdate(obj[key]);
                }
              }
            };
            targetDataUpdate(updateData);

            updatedChild = updateData;
          }

          UIReduxHelper.updateOutputChild(
            output,
            updatedChild,
            dropZone.location,
            dispatch
          );
          if (newActiveFormPropertyValue) {
            dispatch(updateActivateProps(newActiveFormPropertyValue));
          }
        }
      );

      /******** 1-2.listLayout drag & drop ***************/
    } else if (component.componentType === Enums.ComponentType.LIST_LAYOUT) {
      //footer영역에 listLayout 넣지 못하게한다.
      if (dropZone.rootLocation === Enums.ComponentType.FOOTER) return false;

      let preHandleOptions =
        (typeof component.preHandleOptions === "string"
          ? JsonUtils.parseJson(component.preHandleOptions)
          : component.preHandleOptions) || {};

      let dropNode;
      if (splitDropZonePath.length === 1) {
        dropNode = output.page;
      } else {
        dropNode = output.page;
        for (let i = 0; i < splitDropZonePath.length - 1; i++) {
          dropNode = dropNode.child[Number(splitDropZonePath[i])];
        }
      }
      const dataModelNm = JsonUtils.findDataModel(
        output,
        dropNode.compId, //drop down되는 node의 comp Id
        true
      );
      if (!StringUtils.isEmpty(dataModelNm)) {
        preHandleOptions.defaultValues = {
          ...preHandleOptions.defaultValues,
          ...{ dataModelNm: dataModelNm },
        };
      }

      UITemplateHandler.call(
        this,
        dropZone,
        output,
        item,
        preHandleOptions,
        templateComponents,
        (newItem) => {
          let newChildren = !ArrayUtils.isArray(newItem) ? [newItem] : newItem;

          newChildren.map((newChild, index) => {
            let updatedChild = UIDndHelper.addChildToChildren(
              [...output.page.child],
              splitDropZonePath,
              newChild
            );
            UIReduxHelper.updateOutputChild(
              output,
              updatedChild,
              dropZone.rootLocation,
              dispatch
            );
          });
        }
      );
      /******** 1-3.GRID drag & drop ***************/
    } else if (component.componentType === Enums.ComponentType.GRID) {
      //footer영역에 Grid를 넣지 못하게한다.
      if (dropZone.rootLocation === Enums.ComponentType.FOOTER) return false;

      let preHandleOptions =
        (typeof component.preHandleOptions === "string"
          ? JsonUtils.parseJson(component.preHandleOptions)
          : component.preHandleOptions) || {};

      //Grid 일경우 이전 node에 선택된 Data model이 있을 경우 defaultValue에 담아서 넘긴다.
      let dropNode;
      if (splitDropZonePath.length === 1) {
        dropNode = output.page;
      } else {
        dropNode = output.page;
        for (let i = 0; i < splitDropZonePath.length - 1; i++) {
          dropNode = dropNode.child[Number(splitDropZonePath[i])];
        }
      }
      const dataModelNm = JsonUtils.findDataModel(
        output,
        dropNode.compId, //drop down되는 node의 comp Id
        true
      );
      if (!StringUtils.isEmpty(dataModelNm)) {
        preHandleOptions.defaultValues = {
          ...preHandleOptions.defaultValues,
          ...{ dataModelNm: dataModelNm },
        };
      }

      UITemplateHandler.call(
        this,
        dropZone,
        output,
        item,
        preHandleOptions,
        templateComponents,
        (newItem) => {
          let newChildren = !ArrayUtils.isArray(newItem) ? [newItem] : newItem;

          newChildren.map((newChild, index) => {
            let updatedChild = UIDndHelper.addChildToChildren(
              [...output.page.child],
              splitDropZonePath,
              newChild
            );
            UIReduxHelper.updateOutputChild(
              output,
              updatedChild,
              dropZone.rootLocation,
              dispatch
            );
          });
        }
      );

      /******** 1-3. serivce component drag & drop ***************/
    } else if (component.componentType === Enums.ComponentType.SERVICE) {
      //없으면  return
      let newItem = JsonUtils.parseJson(component.componentOutput);
      //저장된 Comp ID를 재정립 해준다.
      JsonUtils.updateCompId(newItem);
      // service데이터 drop시 dataBinding 제거 | Tuleap #1669
      JsonUtils.removeKey(newItem, "dataBinding");
      let newChildren = !ArrayUtils.isArray(newItem) ? [newItem] : newItem;
      let updatedChild = [...output.page.child];

      //child path때문에 reverse한다.
      newChildren.reverse().map((newChild, index) => {
        updatedChild = UIDndHelper.addComponentIntoEditor(
          dropZone.location,
          updatedChild,
          splitDropZonePath,
          newChild,
          templateComponents
        );
      });
      UIReduxHelper.updateOutputChild(
        output,
        updatedChild,
        dropZone.rootLocation,
        dispatch
      );

      /******** 1-4. widget component drag & drop ***************/
      //WidgetContainer(Empty widget box) 제외한 Widget component들의 경우 template 선택 화면을 띄운다
    } else if (
      component.componentType === Enums.ComponentType.WIDGET_TEMPLATE
    ) {
      let preHandleOptions =
        (typeof component.preHandleOptions === "string"
          ? JsonUtils.parseJson(component.preHandleOptions)
          : component.preHandleOptions) || {};

      UITemplateHandler.call(
        this,
        dropZone,
        output,
        item,
        preHandleOptions,
        templateComponents,
        (newItem) => {
          let newChildren = !ArrayUtils.isArray(newItem) ? [newItem] : newItem;
          let updatedChild = [...output.page.child];
          //child path때문에 reverse한다.
          newChildren.reverse().map((newChild, index) => {
            if (
              newChild.type === Enums.ComponentType.WIDGET_CONTAINER &&
              dropZone.location !== Enums.ComponentType.PAGE
            ) {
              delete newChild.propertyValue.widthLaptop;
              delete newChild.propertyValue.widthPhone;
              delete newChild.propertyValue.widthSmall;
              delete newChild.propertyValue.widthTablet;
            }

            updatedChild = UIDndHelper.addComponentIntoEditor(
              dropZone.location,
              updatedChild,
              splitDropZonePath,
              newChild,
              templateComponents
            );
          });
          UIReduxHelper.updateOutputChild(
            output,
            updatedChild,
            dropZone.rootLocation,
            dispatch
          );
        }
      );
      /******** 1-5. Component drag & drop  ***************/
    } else {
      newItem = {
        ...newItem,
        ...generateUiComponent({ componentItem: component, dropZone, output }),
      };
      const rootNode = getRoot(dropZone.rootLocation, output);

      const isFromElementTab = newItem.viewerAttr?.isDataStudioElement;

      const outputUpdate = (updateItem, updateData) => {
        if (isFromElementTab && newItem.propertyValue?.fromCompId) {
          let newActiveFormPropertyValue = null;
          const targetCompId = newItem.propertyValue.fromCompId;
          const targetData = {
            type: newItem.type,
            useYn: true,
            target: newItem.propertyValue.id,
            entityVariable: newItem.propertyValue?.entityVariable,
          };
          // 깊은 복사
          const targetDataUpdate = (obj) => {
            for (let key in obj) {
              if (obj[key] === targetCompId) {
                obj.propertyValue = {
                  ...obj.propertyValue,
                  form: [...obj.propertyValue.form, targetData],
                };
                newActiveFormPropertyValue = obj.propertyValue;
              }
              if (typeof obj[key] === "object" && obj[key] != null) {
                targetDataUpdate(obj[key]);
              }
            }
          };
          targetDataUpdate(updateData);
          dispatch(updateActivateProps(newActiveFormPropertyValue));
        } else {
          //[activedComponent] --- left properties tab을 활성화 시킨다.
          //activedComponent - json type의 propertyValue 와 compId를 update 하고 activation 시킨다.
          const activedComponent = produce(component, (draft) => {
            draft.propertyValue = newItem.propertyValue;
            draft.compId = newItem.compId;
          });

          UIReduxHelper.activateComponent(activedComponent, dispatch);
        }

        UIReduxHelper.updateOutputChild(
          output,
          UIDndHelper.addComponentIntoEditor(
            dropZone.location,
            updateData || [...rootNode.child],
            splitDropZonePath,
            updateItem,
            templateComponents
          ),
          dropZone.rootLocation,
          dispatch
        );
      };

      //data studio card layout 컴포넌트
      if (
        isFromElementTab &&
        newItem.type === Enums.ComponentType.CARD_LAYOUT
      ) {
        const defaultValues = JSON.parse(
          item.component.preHandleOptions
        )?.defaultValues;
        const updateData = cloneDeep([...rootNode.child]);
        Popup.open(
          <CardLayoutSettingPopup
            componentInfo={newItem}
            defaultValues={defaultValues}
            componentContext={componentContext}
            templateComponents={templateComponents}
            output={output}
            callbackFn={(formData) => outputUpdate(formData, updateData)}
          />,
          { style: { content: { width: "50%", minWidth: "1200px" } } }
        );
      }

      // data studio form Type인 컴포넌트
      else if (isFromElementTab && newItem.type === "form") {
        const defaultValues = JSON.parse(
          item.component.preHandleOptions
        )?.defaultValues;
        const updateData = cloneDeep([...rootNode.child]);

        Popup.open(
          <FormTypeSelectPopup
            componentInfo={newItem}
            defaultValues={defaultValues}
            componentContext={componentContext}
            templateComponents={templateComponents}
            output={output}
            callbackFn={(formData) => outputUpdate(formData, updateData)}
          />,
          { style: { content: { width: "50%", minWidth: "1200px" } } }
        );
      } else {
        outputUpdate(newItem);
      }
    }

    /***************************************************
     * 2.drag & drop inside Editor
     * *************************************************/
  } else {
    // move down here since sidebar items dont have path
    const splitItemPath = item.path.split("-");
    const pathToItem = splitItemPath.slice(0, -1).join("-");

    /******** 2.1 Grid Column drag & drop ***************/
    if (item.type === Enums.ComponentType.GRID_COLUMN) {
      let updatedLayout = produce(output, (draft) => {
        let beforeIndex = Number(splitItemPath.slice(-1));
        let afterIndex = Number(splitDropZonePath.slice(-1));
        if (beforeIndex < afterIndex) afterIndex = afterIndex - 1;
        const gridNode = JsonUtils.findNode(
          draft,
          "gridId",
          item.propertyValue.gridId
        );
        gridNode.columns = UIDndHelper.reorder(
          gridNode.columns,
          beforeIndex,
          afterIndex
        );
        //sortOrder 변경
        gridNode.columns.map((column, index) => {
          column.sortOrder = index + 1;
        });
      });
      UIReduxHelper.updateOutput(updatedLayout, dispatch);
      /******** 2.2 나머지 drag & drop ***************/
    } else {
      if (item.child) {
        newItem.child = item.child;
      } else {
        newItem.child = [];
      }
      newItem.baseCompId = item.baseCompId;
      newItem.propertyValue = cloneDeep(item.propertyValue);
      newItem.editorAttr = cloneDeep(item.editorAttr);
      newItem.viewerAttr = cloneDeep(item.viewerAttr);
      if (
        item.type === Enums.ComponentType.WIDGET_CONTAINER &&
        dropZone.location !== Enums.ComponentType.PAGE
      ) {
        delete newItem.propertyValue.widthLaptop;
        delete newItem.propertyValue.widthPhone;
        delete newItem.propertyValue.widthSmall;
        delete newItem.propertyValue.widthTablet;
      }

      const rootNode = getRoot(dropZone.rootLocation, output);

      // 2. 동일 level 이동
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. 동일 parent내에서 이동
        if (pathToItem === pathToDropZone) {
          UIReduxHelper.updateOutputChild(
            output,
            UIDndHelper.moveWithinParent(
              dropZone.location,
              [...rootNode.child],
              splitDropZonePath,
              splitItemPath,
              templateComponents
            ),
            dropZone.rootLocation,
            dispatch
          );
        } else {
          //2.b 다른 parent로 이동
          UIReduxHelper.updateOutputChild(
            output,
            UIDndHelper.moveDifferentParent(
              dropZone.location,
              [...rootNode.child],
              splitDropZonePath,
              splitItemPath,
              newItem,
              templateComponents
            ),
            dropZone.rootLocation,
            dispatch
          );
        }
        //다른 level 이동
      } else {
        //page컴포넌트에서 Footer로 이동하는 경우
        //Footer에서 Page 컴포넌트로 이동하는 경우
        if (item.rootLocation !== dropZone.rootLocation) {
          let updatedLayout = null;
          //location 위치 자체가 달라지기 때문에 output에서 item을 제거해야함
          output = JsonUtils.removeObject(output, "compId", item.compId);
          if (
            StringUtils.equalsIgnoreCase(
              dropZone.rootLocation,
              Enums.ComponentType.PAGE
            )
          ) {
            //target node의 child에 추가
            updatedLayout = UIDndHelper.addChildToChildren(
              [...output.page.child],
              splitDropZonePath,
              item
            );
          } else {
            updatedLayout = UIDndHelper.addChildToChildren(
              [...output.page.footer.child],
              splitDropZonePath,
              item
            );
          }
          // 3. Move + Create
          UIReduxHelper.updateOutputChild(
            output,
            updatedLayout,
            dropZone.rootLocation,
            dispatch
          );
        } else {
          // 3. Move + Create
          UIReduxHelper.updateOutputChild(
            output,
            UIDndHelper.moveDifferentParent(
              dropZone.location,
              [...rootNode.child],
              splitDropZonePath,
              splitItemPath,
              newItem,
              templateComponents
            ),
            dropZone.rootLocation,
            dispatch
          );
        }
      }
    }
  }
};

/**
 * sidebar에서 나온 컴포넌트를 Editor에 쓰일 수 있도록 변환하는 함수
 * UI Editor와 Tree에서 동시에 사용하기 때문에 별도로 분리함
 * @param {Object} props
 * @param {Object} props.componentItem 드래깅 또는 드랍 아이템의 component : item.component
 * @param {Object} props.dropZone
 * @param {Object} props.output
 * @returns
 */
export const generateUiComponent = ({ componentItem, dropZone, output }) => {
  const editorComponentInfo = {};
  if (
    dropZone &&
    componentItem.componentType === Enums.ComponentType.CARD_LAYOUT
  ) {
    if (dropZone.location !== "page") {
      return false;
    }
  }

  editorComponentInfo.compId = `${StringUtils.substringAfter(
    componentItem.componentClass,
    "/"
  )}-${StringUtils.getUuid()}`;
  editorComponentInfo.type = componentItem.componentType;
  editorComponentInfo.baseCompId = componentItem.componentDtlId;
  editorComponentInfo.propertyValue =
    JsonUtils.parseJson(componentItem.defaultProperty) || {};
  editorComponentInfo.editorAttr = JsonUtils.parseJson(
    componentItem.editorAttr
  );
  editorComponentInfo.viewerAttr = JsonUtils.parseJson(
    componentItem.viewerAttr
  );

  if (NamingUtils.hasIdNamingRule(componentItem.componentType)) {
    editorComponentInfo.propertyValue.id = NamingUtils.getComponentId(
      editorComponentInfo.type,
      output.page,
      editorComponentInfo.propertyValue
    );
  }

  return editorComponentInfo;
};

/**
 * Page Mouse over
 * @param {Event} e
 */
export const handlePageMouseOver = (e) => {
  let targetObject = e.target;
  if (e.target.classList.contains("drop-zone")) {
    targetObject = e.target.parentElement;
    if (targetObject.classList.contains("pass-focus")) {
      targetObject = targetObject.parentElement;
    }
  }

  if (
    targetObject.classList.contains("editor-base") &&
    !targetObject.classList.contains("component-select")
  ) {
    targetObject.classList.add("component-hover");
  }
  stopEvent(e);
};

/**
 * Page Mouse out
 * @param {Event} e
 */
export const handlePageMouseOut = (e) => {
  let targetObject = e.target;
  if (e.target.classList.contains("drop-zone")) {
    targetObject = e.target.parentElement;
    if (targetObject.classList.contains("editor-columns")) {
      targetObject = targetObject.parentElement;
    }
  }
  targetObject.classList.remove("component-hover");
  stopEvent(e);
};

/**
 * Page click
 * @param {*} e
 * @param {*} activedComponent
 * @param {*} output
 * @param {*} pageTemplate
 * @returns
 */
export const handlePageClick = (
  e,
  dispatch,
  activedComponent,
  output,
  pageTemplate
) => {
  if (activedComponent.componentType === Enums.ComponentType.PAGE) {
    stopEvent(e);
    return;
  }
  //[activedComponent] --- left properties tab을 활성화 시킨다.
  //activedComponent - json type의 propertyValue 와 compId를 update 하고 activation 시킨다.
  const activedPageComponent = produce(pageTemplate, (draft) => {
    draft.propertyValue = output.page.propertyValue;
    draft.compId = output.page.compId;
  });

  UIReduxHelper.activateComponent(activedPageComponent, dispatch);
  stopEvent(e);
};

/**
 * Component 클릭시 해당 Component를 activation 시키다.
 * @param {*} e
 * @param {*} item 해당 component item
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} activedComponent 현재 active된 component
 * @param {*} dispatch
 * @returns
 */
export const clickComponent = (
  e,
  item,
  components,
  activedComponent,
  dispatch
) => {
  //미리보기는 Click event에서 제외
  let targetObject = null;
  if (e && e.target) targetObject = e.target;
  while (true) {
    if (
      targetObject &&
      (targetObject.classList.contains("drop-zone") ||
        targetObject.classList.contains("pass-focus")) &&
      !ObjectUtils.isEmpty(targetObject.parentElement)
    ) {
      targetObject = targetObject.parentElement;
    } else {
      break;
    }
  }
  if (
    targetObject &&
    (activedComponent.compId === item.compId ||
      (!StringUtils.isEmpty(targetObject.dataset.id) &&
        targetObject.dataset.id !== item.compId))
  ) {
    stopEvent(e);
    return;
  }

  let newActiveComponent;
  //Grid cell/header
  if (
    item.type === Enums.ComponentType.GRID_CELL ||
    item.type === Enums.ComponentType.GRID_HEADER ||
    item.type === Enums.ComponentType.LIST_CELL ||
    item.type === Enums.ComponentType.LIST_HEADER
  ) {
    newActiveComponent = { ...item };

    //grid cell/header 이외 component/layout
  } else {
    let targetComponents = components.filter(
      (compt) => compt.componentDtlId === item.baseCompId
    );
    if (!ArrayUtils.isEmpty(targetComponents)) {
      //activedComponent - json type의 propertyValue 와 compId를 update 하고 activation 시킨다.
      newActiveComponent = produce(targetComponents[0], (draft) => {
        draft.propertyValue = item.propertyValue;
        draft.compId = item.compId;
        if (item.editorAttr) draft.editorAttr = item.editorAttr;
      });
    }
  }
  if (!ObjectUtils.isEmpty(newActiveComponent)) {
    UIReduxHelper.activateComponent(newActiveComponent, dispatch);
  }
  stopEvent(e);
};

/**
 * 신규 활성화될 Component 생성한다.
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} targetNode
 * @param {*} baseComponent
 * @returns
 */
export const getNewActiveComponent = (
  components,
  targetNode,
  baseComponent
) => {
  let activeComponent = {};

  if (baseComponent) {
    activeComponent = cloneDeep(baseComponent);
  } else {
    activeComponent = components.filter(
      (compt) => compt.componentDtlId === targetNode.baseCompId
    );
    if (activeComponent.length > 0) {
      activeComponent = activeComponent[0];
    }
  }

  if (!ObjectUtils.isEmpty(activeComponent)) {
    activeComponent = {
      ...activeComponent,
      compId: targetNode.compId,
    };
    // activeComponent.compId = targetNode.compId;
    activeComponent.propertyValue = cloneDeep(targetNode.propertyValue);
  }
  return activeComponent;
};

/**
 * component(item) 선택 후 복사 (신규 Component를 삽입한다)
 * @param {Event} e
 * @param {String} type "B" : Before, "A" : After
 * @param {*} item 해당 component item
 * @param {*} output
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} dispatch
 * @param {*} propertyValue
 * @param {*} activedComponent 현재 active되어있는 component
 */
export const insertNode = (
  e,
  type,
  item,
  output,
  components,
  dispatch,
  propertyValue,
  activedComponent
) => {
  if (
    item.type === Enums.ComponentType.GRID_CELL ||
    item.type === Enums.ComponentType.GRID_HEADER
  ) {
    insertGridColumn(e, type, item, output, dispatch, propertyValue);
    return;
  }

  if (
    item.type === Enums.ComponentType.LIST_CELL ||
    item.type === Enums.ComponentType.LIST_HEADER
  ) {
    insertListLayoutColumn(e, type, item, output, dispatch, propertyValue);
    return;
  }
  //Editor (dnd container)가 아닌 Properties Tab 등에서 추가가 호출된경우
  //이경우에는 item(component)의 path정보가 없다.
  if (StringUtils.isEmpty(item.path)) {
    insertComponent(
      e,
      type,
      item,
      output,
      components,
      dispatch,
      propertyValue,
      activedComponent
    );
    return;
  }

  //Editor (dnd container)에서 추가가 이루어진 경우
  const splitItemPath = item.path.split("-");
  let activeComponent;
  let updatedLayout;
  let targetNode;

  const root = getRoot(item.rootLocation, output);

  const lastIndex = splitItemPath.length - 1;
  updatedLayout = produce(root, (draft) => {
    let currentNode = draft.child;
    let newNode = {};
    splitItemPath.some((path, index) => {
      if (splitItemPath.length === 1 || index === lastIndex) {
        //newNode = { ...currentNode[Number(path)] }; //하위까지 deep copy가 되지 않음
        newNode = produce(currentNode[Number(path)], (draftNew) => {
          JsonUtils.updateCompId(draftNew); //compId 재생성
          JsonUtils.updateGridId(draftNew, "Grid" + StringUtils.getUuid()); //Grid Id 재 생성
          activeComponent = getNewActiveComponent(components, draftNew);
          //activeComponent.compId = draftNew.compId;
        });

        const insertIndex = type === "B" ? Number(path) : Number(path) + 1;
        //Page 이후 최상위 level 추가시
        if (splitItemPath.length === 1) {
          draft.child = UIDndHelper.insert(draft.child, insertIndex, newNode);
        } else {
          targetNode.child = UIDndHelper.insert(
            targetNode.child,
            insertIndex,
            newNode
          );
        }
        return true;
      } else if (index === lastIndex - 1) {
        targetNode = currentNode[Number(path)];
      }
      currentNode = currentNode[Number(path)].child;
    });
  });

  UIReduxHelper.updateOutputPage(
    output,
    updatedLayout,
    item.rootLocation,
    dispatch
  );
  UIReduxHelper.activateComponent(activeComponent, dispatch);
  stopEvent(e);
};
/**
 * component(item) 선택 후 복사 (신규 Component를 삽입한다)
 * @param {Event} e
 * @param {String} type "B" : Before, "A" : After
 * @param {*} item 해당 component item
 * @param {*} output
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} dispatch
 * @param {*} propertyValue
 * @param {*} activedComponent 현재 active되어있는 component
 */
export const createComponent = (
  e,
  type,
  item,
  output,
  components,
  dispatch,
  propertyValue,
  activedComponent
) => {
  let activeComponent;
  let updatedLayout = produce(output, (draft) => {
    let insertIndex = 0;
    let newNode;
    //parent node
    const parentNode = JsonUtils.findParentNode(
      draft,
      "compId",
      activedComponent.compId
    );
    if (!ArrayUtils.isEmpty(parentNode)) {
      let currIndex = ArrayUtils.getIndex(parentNode, "compId", item.compId);

      newNode = produce(item, (draftNew) => {
        JsonUtils.updateCompId(draftNew);
        activeComponent = getNewActiveComponent(components, draftNew);
        //activeComponent.compId = draftNew.compId;

        if (!ObjectUtils.isEmpty(propertyValue)) {
          draftNew.propertyValue = { ...propertyValue };
        }
      });

      insertIndex = type === "B" ? currIndex : currIndex + 1;
      parentNode.splice(insertIndex, 0, newNode);

      //완전 신규
    } else {
      activeComponent = getNewActiveComponent(components, item);
      //activeComponent.compId = item.compId;
      const targetNode = JsonUtils.findNode(
        draft,
        "compId",
        activedComponent.compId
      );

      if (!ObjectUtils.isEmpty(targetNode)) {
        if (ObjectUtils.isEmpty(targetNode.child)) {
          targetNode.child = [item];
        } else {
          targetNode.child.push(item);
        }
      }
    }
  });

  UIReduxHelper.activateComponent(activeComponent, dispatch);
  UIReduxHelper.updateOutput(updatedLayout, dispatch);
  stopEvent(e);
};

/**
 * component(item) 선택 후 복사 (신규 Component를 삽입한다)
 * @param {Event} e
 * @param {String} type "B" : Before, "A" : After
 * @param {*} item 해당 component item
 * @param {*} output
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} dispatch
 * @param {*} propertyValue
 * @param {*} activedComponent 현재 active되어있는 component
 */
export const insertComponent = (
  e,
  type,
  item,
  output,
  components,
  dispatch,
  propertyValue,
  activedComponent
) => {
  let activeComponent;
  let updatedLayout = produce(output, (draft) => {
    let insertIndex = 0;
    let newNode;
    //parent node
    const parentNode = JsonUtils.findParentNode(draft, "compId", item.compId);
    if (!ArrayUtils.isEmpty(parentNode)) {
      let currIndex = ArrayUtils.getIndex(parentNode, "compId", item.compId);

      newNode = produce(item, (draftNew) => {
        JsonUtils.updateCompId(draftNew);
        activeComponent = getNewActiveComponent(components, draftNew);
        //activeComponent.compId = draftNew.compId;

        if (!ObjectUtils.isEmpty(propertyValue)) {
          draftNew.propertyValue = { ...propertyValue };
        }
      });

      insertIndex = type === "B" ? currIndex : currIndex + 1;
      parentNode.splice(insertIndex, 0, newNode);

      //완전 신규
    } else {
      activeComponent = getNewActiveComponent(components, item);
      //activeComponent.compId = item.compId;
      const targetNode = JsonUtils.findNode(
        draft,
        "compId",
        activedComponent.compId
      );

      if (!ObjectUtils.isEmpty(targetNode)) {
        targetNode.child = [item];
      }
    }
  });

  UIReduxHelper.activateComponent(activeComponent, dispatch);
  UIReduxHelper.updateOutput(updatedLayout, dispatch);
  stopEvent(e);
};

/**
 * 선택된  Node(Component)를 삭제한다.
 * @param {*} e
 * @param {*} item 삭제대상 component(item)
 * @param {*} output
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} pageCompnent /모두 삭제된 경우 Page에 focus를 두기위해
 * @param {*} dispatch
 */
export const removeNode = (
  e,
  item,
  output,
  components,
  pageCompnent,
  dispatch
) => {
  if (
    item.type === Enums.ComponentType.GRID_CELL ||
    item.type === Enums.ComponentType.GRID_HEADER
  ) {
    removeGridColumn(e, item, output, dispatch);
    return;
  }

  if (
    item.type === Enums.ComponentType.LIST_CELL ||
    item.type === Enums.ComponentType.LIST_HEADER
  ) {
    removeListLayoutColumn(e, item, output, dispatch);
    return;
  }

  //그리드나,폼 삭제시 해당 component을 타겟으로 하는 이벤트는 삭제한다.
  if (
    StringUtils.equalsIgnoreCase(item.type, Enums.ComponentType.GRID) ||
    StringUtils.equalsIgnoreCase(item.componentType, Enums.ComponentType.GRID)
  ) {
    output = JsonUtils.removeObject(
      output,
      "target",
      item.propertyValue.gridOptions.gridId
    );
  }
  if (
    StringUtils.equalsIgnoreCase(item.type, Enums.ComponentType.FORM) ||
    StringUtils.equalsIgnoreCase(item.componentType, Enums.ComponentType.FORM)
  ) {
    output = JsonUtils.removeObject(output, "target", item.propertyValue.id);
  }

  //Editor (dnd container)가 아닌 Properties Tab 등에서 삭제가 호출된경우
  //이경우에는 item(component)의 path정보가 없다.
  if (StringUtils.isEmpty(item.path)) {
    removeComponent(e, item, output, components, pageCompnent, dispatch);
    return;
  }

  const root = getRoot(item.rootLocation, output);
  const splitItemPath = item.path.split("-");
  // const filterBtn = JsonUtils.findNodeById(item, "btnFilter");

  let updatedLayout = UIDndHelper.removeChildFromChildren(
    root.child,
    splitItemPath
  );

  let activeComponent;
  //Page 이후 최상위 level 삭제시
  if (splitItemPath.length === 1) {
    //모두 삭제된 경우 Page에 focus
    if (updatedLayout.length === 0) {
      activeComponent = getNewActiveComponent(
        components,
        output.page,
        pageCompnent
      );
    } else {
      const targetIndex = Number(splitItemPath);
      activeComponent = getNewActiveComponent(
        components,
        updatedLayout.length - 1 < targetIndex
          ? updatedLayout[targetIndex - 1]
          : updatedLayout[targetIndex]
      );
    }
    //삭제된 component와 동일 level또는 상위를 선택하게한다.
  } else {
    let currentNode = root.child;
    let targetNode;
    const lastIndex = splitItemPath.length - 1;
    splitItemPath.some((path, index) => {
      if (index === lastIndex) {
        targetNode = currentNode[Number(path) === 0 ? 1 : Number(path) - 1];
        return true;
      } else if (index === lastIndex - 1) {
        if (currentNode[Number(path)].child.length === 1) {
          targetNode = currentNode[Number(path)];
          return true;
        }
      }
      currentNode = currentNode[Number(path)].child;
    });
    activeComponent = getNewActiveComponent(components, targetNode);
  }

  UIReduxHelper.updateOutputChild(
    output,
    updatedLayout,
    item.rootLocation,
    dispatch
  );

  //filter 영역에서 Form 삭제시 상단 page의 propertyValue가 삭제되는 현상 발생
  if (root.pageType !== "filter") {
    UIReduxHelper.activateComponent(activeComponent, dispatch);
  }
  stopEvent(e);
};

/**
 * 선택된  Node(Component)를 삭제한다.
 * @param {*} e
 * @param {*} item 삭제대상 component(item)
 * @param {*} output
 * @param {*} components  component item들 (u_component_dtl)
 * @param {*} pageCompnent /모두 삭제된 경우 Page에 focus를 두기위해
 * @param {*} dispatch
 */
export const removeComponent = (
  e,
  item,
  output,
  components,
  pageCompnent,
  dispatch
) => {
  let updatedLayout = produce(output, (draft) => {
    //parent node
    const parentNode = JsonUtils.findParentNode(draft, "compId", item.compId);
    if (!ArrayUtils.isEmpty(parentNode)) {
      let currIndex = ArrayUtils.getIndex(parentNode, "compId", item.compId);
      parentNode.splice(currIndex, 1);
    }
  });

  UIReduxHelper.updateOutput(updatedLayout, dispatch);

  // const filterBtn = JsonUtils.findNodeById(item, "btnFilter");
  // // 필터버튼 삭제시 필터영역 초기화 조건 추가
  // if (!ObjectUtils.isEmpty(filterBtn)) {
  //   UIReduxHelper.updateOutputCleanFilter(output, updatedLayout, dispatch);
  // } else {
  //   UIReduxHelper.updateOutput(updatedLayout, dispatch);
  // }

  stopEvent(e);
};

/**
 * 선택된  Node(Component) 클립보드에 복사시킨다.
 * @param {*} e
 * @param {*} component
 * @param {*} output
 * @param {*} dispatch
 */
export const copyNode = (e, component, output, dispatch) => {
  stopEvent(e);
  //1. 리덕스에 담기
  findNodeAndsetClipboard(component, output, dispatch);
  Message.alert("Target Copied.", Enums.MessageType.SUCCESS);
};

/**
 * output에서 component를 검색 후 해당 노드를 클립보드에 저장하는 로직
 * @param {*} component
 * @param {*} output
 * @param {Function} setClipboard
 * @returns
 */
export const findNodeAndsetClipboard = (component, output, dispatch) => {
  let currentNode;
  //그리드
  if (component.type === Enums.ComponentType.GRID_HEADER) {
    const compId = component.compId.split("-")[1];
    currentNode = JsonUtils.findNode(output, "compId", compId);
    const updateIdNode = produce(currentNode, (draftNew) => {
      draftNew.type = Enums.ComponentType.GRID_HEADER;
    });
    dispatch(setClipboard(updateIdNode));
  } else {
    //1. 노드 찾기
    currentNode = JsonUtils.findNode(output, "compId", component.compId);
    //3. 리덕스에 담기
    dispatch(setClipboard(currentNode));
  }

  return currentNode;
};

/**
 * 프로그램에서 컴포넌트 잘라내는 로직
 * @param {*} e
 * @param {*} component
 * @param {*} output
 * @param {*} setClipboard
 * @param {*} treeNodeIds
 */
export const cutNode = (
  e,
  component,
  output,
  treeNodeIds,
  context,
  dispatch
) => {
  //1. 리덕스에 담기
  const currentNode = findNodeAndsetClipboard(component, output, dispatch);
  //2. 포커싱 부모 노드로 옮기기
  const parentNode = JsonUtils.findNode(output, "compId", treeNodeIds.at(-2));
  clickComponent(
    e,
    parentNode,
    context.component.getComponentList("B"),
    component,
    dispatch
  );
  //3. 아웃풋에서 삭제
  removeNode(
    e,
    currentNode,
    output,
    context.component.getComponentList("B"),
    context.component.getPageComponent(),
    dispatch
  );
  //4. 트리노드 IDs 맨뒤 하나 삭제
  const newTreeNodeIds = [...treeNodeIds].slice(0, -1);
  dispatch(setBuilderTreeNodeIds(newTreeNodeIds));
  Message.alert("The Target has been removed.", Enums.MessageType.SUCCESS);
};

/**
 * Component collapse, expand event
 * @param {*} e
 * @param {*} item collapse 대상 component(item)
 * @param {*} output
 * @param {*} collapse 현재 collapse되어있는지 여부
 * @param {*} dispatch
 */
export const callaseNode = (e, item, output, collapse, dispatch) => {
  const changedOutput = produce(output.page.child, (draft) => {
    const targetNode = JsonUtils.findNode(draft, "compId", item.compId);
    if (ObjectUtils.isEmpty(targetNode.editorAttr)) {
      targetNode.editorAttr = {};
    }
    targetNode.editorAttr.collapse = !collapse;
  });
  UIReduxHelper.updateOutputChild(
    output,
    changedOutput,
    item.rootLocation,
    dispatch
  );
  stopEvent(e);
};

/**
 * Grid column 선택 후 복사 (신규 column을 삽입한다)
 * @param {Event} e
 * @param {String} type "B" : Before, "A" : After
 * @param {*} item 해당 Grid Column
 * @param {*} output
 * @param {*} dispatch
 * @param {*} propertyValue
 */
export const insertGridColumn = (
  e,
  type,
  item,
  output,
  dispatch,
  propertyValue
) => {
  const splitItemPath = item.path.split("-");
  let activeComponent;
  const updatedLayout = produce(output, (draft) => {
    //grid node
    const gridNode = JsonUtils.findNode(draft, "gridId", item.gridId);
    if (ObjectUtils.isEmpty(gridNode)) {
      console.log("Couldn't find grid node [Grid id : " + item.gridId + "]");
      return false;
    }

    const sourceColIndex = Number(splitItemPath[splitItemPath.length - 1]);
    const insertIndex = type === "B" ? sourceColIndex : sourceColIndex + 1;

    const newColNode = ObjectUtils.isEmpty(propertyValue)
      ? cloneDeep(gridNode.columns[sourceColIndex])
      : cloneDeep(propertyValue);
    newColNode.compId = StringUtils.getUuid();
    newColNode.name = "New-" + newColNode.compId;

    let newPath = [...splitItemPath];
    newPath[splitItemPath.length - 1] = insertIndex;
    activeComponent = {};
    activeComponent.compId = "Header-" + newColNode.compId;
    activeComponent.gridId = item.gridId;
    activeComponent.name = newColNode.name;
    activeComponent.type = Enums.ComponentType.GRID_HEADER;
    activeComponent.componentClass = "grid/GridHeader";
    activeComponent.propertyValue = cloneDeep(newColNode);
    activeComponent.path = newPath.join("-");

    gridNode.columns = UIDndHelper.insert(
      gridNode.columns,
      insertIndex,
      newColNode
    );

    //sortOrder 재조정
    gridNode.columns.map((column, index) => {
      column.sortOrder = index + 1;
    });
  });
  if (updatedLayout !== false && !ObjectUtils.isEmpty(updatedLayout)) {
    UIReduxHelper.updateOutput(updatedLayout, dispatch);
    UIReduxHelper.activateComponent(activeComponent, dispatch);
  }
  stopEvent(e);
};

/**
 * 선택된  Grid column을  삭제한다.
 * @param {*} e
 * @param {*} item 삭제대상 Column
 * @param {*} output
 * @param {*} dispatch
 */
export const removeGridColumn = (e, item, output, dispatch) => {
  const splitItemPath = item.path.split("-");
  let activeComponent;

  const updatedLayout = produce(output, (draft) => {
    //grid node
    const gridNode = JsonUtils.findNode(draft, "gridId", item.gridId);
    if (ObjectUtils.isEmpty(gridNode)) {
      console.log("Couldn't find grid node [Grid id : " + item.gridId + "]");
      return false;
    }
    //column이 1개 있을 경우 지우지 않는다.
    if (gridNode.columns.length === 1) {
      return false;
    }

    let targetColIndex = Number(splitItemPath[splitItemPath.length - 1]);
    let activeColIndex = targetColIndex;
    gridNode.columns.splice(targetColIndex, 1); //not Immutable (원본삭제)

    if (gridNode.columns.length - 1 < targetColIndex)
      activeColIndex = gridNode.columns.length - 1;
    else activeColIndex = targetColIndex;

    activeComponent = cloneDeep(gridNode.columns[activeColIndex]);
    activeComponent.compId =
      (item.type === Enums.ComponentType.GRID_HEADER ? "Header-" : "Cell-") +
      activeComponent.compId;
    activeComponent.gridId = item.gridId;
    activeComponent.componentClass =
      item.type === Enums.ComponentType.GRID_HEADER
        ? "grid/GridHeader"
        : "grid/GridCell";

    //sortOrder 재조정
    gridNode.columns.map((column, index) => {
      column.sortOrder = index + 1;
    });
  });

  if (updatedLayout !== false && !ObjectUtils.isEmpty(updatedLayout)) {
    UIReduxHelper.updateOutput(updatedLayout, dispatch);
    UIReduxHelper.activateComponent(activeComponent, dispatch);
  }
  stopEvent(e);
};

/**
 * ListLayout column 선택 후 복사 (신규 column을 삽입한다)
 * @param {Event} e
 * @param {String} type "B" : Before, "A" : After
 * @param {*} item 해당 Grid Column
 * @param {*} output
 * @param {*} dispatch
 * @param {*} propertyValue
 */
export const insertListLayoutColumn = (
  e,
  type,
  item,
  output,
  dispatch,
  propertyValue
) => {
  const splitItemPath = item.path.split("-");
  let activeComponent;
  const updatedLayout = produce(output, (draft) => {
    const listLayoutId = item.templateDetail.id;
    //list node
    const listNode = JsonUtils.findNode(draft, "id", listLayoutId);
    if (ObjectUtils.isEmpty(listNode)) {
      console.log("Couldn't find list node [List id : " + listLayoutId + "]");
      return false;
    }

    const sourceColIndex = Number(splitItemPath[splitItemPath.length - 1]);
    const insertIndex = type === "B" ? sourceColIndex : sourceColIndex + 1;

    const newColNode = ObjectUtils.isEmpty(propertyValue)
      ? cloneDeep(listNode.columns[sourceColIndex])
      : cloneDeep(propertyValue);
    newColNode.compId = StringUtils.getUuid();
    newColNode.name = "New-" + newColNode.compId;

    let newPath = [...splitItemPath];
    newPath[splitItemPath.length - 1] = insertIndex;
    activeComponent = {};
    activeComponent.compId = "Header-" + newColNode.compId;
    activeComponent.id = listLayoutId;
    activeComponent.name = newColNode.name;
    activeComponent.type = Enums.ComponentType.LIST_HEADER;
    activeComponent.componentClass = "layout/list/ListHeader";
    activeComponent.propertyValue = cloneDeep(newColNode);
    activeComponent.path = newPath.join("-");

    listNode.columns = UIDndHelper.insert(
      listNode.columns,
      insertIndex,
      newColNode
    );

    //sortOrder 재조정
    listNode.columns.map((column, index) => {
      column.sortOrder = index + 1;
    });
  });
  if (updatedLayout !== false && !ObjectUtils.isEmpty(updatedLayout)) {
    UIReduxHelper.updateOutput(updatedLayout, dispatch);
    UIReduxHelper.activateComponent(activeComponent, dispatch);
  }
  stopEvent(e);
};

/**
 * 선택된  listLayout column을  삭제한다.
 * @param {*} e
 * @param {*} item 삭제대상 Column
 * @param {*} output
 * @param {*} dispatch
 */
export const removeListLayoutColumn = (e, item, output, dispatch) => {
  const splitItemPath = item.path.split("-");
  let activeComponent;

  const updatedLayout = produce(output, (draft) => {
    const listLayoutId = item.templateDetail.id;
    //node
    const listNode = JsonUtils.findNode(draft, "id", listLayoutId);
    if (ObjectUtils.isEmpty(listNode)) {
      console.log("Couldn't find list node [List id : " + listLayoutId + "]");
      return false;
    }
    //column이 1개 있을 경우 지우지 않는다.
    if (listNode.columns.length === 1) {
      return false;
    }

    let targetColIndex = Number(splitItemPath[splitItemPath.length - 1]);
    let activeColIndex = targetColIndex;
    listNode.columns.splice(targetColIndex, 1); //not Immutable (원본삭제)

    if (listNode.columns.length - 1 < targetColIndex)
      activeColIndex = listNode.columns.length - 1;
    else activeColIndex = targetColIndex;

    activeComponent = cloneDeep(listNode.columns[activeColIndex]);
    activeComponent.compId =
      (item.type === Enums.ComponentType.LIST_HEADER ? "Header-" : "Cell-") +
      activeComponent.compId;
    activeComponent.id = listLayoutId;
    activeComponent.componentClass =
      item.type === Enums.ComponentType.LIST_HEADER
        ? "layout/list/ListHeader"
        : "layout/list/ListCell";

    //sortOrder 재조정
    listNode.columns.map((column, index) => {
      column.sortOrder = index + 1;
    });
  });

  if (updatedLayout !== false && !ObjectUtils.isEmpty(updatedLayout)) {
    UIReduxHelper.updateOutput(updatedLayout, dispatch);
    UIReduxHelper.activateComponent(activeComponent, dispatch);
  }
  stopEvent(e);
};
