import ActionType from "components/builder/BuilderActionType";
import StyleUtils from "components/common/utils/StyleUtils";

export function setIsMobileEditor(flag) {
  return {
    type: ActionType.MOBILE.IS_MOBILE_EDITOR,
    payload: flag,
  };
}

export function setMobileScreenWidth(value) {
  return {
    type: ActionType.MOBILE.SCREEN_WIDTH,
    payload: value,
  };
}

export function setMobileScreenHeight(value) {
  return {
    type: ActionType.MOBILE.SCREEN_HEIGHT,
    payload: value,
  };
}

export function setAppLayoutList(value) {
  return {
    type: ActionType.MOBILE.SET_APP_LAYOUT,
    payload: value,
  };
}

/**
 * Property Tab 보여줄지 여부
 * @param {*} flag
 */
export function setShowPropertiesTab(flag) {
  return {
    type: ActionType.MOBILE.SET_PROP_TAB,
    payload: flag,
  };
}

/**
 * Viewport 방햘 설정
 * @param {*} flag
 */
export function setViewportDirection(dir) {
  return {
    type: ActionType.MOBILE.SET_VIEWPORT_DIRECTION,
    payload: dir,
  };
}

/**
 * device OS 설정
 * @param {*} flag
 */
export function setDeviceOs(os) {
  return {
    type: ActionType.MOBILE.SET_DEVICE_OS,
    payload: os,
  };
}

/**
 * device Button 노출 설정
 * @param {*} flag
 */
export function setShowDeviceButton(flag) {
  return {
    type: ActionType.MOBILE.SET_DEVICE_BUTTON_SHOW,
    payload: flag,
  };
}
/**
 * device Button 노출 설정
 * @param {*} flag
 */
export function setShowEmulator(flag) {
  return {
    type: ActionType.MOBILE.SET_SHOW_EMULATOR,
    payload: flag,
  };
}

export function setMobileApp(app) {
  return {
    type: ActionType.MOBILE.SET_MOBILE_APP,
    payload: app,
  };
}

export function setViewportScale(scale) {
  return {
    type: ActionType.MOBILE.SET_VIEWPORT_SCALE,
    payload: scale,
  };
}

export function setActiveFilter(filterProgram) {
  return {
    type: ActionType.MOBILE.SET_ACTIVE_FILTER,
    payload: filterProgram,
  };
}

export function initMobile() {
  StyleUtils.ApplyOffStyle(null, "C-theme");

  return {
    type: ActionType.MOBILE.INIT_MOBILE,
  };
}
