import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import NumberUtils from "components/common/utils/NumberUtils";
import SessionUtils from "components/common/utils/SessionUtils";
import { createPortal } from "react-dom";
import moment from "moment";
import Message from "../Message";
import { Enums } from "components/builder/BuilderEnum";
import LocalStorageService from "services/common/LocalService";

class CommonUtils {
  static optionalPortal = (styles, element) => {
    if (styles.position === "fixed") {
      return createPortal(
        element,
        document.getElementById("builder-draggable")
      );
    }
    return element;
  };

  /**
   *
   * @param {Date || moment} dateTime
   * @returns moment()
   */
  static getTZTime(_dateTime) {
    const dateTime = new Date(_dateTime);
    const currentTzOffset = new Date().getTimezoneOffset();
    // const currentTzTime = moment(dateTime).add(currentTzOffset, "m");
    return moment(dateTime);
  }

  /**
   *
   * @param {*} _dateTime
   * @param {"date"|"time"|"datetime"} dateType
   * @returns
   */
  static getDate(_dateTime, dateType = "date") {
    if (!_dateTime) return null;
    const nowDate = moment(this.getTZTime(_dateTime));

    if (dateType.toLocaleLowerCase() === "date") {
      return moment(nowDate).format("YYYY-MM-DD");
    } else if (dateType.toLocaleLowerCase() === "time") {
      return moment(nowDate).format("HH:mm:ss");
    } else if (dateType.toLocaleLowerCase() === "datetime") {
      return moment(nowDate).format("YYYY-MM-DD HH:mm:ss");
    }
  }

  static getBytesFormat(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * 칼럼에 입력하는 항목
   *  - 첫 글자는 영어만 가능
   *  - 그 뒤에는 영어 또는 숫자
   *  - 특수 문자 불가
   * @param {String} str
   */
  static CheckOnlyEng(str) {
    if (str.length === 1) {
      let reg_exp = new RegExp("^[a-zA-Z]");
      let match = reg_exp.exec(str);
      if (!match) {
        Message.alert(
          "The first character must be an English.",
          Enums.MessageType.WARN
        );
        return "";
      } else {
        return str;
      }
    } else if (str.length > 1) {
      let reg_exp = new RegExp("^[a-zA-Z][a-z|A-Z|0-9|]+$");
      let match = reg_exp.exec(str);
      if (!match) {
        Message.alert(
          "Input format is Invalid.\n The first character must be an English, and the rest can be either letters or numbers.",
          Enums.MessageType.WARN
        );
        return str.slice(0, str.length - 1);
      } else {
        return str;
      }
    } else {
      return str;
    }
  }

  /**
   * App에서 ConfigCd 값을 가져오는 로직
   * App 필수
   * @param {*} app
   * @param {'appAccessibleYn'|'iconAttachYn'|'trdUseYn'|'gptYn'|'defaultValueUseYn'|'globalVariableUseYn'|'theme'} configCd 설정코드
   * @param {Boolean} isInCludeConfigData config 전체 데이터 리턴 여부
   * @returns
   */
  static getAppConfig = (app, configCd, isInCludeConfigData = false) => {
    if (!app) {
      const hList = LocalStorageService.get(
        Enums.LocalStorageName.WORKSPACE_HISTORY
      ).list;
      app = hList ? hList[0] : null;
    }
    if (ObjectUtils.isEmpty(app)) return null;
    if (!configCd) return app.config;
    const config = app.config.find((config) =>
      StringUtils.equalsIgnoreCase(config.configCd, configCd)
    );
    if (config) {
      if (isInCludeConfigData) {
        return config;
      } else return config.configValue;
    } else {
      return null;
    }
  };

  /**
   * 활성화 된 Workspace 테마
   */
  static getTheme = () => {
    // return "common";
    let defaultTheme = "common";
    const workspaceTheme = this.getAppConfig(null, "theme");
    if (workspaceTheme) {
      return workspaceTheme;
    } else {
      return defaultTheme;
    }
  };

  /**
   * 다차원 배열 or 깊은 depth의 오브젝트를 Deep Copy 하는 함수
   * @param {Object} copyData copy할 원본 데이터
   * @returns Deep Copy Data
   */
  static deepCopy = (copyData) => {
    return JSON.parse(JSON.stringify(copyData));
  };

  /**
   * 스페이스바를 입력하지 못하도록 하는 로직
   * Entity Variable 입력할때 사용
   * @param {*} e
   * @returns
   */
  static preventInputSpace = (e) => {
    //공백을 허용하지 않는 정규식
    const noSpaceAnywhereRegex = /^[^\s]+$/;
    if (typeof e.target.value === "string") {
      if (noSpaceAnywhereRegex.test(e.target.value)) {
        return e;
      } else {
        Message.alert(
          `Spaces (" ") are not allowed as input.`,
          Enums.MessageType.INFO
        );
        e.target.value = String(e.target.value).replaceAll(/\s/g, "");
        return e;
      }
    } else {
      return e;
    }
  };
}

export {
  StringUtils,
  ObjectUtils,
  ArrayUtils,
  JsonUtils,
  NumberUtils,
  SessionUtils,
};

export default CommonUtils;
