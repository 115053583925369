/*!
 * Builder TemplateHandler for react v.17
 *
 * Bizentro Layout Template을 구성하기 위한 Handler
 * Side component panel에서 Template을 Drag & Drop 할때 실행됨
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import MobileGridLayoutTemplate from "components/builder/mobile/mobileComponents/template/GridLayoutTemplate";
import ListLayoutTemplate from "components/builder/mobile/mobileComponents/template/ListLayoutTemplate";

import Popup from "components/common/Popup";
import CommonUtils, {
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";

import { lazy, Suspense } from "react";

/**
 * Layout의 설정에 따라 Editor에 각 Component를 구성한다.
 * @param {*} pOutput
 * @param {*} pItem
 * @param {*} pOptions
 * @param {*} pTemplateComponents
 * @param {*} pCallbackFnc
 */
const UITemplateHandler = (
  pDropZone,
  pOutput,
  pItem,
  pOptions,
  pTemplateComponents,
  pCallbackFnc
) => {
  const dropZone = pDropZone;
  const output = pOutput;
  const item = pItem;
  const templateComponents = pTemplateComponents;
  const options = pOptions;
  let theme = CommonUtils.getTheme();

  /**
   * Caller의 callback function 호출
   * @param {*} data
   */
  const callbackTemplateFnc = (data) => {
    if (typeof pCallbackFnc === "function") {
      pCallbackFnc.call(this, data);
    }
  };

  /**
   * Caller의 callback function 호출
   * @param {*} data
   */
  const callbackGridFnc = (data) => {
    data.propertyValue.gridOptions.columns.forEach((element) => {
      if (StringUtils.includes(element.dataType, ["numeric", "number"])) {
        element.className = "header-right";
      } else {
        element.className = "header-left";
      }
    });

    if (typeof pCallbackFnc === "function") {
      pCallbackFnc.call(this, data);
    }
  };

  /**
   * Caller의 callback function 호출
   * @param {*} data
   */
  const callbackListLayoutFnc = (data) => {
    data.propertyValue.templateDetail.columns.forEach((element) => {
      if (StringUtils.includes(element.dataType, ["numeric", "number"])) {
        element.className = "header-right";
      } else {
        element.className = "header-left";
      }
    });

    if (typeof pCallbackFnc === "function") {
      pCallbackFnc.call(this, data);
    }
  };

  /**
   * 모든 Template 설정 popup 오픈
   */
  const openPopup = () => {
    let Component = null;
    if (item.isMobile) {
      Component = lazy(() =>
        import(
          `components/builder/mobile/mobileComponents/${item.component.componentClass}`
        )
      );
    } else {
      Component = lazy(() =>
        import(
          `components/builder/ui/editor/theme/${theme}/${item.component.componentClass}`
        )
      );
    }
    componentPopupSet(Component);
  };

  /**
   * 컴포넌트 설정 팝업 set
   * 모바일과 UI builder 팝업파일만 다르고 Props는 같아서 분기처리를 위해 컴포넌트를 받아서 사용하는 함수로 처리
   * @param {*} Component
   */
  const componentPopupSet = (Component) => {
    if (!ObjectUtils.isEmpty(options.popupOptions)) {
      const popupOptions = options.popupOptions;
      const defaultOptions = { style: { content: {} } };
      if (!StringUtils.isEmpty(popupOptions.width)) {
        defaultOptions.style.content.width = popupOptions.width;
      }
      if (!StringUtils.isEmpty(popupOptions.height)) {
        defaultOptions.style.content.height = popupOptions.height;
      }
      let defaultValues = options.defaultValues;
      if (item.isMobile) {
        defaultValues = options.mobileDefaultValues
          ? options.mobileDefaultValues
          : options.defaultValues;
      }

      Popup.open(
        <Suspense fallback={<div></div>}>
          <Component
            componentInfo={item.component}
            defaultValues={defaultValues}
            templateComponents={templateComponents}
            dropZone={dropZone}
            output={output}
            event="layout"
            callbackFnc={callbackTemplateFnc}
          />
        </Suspense>,
        defaultOptions,
        "TemplateSettingsPopup"
      );
    }
  };

  /**
   * Grid component 설정 popup 오픈
   * @param {String} source
   */
  const openGridPopup = (source) => {
    //데이터 모델 ID -> 페이지에서 선택된 경우
    let dataModelId = JsonUtils.findDataModel(output, output.page.compId, true);
    //tableMstId -> Workflow에서 리턴된 trd Table을 사용하는 경우
    let tableMstId = null;
    //entityId -> Entity에서 리턴된 trd Table을 사용하는 경우
    let entityId = null;
    // DataStudio 에서 나온 Element 인지 여부
    let isDataStudioElement = false;
    // workflow 리턴 data에 필요한 값
    let entityVariable = null;
    let workflowEntityFieldList = null;
    let fromCompId = null;
    // DataModel 사용시 참조 Element List
    let entityElementList = [];

    let defaultOptions = { style: { content: {} } };
    if (
      !ObjectUtils.isEmpty(options.popupOptions) &&
      !StringUtils.isEmpty(options.popupOptions.width)
    ) {
      defaultOptions.style.content.width = options.popupOptions.width;
    }
    //TRD TABLE MST ID 정보 확인을 위해서는 ViewerAttr정보에 있는 tableMstId 또는 DatamodelId를 넘긴다.
    if (!ObjectUtils.isEmpty(item.component.viewerAttr)) {
      const attr = item.component.viewerAttr;
      tableMstId = attr?.tableMstId; //trdTableId
      entityId = attr?.entityId;
      if (tableMstId || entityId) {
        isDataStudioElement = true;
      }
    }
    if (!ObjectUtils.isEmpty(item.component.editorAttr)) {
      const attr = item.component.editorAttr;
      entityVariable = attr.entityVariable;
      entityElementList = attr.entityElementList;
      workflowEntityFieldList = attr.workflowEntityFieldList; // workflowEntityFieldList
      fromCompId = attr.fromCompId; // workflowEntityFieldList
      if (entityVariable) {
        isDataStudioElement = true;
      }
    }
    let GridLayoutTemplate = lazy(() =>
      import(
        `components/builder/ui/editor/theme/${theme}/template/GridLayoutTemplate`
      )
    );
    const Component = item.isMobile
      ? MobileGridLayoutTemplate
      : GridLayoutTemplate;

    let defaultValues = options.defaultValues;
    if (item.isMobile) {
      defaultValues = options.mobileDefaultValues
        ? options.mobileDefaultValues
        : options.defaultValues;
    }

    Popup.open(
      <Suspense fallback={<div></div>}>
        <Component
          componentInfo={item.component}
          defaultValues={defaultValues}
          templateComponents={templateComponents}
          output={output}
          event={source}
          dropZone={dropZone}
          callbackFnc={callbackGridFnc}
          dataModelId={dataModelId}
          tableMstId={tableMstId}
          entityId={entityId}
          entityVariable={entityVariable}
          workflowEntityFieldList={workflowEntityFieldList}
          fromCompId={fromCompId}
          entityElementList={entityElementList}
          isDataStudioElement={isDataStudioElement}
        />
      </Suspense>,
      defaultOptions,
      "TemplateSettingsPopup"
    );
  };

  /**
   * ListLayout component 설정 popup 오픈
   */
  const openListLayoutPopup = () => {
    let defaultOptions = { style: { content: {} } };
    if (
      !ObjectUtils.isEmpty(options.popupOptions) &&
      !StringUtils.isEmpty(options.popupOptions.width)
    ) {
      defaultOptions.style.content.width = options.popupOptions.width;
    }

    const Component = ListLayoutTemplate;

    let defaultValues = options.defaultValues;

    Popup.open(
      <Suspense fallback={<div></div>}>
        <Component
          componentInfo={item.component}
          defaultValues={defaultValues}
          templateComponents={templateComponents}
          output={output}
          dropZone={dropZone}
          callbackFnc={callbackListLayoutFnc}
        />
      </Suspense>,
      defaultOptions,
      "TemplateSettingsPopup"
    );
  };

  switch (item.component.componentClass) {
    case "template/SearchFormLayoutTemplate": //검색 Form layout template
    case "template/FilterFormLayoutTemplate": //Filter Form layout template
    case "template/InputGroupTemplate": //InputGroupTemplate layout template
    case "template/TabTemplate": //TabTemplate layout template
    case "template/StepWizardTemplate": //StepWizardTemplate layout template
    case "layout/Table": //Form layout template
    case "widget/NumericMetricTemplate": //Form layout template
    case "widget/FormWidgetTemplate": //form Widget
    case "widget/TextWidgetTemplate": //Text Widget
    case "widget/ListWidgetTemplate": //List Widget
    case "widget/chart/AMChart3WidgetTemplate": //Chart Widget
    case "template/FormLayoutTemplate": //Form layout template
      openPopup();
      break;
    case "layout/list/ListLayout":
      openListLayoutPopup();
      break;
    case "template/GridLayoutTemplate": //Grid layout template
      openGridPopup("template");
      break;
    case "grid/Grid": //Grid 단독 component template
      openGridPopup("component");
      break;
    default:
    //nothing
  }
};

export default UITemplateHandler;
