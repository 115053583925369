import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import PageTemplate from "page/common/PageTemplate";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaList } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";

/**
 * 2개의 히스토리를 비교하는 페이지
 * @returns
 */
function MobileProgramHistoryCompare() {
  const { programId, historyMstId } = useParams(); // => historyMstId 로 변경됨
  const navigate = useNavigate();
  const [historyList, setHistoryList] = useState([]);
  const [selectedHistories, setSelectedHistories] = useState([]);

  const [sourceContent, setSourceContent] = useState({});
  const [targetyContent, setTargetyContent] = useState({});
  const [diffList, setDiffList] = useState([]);

  const diffCompId = useRef([]);

  const breadcrum = [
    { name: "Program History", url: Enums.BuilderPath.HISTORY },
    { name: "Compare Program History", subName: "", active: true },
  ];

  useEffect(() => {
    getHistoryList();
  }, []);
  useEffect(() => {
    if (!ArrayUtils.isEmpty(historyList)) {
      getHistories();
    }
  }, [historyList]);

  const getHistoryList = () => {
    const body = {
      historyMstId,
    };
    ProgramService.selectProgramHistoryGrid(
      body,
      (res) => {
        setHistoryList(res.data);
      },
      () => {
        Message.alert(
          "An error occurred while loading the list.",
          Enums.MessageType.ERROR
        );
      }
    );
  };

  /**
   * 선택된 이력 목록을 불러옴
   * 선택된 이력목록이 없는 경우 제일 최신 데이터를 가져온다.
   */
  const getHistories = async () => {
    const selectList = [...selectedHistories];
    if (ArrayUtils.isEmpty(selectList)) {
      selectList.push(historyList[0]);
      selectList.push(historyList[1]);
    }

    /**
     * 1. 선택된 항목을 최종 수정일 오름 차순으로 수정
     *  1-1. 선택된 항목이 없는 경우(최초 열기) 최상단 항목과 바로 다음 항목을 비교한다.
     *  1-2. 선택된 항목이 1개 뿐이면 선택된 항목과 다음 항목을 비교한다.
     * 2. 날짜 조정
     * 3. 각 항목을 순회하면서 데이터를 가져온다.
     *  3-0 . 각 항목을 순회할때 ProgramContents가 있으면 불러오지 않는다.
     * 4. 불러온 항목을 정리하여 트리에 넣는다.
     */

    selectList.sort((a, b) => {
      if (a.updtDt > b.updtDt) {
        return -1;
      } else {
        return 1;
      }
    });

    //날짜(최종 수정일)별로 순서
    if (!ArrayUtils.isEmpty(selectList)) {
      const tmpSelectList = [...selectList];
      const promiseList = [];

      for (const history of tmpSelectList) {
        promiseList.push(
          new Promise((resolve, reject) => {
            const { programContent: historyProgramContent, programHistoryId } =
              history;
            if (ObjectUtils.isEmpty(historyProgramContent)) {
              ProgramService.getProgramHistoryDetail(
                {
                  programHistoryId,
                },
                (res) => {
                  history.programContent = JSON.parse(res.data.programContent);
                  resolve(history);
                },
                (err) => {
                  reject(err);
                }
              );
            }
          })
        );
      }
      try {
        const resultList = await Promise.all(promiseList);
        compareProgramContent(
          resultList[1].programContent,
          resultList[0].programContent
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  /**
   *
   */
  const compareProgramContent = (targetProgram, sourceProgram) => {
    console.log(sourceProgram, targetProgram);

    const sourcePage = sourceProgram.page;
    const targetPage = targetProgram.page;

    //page는 컴포넌트 타입이 안붙기 때문에 억지로 붙임
    sourcePage.type = "page";
    targetPage.type = "page";

    /**
     * 1. 각 페이지를 분리한다.
     * 2. target 페이지를 기준으로 순회한다.
     * 3. target 페이지의 각 오브젝트를 순회할때 키값을 비교한다.
     *  3-1. 같은 키로 소스 페이지와 비교한다.
     * 4. 해당 키(compId)가 소스 페이지에서 보이지 않으면 해당 컴포넌트는 추가된 컴포넌트이다.
     * 5. 해당 키의 값이 source와 다르면 updated 컴포넌트이다.
     * 6. 해당 키(compId)가 소스페이지에서 확인되지 않으면 삭제된 컴포넌트이다.
     */

    const deletedComponentCompId = [];
    const updatedComponentCompId = [];
    const addedComponentCompId = [];

    const isComponent = (obj) => {
      return obj.compId ? true : false;
    };

    // let keyEntries = [];

    //비교 하지 않는 키
    const unCompareKey = ["lastUpdatedDate"];
    /**
     *  - 비교기준
     * 1. compId가 있으면 컴포넌트라고 판단한다.
     * 2. child로 들어갈때는, 키엔트리를 통해서 소스코드함께 비교한다.
     *  - target child와 source child의 길이가 다르면 양쪽을 비교한다.
     *  - target에만 있는 컴포넌트는 삭제된, source에만 있는 컴포넌트는 추가된 항목으로 분류한다.
     * 3. 양쪽을 비교한다음에 서로에게(엄밀히 본다면 target에만) 있는 항목을 순회하면서 어떤 부분이 달라졌는지 확인한다.
     *  */

    /**
     * target 의 오브젝트 비교
     * @param {*} object
     * @param {*} keyPath
     */
    const comparePage = (object, keyPath = []) => {
      const keyPathCopy = [...keyPath];
      try {
        if (isComponent(object)) {
          // if (keyPathCopy.length > 0) {
          //   const objPath = `[${keyPathCopy.join("][")}]`;
          //   sourceObj = eval(`sourcePage${objPath}`);
          // } else {
          //   sourceObj = { ...sourcePage };
          // }

          const loopObj = (obj, cKeyPath = []) => {
            try {
              const cKeyPathCopy = [...cKeyPath];
              let sourceObj = null;
              if (cKeyPathCopy.length > 0) {
                const objPath = `[${cKeyPathCopy.join("][")}]`;
                sourceObj = eval(`sourcePage${objPath}`);
              } else {
                sourceObj = sourcePage;
              }

              for (const key in obj) {
                if (key === "child") {
                  /**
                   * 1. 둘다 자녀를 비교한다.
                   * 2. 둘의 길이 차이가 있으면 compId를 비교하여 어떤게 추가되고 삭제되었는지 확인한다.
                   * 3. 길이 차이가 같으면 comparePage 반복
                   */
                  const targetChild = obj[key];
                  const sourceChild = sourceObj[key];
                  if (targetChild.length !== sourceChild.length) {
                    for (const tobj of targetChild) {
                      const isExist = sourceChild.findIndex(
                        (sobj) => sobj.compId === tobj.compId
                      );
                      if (isExist === -1)
                        deletedComponentCompId.push(tobj.compId);
                    }
                    for (const sobj of sourceChild) {
                      const isExist = targetChild.findIndex(
                        (tobj) => sobj.compId === tobj.compId
                      );
                      if (isExist === -1)
                        addedComponentCompId.push(sobj.compId);
                    }
                  } else {
                    for (const index in targetChild) {
                      cKeyPathCopy.push(`"${key}"`);
                      cKeyPathCopy.push(index);
                      comparePage(targetChild[index], cKeyPathCopy);
                    }
                  }
                } else if (ObjectUtils.isObject(obj[key])) {
                  loopObj(obj[key], sourceObj[key]);
                } else if (obj[key] !== sourceObj[key]) {
                  updatedComponentCompId.push(object.compId);
                }
              }
            } catch (error) {
              console.log(cKeyPath);
            }
          };

          loopObj(object, keyPathCopy);
        }
      } catch (error) {
        // console.log(keyPathCopy);
        // console.log(error);
      }
    };

    comparePage(targetPage);
    console.log(
      deletedComponentCompId,
      updatedComponentCompId,
      addedComponentCompId
    );
  };

  const columns = [
    { field: "programHistoryId", headerName: "history ID", width: 150 },
    {
      field: "commitComment",
      headerName: "save Comment",
      width: "*",
      renderCell: (params) => {
        if (StringUtils.isEmpty(params.commitComment)) return "코멘트 없음";
        else return params.commitComment;
      },
    },
    {
      field: "updtUserId",
      headerName: "update User",
      width: 100,
    },
    {
      field: "updtDt",
      headerName: "update Date",
      width: 200,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "dateTime")}`,
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line mb-3">
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              navigate(
                `${Enums.BuilderPath.MOBILE.MAIN}/${Enums.BuilderPath.MOBILE.HISTORY}`
              );
            }}
          >
            <FaList /> List
          </Button>
        </div>
        <div style={{ width: "100%", height: "480px" }} className="mb-1">
          <div
            className="compare-tab-wrapper"
            style={{
              width: "100%",
              display: "flex",
              borderBottom: "1px solid lightgray",
              height: "30px",
            }}
          >
            <div
              className="tab"
              style={{
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: "1px solid lightgray",
                borderRight: "1px solid lightgray",
                borderTop: "1px solid lightgray",
              }}
              onClick={getHistories}
            >
              Document Structure
            </div>
            <div
              className="tab"
              style={{
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: "1px solid lightgray",
                borderRight: "1px solid lightgray",
                borderTop: "1px solid lightgray",
              }}
            >
              Screen
            </div>
            <div
              className="tab"
              style={{
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderLeft: "1px solid lightgray",
                borderRight: "1px solid lightgray",
                borderTop: "1px solid lightgray",
              }}
            >
              Difference
            </div>
          </div>
          <div
            className="compare-view"
            style={{
              display: "flex",
              height: "calc(100% - 30px)",
              border: "1px solid lightgray",
              borderTop: "none",
            }}
          >
            <div></div>
            <div></div>
          </div>
        </div>
        <div style={{ height: "200px" }}>
          <WijmoGrid
            columns={columns}
            rows={historyList}
            style={{ height: "100%" }}
          />
        </div>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MobileProgramHistoryCompare;
