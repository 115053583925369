import { connect } from "react-redux";
import BaseListCell from "components/builder/mobile/mobileComponents/layout/list/BaseListCell";

class ListCell extends BaseListCell {}

export default connect(
  (state) => {
    return {
      workspace: state.workspace,
    };
  },
  (dispatch) => {
    return {};
  }
)(ListCell);
