import { Enums } from "components/builder/BuilderEnum";
import BuilderSidebarContextMenu from "components/builder/contextMenu/BuilderSidebarContextMenu";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import ProgramListPopup from "page/popup/ProgramListPopup";
import { Form } from "react-bootstrap";
import {
  AiFillEdit,
  AiOutlineFileAdd,
  AiOutlineHistory,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { RxOpenInNewWindow } from "react-icons/rx";
import { connect } from "react-redux";
import LocalStorageService from "services/common/LocalService";
import ProgramService from "services/ui/ProgramService";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "../eventhandler/reducer/EventHandlerAction";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";

class ProgramMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.onNewProgram = this.onNewProgram.bind(this);
    this.onLoadProgram = this.onLoadProgram.bind(this);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    this.getRecentMenuList(Enums.LocalStorageName.MOBILE_PROGRAM_HISTORY);
  }

  /**
   * 신규 프로그램
   * @param {*} event
   * @returns
   */
  onNewProgram(event) {
    if (event) event.preventDefault();

    if (!this.props.isMobileEditor) {
      UIReduxHelper.setUIOutput(this.dispatch, this.props.output);
    }

    const _goNext = () => {
      const componentInfo = this.context.component.getPageComponent(); //Page Component
      componentInfo.builderType = Enums.BuilderType.MOBILE;

      componentInfo.filter = {
        page: {
          pageType: Enums.ComponentType.FILTER,
          child: [],
        },
      };

      UIReduxHelper.createPage(this.dispatch, componentInfo);
      this.props.updateEventWorkspace({});
      this.props.updateEventOutput("");
      UIReduxHelper.updateInformation(this.dispatch, null);
      this.goToPage(Enums.BuilderPath.MOBILE.EDITOR);
    };

    if (!ObjectUtils.isEmpty(this.props.output)) {
      Message.confirm(
        "There is a program in progress. \n Unsaved changes will be deleted. Continue? ",
        () => {
          _goNext();
        }
      );
    } else {
      _goNext();
    }
  }

  /**
   * 불러오기 버튼 클릭
   * @param {Event} event
   */
  openLoadPopup = (event) => {
    if (event) event.preventDefault();
    //팝업창 열기
    const popupTitle = <>프로그램 불러오기</>;
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "70%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <ProgramListPopup
        workspace={this.props.workspace}
        title={popupTitle}
        callbackFnc={(program) => this.onLoadProgram(null, program)}
      />,
      options
    );
  };

  /**
   * 데이터 목록 불러옴
   * @returns {Promise}
   */
  onLoadDataList() {
    //검색 후보 목록 호출
    this.setState({
      dataLoading: true,
    });
    return new Promise((resolve, reject) => {
      const body = {
        // moduleCd: moduleCd === "*" ? "" : moduleCd,
        appId: this.props.workspace.appId,
        moduleCd: this.props.workspace.moduleCd,
        appReleaseId: this.props.workspace.appReleaseId,
        tenantId: this.props.workspace.tenantId,
        coCd: this.props.workspace.coCd,
        builderType: Enums.BuilderType.MOBILE,
      };

      ProgramService.getProgramList(
        body,
        (res) => {
          if (!res?.isError) {
            const { data } = res;
            const list = ProgramService.filterByTenantIdAndCoCd(
              data,
              body.tenantId,
              body.coCd
            );
            resolve([...list]);
          }
        },
        () => {
          this.setState({
            dataLoading: false,
          });
        }
      );
    });
  }

  /**
   * 프로그램 호출
   * @param {*} e
   * @param {*} program
   */
  onLoadProgram(e, program, cb) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    ProgramService.getProgram(
      program,
      (res) => {
        const { programContent, dataModelList, ...information } = res.data;
        const componentInfo = this.context.component.getPageComponent(); //Page Component
        const { memo, ...output } = JSON.parse(programContent);
        this.goToPage(Enums.BuilderPath.MOBILE.EDITOR);
        UIReduxHelper.loadTemplate(
          this.dispatch,
          componentInfo,
          JSON.stringify(output)
        );
        information.programContent = JSON.parse(programContent);
        this.props.updateEventWorkspace({});
        this.props.updateEventOutput("");
        UIReduxHelper.updateInformation(this.dispatch, information);
        if (memo) this.dispatch(setMemo(memo));
        else this.dispatch(setMemo([]));
        Message.alert(
          `'${information.programNm}' program has been loaded.`,
          Enums.MessageType.SUCCESS
        );
        Popup.close();
        if (cb) cb();
      },
      () => {
        if (cb) cb();
        throw new Error("Error occured while loading program.");
      }
    );
  }

  /**새탭에서 열기 */
  onOpenNewTab = (e, program) => {
    e.stopPropagation();
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: this.workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/program/${program.programUid}`,
      "_blank"
    );
  };

  /**
   * 해당 페이지 이동
   * @param {String} url
   */
  goToPage(url) {
    this.navigate(Enums.BuilderPath.MOBILE.MAIN + "/" + url);
    this.menuClose();
  }

  setOutput() {
    if (this.props.isMobileEditor) {
      UIReduxHelper.setMobileOutput(this.dispatch, this.props.output);
    } else {
      UIReduxHelper.setUIOutput(this.dispatch, this.props.output);
    }
  }

  onMobileEditorPage() {
    this.setOutput();
    if (!ObjectUtils.isEmpty(this.props.mobileOutput)) {
      UIReduxHelper.getMobileOutput(this.dispatch, this.props.mobileOutput);
    } else {
      const componentInfo = this.context.component.getPageComponent(); //Page Component
      UIReduxHelper.createPage(this.dispatch, componentInfo);
      this.props.updateEventWorkspace({});
      this.props.updateEventOutput("");
      UIReduxHelper.updateInformation(this.dispatch, null);
    }
    this.goToPage(Enums.BuilderPath.MOBILE.EDITOR);

    // this.navigate(Enums.BuilderPath.MOBILE.MAIN + "/" + url);
    // this.menuClose();
  }

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(FaMobileAlt, "MOBILE UI Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "MOBILE UI Editor", (e) =>
            this.onMobileEditorPage()
          )}
          {this.renderContextMenu(
            AiOutlineFileAdd,
            "New Mobile Program",
            this.onNewProgram
          )}

          {this.renderContextMenu(AiOutlineUnorderedList, "Program List", (e) =>
            this.goToPage(Enums.BuilderPath.MOBILE.LIST)
          )}
          {this.renderContextMenu(AiOutlineHistory, "Program History", (e) =>
            this.goToPage(Enums.BuilderPath.MOBILE.HISTORY)
          )}
          {this.renderContextMenu(
            BsLayoutTextSidebarReverse,
            "APP Configuration",
            (e) => this.goToPage(Enums.BuilderPath.MOBILE.APP_CONFIGURAION)
          )}
        </div>
        <hr />
        <div className="sub-title">Search Program</div>
        <div className="search-area">
          <Form.Control
            className="work-search-input"
            placeholder="Enter Program Name or ID."
            onChange={(e) => this.onSearchData(e, ["programId", "programNm"])}
            value={this.state.searchTerm}
          />
        </div>
        <div className="sub-list">
          {this.state.searchResultList.map((work) => {
            return (
              <div
                className="work"
                key={work.programId}
                onClick={(e) => this.onLoadProgram(e, work)}
              >
                <div className="col-2">{work.programNm}</div>
                <div className="col-1">
                  <button
                    type="button"
                    onClick={(e) => this.onOpenNewTab(e, work)}
                  >
                    <RxOpenInNewWindow />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sub-title">Recently Modified Program</div>
        <div className="sub-list">
          {this.renderRecentMenuList(
            Enums.LocalStorageName.MOBILE_PROGRAM_HISTORY
          )}
        </div>
      </div>
    );
  }
}

const dispatchNewBlankPage = (dispatch) => {
  return {
    updateEventWorkspace: (workspace) => {
      dispatch(updateEventWorkspace(workspace));
    },
    updateEventOutput: (output) => {
      dispatch(updateEventOutput(output));
    },
  };
};

export default connect((state) => {
  return {
    output: state.outputUI.output,
    mobileOutput: state.outputUI.mobileOutput,
    isMobileEditor: state.mobile.isMobileEditor,
  };
}, dispatchNewBlankPage)(ProgramMenu);
