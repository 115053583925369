import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  trace: [], //디버깅시 진행중인 노드 배열
};

export default function WorkflowDebugTraceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ActionType.WORKFLOW.DEBUG.INIT_TRACE:
      return initialState;
    case ActionType.WORKFLOW.DEBUG.UPDATE_TRACE:
      return produce(state, (draft) => {
        draft.trace = action.payload;
      });

    default:
      return state;
  }
}
