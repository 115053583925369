import React, { useCallback, useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import produce from "immer";

import List from "components/builder/mobile/mobileComponents/layout/list/ListLayout";
import ListHeader from "components/builder/mobile/mobileComponents/layout/list/ListHeader";
import ListCell from "components/builder/mobile/mobileComponents/layout/list/ListCell";

import * as Enums from "components/builder/BuilderEnum";

const MobileEditorListColumn = (props) => {
  const { data, path, style, className, templateDetail } = props;
  const ref = useRef(null);
  const item = produce(data, (draft) => {
    draft.path = path;
    draft.templateDetail = templateDetail;
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const opacity = isDragging ? 0.5 : 1;
  drag(ref);

  const getHeaderComponentInfo = useCallback(() => {
    let headerItem = { ...item };
    headerItem.type = Enums.ComponentType.LIST_HEADER;
    headerItem.id = item.propertyValue.id;
    headerItem.compId = "Header-" + item.propertyValue.compId;
    headerItem.componentClass = "layout/list/ListHeader";

    return headerItem;
  }, [item]);

  const getCellComponentInfo = useCallback(() => {
    let cellItem = { ...item };
    cellItem.type = Enums.ComponentType.LIST_CELL;
    cellItem.id = item.propertyValue.id;
    cellItem.compId = "Cell-" + item.propertyValue.compId;
    cellItem.componentClass = "layout/list/ListCell";

    return cellItem;
  }, [item]);

  //dragging 중일때 Column 사이의 drop-zone을 활성화
  if (isDragging) {
    ref.current.parentElement.classList.add("dragging");
  } else {
    if (ref.current) ref.current.parentElement.classList.remove("dragging");
  }
  return (
    <div ref={ref} style={{ ...style, opacity }} className={className}>
      <ListHeader
        componentInfo={getHeaderComponentInfo()}
        event="renderEditor"
      />
      <ListCell componentInfo={getCellComponentInfo()} event="renderEditor" />
    </div>
  );
};
export default React.memo(MobileEditorListColumn);
