import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import StyleUtils from "components/common/utils/StyleUtils";
import produce from "immer";
import { MobileLayoutContext } from "page/mobile/appConfiguration";
import { useContext, useEffect, useRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";

const MobileThemeSetting = ({
  initial,
  nextCallback,
  prevCallback,
  ...props
}) => {
  const { theme, setTheme, themeTmpl, saveLayout } =
    useContext(MobileLayoutContext);

  const fileRef = useRef();
  const initLoad = useRef(false); // 반복해서 css가 로드되지 않도록 플래그 처리
  const customInitLoad = useRef(false);

  useEffect(() => {
    //css를 동적으로 미리 준비하여 적용함
    if (!ArrayUtils.isEmpty(themeTmpl) && !initLoad.current) {
      setPresetThemeCss();
      initLoad.current = true;
    }
  }, [themeTmpl]);

  useEffect(() => {
    if (theme?.customContent && !customInitLoad.current) {
      const { theme: customTheme } = theme.customContent;
      applyTheme({ tmplType: "C", content: customTheme });
      customInitLoad.current = true;
    }
  }, [theme]);

  const setPresetThemeCss = () => {
    //preset CSS 적용
    //껍데기 클래스는 'tmpType'-theme-'index' 로 한다.
    //StyleUtils에 맞도록 데이터 수정함

    themeTmpl.forEach((tmpl, idx) => {
      if (tmpl.presetContent) {
        const presetContent = tmpl.presetContent;
        const { theme } = presetContent;
        applyTheme({ tmplType: "P", content: theme, idx });
      }
    });
  };

  /**
   * 테마 적용 로직
   * @param {*} param0
   */
  const applyTheme = ({ tmplType, content, idx }) => {
    const styleSheetTmpl = {
      styleCode: "",
      styleId: "",
      apply: true,
    };

    let styleWrapperClass = `${tmplType}-theme`;
    if (idx != null && idx > -1) {
      styleWrapperClass += `-${idx}`;
    }
    const tmplStyleSheet = {
      ...styleSheetTmpl,
      styleCode: `.${styleWrapperClass} { ${content}}`,
    };
    StyleUtils.ApplyStyle(null, tmplStyleSheet, styleWrapperClass);
  };

  /**
   * CSS 파일 선택시
   * @param {*} e
   * @returns
   */
  const onChangeInputFile = (e) => {
    const file = e.target.files[0];
    const customContent = {};
    if (!StringUtils.equalsIgnoreCase(file.type, "text/css")) {
      fileRef.current.value = null;

      return Message.alert(
        "Only style files are allowed.",
        Enums.MessageType.ERROR
      );
    }
    customContent.fileNm = file.name;
    const reader = new FileReader();

    reader.onload = () => {
      const resultString = reader.result;
      customContent.theme = resultString;
      applyTheme({ tmplType: "C", content: resultString });
      setTheme(
        produce(theme, (draft) => {
          draft.tmplType = "C";
          draft.layoutType = "C";
          draft.customContent = customContent;
        })
      );
    };

    reader.readAsText(file);
  };

  /**
   * 테마저장
   */
  const saveTheme = () => {
    saveLayout(theme);
    if (initial) {
      nextCallback();
    }
  };

  /**
   * 테마 선택
   * @param {*} tmpl
   */
  const onSelectTheme = (tmpl) => {
    setTheme({ ...theme, ...tmpl });
  };

  return (
    <Row className="ls-container">
      <Col sm={2}>
        <div className="ls-header">테마 선택</div>
        <div className="ls-description">
          모바일 빌더 화면의 색상 테마입니다.
        </div>
      </Col>
      <Col sm={10}>
        <div
          className="ls-content"
          style={{
            alignItems: "center",
          }}
        >
          <Row style={{ justifyContent: "center" }} className="mb-3">
            <Col xs={8}>
              <Row>
                {themeTmpl.map((tmp, idx) => {
                  return (
                    <Col
                      className={`theme-box ${
                        theme?.tmplType === "P" &&
                        theme?.layoutPresetId === tmp.layoutPresetId
                          ? "selected"
                          : ""
                      }`}
                      key={tmp.layoutPresetId}
                      onClick={(e) =>
                        onSelectTheme({
                          layoutPresetId: tmp.layoutPresetId,
                          tmplType: "P",
                        })
                      }
                    >
                      <div className={`theme-content-box P-theme-${idx}`}>
                        <div className="color-theme-title default-input"></div>
                        <div className="color-theme-sub default-input" />
                      </div>
                      <div className="theme-label">{tmp.presetNm}</div>
                    </Col>
                  );
                })}
                {theme?.customContent && (
                  <>
                    <Col
                      className={`theme-box ${
                        theme.tmplType === "C" ? "selected" : ""
                      }`}
                      onClick={(e) => onSelectTheme({ tmplType: "C" })}
                    >
                      <div className={`theme-content-box C-theme`}>
                        <div className="color-theme-title default-input"></div>
                        <div className="color-theme-sub default-input" />
                      </div>
                      <div className="theme-label">
                        {theme.customContent.fileNm}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Col>

            <Col sm={4}>
              <div className="navi-detail">
                <Row className="navi-detail-row">
                  <Col sm={12}>
                    <Form.Label>Custom Style</Form.Label>
                    <InputGroup>
                      <Form.Control
                        value={theme?.customContent?.fileNm || ""}
                        onChange={() => {}}
                        onClick={(e) => fileRef.current.click()}
                        readOnly
                      />
                      <Button
                        size="sm"
                        onClick={(e) => fileRef.current.click()}
                      >
                        <FaUpload />
                      </Button>
                    </InputGroup>
                    <input
                      ref={fileRef}
                      type={"file"}
                      accept="text/css"
                      className="upload-input hide"
                      onChange={onChangeInputFile}
                      value={""}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            {initial ? (
              <>
                <Col
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button variant="link" onClick={prevCallback}>
                    Prev
                  </Button>
                  <Button variant="link" onClick={saveTheme}>
                    Save & Next
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={saveTheme}>Save</Button>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default MobileThemeSetting;
