import { PureComponent } from "react";
import DraggableComponent from "components/builder/ui/DraggableComponent";
import { AppContext } from "components/common/AppContextProvider";
import { Accordion } from "react-bootstrap";

class MobileComponentsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static contextType = AppContext;

  render() {
    const componentList = this.context.component.getComponentList("B", true);

    return (
      <div
        className="basic-component-panel component-panel"
        style={{ height: this.props.height, overflow: "auto" }}
      >
        <Accordion
          style={{ paddingTop: "1px" }}
          defaultActiveKey={[0, 1, 2, 3, 4]}
          alwaysOpen
        >
          {componentList.map((compMst, mstIdx) => (
            <Accordion.Item eventKey={mstIdx} key={compMst.componentMstId}>
              <Accordion.Header>{compMst.componentMstNm}</Accordion.Header>
              <Accordion.Body>
                {compMst.componentDtl.map((compDtl, dtlIdx) => {
                  if (compDtl.mobileUseYn === "Y") {
                    return (
                      <DraggableComponent
                        key={compDtl.componentDtlId}
                        data={compDtl}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default MobileComponentsTab;
