import classNames from "classnames";
import * as Enums from "components/builder/BuilderEnum";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";

const UIEditorDNDContainer = ({
  data,
  onDrop,
  isLast,
  className,
  templateComponents,
  ...props
}) => {
  const output = useSelector((state) => state.outputUI.output);
  const acceptKey =
    data.rootLocation === Enums.ComponentType.FOOTER
      ? Enums.ComponentType.FOOTER
      : data.location;

  const accept = Enums.getComponentDropAccept(acceptKey, {
    ...data,
    output: output.page,
  });
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accept,
    drop: (item, monitor) => {
      onDrop(data, item, templateComponents, output);
    },
    canDrop: (item, monitor) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;
      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        return true;
      }

      const splitItemPath = itemPath.split("-");

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      if (
        diffRow &&
        splitDropZonePath.length === 2 &&
        data.childrenCount >= 3
      ) {
        return false;
      }
      /*
      // Invalid (Can't drop a parent element (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) {
        return false;
      }
      */
      // Current item can't possible move to it's own location
      if (itemPath === dropZonePath) {
        return false;
      }

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const isActive = isOver && canDrop;
  return (
    <div
      className={classNames(
        "drop-zone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
    >
      {props.children}
    </div>
  );
};
export default UIEditorDNDContainer;

export const UIViewerDNDContainer = () => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "none",
    drop: (item, monitor) => {},
    canDrop: (item, monitor) => {
      return false;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return <div ref={drop} />;
};

export const UIEditorDNDGridContainer = ({
  data,
  onDrop,
  isLast,
  className,
  templateComponents,
}) => {
  const output = useSelector((state) => state.outputUI.output);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: Enums.ComponentDropAccept[data.location],
    drop: (item, monitor) => {
      onDrop(data, item, templateComponents, output);
    },
    canDrop: (item, monitor) => {
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <div
      className={classNames(
        "drop-zone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
    ></div>
  );
};

export const UIEditorDNDListContainer = ({
  data,
  onDrop,
  isLast,
  className,
  templateComponents,
}) => {
  const output = useSelector((state) => state.outputUI.output);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: Enums.ComponentDropAccept[data.location],
    drop: (item, monitor) => {
      onDrop(data, item, templateComponents, output);
    },
    canDrop: (item, monitor) => {
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <div
      className={classNames(
        "drop-zone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
    ></div>
  );
};
