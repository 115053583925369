import BootstrapSwitchButton from "bootstrap-switch-button-react";
import produce from "immer";
import React from "react";
import { Accordion } from "react-bootstrap";

import * as Enums from "components/builder/BuilderEnum";
import UIComponent from "components/builder/ui/editor/theme/common/UIComponent";
import {
  ListWidget,
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/editor/theme/common/UIComponentStyle";
import { AppContext } from "components/common/AppContextProvider";
import UInputPopup from "components/common/element/UInputPopup";
import USelectbox from "components/common/element/USelectbox";
import UTextarea from "components/common/element/UTextarea";
import Popup from "components/common/Popup";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import { Button } from "react-bootstrap";
import { IoMdSettings } from "react-icons/io";
import UIReduxHelper from "../../../helper/UIReduxHelper";
import UITemplateHelper from "../../../helper/UITemplateHelper";
import LoginTemplate from "../login/LoginTemplate";

import FormatSettingPopup from "page/popup/FormatSettingPopup";

class StandardPage extends UIComponent {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
    this.state = {
      ...this.state,
      oldUiTemplate: "standard-image",
      formatOption: {},
    };
    this.openPopupEvent = this.openPopupEvent.bind(this);
  }

  static contextType = AppContext;

  /**
   * Popup option 변경
   * @param {Event} event
   */
  onChangePopupOptions = (event) => {
    this.onChangePopupPropertyValue(event.target.id, event.target.value);
  };

  /**
   * Popup option 변경에 따른 property value 갱신
   * @param {String} propId
   * @param {String} propValue
   */
  onChangePopupPropertyValue = (propId, propValue) => {
    const popupOptions = { ...(this.state.propertyValue.popupOptions || {}) };
    if (StringUtils.defaultString(popupOptions[propId]) !== propValue) {
      if (
        StringUtils.isEmpty(propValue) &&
        !StringUtils.isEmpty(popupOptions[propId])
      ) {
        delete popupOptions[propId];
      } else {
        popupOptions[propId] = propValue;
      }

      //Header관련 설정 제거
      if (propId === "displayHeader" && propValue === false) {
        delete popupOptions["headerTitle"];
      }

      //Footer관련 설정 제거
      if (propId === "displayFooter" && propValue === false) {
        delete popupOptions["footerCloseBtn"];
      }
      this.updatePropertyValue(
        produce(this.state.propertyValue, (draft) => {
          draft.popupOptions = popupOptions;
        })
      );
    }
  };

  /**
   * Propgram type  변경에 따른 property value 변경
   * @param {Event} event
   */
  onChangeProgramType = (event) => {
    const programType = event.target.value;
    let newPropertyValue = { ...this.state.propertyValue };
    newPropertyValue[event.target.id] = programType;

    //Login이 아닐 경우, Template 항목 삭제
    if (programType !== Enums.ProgramType.LOGIN_PROGRAM) {
      delete newPropertyValue.uiTemplate;
    } else {
      newPropertyValue.uiTemplate = "standard-image";
      this.onChangeUiTemplate("standard-image");
    }

    //popup이 아닐 경우 popup options 삭제
    if (
      programType !== Enums.ProgramType.POPUP_PROGRAM &&
      !ObjectUtils.isEmpty(newPropertyValue.popupOptions)
    ) {
      delete newPropertyValue.popupOptions;
    }
    this.updatePropertyValue(newPropertyValue);
  };

  onChangeUiTemplate = (uiTemplate) => {
    const componentContext = this.context.component;
    /* template 용 표준 base components - e.g) page, form, row, col... */
    const templateComponents =
      UITemplateHelper.getTemplateComponents(componentContext);

    let template = LoginTemplate.prototype.createLayoutData(
      this.state.oldUiTemplate,
      uiTemplate,
      templateComponents,
      this.props
    );

    if (!ObjectUtils.isEmpty(template)) {
      UIReduxHelper.updateOutputChild(
        this.props.output,
        [template],
        null,
        this.props.dispatch
      );
    }
    this.setState({ oldUiTemplate: uiTemplate });

    let newPropertyValue = { ...this.state.propertyValue };
    newPropertyValue["uiTemplate"] = uiTemplate;
    this.updatePropertyValue(newPropertyValue);
    // window.location.reload(false);
    this.forceUpdate();
  };

  onCancelDataModel = () => {
    const newOutput = produce(this.props.output, (draft) => {
      const findDelete = (obj) => {
        if (typeof obj === "object") {
          for (const key in obj) {
            if (
              StringUtils.includesIgnoreCase(key, [
                "dataModelNm",
                "dataModelId",
                "dataModelEntityId",
              ])
            ) {
              delete obj[key];
            } else if (typeof obj[key] === "object") {
              findDelete(obj[key]);
            }
          }
        }
      };
      findDelete(draft);
    });
    this.setState(
      produce(this.state, (draft) => {
        draft.propertyValue.dataModelNm = "";
      })
    );
    this.props.updateOutput(newOutput);
  };

  /**
   * 포맷설정 panel의 팝업
   * @param {*} e
   */
  openPopupEvent = (e) => {
    if (true) {
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "40%",
          },
        },
      };
      Popup.open(
        <FormatSettingPopup
          title="Custom Number Format Setting"
          userDefindFormat={
            this.state.propertyValue.userDefindFormat
              ? this.state.propertyValue.userDefindFormat
              : []
          }
          callbackFnc={(getItem) => {
            // CallbackData가 있으면 실행
            if (getItem) {
              let newPropertyValue = {
                ...this.state.propertyValue,
                userDefindFormat: getItem,
              };
              this.updatePropertyValue(newPropertyValue);
            }
          }}
          contextData={this.context.code.getCodeList("Z0047")} // 데이터만 전달해서 combobox 구성
        />,
        options
      );
    }
  };

  /**
   * Popup option properties panel을 redering한다.
   * @param {Number} accordionIndex
   * @returns
   */
  renderPopupPanel = (accordionIndex) => {
    const popupOptions = this.state.propertyValue.popupOptions || {};
    const displayHeader = StringUtils.defaultString(
      popupOptions.displayHeader,
      true
    );
    const displayFooter = StringUtils.defaultString(
      popupOptions.displayFooter,
      true
    );
    return (
      <Accordion.Item eventKey={accordionIndex}>
        <Accordion.Header>Popup Setting</Accordion.Header>
        <Accordion.Body>
          <PropertyLable>Size</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="size"
              onChange={this.onChangePopupOptions}
              defaultValue={StringUtils.defaultString(popupOptions.size, "xl")}
              type="common"
              mstCd="Z0021"
            />
          </PropertyValue>
          <PropertyLable>Location</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="position"
              onChange={this.onChangePopupOptions}
              defaultValue={StringUtils.defaultString(
                popupOptions.position,
                "center"
              )}
              type="common"
              mstCd="Z0030"
            />
          </PropertyValue>
          <PropertyLable>Effect (In)</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="animInClass"
              onChange={this.onChangePopupOptions}
              defaultValue={StringUtils.defaultString(popupOptions.animInClass)}
              items={[
                { id: "", text: "None" },
                { id: "bounceIn", text: "[Bouncing] -bounceIn" },
                { id: "bounceInDown", text: "[Bouncing] -bounceInDown" },
                { id: "bounceInLeft", text: "[Bouncing] -bounceInLeft" },
                { id: "bounceInRight", text: "[Bouncing] -bounceInRight" },
                { id: "bounceInUp", text: "[Bouncing] -bounceInUp" },
                { id: "fadeIn", text: "[Fading] - fadeIn" },
                { id: "fadeInDown", text: "[Fading] - fadeInDown" },
                { id: "fadeInDownBig", text: "[Fading] - fadeInDownBig" },
                { id: "fadeInLeft", text: "[Fading] - fadeInLeft" },
                { id: "fadeInLeftBig", text: "[Fading] - fadeInLeftBig" },
                { id: "fadeInRight", text: "[Fading] - fadeInRight" },
                { id: "fadeInRightBig", text: "[Fading] - fadeInRightBig" },
                { id: "fadeInUp", text: "[Fading] - fadeInUp" },
                { id: "fadeInUpBig", text: "[Fading] - fadeInUpBig" },
                { id: "rotateIn", text: "[Rotating] - rotateIn" },
                {
                  id: "rotateInDownLeft",
                  text: "[Rotating] - rotateInDownLeft",
                },
                {
                  id: "rotateInDownRight",
                  text: "[Rotating] - rotateInDownRight",
                },
                { id: "rotateInUpLeft", text: "[Rotating] - rotateInUpLeft" },
                { id: "rotateInUpRight", text: "[Rotating] - rotateInUpRight" },
                { id: "zoomIn", text: "[Zooming] - zoomIn" },
                { id: "zoomInDown", text: "[Zooming] - zoomInDown" },
                { id: "zoomInLeft", text: "[Zooming] - zoomInLeft" },
                { id: "zoomInRight", text: "[Zooming] - zoomInRight" },
                { id: "zoomInUp", text: "[Zooming] - zoomInUp" },
                { id: "slideInDown", text: "[Sliding] -slideInDown" },
                { id: "slideInLeft", text: "[Sliding] -slideInLeft" },
                { id: "slideInRight", text: "[Sliding] -slideInRight" },
                { id: "slideInUp", text: "[Sliding] -slideInUp" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </PropertyValue>
          <PropertyLable>Effect (Out)</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="animOutClass"
              onChange={this.onChangePopupOptions}
              defaultValue={StringUtils.defaultString(
                popupOptions.animOutClass
              )}
              items={[
                { id: "", text: "None" },
                { id: "bounceOut", text: "[Bouncing] -bounceOut" },
                { id: "bounceOutDown", text: "[Bouncing] -bounceOutDown" },
                { id: "bounceOutLeft", text: "[Bouncing] -bounceOutLeft" },
                { id: "bounceOutRight", text: "[Bouncing] -bounceOutRight" },
                { id: "bounceOutUp", text: "[Bouncing] -bounceOutUp" },
                { id: "fadeOut", text: "[Fading] - fadeOut" },
                { id: "fadeOutDown", text: "[Fading] - fadeOutDown" },
                { id: "fadeOutDownBig", text: "[Fading] - fadeOutDownBig" },
                { id: "fadeOutLeft", text: "[Fading] - fadeOutLeft" },
                { id: "fadeOutLeftBig", text: "[Fading] - fadeOutLeftBig" },
                { id: "fadeOutRight", text: "[Fading] - fadeOutRight" },
                { id: "fadeOutRightBig", text: "[Fading] - fadeOutRightBig" },
                { id: "fadeOutUp", text: "[Fading] - fadeOutUp" },
                { id: "fadeOutUpBig", text: "[Fading] - fadeOutUpBig" },
                { id: "rotateOut", text: "[Rotating] - rotateOut" },
                {
                  id: "rotateOutDownLeft",
                  text: "[Rotating] - rotateOutDownLeft",
                },
                {
                  id: "rotateOutDownRight",
                  text: "[Rotating] - rotateOutDownRight",
                },
                { id: "rotateOutUpLeft", text: "[Rotating] - rotateOutUpLeft" },
                {
                  id: "rotateOutUpRight",
                  text: "[Rotating] - rotateOutUpRight",
                },
                { id: "zoomOut", text: "[Zooming] - zoomOut" },
                { id: "zoomOutDown", text: "[Zooming] - zoomOutDown" },
                { id: "zoomOutLeft", text: "[Zooming] - zoomOutLeft" },
                { id: "zoomOutRight", text: "[Zooming] - zoomOutRight" },
                { id: "zoomOutUp", text: "[Zooming] - zoomOutUp" },
                { id: "slideOutDown", text: "[Sliding] -slideOutDown" },
                { id: "slideOutLeft", text: "[Sliding] -slideOutLeft" },
                { id: "slideOutRight", text: "[Sliding] -slideOutRight" },
                { id: "slideOutUp", text: "[Sliding] -slideOutUp" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </PropertyValue>
          <PropertyLable>Background</PropertyLable>
          <PropertyValue>
            <USelectbox
              id="backdrop"
              onChange={this.onChangePopupOptions}
              defaultValue={StringUtils.defaultString(
                popupOptions.backdrop,
                "true"
              )}
              items={[
                { id: "true", text: "Dark Transparent Background" },
                { id: "false", text: "No Background" },
              ]}
              options={{ matchCd: "id", matchNm: "text" }}
            />
          </PropertyValue>
          <PropertyLable>Draggable</PropertyLable>
          <PropertyValue>
            <BootstrapSwitchButton
              id="draggable"
              defaultValue={StringUtils.defaultString(
                popupOptions.draggable,
                false
              )}
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="Yes"
              offlabel="No"
              onChange={(checked) => {
                this.onChangePopupPropertyValue("draggable", checked);
              }}
            />
          </PropertyValue>
          <PropertyLable>Header</PropertyLable>
          <PropertyValue>
            <BootstrapSwitchButton
              id="displayHeader"
              checked={displayHeader}
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="Yes"
              offlabel="No"
              onChange={(checked) => {
                this.onChangePopupPropertyValue("displayHeader", checked);
              }}
            />
          </PropertyValue>
          {displayHeader === true ? (
            <React.Fragment>
              <PropertyLable> - Header Title</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="headerTitle"
                  defaultValue={StringUtils.defaultString(
                    popupOptions.headerTitle
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChangePopupOptions}
                />
              </PropertyValue>
            </React.Fragment>
          ) : (
            ""
          )}
          <PropertyLable>Footer</PropertyLable>
          <PropertyValue>
            <BootstrapSwitchButton
              id="displayFooter"
              checked={displayFooter}
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="Yes"
              offlabel="No"
              onChange={(checked) => {
                this.onChangePopupPropertyValue("displayFooter", checked);
              }}
            />
          </PropertyValue>
          {displayFooter === true ? (
            <React.Fragment>
              <PropertyLable> - Close Button</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="footerCloseBtn"
                  checked={StringUtils.defaultString(
                    popupOptions.footerCloseBtn,
                    true
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(checked) => {
                    this.onChangePopupPropertyValue("footerCloseBtn", checked);
                  }}
                />
              </PropertyValue>
            </React.Fragment>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const programType = StringUtils.defaultString(
      this.state.propertyValue.programType,
      "M"
    );

    let accordionIndex = 0;
    // const defaultActiveKey = programType === "P" ? [0, 1] : [0];
    const defaultActiveKey = [0, 1, 2];

    const appGroupCd = this.props.workspace.appGroupCd;
    if (
      this.state.propertyValue &&
      StringUtils.isEmpty(this.state.propertyValue.appGroupCd)
    ) {
      this.onChangePropertyValue("appGroupCd", appGroupCd);
    }

    return (
      <React.Fragment>
        {/* Title */}
        <PropertiesHeader>Page</PropertiesHeader>
        <Accordion defaultActiveKey={defaultActiveKey} alwaysOpen>
          <Accordion.Item eventKey={accordionIndex}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable $requried="true">Program Type</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="programType"
                  type="common"
                  onChange={this.onChangeProgramType}
                  defaultValue={programType}
                  mstCd="Z0028"
                  options={{
                    isChoose: false,
                  }}
                />
              </PropertyValue>
              {this.state.propertyValue.programType ===
              Enums.ProgramType.LOGIN_PROGRAM ? (
                <React.Fragment>
                  <PropertyLable $requried="true">Template Type</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      id="uiTemplate"
                      type="common"
                      onFocus={(e) => {
                        this.setState({ oldUiTemplate: e.target.value });
                      }}
                      onChange={(e) => {
                        this.onChangeUiTemplate(e.target.value);
                      }}
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.uiTemplate,
                        "standard-image"
                      )}
                      mstCd="Z0037"
                      options={{
                        isChoose: false,
                      }}
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
              <PropertyLable $requried="true">Program ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="programId"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.programId
                  )}
                  onBlur={this.onChange}
                  onChange={(event) => {
                    event.target.value = event.target.value
                      .toUpperCase()
                      .split(" ")
                      .join("");
                  }}
                  className="form-control form-control-sm"
                  disabled={this.props.information ? true : false}
                />
              </PropertyValue>
              <PropertyLable $requried="true">Program Name</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="programName"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.programName
                  )}
                  onBlur={this.onChange}
                  className="form-control form-control-sm"
                />
              </PropertyValue>
              <PropertyLable>Program Description</PropertyLable>
              <PropertyValue>
                <UTextarea
                  popTitle="Program Description"
                  textareaId="programDesc"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.programDesc
                  )}
                  onBlur={this.onChange}
                  isEventBuilder={false}
                />
              </PropertyValue>
              <PropertyLable>Data Model</PropertyLable>
              <PropertyValue>
                <UInputPopup
                  readonlyTp="input"
                  id="dataModelNm"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.dataModelNm,
                    ""
                  )}
                  onClick={this.openDataModelPopup}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* 숫자 Format property */}
          {
            <Accordion.Item eventKey={++accordionIndex}>
              <Accordion.Header>Number Format Setting</Accordion.Header>
              <Accordion.Body>
                {this.props.workspace.appGroupCd === Enums.AppType.UNIERP ? (
                  <React.Fragment>
                    <PropertyLable>Input/Output</PropertyLable>
                    <PropertyValue>
                      <USelectbox
                        type="common"
                        id="formType"
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.formType
                        )}
                        onChange={(e) => {
                          let param = {
                            [e.target.id]: e.target.value,
                          };

                          if (StringUtils.isEmpty(e.target.value)) {
                            param = {
                              ...param,
                              moduleCd: null,
                            };
                          }

                          this.onChangePropertyValues(param);
                        }}
                        mstCd="Z0041"
                      />
                    </PropertyValue>
                    <PropertyLable>Module</PropertyLable>
                    <PropertyValue>
                      <USelectbox
                        type="static"
                        id="moduleCd"
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.moduleCd
                        )}
                        onChange={this.onChange}
                        items={this.context.format.list.reduce((arr, item) => {
                          if (
                            item.formType === this.state.propertyValue.formType
                          ) {
                            if (
                              arr.findIndex(({ moduleCd }) => {
                                return moduleCd === item.moduleCd;
                              }) === -1
                            ) {
                              arr.push(item);
                            }
                          }
                          return arr;
                        }, [])}
                        options={{
                          matchId: "moduleCd",
                          matchNm: "moduleNm",
                          chooseText: `${
                            !User.getConnection(
                              this.props.workspace.tenantMstId
                            )?.token
                              ? "Authentication server connection required"
                              : "Select"
                          }`,
                        }}
                        readOnly={StringUtils.isEmpty(
                          this.state.propertyValue.formType
                        )}
                      />
                    </PropertyValue>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <PropertyLable>User Custom</PropertyLable>
                <PropertyValue>
                  {
                    <Button
                      variant="secondary"
                      onClick={(e) => this.openPopupEvent(e)}
                      size="sm"
                      className="btn btn-light btn-sm"
                    >
                      <IoMdSettings />
                    </Button>
                  }
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
          }

          {/* Popup property */}
          {programType === Enums.ProgramType.POPUP_PROGRAM
            ? this.renderPopupPanel(++accordionIndex)
            : ""}
          {/* Event property */}
          <Accordion.Item eventKey={++accordionIndex}>
            <Accordion.Header>Event</Accordion.Header>
            <Accordion.Body>
              <div className="event-list-props">
                <div className="event-list-group">
                  <ListWidget
                    title="Onload"
                    desc="Executes when the page load is complete."
                  >
                    {this.renderEventTextArea("onLoad", "onLoad", {
                      eventCd: "page.load",
                    })}
                  </ListWidget>
                  <ListWidget
                    title="Unload"
                    desc="Executes when the page (or tab) is closed."
                  >
                    {this.renderEventTextArea("unLoad", "unLoad", {
                      eventCd: "page.unload",
                    })}
                  </ListWidget>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {/* Style property */}
          {this.renderStylePanel("page", ++accordionIndex)}
        </Accordion>
      </React.Fragment>
    );
  };

  renderPopupHeader = (popupOptions) => {
    if (popupOptions.displayHeader === false) return "";
    else
      return (
        <div className="popup-header pop_head">
          <h2 className="tit_pop">
            {StringUtils.defaultString(popupOptions.headerTitle, "")}
          </h2>
          <div className="btn_close" />
        </div>
      );
  };

  /**
   * Editor의 component를 Redering
   * << Page editor props>>
   *   - componentInfo - Page component object
   *   - className
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    if (
      !ObjectUtils.isEmpty(this.props.componentInfo) &&
      !ObjectUtils.isEmpty(this.props.componentInfo.propertyValue) &&
      this.props.componentInfo.propertyValue.programType ===
        Enums.ProgramType.POPUP_PROGRAM
    ) {
      const propertyValue = this.props.componentInfo.propertyValue || {};
      const popupOptions = propertyValue.popupOptions || {};
      const style = {};
      if (popupOptions.displayFooter !== false) {
        style.paddingBottom = Enums.Style.FOOTER_HEIGHT;
      }
      return (
        <div className="pop_wrap" style={{ ...style }}>
          {propertyValue.programType === Enums.ProgramType.POPUP_PROGRAM
            ? this.renderPopupHeader(popupOptions)
            : ""}
          {this.props.children}
        </div>
      );
    } else {
      return this.props.children;
    }
  };
}
export default StandardPage;
