import { TreeItem, TreeView } from "@mui/lab";
import * as Enums from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import * as icon from "components/common/icon/UIcons";
import Modal from "components/common/modal/UModal";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BiFolder } from "react-icons/bi";
import { GiSave } from "react-icons/gi";
import {
  MdAdd,
  MdDelete,
  MdEdit,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import { RiDragDropFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import ComponentService from "services/common/ComponentService";
import styled from "styled-components";
import SvcChangePopup from "./ui/SvcChangePopup";

const ManagerWrraper = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  gap: 10px;
`;

const ManagerLeft = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const MangerRight = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ControlWrapper = styled.div`
  width: 100%;
  height: 10%;
  max-height: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

/**
 * comp가 폴더인지 아닌지 확인하는 메서드
 * @param {Object} comp
 * @returns {boolean}
 */
const isFolderComponent = (comp) => {
  if (comp.componentClass?.indexOf("Folder") > -1) {
    return true;
  } else {
    return false;
  }
};

const renderIcon = (comp) => {
  const { componentClass } = comp;

  let DraggableIcon;
  if (!StringUtils.isEmpty(componentClass)) {
    if (isFolderComponent(comp)) {
      DraggableIcon = BiFolder;
    } else {
      DraggableIcon = icon[StringUtils.substringAfter(componentClass, "/")];
      if (!DraggableIcon) {
        DraggableIcon = RiDragDropFill;
      }
    }
  } else {
    DraggableIcon = RiDragDropFill;
  }

  if (DraggableIcon === RiDragDropFill || isFolderComponent(comp)) {
    return <DraggableIcon size={25} key={comp.svcComponentDtlId} />;
  } else {
    return (
      <DraggableIcon
        size={25}
        className={`u-icon-${StringUtils.substringAfter(componentClass, "/")}`}
        key={comp.svcComponentDtlId}
        style={{ fontSize: "x-large" }}
      />
    );
  }
};

const ComponentSavePopup = ({
  getComponentCodeFromOutput,
  componentInfo,
  refreshSvcComponent,
  saveDisabled = false,
  workspace,
}) => {
  const [serviceComponentList, setServiceComponentList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState({}); //트리에서 선택한 컴포넌트
  const [expandTreeItem, setExpandTreeItem] = useState([]); //펼쳐져야하는 트리뷰
  const [svcComponentDtlNm, setSvcComponentDtlNm] = useState("");
  // const expandTreeItemRef = useRef([]); //펼쳐져야하는 트리뷰 Ref wijmo CallbyValue 때문에 사용
  const { appGroupCd, appId, moduleCd, moduleNm } = workspace;

  const lastComponent = useRef();
  //이름 정보 바뀔때 사용할 원본
  const serviceComponentRef = useRef([]);

  const columns = [
    {
      field: "componentClass",
      headerName: "",
      renderCell: (params) => renderIcon(params),
      width: 50,
    },
    {
      field: "svcComponentDtlNm",
      headerName: "Name",
      width: 125,
    },
    {
      field: "svcComponentDtlId",
      headerName: "UID",
      width: 80,
      renderCell: (params) => {
        if (params.componentClass.indexOf("Folder") === -1) {
          return params.svcComponentDtlId;
        } else {
          return "";
        }
      },
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 170,
      renderCell: (params) => CommonUtils.getDate(params.updtDt, "datetime"),
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 170,
    },
    {
      field: "remark",
      headerName: "Remarks",
      align: "center",
      renderCell: (params, index) => {
        return (
          <>
            {params.componentClass.indexOf("Folder") === -1 && (
              <Button
                variant="outline-success"
                onClick={(e) => onClickNameChange(e, params, index)}
                size="sm"
              >
                Change Name
              </Button>
            )}
            <Button
              variant="outline-danger"
              onClick={(e) => onDeleteComponent(params)}
              size="sm"
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    init().then((list) => {
      if (list.length > 0) {
        setSelectedComponent(list[0]);
        setExpandTreeItem([list[0].treeNodeId]);
      }
    });
  }, []);

  const init = () => {
    return new Promise((resolve, reject) => {
      ComponentService.getServiceComponentList(
        {
          appId,
        },
        (res) => {
          setServiceComponentList(res.data);
          resolve(res.data);
        }
      );
    });
  };

  /**
   * 트리뷰 노드 그리는 함수
   * @param {Array} childList
   * @returns
   */
  const renderTreeItems = (childList = []) => {
    return childList
      .filter((c) => isFolderComponent(c) > -1)
      .map((_child) => {
        if (_child.child) {
          return (
            <TreeItem
              key={_child.treeNodeId}
              nodeId={_child.treeNodeId}
              label={_child.svcComponentDtlNm}
              onClick={(e) => onClickTreeItem(e, _child)}
            >
              {renderTreeItems(_child.child)}
            </TreeItem>
          );
        } else {
          return null;
        }
      });
  };

  /**
   * 트리뷰에서 선택
   * @param {event} e
   * @param {Object} comp
   * @param {String} treeNodeId
   */
  const onClickTreeItem = (e, comp) => {
    e.preventDefault();
    setSelectedComponent(comp);
    lastComponent.current = comp;
    //마지막에 선택한 것을 다시 선택하는 경우 취소함
    if (
      expandTreeItem.length > 0 &&
      expandTreeItem[expandTreeItem.length - 1] === comp.treeNodeId
    ) {
      const newExpand = [...expandTreeItem];
      newExpand.splice(-1);
      setExpandTreeItem(newExpand);
    } else {
      if (comp.parentsId !== undefined) {
        setExpandTreeItem([...expandTreeItem, comp.treeNodeId]);
      } else {
        setExpandTreeItem([comp.treeNodeId]);
      }
    }
  };

  /**
   * 트리뷰 더블클릭
   * SVC Component 이름 변경
   * @param {*} e
   * @param {*} componentMst
   */
  const onDoubleClickTreeItem = (e, componentMst) => {
    // const { child, serviceComponentDtl, ...mstInfo } = componentMst;
    // onOpenComponentPopup(e, mstInfo);
  };

  /**
   * 파일 매니저에서 파일 선택
   * @param {event} e
   * @param {Object} comp
   * @returns
   */
  const onOpenFolder = (comp, e) => {
    // comp = comp.row;

    e.preventDefault();
    e.stopPropagation();
    if (comp.svcComponentDtlId === "rootFolder") {
      //상위폴더
      setSelectedComponent(lastComponent.current.parents);
      setExpandTreeItem(lastComponent.current.treeOrder);
    } else if (isFolderComponent(comp)) {
      //폴더 내부로 이동
      lastComponent.current = selectedComponent;
      setSelectedComponent(comp);
      setExpandTreeItem(comp.treeOrder);
    } else {
      return false;
    }
  };
  const onRowClick = (data, event) => {
    const comp = data;
    //상위 폴더 눌렀을때
    if (comp.svcComponentDtlId === "rootFolder") return false;

    if (!isFolderComponent(comp)) {
      setSvcComponentDtlNm(comp.svcComponentDtlNm);
    } else {
      setSvcComponentDtlNm("");
    }
  };

  /**
   * 새폴더 생성 팝업 오픈 메서드
   * @param {event} e
   * @returns
   */
  const onOpenNewFolderPopup = (e) => {
    if (ObjectUtils.isEmpty(selectedComponent))
      return Message.alert(
        "Category or Folder has not been selecteed.",
        Enums.MessageType.WARN
      );
    e.preventDefault();
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "25%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    if (
      Number.isInteger(selectedComponent.level) &&
      selectedComponent.level + 1 > 5
    ) {
      return Message.alert(
        "The hierarchy can have a maximum of five levels.",
        Enums.MessageType.ERROR
      );
    }
    /**
     * 폴더 생성 메서드
     * @param {String} newFolderName
     * @param {function} cb
     * @returns
     */
    const callback = (newFolderName, cb) => {
      const body = {
        svcComponentDtlNm: newFolderName,
        componentClass: "template/Folder",
        componentOutput: null,
      };
      SaveComponent(body, cb, true);
    };

    Popup.open(<NewFolderPopup onOkClick={callback} />, options);
  };

  /**
   * component 저장
   */
  const onSaveComponent = () => {
    if (StringUtils.isEmpty(svcComponentDtlNm)) {
      return Message.alert("Please enter File Name.", Enums.MessageType.INFO);
    }
    const componentOutput = getComponentCodeFromOutput(componentInfo.compId);
    if (Object.keys(componentOutput).length === 0) {
      return Message.alert(
        "Component ID is not found.\n Please retry after closing popup.",
        Enums.MessageType.ERROR
      );
    }
    const { componentClass } = componentInfo;
    //동일한 파일명이 있는지 확인
    const dupComp = selectedComponent.child?.find(
      (_s) => _s.svcComponentDtlNm === svcComponentDtlNm
    );

    let saveBody = {
      componentOutput: JSON.stringify(componentOutput),
      componentClass,
      svcComponentDtlNm,
    };

    if (dupComp) {
      const { parents, ...saveContents } = dupComp;

      Message.confirm(
        "There is a file with the same name. Do you want to overwrite it?",
        () => {
          saveBody = { ...saveContents, ...saveBody };
          SaveComponent(
            saveBody,
            () => {
              Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
            },
            true
          );
        }
      );
    } else {
      SaveComponent(
        saveBody,
        () => {
          Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
        },
        true
      );
    }
  };

  /**
   * 저장 메서드
   * @param {Object} args
   * @param {function} callback
   * @param {boolean} refresh default true
   * @returns
   */
  const SaveComponent = (args, callback, refresh = true) => {
    const {
      svcComponentMstId,
      level = -1,
      svcComponentDtlId = 0,
    } = selectedComponent;

    const body = {
      appGroupCd: selectedComponent.appGroupCd
        ? selectedComponent.appGroupCd
        : workspace.appGroupCd,
      appId,
      moduleCd: selectedComponent.moduleCd
        ? selectedComponent.moduleCd
        : workspace.moduleCd,
      moduleNm,
      svcComponentMstId,
      parentsId: svcComponentDtlId,
      level: level + 1,
      componentType: Enums.ComponentType.SERVICE,
      ...args,
    };
    ComponentService.saveServiceComponent(
      body,
      (res) => {
        const { isError, data } = res;
        if (!isError) {
          try {
            if (refresh) {
            }
            if (!ObjectUtils.isEmpty(selectedComponent)) {
              //중복입력인지 확인 후 배열에 추가하여 표시
              const existIndex = selectedComponent.child.findIndex(
                (dtl) => dtl.svcComponentDtlId === data.svcComponentDtlId
              );
              const newObj = { ...selectedComponent };
              if (existIndex > -1) {
                newObj.child[existIndex] = { ...data };
              } else {
                newObj.child.push(data);
              }
              setSelectedComponent(newObj);
              init();
            } else {
              //아무것도 없는 상태에서 만드는 경우
              init().then((list) => {
                setSelectedComponent(list[0]);
                setExpandTreeItem([list[0].treeNodeId]);
              });
            }
            refreshSvcComponent();
          } finally {
            if (callback) {
              callback();
            }
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /**
   * 삭제 메서드
   * @param {event} e
   */
  const onDeleteComponent = (svcComponent) => {
    const body = {
      svcComponentDtlId: svcComponent.svcComponentDtlId,
    };
    Message.confirm(`Delete ${svcComponent.svcComponentDtlNm}.`, () => {
      ComponentService.deleteServiceComponent(body, (res) => {
        const { data } = res;
        if (data) {
          init();
          const newComp = { ...selectedComponent };
          newComp.child = newComp.child.filter(
            (c) => c.svcComponentDtlId !== svcComponent.svcComponentDtlId
          );
          setSelectedComponent(newComp);
          refreshSvcComponent();
          Message.alert("Deleted Successfully.", Enums.MessageType.SUCCESS);
        } else {
          Message.alert("Failed to delete.", Enums.MessageType.ERROR);
        }
      });
    });
  };

  /**
   * 공통 컴포넌트 추가 버튼
   * @param {*} e
   */
  const onOpenComponentPopup = (e, component) => {
    if (e) e.stopPropagation();

    const createCommonCategory = (svcComponentMstNm, cb) => {
      ComponentService.saveCategory(
        {
          ...component,
          appId,
          appGroupCd,
          svcComponentMstNm,
        },
        (res) => {
          if (cb) cb();

          init();
          refreshSvcComponent();
        }
      );
    };

    const options = {
      effect: Popup.ScaleUp,
      style: {
        content: {
          width: "25%",
        },
      },
    };
    Popup.open(
      <ComponentCategoryPopup
        onOkClick={createCommonCategory}
        component={component}
      />,
      options
    );
  };

  const childFolderSorting = (childFolderList) => {
    if (!childFolderList) return [];
    return childFolderList.sort((a, b) => {
      if (
        a.componentClass.indexOf("Folder") > -1 &&
        b.componentClass.indexOf("Folder") === -1
      ) {
        return -1;
      } else if (
        a.componentClass.indexOf("Folder") === -1 &&
        b.componentClass.indexOf("Folder") > -1
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const onDeleteSvcMst = () => {
    //appId가 있으면 Mst Service라고 가정한다.
    if (selectedComponent.appId) {
      Message.confirm(`Delete ${selectedComponent.svcComponentMstNm}.`, () => {
        ComponentService.deleteSvcComponentMst(selectedComponent, (res) => {
          Message.alert("Deleted Successfully.", Enums.MessageType.SUCCESS);
          init();
        });
      });
    } else {
      onDeleteComponent(selectedComponent);
    }
  };

  /**
   * 선택된 항목 이름 변경
   * @param {*} e
   */
  const onClickNameChange = (e, component, index) => {
    stopEvent(e);
    const callback = (changedName) => {
      if (component.appId) {
        //svc 카테고리
        ComponentService.updateCategoryName(
          {
            svcComponentMstId: component.svcComponentMstId,
            svcComponentMstNm: changedName,
          },
          (res) => {
            const newSvcList = serviceComponentList.map((se) => {
              if (se.svcComponentMstId === component.svcComponentMstId) {
                se.svcComponentMstNm = changedName;
              }
              return se;
            });
            setServiceComponentList(newSvcList);
            refreshSvcComponent();
            Popup.close();
          }
        );
      } else {
        //svc 컴포넌트 -> 폴더
        ComponentService.updateComponentName(
          {
            svcComponentDtlId: component.svcComponentDtlId,
            svcComponentDtlNm: changedName,
          },
          (res) => {
            //index가 있는 경우는 Component가 바뀔때
            if (index !== null && index !== undefined) {
              const selectedCopy = { ...selectedComponent };
              selectedCopy.child[index].svcComponentDtlNm =
                res.data.svcComponentDtlNm;
              setSelectedComponent(selectedCopy);
            } else {
              init();
            }
            refreshSvcComponent();
            Popup.close();
          }
        );
      }
    };

    Popup.open(<SvcChangePopup component={component} callback={callback} />, {
      style: { content: { width: "300px" } },
      disableDragging: true,
    });
  };

  return (
    <Modal>
      <Modal.Header title={"Component Manager"} />
      <Modal.Body>
        <ManagerWrraper>
          <ManagerLeft>
            <ControlWrapper>
              {StringUtils.includesIgnoreCase(User.getAuthType(), [
                "AM",
                "S",
              ]) && (
                <>
                  <Button
                    onClick={(e) => onClickNameChange(e, selectedComponent)}
                    variant="outline-success"
                    size="sm"
                  >
                    <MdEdit /> Change Name
                  </Button>
                  <Button
                    onClick={onOpenComponentPopup}
                    variant="outline-primary"
                    size="sm"
                  >
                    <MdAdd /> ADD
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={onDeleteSvcMst}
                  >
                    <MdDelete /> Delete
                  </Button>
                </>
              )}
            </ControlWrapper>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<MdOutlineExpandLess />}
              defaultExpandIcon={<MdOutlineExpandMore />}
              multiSelect={false}
              selected={selectedComponent.treeNodeId || ""}
              expanded={expandTreeItem}
              sx={{
                flexGrow: 1,
                height: "100%",
                overflowY: "auto",
                border: "1px solid lightgray",
              }}
            >
              {serviceComponentList.map((_comp, idx) => {
                return (
                  <TreeItem
                    key={_comp.treeNodeId}
                    nodeId={_comp.treeNodeId}
                    label={_comp.svcComponentMstNm}
                    onClick={(e) => onClickTreeItem(e, _comp)}
                    onDoubleClick={(e) => onDoubleClickTreeItem(e, _comp)}
                  >
                    {_comp.child ? renderTreeItems(_comp.child) : <></>}
                  </TreeItem>
                );
              })}
            </TreeView>
          </ManagerLeft>
          <MangerRight>
            <ControlWrapper>
              <Button onClick={onOpenNewFolderPopup}>ADD Folder</Button>
            </ControlWrapper>
            <WijmoGrid
              getRowId={(row) => row.svcComponentDtlId}
              rows={
                selectedComponent.parents
                  ? [...childFolderSorting(selectedComponent.child)]
                  : childFolderSorting(selectedComponent.child)
              }
              columns={columns}
              onRowClick={onRowClick}
              onRowDoubleClick={onOpenFolder}
              {...MuiConfig.grid.options}
              headersVisibility="Column"
              style={{ height: saveDisabled ? "67vh" : `55vh` }}
            />
            {!saveDisabled && (
              <Form style={{ height: "15%" }} onSubmit={onSaveComponent}>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label className="required">Component Name</Form.Label>
                    <input
                      className="form-control"
                      placeholder="Enter Component Name"
                      value={svcComponentDtlNm}
                      onChange={(e) => setSvcComponentDtlNm(e.target.value)}
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}
          </MangerRight>
        </ManagerWrraper>
      </Modal.Body>
      <Modal.Footer>
        {!saveDisabled && (
          <Modal.Footer.Button onClick={onSaveComponent}>
            Save
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

/**
 * 새 폴더용 팝업
 * @param {Object} param0
 * @returns
 */
const NewFolderPopup = ({ onOkClick }) => {
  const [folderName, setFolderName] = useState("");
  const inputRef = useRef();
  const onClickSave = (e) => {
    e.preventDefault();
    if (StringUtils.isEmpty(folderName)) {
      return Message.alert("Please enter Folder Name", Enums.MessageType.WARN);
    }
    onOkClick(folderName, () => {
      Popup.close();
    });
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Modal>
      <Modal.Header title="Add New Folder" />
      <Modal.Body>
        <Form onSubmit={onClickSave}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label className="required">Folder Name</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.currentTarget.value)}
                ref={inputRef}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickSave}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

const ComponentCategoryPopup = ({ onOkClick, component, ...props }) => {
  const [folderName, setFolderName] = useState(
    component ? component.svcComponentMstNm : ""
  );
  const inputRef = useRef();
  const onClickSave = (e) => {
    e.preventDefault();
    if (StringUtils.isEmpty(folderName)) {
      return Message.alert("Please enter Folder Name", Enums.MessageType.WARN);
    }
    onOkClick(folderName, () => {
      Popup.close();
    });
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Modal>
      <Modal.Header title="Edit Category Component" />
      <Modal.Body>
        <Form onSubmit={onClickSave}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label className="required">Category Name</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Category Name"
                value={folderName}
                onChange={(e) => setFolderName(e.currentTarget.value)}
                ref={inputRef}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickSave}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 * 저장 팝업 부르는 버튼
 * @param {*} props
 * @returns
 */
export function ComponentSavePopupButton({
  children,
  style,
  variant,
  ...props
}) {
  const {
    serviceComponent: { refreshSvcComponent },
  } = useContext(AppContext);
  const workspace = useSelector((state) => state.workspace);
  const { isMobileEditor } = useSelector((state) => state.mobile);

  const onClickButton = (e) => {
    e.preventDefault();
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "60%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <ComponentSavePopup
        workspace={workspace}
        {...props}
        refreshSvcComponent={refreshSvcComponent}
      />,
      options
    );
  };
  return (
    <Button
      onClick={onClickButton}
      size="sm"
      variant={"outline-secondary"}
      className={"light-font-color"}
    >
      <GiSave size="14" /> {children || "Save Component"}
    </Button>
  );
}

export default ComponentSavePopup;
