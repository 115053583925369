import { MessageType } from "components/builder/BuilderEnum";
import {
  setDesign,
  setDesignLayoutColumn,
} from "components/builder/programDesign/reducer/ProgramDesignAction";
import WijmoGrid from "components/common/element/WijmoGrid";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import {
  BsDashCircleFill,
  BsFillPlusCircleFill,
  BsFillPlusSquareFill,
} from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CodeService from "services/common/CodeService";
import ProgramDesignService from "services/programDesignService/ProgramDesignService";
// import TrdService from "services/trd/TrdService";
import styled from "styled-components";

export const ScrollDiv = styled.div`
  max-height: calc(100vh - 280px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const ProgramDesignSelectionLayout = ({
  designMst,
  setDesignMst,
  designLayoutColumnList,
  setDesignLayoutColumnList,
  flag,
}) => {
  const [displayTypeList, setDisplayTypeList] = useState([]);
  const [displayDtlTypeList, setDisplayDtlTypeList] = useState([]);
  const [displayHandleTypeList, setDisplayHandleTypeList] = useState([]);
  const [cellEdit, setCellEdit] = useState({});
  const [cellPaste, setCellPaste] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const userLang = User.getLanguage().toLowerCase();
  const technicalDesign = useSelector((state) => state.technicalDesign);
  // const [trdTableList, setTrdTableList] = useState([]);
  const dispatch = useDispatch();

  const [tableNmList, setTableNmList] = useState([]);
  // const [curColumnData, setCurColumnData] = useState({});

  const TAB_SELECT = "selection";
  const langColumnList = [
    "columnLogicalNm",
    "columnDesc",
    "displayDtlDesc",
    "remarks",
  ];

  useEffect(() => {
    CodeService.getCodeCombo({ codeMstCd: "TD005" }, (res) => {
      setDisplayTypeList(res.data);
    });
    CodeService.getCodeCombo({ codeMstCd: "TD006" }, (res) => {
      setDisplayDtlTypeList(res.data);
    });
    CodeService.getCodeCombo({ codeMstCd: "TD007" }, (res) => {
      setDisplayHandleTypeList(res.data);
    });
  }, []);

  // useEffect(() => {
  //   if (!ObjectUtils.isEmpty(designMst) && ArrayUtils.isEmpty(trdTableList)) {
  //     TrdService.getTableListWithoutTrd({ appEnvId: designMst.appEnvId }).then(
  //       (res) => {
  //         setTrdTableList(res.data);
  //       }
  //     );
  //   }
  // }, [designMst]);

  useEffect(() => {
    setDisplayList(designLayoutColumnList);
    let tableNm = [];
    if (
      typeof technicalDesign.design.designMultilang !== "undefined" &&
      technicalDesign.design.designMultilang !== null
    ) {
      tableNm = technicalDesign.design.designMultilang.filter((item) =>
        item.multilangCd.includes(TAB_SELECT)
      );
    }
    setTableNmList(tableNm);
  }, [technicalDesign]);

  useEffect(() => {
    setDisplayList(designLayoutColumnList);
  }, [designLayoutColumnList]);

  useEffect(() => {
    cellEditEndingEvt(cellEdit.gridInfo, cellEdit.e, cellEdit.value);
  }, [cellEdit]);

  useEffect(() => {
    pastedEvt(cellPaste.data, cellPaste.e);
  }, [cellPaste.data]);

  useEffect(() => {
    if (flag) {
      setDesignLayoutColumnList([]);
    }
  }, [flag]);

  /**
   * 그리드에 복사 붙여넣기 시 이벤트
   * @param {*} data
   * @param {*} e
   * @returns
   */
  const pastedEvt = (data, e) => {
    let tmpGridData = gridDataToList(data);
    if (typeof tmpGridData === "undefined") {
      return;
    }
    let tmpDisplayList = [...displayList];
    for (let data of tmpGridData) {
      let idx = displayList.findIndex(
        (item) => item.columnId === data.columnId
      );
      tmpDisplayList[idx] = data;
    }
    setDisplayList(tmpDisplayList);
    setDesignLayoutColumnList(tmpDisplayList);
    dispatch(setDesignLayoutColumn(tmpDisplayList));
  };

  /**
   * rowDeleteFnc : row Data 삭제 함수
   */
  const deleteRow = (e, param) => {
    const body = displayList.find((item) => item.columnId === param.columnId);
    const refreshTable = () => {
      const list = displayList.filter(
        (item) => item.columnId !== body.columnId
      );
      setDisplayList(list);
      setDesignLayoutColumnList(list);
      dispatch(setDesignLayoutColumn(list));
      Message.alert("Deleted Successfully.", MessageType.SUCCESS);
    };

    if (typeof body.columnId !== "string") {
      ProgramDesignService.deleteDesignLayoutColumn(body, (res) => {
        if (!res.isError) {
          refreshTable();
        }
      });
    } else {
      refreshTable();
    }
  };

  /**
   * list 받아서 grid에서 쓸 수 있게끔 변경
   * Item 내부의 lang 중 해당 user lang의 데이터를 일시적으로 list의 데이터에 추가해줌
   * @param {*} list
   * @returns
   */
  const listToGridData = (list) => {
    if (!ArrayUtils.isEmpty(list)) {
      const tmpList = [...list];
      let displayTmpList = [];
      for (let item of tmpList) {
        let tmpItem = { ...item };
        const langItem = tmpItem.designLayoutColumnLang.find((item2) =>
          StringUtils.equalsIgnoreCase(item2.langCd, userLang)
        );
        if (typeof langItem === "undefined") {
          tmpItem.langCd = userLang;
        } else {
          for (let langC of langColumnList) {
            tmpItem[langC] = langItem[langC];
          }
        }
        displayTmpList = [...displayTmpList, tmpItem];
      }
      return displayTmpList;
    } else {
      return [];
    }
  };

  const gridDataToList = (gridData) => {
    if (ArrayUtils.isEmpty(gridData)) {
      return;
    }
    let tmpGridData = [...gridData];
    for (let i = 0; i < tmpGridData.length; i++) {
      let tmpItem = { ...tmpGridData[i] };
      let langIdx = tmpItem.designLayoutColumnLang.findIndex((item2) =>
        StringUtils.equalsIgnoreCase(item2.langCd, userLang)
      );
      let tmpLang = { ...tmpItem.designLayoutColumnLang[langIdx] };
      for (let langC of langColumnList) {
        tmpLang[langC] = tmpItem[langC];
      }
      let tmpLangList = [...tmpItem.designLayoutColumnLang];
      tmpLangList[langIdx] = tmpLang;
      tmpItem.designLayoutColumnLang = tmpLangList;
      tmpGridData[i] = tmpItem;
    }
    return tmpGridData;
  };

  /**
   * column combo 변경시 displayList에 값 매핑
   */
  const onChangeColumn = (e, data) => {
    data[e.target.id] = e.target.value;
    let tmpList = [...displayList];
    const index = tmpList.findIndex((item) => item.columnId === data.columnId);
    tmpList[index] = data;

    setDisplayList(tmpList);
    setDesignLayoutColumnList(tmpList);
    dispatch(setDesignLayoutColumn(tmpList));
  };
  /**
   * 그리드에서 데이터 수정 시 displayList에 값 매핑해주는 event
   * @param {*} s wijmogrid 데이터
   * @param {*} e event
   * @param {*} v 변경된 값
   */
  const cellEditEndingEvt = (s, e, v) => {
    if (s && e) {
      let list = [...displayList];
      let rowData = { ...e.getRow()._data }; //변경된 row 데이터
      let key = e.getColumn().binding; //변경된  key
      // if (key === null && e.getColumn()._idx === 1) {
      //   key = "tableMstId";
      // }
      let value = v; //변경된 값

      const index = list.findIndex(
        (item) => item.columnId === rowData.columnId
      );
      if (langColumnList.includes(key)) {
        //lang에 속하는 값일 경우
        const index2 = rowData.designLayoutColumnLang.findIndex((item) =>
          StringUtils.equalsIgnoreCase(item.langCd, userLang)
        );
        if (index2 !== -1) {
          const rowDataLang = { ...rowData.designLayoutColumnLang[index2] };
          rowDataLang[key] = value;
          let rowDataLangList = [...rowData.designLayoutColumnLang];
          rowDataLangList.splice(index2, 1, rowDataLang);
          rowData.designLayoutColumnLang = rowDataLangList;
          list[index] = rowData;
        }
      } else {
        if (key === "characterLength") {
          value = parseInt(value);
        }

        rowData[key] = value;

        list[index] = rowData;
      }

      setDisplayList(list);
      setDesignLayoutColumnList(list);
      dispatch(setDesignLayoutColumn(list));
    }
  };

  /**
   * add Row 할 때 임의의 데이터 추가
   */
  const addRowFnc = (key) => {
    const data = {
      columnId: StringUtils.getUuid(),
      designMstId: designMst.designMstId,
      layoutCd: key,
      displayType: "",
      designLayoutColumnLang: [],
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    const langData = {
      langCd: userLang,
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    data.designLayoutColumnLang[0] = langData;
    const listData = [...displayList, data];
    setDisplayList(listData);
    setDesignLayoutColumnList(listData);
    dispatch(setDesignLayoutColumn(listData));
  };

  const onChangeTableNm = (name, type) => {
    //해당 탭 내의 테이블 리스트 저장
    let tableTmpList = [...tableNmList];

    let findIndex = tableNmList.findIndex((item) => item.multilangCd === type);

    if (findIndex > -1) {
      tableTmpList = produce(tableNmList, (draft) => {
        draft[findIndex].multilangText = name;
      });
    } else {
      const newData = {
        multilangCd: type,
        multilangText: name,
        langCd: User.getLanguage().toLowerCase(),
      };
      tableTmpList = produce(tableNmList, (draft) => {
        draft.push(newData);
      });
    }

    // designMst 저장
    let designTmpMst = { ...designMst };

    findIndex = designMst.designMultilang?.findIndex(
      (item) => item.multilangCd === type
    );

    if (findIndex > -1) {
      designTmpMst = produce(designMst, (draft) => {
        draft.designMultilang[findIndex].multilangText = name;
      });
    } else {
      const newData = {
        multilangCd: type,
        multilangText: name,
        langCd: User.getLanguage().toLowerCase(),
      };
      designTmpMst = produce(designMst, (draft) => {
        if (!ArrayUtils.isArray(draft.designMultilang)) {
          draft.designMultilang = [];
        }
        draft.designMultilang.push(newData);
      });
    }

    setTableNmList(tableTmpList);
    setDesignMst(designTmpMst); //state update

    dispatch(setDesign(designTmpMst)); //redux update
  };

  /**
   * 새 component 추가 버튼 (grid 자체 추가)
   */
  const addNewComponent = () => {
    let result = 1;
    if (!ArrayUtils.isEmpty(tableNmList)) {
      const lastIdx = tableNmList.at(tableNmList.length - 1);
      var regex = /[^0-9]/g;
      result = parseInt(lastIdx.multilangCd.replace(regex, "")) + 1;
    }
    const body = {
      langCd: userLang,
      multilangCd: TAB_SELECT + "_layout" + result,
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    const tmpNmList = [...tableNmList, body];
    setTableNmList(tmpNmList);

    let tmpDesignMst = { ...designMst };
    if (ObjectUtils.isEmpty(designMst)) {
      let newDesignMultilang = [];
      newDesignMultilang.push(body);
      tmpDesignMst.designMultilang = newDesignMultilang;
    } else {
      const tmpDesignMultilang = [...designMst.designMultilang, body];
      tmpDesignMst.designMultilang = tmpDesignMultilang;
    }

    setDesignMst(tmpDesignMst);

    dispatch(setDesign(tmpDesignMst));
  };

  /**
   * 컴포넌트 자체 삭제
   * @param {*} item
   * @param {*} e
   */
  const deleteComponent = (item, e) => {
    let data = { ...item };
    if (item.designMstId === null) {
      data.designMstId = designMst.designMstId;
    }
    const refreshTable = () => {
      const list = [
        ...tableNmList.filter((item) => item.multilangCd !== data.multilangCd),
      ];
      let tmpDesignMst = { ...designMst };
      const tmpDesignMultilang = designMst.designMultilang.filter(
        (item) => item.multilangCd !== data.multilangCd
      );
      setTableNmList(list);
      tmpDesignMst.designMultilang = tmpDesignMultilang;
      setDesignMst(tmpDesignMst);
      dispatch(setDesign(tmpDesignMst));

      const tmpDisplayList = [
        ...displayList.filter((item) => item.layoutCd !== data.multilangCd),
      ];
      setDisplayList(tmpDisplayList);
      setDesignLayoutColumnList(tmpDisplayList);
      dispatch(setDesignLayoutColumn(tmpDisplayList));
      Message.alert("Deleted Successfully.", MessageType.SUCCESS);
    };
    if (typeof data.multilangId === "undefined") {
      refreshTable();
    } else {
      ProgramDesignService.deleteDesignLayoutComponent(data, (res) => {
        if (!res.isError) {
          refreshTable();
        } else {
          Message.alert(
            "Error has occured while deleting component.",
            MessageType.ERROR
          );
        }
      });
    }
  };

  /**
   * 그리드 컬럼 정보
   */
  const columns = [
    {
      field: "columnPhysicalNm",
      headerName: "Column ID",
      width: 150,
    },
    {
      field: "tablePhysicalNm",
      headerName: "Table Name",
      width: 150,
    },
    {
      field: "columnLogicalNm",
      headerName: "Label Name",
      width: 150,
      // isReadOnly: true,
    },
    {
      field: "columnDesc",
      headerName: "Description",
      width: "2*",
      name: "columnDesc",
    },

    {
      field: "displayType",
      headerName: "Display Type",
      width: 130,
      isReadOnly: true,
      renderCell: (params) => {
        return (
          <Form.Select
            size="sm"
            id="displayType"
            value={
              displayTypeList.find((item) => item.id === params.displayType)?.id
            }
            onClick={() => {}}
            onChange={(e) => {
              onChangeColumn(e, params);
            }}
          >
            <option value={""} key="select">
              Select
            </option>
            {displayTypeList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.text}
                </option>
              );
            })}
          </Form.Select>
        );
      },
    },
    {
      field: "displayDtlType",
      headerName: "Display Dtl Type",
      width: 150,
      isReadOnly: true,
      renderCell: (params) => {
        return (
          <Form.Select
            size="sm"
            id="displayDtlType"
            value={
              displayDtlTypeList.find(
                (item) => item.id === params.displayDtlType
              )?.id
            }
            onClick={() => {}}
            onChange={(e) => {
              onChangeColumn(e, params);
            }}
          >
            <option value={""} key="select">
              Select
            </option>
            {displayDtlTypeList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.text}
                </option>
              );
            })}
          </Form.Select>
        );
      },
    },
    {
      field: "displayDtlDesc",
      headerName: "Display Description",
      width: "2*",
    },
    {
      field: "displayHandleType",
      headerName: "Handle Type",
      width: 150,
      isReadOnly: true,
      renderCell: (params) => {
        return (
          <Form.Select
            size="sm"
            id="displayHandleType"
            value={
              displayHandleTypeList.find(
                (item) => item.id === params.displayHandleType
              )?.id
            }
            onClick={() => {}}
            onChange={(e) => {
              onChangeColumn(e, params);
            }}
          >
            <option value={""} key="select">
              Select
            </option>
            {displayHandleTypeList.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.text}
                </option>
              );
            })}
          </Form.Select>
        );
      },
    },
    {
      field: "defaultValue",
      headerName: "Default",
      width: 110,
    },
    {
      field: "characterLength",
      headerName: "Length",
      width: 110,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 110,
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      width: 100,
      isReadOnly: true,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => {
              deleteRow(e, param);
            }}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <Button
          size="sm"
          style={{
            marginTop: "5px",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
          }}
          variant="outline-primary"
          onClick={() => addNewComponent()}
        >
          <BsFillPlusCircleFill style={{ margin: "2px" }} />
          Add New Component
        </Button>
      </div>
      <ScrollDiv>
        {tableNmList.map((item) => {
          let gridList = displayList?.filter((data) =>
            StringUtils.equalsIgnoreCase(data.layoutCd, item.multilangCd)
          );
          gridList = listToGridData(gridList);
          return (
            <>
              <div
                style={{
                  margin: "10px 0px 5px 0px",
                  display: "flex",
                  // marginTop: "10px",
                }}
              >
                <Col sm={4} style={{ marginRight: "5px" }}>
                  <Form.Control
                    value={item.multilangText}
                    onChange={(e) => {
                      onChangeTableNm(e.target.value, item.multilangCd);
                    }}
                  />
                </Col>
                <Button
                  size="sm"
                  style={{
                    marginRight: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                  }}
                  variant="outline-danger"
                  onClick={(e) => deleteComponent(item, e)}
                >
                  <BsDashCircleFill style={{ margin: "2px" }} />
                  Delete Component
                </Button>
                <Button
                  size="sm"
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                  }}
                  variant="outline-success"
                  onClick={() => addRowFnc(item.multilangCd)}
                >
                  <BsFillPlusSquareFill style={{ margin: "2px" }} />
                  Add Row
                </Button>
              </div>

              <WijmoGrid
                columns={columns}
                rows={gridList}
                isReadOnly={false}
                cellEditEnding={(s, e) => {
                  let value = s.activeEditor.value;
                  let data = { gridInfo: s, e: e, value: value };
                  setCellEdit(data);
                }}
                pasted={(s, e) => {
                  let data = s.collectionView.items;
                  setCellPaste({ data: data, e: e });
                }}
                selectMode="CellRange"
              />
            </>
          );
        })}
      </ScrollDiv>
    </React.Fragment>
  );
};
export default ProgramDesignSelectionLayout;
