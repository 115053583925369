import * as Enums from "components/builder/BuilderEnum";
import * as Editor from "components/builder/ui/editor/render/";
import * as MobileEditor from "components/builder/mobile/editor/render/";
import JsonUtils from "components/common/utils/JsonUtils";

const UIEditorChildRender = (props) => {
  const type = props.data.type;

  if (type === Enums.ComponentType.FORM) {
    return <Editor.UIEditorForm {...props} />;
  } else if (type === Enums.ComponentType.ROW) {
    return <Editor.UIEditorRow {...props} />;
  } else if (type === Enums.ComponentType.COLUMN) {
    return <Editor.UIEditorColumn {...props} />;
  } else if (type === Enums.ComponentType.GRID) {
    return <Editor.UIEditorGrid {...props} />;
  } else if (type === Enums.ComponentType.TREE) {
    return <Editor.UIEditorTree {...props} />;
  } else if (type === Enums.ComponentType.COMPONENT) {
    if (
      JsonUtils.defaultString(props.data.editorAttr, "componentClass") ===
      "widget/chart/AMChart3Component"
    )
      return <Editor.UIEditorAMChart3 {...props} />;
    else {
      return <Editor.UIEditorComponent {...props} />;
    }
  } else if (type === Enums.ComponentType.SPACE) {
    return <Editor.UIEditorSpace {...props} />;
  } else if (type === Enums.ComponentType.TAB_CONTAINER) {
    return <Editor.UIEditorTab {...props} />;
  } else if (type === Enums.ComponentType.STEP_CONTAINER) {
    return <Editor.UIEditorStep {...props} />;
  } else if (type === Enums.ComponentType.WIDGET_CONTAINER) {
    return <Editor.UIEditorWidget {...props} />;
  } else if (type === Enums.ComponentType.LIST_LAYOUT) {
    return <MobileEditor.MobileEditorListLayout {...props} />;
  } else {
    return <Editor.UIEditorContainer {...props} />;
  }
};

export default UIEditorChildRender;
