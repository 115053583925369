import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import * as Enums from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import MobileProgramHistoryAccordionContents from "components/builder/mobile/history/MobileProgramHistoryAccordionContents";
import Message from "components/common/Message";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import produce from "immer";
import moment from "moment";
import PageTemplate from "page/common/PageTemplate";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdOutlineUpdate } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";
import styled from "styled-components";

const MoreButtonLine = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const initSearchTerm = {
  workerId: null,
  commitComment: null,
  dateFrom: null,
  dateTo: null,
};

function MobileProgramHistoryDetail(props) {
  const { programId, historyMstId } = useParams(); // => historyMstId 로 변경됨
  const [ProgramHistoryList, setProgramHistoryList] = useState([]); //이력 목록
  const [displayProgramList, setDisplayProgramList] = useState([]); //날짜별 가시화 한 이력 목록
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pageNumber = useRef(0);
  const navigate = useNavigate();
  const searchTermRef = useRef({ ...initSearchTerm });

  //searchTerm
  const [searchTerm, setSearchTerm] = useState({ ...initSearchTerm });
  const [insertDt, setInsertDt] = useState("");

  useEffect(() => {
    searchList();
    return () => {
      setProgramHistoryList([]);
      setDisplayProgramList([]);
    };
  }, [historyMstId]);

  const searchList = () => {
    pageNumber.current = 0;
    getProgramHistory(pageNumber.current, true);
  };

  const getProgramHistory = (pageNumber, init) => {
    setIsLoading(true);
    const body = {
      historyMstId,
      pageNumber,
      ...searchTermRef.current,
    };
    try {
      ProgramService.selectProgramHistory(body, (res) => {
        const {
          data: { content: programList, last },
        } = res;
        if (programList.length > 0) {
          //separate with commit date in displayList
          let newList = [];
          if (init) {
            newList = [...programList];
          } else {
            newList = [...ProgramHistoryList, ...programList];
          }

          //original program list save
          setProgramHistoryList(newList);
          const wholeList = [];
          let tmpDate = "";
          let tmpList = [];
          newList.map((_program, idx) => {
            if (
              CommonUtils.getTZTime(_program.insertDt).get("y") ===
                CommonUtils.getTZTime(tmpDate).get("y") &&
              CommonUtils.getTZTime(_program.insertDt).get("month") ===
                CommonUtils.getTZTime(tmpDate).get("month") &&
              CommonUtils.getTZTime(_program.insertDt).get("d") ===
                CommonUtils.getTZTime(tmpDate).get("d")
            ) {
              // same day with tmpDate
              tmpList.push(_program);
            } else {
              // diff day with tmpDate
              if (tmpList.length > 0) {
                wholeList.push(tmpList);
              }
              tmpList = [];
              tmpList.push(_program);
              tmpDate = _program.insertDt;
            }

            if (idx === newList.length - 1 && tmpList.length > 0) {
              wholeList.push(tmpList);
            }
          });
          setIsLoading(false);
          setDisplayProgramList(wholeList);
        }
        setIsLast(last);
      });
    } catch (error) {
      console.log(error);
      Message.alert(
        "An error occurred while loading the history.",
        Enums.MessageType.ERROR
      );
    }
  };

  const onClickNextPage = () => {
    if (isLoading) return false;
    pageNumber.current = pageNumber.current + 1;
    getProgramHistory(pageNumber.current);
  };

  const onMoveToList = () => {
    navigate(
      `${Enums.BuilderPath.MOBILE.MAIN}/${Enums.BuilderPath.MOBILE.HISTORY}`
    );
  };

  const breadcrum = [
    { name: "Mobile Program History", url: Enums.BuilderPath.HISTORY },
    {
      name: "Mobile Program History Details",
      subName: programId,
      active: true,
    },
  ];

  const onChangeSearchTerm = (e) => {
    setSearchTerm(
      produce(searchTerm, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
    searchTermRef.current[e.target.id] = e.target.value;
  };

  const onChangeDate = (e) => {
    const from = moment(e.target.value);
    const to = moment(e.target.value);
    from.set("hour", 0);
    from.set("minutes", 0);
    from.set("seconds", 0);
    to.set("hour", 23);
    to.set("minutes", 59);
    to.set("seconds", 59);
    setInsertDt(e.target.value);

    setSearchTerm(
      produce(searchTerm, (draft) => {
        if (StringUtils.equalsIgnoreCase(e.target.id, "insertDt")) {
          draft.dateFrom = from;
          draft.dateTo = to;
          searchTermRef.current.dateFrom = from;
          searchTermRef.current.dateTo = to;
        }
      })
    );
  };

  const initSearch = (e) => {
    stopEvent(e);
    setInsertDt("");
    setSearchTerm({
      ...initSearchTerm,
    });
    searchTermRef.current = {
      ...initSearchTerm,
    };
    searchList();
  };

  /**
   * 변경점 히스토리 확인하기
   * @param {*} e
   */
  const onCompareHistory = (program) => {
    // const target1 = ProgramHistoryDetail
    // const target2 =
    const target1Index = ProgramHistoryList.findIndex(
      (p) => p.programHistoryId === program.programHistoryId
    );
    const currentTarget = ProgramHistoryList[target1Index].programHistoryId;
    const prevTarget2 = ProgramHistoryList[target1Index + 1].programHistoryId;

    navigate(
      `${Enums.BuilderPath.MOBILE.MAIN}/${Enums.BuilderPath.MOBILE.HISTORY}/${programId}/${historyMstId}/compare/${currentTarget}/${prevTarget2}`
    );
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line">
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> List
          </Button>
        </div>
        <Form>
          <Row className="pb-3 pt-3">
            <Col xs={1} />
            <Col>
              <TextField
                size="small"
                label="Commit Content"
                placeholder="Commit Content"
                fullWidth={true}
                id="commitComment"
                value={searchTerm.commitComment || ""}
                onChange={onChangeSearchTerm}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                label="Worker ID"
                placeholder="Worker ID"
                fullWidth={true}
                id="workerId"
                value={searchTerm.workerId || ""}
                onChange={onChangeSearchTerm}
              />
            </Col>
            <Col>
              <input
                className="history-date-picker"
                id="insertDt"
                value={insertDt}
                onChange={onChangeDate}
              />
            </Col>
            <Col style={{ display: "flex", gap: "10px" }}>
              <Button variant="primary" onClick={searchList}>
                Search <BsSearch size="14" />
              </Button>
              <Button variant="secondary" onClick={initSearch}>
                Init Search <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
        </Form>
        {isLoading && (
          <>
            <div className="grid-data-load-box" style={{ height: "600px" }}>
              <CircularProgress size={15} style={{ color: "white" }} />
              Loading...
            </div>
          </>
        )}
        <div style={{ maxHeight: "calc(100% - 200px)", overflow: "auto" }}>
          <Timeline align="left" position="right">
            {displayProgramList.map((_dateProgram, idx) => {
              return (
                <TimelineItem key={idx}>
                  <TimelineOppositeContent
                    sx={{ flex: 0.1 }}
                    color="textSecondary"
                  >
                    <div style={{ marginTop: "7px" }}>
                      {CommonUtils.getDate(_dateProgram[0].insertDt)}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" style={{ fontSize: "larger" }}>
                      <MdOutlineUpdate />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {_dateProgram.map((_program) => {
                      return (
                        <MobileProgramHistoryAccordionContents
                          program={_program}
                          onCompareHistory={(e) => onCompareHistory(_program)}
                          key={_program.programHistoryId}
                        />
                      );
                    })}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
        {!isLast && (
          <MoreButtonLine>
            <Button variant="outline-dark" onClick={onClickNextPage}>
              {isLoading ? (
                <>
                  <CircularProgress size={15} style={{ color: "black" }} />{" "}
                  Loading
                </>
              ) : (
                <>
                  <FiMoreHorizontal /> More
                </>
              )}
            </Button>
          </MoreButtonLine>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MobileProgramHistoryDetail;
