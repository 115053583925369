import produce from "immer";
import { JSON_DATA_TYPE, newArrForm, newObjForm } from ".";
import JsonUtils from "../utils/JsonUtils";
import StringUtils from "../utils/StringUtils";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineLibraryAdd } from "react-icons/md";

export const BuilderWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 15px;
`;

const BuilderRow = styled.div`
  min-width: 600px;
  display: flex;
  border-radius: 5px;

  &:hover {
    background-color: #e1e1e1;
  }
`;
const StepWrapper = styled.div`
  /* width: 15px; */
  ${(props) => {
    if (props.step && props.step > 0) {
      return {
        // width:
        paddingLeft: `${props.step * 15}px`,
      };
    }
  }}
  /* height:40px; */
  & .step {
    width: 10px;
    height: 100%;
    & div:first-child {
      height: 50%;
      border-left: 2px dotted;
      border-bottom: 2px dotted;
    }

    & div:last-child {
      height: 50%;
      border-left: 2px dotted;
    }

    &.last {
      & div:last-child {
        height: 50%;
        border: none;
      }
    }
  }
`;

const ObjectWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 5fr 2fr;
  gap: 15px;
  padding: 2px;
  width: 100%;
`;

const BuilderCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Select = styled.select`
  width: 100%;
  border-color: lightgray;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 4px;
  &:focus {
    box-shadow: 0px 0px 7px dodgerblue;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding-left: 20px;
  padding: 4px;
  &:focus {
    box-shadow: 0px 0px 7px dodgerblue;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  color: dodgerblue;
  font-size: 14px;
  &:hover {
    font-weight: bold;
  }
`;

const EmptyList = styled.div`
  width: 100%;
  min-height: 100px;
  background: #3c3c3c55;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Builder = ({
  builderData = [],
  onChange = () => {},
  keyLabel,
  valueLabel,
  callback,
  isAddFieldButton = true,
  isViewModeChangeButton = false,
  setViewMode,
  show,
  setBuilderData,
  ...props
}) => {
  /**
   * 오브젝트 타입 변환
   * @param {*} e
   */
  const onAddObject = (e) => {
    setBuilderData([...builderData, { ...newObjForm() }]);
  };

  /**
   * input Change 이벤트
   * @param {*} e
   * @param {*} object
   * @param {*} type
   */
  const onChangeObject = (e, object, type) => {
    const newObj = produce(builderData, (draft) => {
      const newOne = JsonUtils.findNode(draft, "compId", object.compId);
      newOne[e.target.id] = e.target.value;
      if (type) {
        newOne.type = type;
      }
    });
    setBuilderData(newObj);
  };

  /**
   * 인풋 추가 array, object 동일
   * @param {*} e
   * @param {*} parentsCompId
   */
  const onAddArr = (e, parentsCompId, type) => {
    const newObj = produce(builderData, (draft) => {
      if (parentsCompId) {
        const obj = JsonUtils.findNode(draft, "compId", parentsCompId);
        if (type === JSON_DATA_TYPE.ARRAY) {
          const _form = newArrForm(obj.value.length ? obj.value.length : 0);
          obj.value.push(_form);
        } else {
          obj.value.push({ ...newObjForm() });
        }
      } else {
        draft.push({ ...newObjForm() });
      }
    });
    setBuilderData(newObj);
  };
  /**
   * 인풋 삭제
   * @param {*} e
   * @param {*} compId
   */
  const onDeleteArr = (e, compId) => {
    const newObj = produce(builderData, (draft) => {
      JsonUtils.removeNode(draft, "compId", compId);
    });

    const cleanupArr = (arr) => {
      return arr.filter((a) => a);
    };
    const result = cleanupArr(newObj);
    setBuilderData(result);
  };

  return (
    <BuilderWrapper
      style={{ ...props.style, display: show ? "block" : "none" }}
    >
      <ButtonWrapper>
        {isAddFieldButton && (
          <Button onClick={onAddObject}>
            {props.addButtonLabel || "+ Add Field"}
          </Button>
        )}
        {isViewModeChangeButton && (
          <Button
            style={{ color: "green" }}
            onClick={(e) => setViewMode("Editor")}
          >
            Editor Viewer
          </Button>
        )}
      </ButtonWrapper>
      {builderData.length > 0 ? (
        builderData.map((_obj, index) => {
          return (
            <BuilderObject
              key={_obj.compId}
              object={_obj}
              step={0}
              onChangeObject={onChangeObject}
              onAddArr={onAddArr}
              onDeleteArr={onDeleteArr}
              lastObject={index === builderData.length - 1}
              keyLabel={keyLabel}
              valueLabel={valueLabel}
              prevKey={[_obj.key]}
              setBuilderData={setBuilderData}
              {...props}
            />
          );
        })
      ) : (
        <EmptyList>{props.emptyMessage || "Please add Field."}</EmptyList>
      )}
    </BuilderWrapper>
  );
};

const BuilderObject = ({
  object,
  step,
  parentsType,
  parentsCompId,
  prevKey = [],
  onChangeObject,
  onAddArr,
  onDeleteArr,
  lastObject,
  builderConfig,
  keyLabel,
  valueLabel,
  ...props
}) => {
  const onChangeType = (e) => {
    const value = e.target.value;
    switch (value) {
      case JSON_DATA_TYPE.DATE_TIME:
      case JSON_DATA_TYPE.STRING:
        onChangeObject({ target: { id: "value", value: "" } }, object, value);
        break;
      case JSON_DATA_TYPE.ARRAY:
        onChangeObject(
          {
            target: {
              id: "value",
              value: [newArrForm(0)],
            },
          },
          object,
          value
        );
        break;
      case JSON_DATA_TYPE.OBJECT:
        onChangeObject(
          { target: { id: "value", value: [newObjForm()] } },
          object,
          value
        );
        break;
      default:
        break;
    }
  };

  const getDateValue = (time) => {
    if (StringUtils.isEmpty(time)) return "";
    const _timeChanger = (time) => {
      return time >= 10 ? time : `0${time}`;
    };
    const _ISOdate = new Date(time);
    const _date = new Date(
      _ISOdate.getTime() + _ISOdate.getTimezoneOffset() * 60000
    );
    // const currentTzTime = moment(dateTime).add(_date.getTimezoneOffset(), "m");
    const _Y = _date.getFullYear();
    const _M = _timeChanger(_date.getMonth() + 1);
    const _D = _timeChanger(_date.getDate());
    const _H = _timeChanger(_date.getHours());
    const _MI = _timeChanger(_date.getMinutes());
    const _S = _timeChanger(_date.getSeconds());
    const _dateValue = `${_Y}-${_M}-${_D} ${_H}:${_MI}:${_S}`;
    return _dateValue;
  };

  return (
    <>
      <BuilderRow>
        {step > 0 ? (
          <StepWrapper step={step}>
            {parentsCompId ? (
              <div className={`step ${lastObject ? "last" : ""}`}>
                <div></div>
                <div></div>
              </div>
            ) : (
              <></>
            )}
          </StepWrapper>
        ) : (
          <></>
        )}

        <ObjectWrapper>
          <BuilderCol>
            {object.subType === JSON_DATA_TYPE.ARRAY_INDEX ? (
              <Input
                placeholder={keyLabel}
                value={object.key}
                onChange={(e) => {}}
                disabled
                id="key"
              />
            ) : builderConfig?.key ? (
              <builderConfig.key
                {...props}
                placeholder={keyLabel || "Enter Key"}
                value={object.key}
                onChange={(e) => onChangeObject(e, object)}
                changeObject={onChangeObject}
                id="key"
                prevKey={prevKey}
                jsonDataSet={object}
              />
            ) : (
              <Input
                placeholder={keyLabel || "Enter Key"}
                value={object.key}
                onChange={(e) => onChangeObject(e, object)}
                id="key"
              />
            )}
          </BuilderCol>
          <BuilderCol>
            <Select value={object.type} onChange={onChangeType} id={"type"}>
              <option value={JSON_DATA_TYPE.STRING}>String</option>
              <option value={JSON_DATA_TYPE.DATE_TIME}>DateTime</option>
              <option value={JSON_DATA_TYPE.ARRAY}>Array</option>
              <option value={JSON_DATA_TYPE.OBJECT}>Map(Object)</option>
            </Select>
          </BuilderCol>
          <BuilderCol>
            {builderConfig?.value && object.type === JSON_DATA_TYPE.STRING ? (
              <builderConfig.value
                placeholder={valueLabel || "Enter Value"}
                value={object.value}
                onChange={(e) => onChangeObject(e, object)}
                changeObject={onChangeObject}
                id="value"
                prevKey={prevKey}
                jsonDataSet={object}
                {...props}
              />
            ) : object.type === JSON_DATA_TYPE.STRING ? (
              <Input
                placeholder={valueLabel || "Enter Value"}
                value={object.value}
                onChange={(e) => onChangeObject(e, object)}
                id="value"
              />
            ) : object.type === JSON_DATA_TYPE.DATE_TIME ? (
              <Input
                placeholder={"YYYY-MM-DD HH:MI:SS"}
                defaultValue={getDateValue(object.value)}
                onBlur={(e) => onChangeObject(e, object)}
                id="value"
              />
            ) : (
              <></>
            )}
          </BuilderCol>
          <BuilderCol>
            {object.type === JSON_DATA_TYPE.ARRAY ||
            object.type === JSON_DATA_TYPE.OBJECT ? (
              <Tooltip title="Add sub-level">
                <Button
                  style={{ color: "lime" }}
                  onClick={(e) => onAddArr(e, object.compId, object.type)}
                >
                  <AiOutlinePlus />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip title="Add same level">
              <Button onClick={(e) => onAddArr(e, parentsCompId, object.type)}>
                <MdOutlineLibraryAdd />
              </Button>
            </Tooltip>
            <Tooltip title="delete">
              <Button
                style={{ color: "tomato" }}
                onClick={(e) => onDeleteArr(e, object.compId)}
              >
                <AiOutlineDelete />
              </Button>
            </Tooltip>
          </BuilderCol>
        </ObjectWrapper>
      </BuilderRow>
      {object.type === JSON_DATA_TYPE.OBJECT ||
      object.type === JSON_DATA_TYPE.ARRAY ? (
        <>
          {object.value.map((childObject, index) => {
            return (
              <BuilderObject
                {...props}
                step={step + 1}
                parentsType={object.type}
                parentsCompId={object.compId}
                prevKey={[...prevKey, childObject.key]}
                key={childObject.compId}
                object={childObject}
                onAddArr={onAddArr}
                onDeleteArr={onDeleteArr}
                onChangeObject={onChangeObject}
                lastObject={index === object.value.length - 1}
                builderConfig={builderConfig}
                keyLabel={keyLabel}
                valueLabel={valueLabel}
              />
            );
          })}
        </>
      ) : object.subType === JSON_DATA_TYPE.ARRAY_INDEX &&
        StringUtils.includesIgnoreCase(object.type, [
          JSON_DATA_TYPE.ARRAY,
          JSON_DATA_TYPE.OBJECT,
        ]) ? (
        <>
          {object.value.map((childObject, _index) => {
            return (
              <BuilderObject
                {...props}
                step={step + 1}
                parentsType={object.type}
                parentsCompId={object.compId}
                prevKey={[...prevKey, childObject.key]}
                key={childObject.compId}
                object={childObject}
                onAddArr={onAddArr}
                onDeleteArr={onDeleteArr}
                onChangeObject={onChangeObject}
                lastObject={_index === object.value.length - 1}
                builderConfig={builderConfig}
                keyLabel={keyLabel}
                valueLabel={valueLabel}
              />
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Builder;
