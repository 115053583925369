import produce from "immer";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";

import * as Enums from "components/builder/BuilderEnum";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UIEditorDNDContainer, {
  UIViewerDNDContainer,
} from "components/builder/ui/editor/UIEditorDNDContainer";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";

import loadable from "@loadable/component";

const style = {};
const UIEditorContainer = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const ref = useRef(null);
  const componentRef = useRef();
  let theme = CommonUtils.getTheme();

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    componentType: item.componentType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  let componentStyle = {};

  let DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  let styleClass = "editor-flex-column pass-focus";
  let componentClassString = "";
  if (
    !ObjectUtils.isEmpty(item.editorAttr) &&
    !StringUtils.isEmpty(item.editorAttr.componentClass)
  ) {
    componentClassString = StringUtils.substringAfter(
      item.editorAttr.componentClass,
      "/"
    );

    /* editor-flex-column : display:flex, direction:colunmn ==> 상-하 row 추가 */
    if (componentClassString === "Container") {
      if (!componentRef.current) {
        componentRef.current = loadable(() =>
          import(`components/builder/ui/editor/theme/${theme}/layout/Container`)
        );
      }

      styleClass = "editor-flex-column pass-focus";
    } else if (componentClassString === "Block") {
      if (!componentRef.current) {
        componentRef.current = loadable(() =>
          import(`components/builder/ui/editor/theme/${theme}/layout/Block`)
        );
      }

      styleClass = "editor-columns pass-focus";
    } else if (componentClassString === "InputGroup") {
      if (!componentRef.current) {
        componentRef.current = loadable(() =>
          import(
            `components/builder/ui/editor/theme/${theme}/layout/InputGroup`
          )
        );
      }

      styleClass = "editor-columns pass-focus";
      DNDContainer = UIViewerDNDContainer;
    } else if (componentClassString === "CardLayout") {
      if (!componentRef.current) {
        componentRef.current = loadable(() =>
          import(
            `components/builder/ui/editor/theme/${theme}/layout/CardLayout`
          )
        );
      }
    }
  } else {
    if (!componentRef.current) {
      componentRef.current = loadable(() =>
        import(`components/builder/ui/editor/theme/${theme}/layout/Container`)
      );
    }
  }

  return (
    <div ref={ref} className="whole-wrap">
      <componentRef.current
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
      >
        <div className={styleClass}>
          {!ArrayUtils.isEmpty(item.child)
            ? item.child.map((child, index) => {
                const currentPath = `${path}-${index}`;
                if (componentClassString === "InputGroup") {
                  componentStyle = {};
                  if (
                    !ObjectUtils.isEmpty(child.propertyValue) &&
                    !ObjectUtils.isEmpty(child.propertyValue.style) &&
                    !StringUtils.isEmpty(child.propertyValue.style.width)
                  ) {
                    // componentStyle = { width: child.propertyValue.style.width };
                  } else if (
                    !ObjectUtils.isEmpty(child.editorAttr) &&
                    child.editorAttr.componentClass !== "form/Button"
                  ) {
                    componentStyle = { flex: "1 1  auto" };
                  }
                }

                return (
                  <React.Fragment key={index}>
                    <DNDContainer
                      data={{
                        location: Enums.ComponentType.CONTAINER,
                        rootLocation: rootLocation,
                        path: currentPath,
                        childrenCount: item.child.length,
                        propertyValue: data.propertyValue,
                        compId: data.compId,
                      }}
                      onDrop={handleDrop}
                      templateComponents={templateComponents}
                      className="horizontal-drag"
                    />
                    <UIEditorChildRender
                      key={child.compId}
                      data={child}
                      handleDrop={handleDrop}
                      templateComponents={templateComponents}
                      path={currentPath}
                      dndContainer={DNDContainer}
                      rootLocation={rootLocation}
                      style={{ ...componentStyle }}
                    />
                  </React.Fragment>
                );
              })
            : ""}
          <DNDContainer
            data={{
              location: Enums.ComponentType.CONTAINER,
              rootLocation: rootLocation,
              path: `${path}-${
                ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
              }`,
              propertyValue: data.propertyValue,
              compId: data.compId,
              childrenCount: ArrayUtils.isEmpty(item.child)
                ? 0
                : item.child.length,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
            className={`horizontal-drag ${
              ArrayUtils.isEmpty(item.child) ? "horizontal-drag-full" : ""
            }`}
          />
        </div>
      </componentRef.current>
    </div>
  );
};
export default React.memo(UIEditorContainer);
