import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Popup from "components/common/Popup";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import FormTypeSelectPopup from "page/popup/FormTypeSelectPopup";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import ReactDOM from "react-dom";
import { CgChevronDoubleDownR } from "react-icons/cg";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { generateUiComponent } from "../editor/handler/UIEditorEventHandler";
import UITemplateHandler from "../editor/handler/UITemplateHandler";
import UITemplateHelper from "../editor/helper/UITemplateHelper";

const TreeItem = styled.div`
  background-color: ${(props) => (props.$selected ? "#405163" : "transparent")};
  width: ${(props) => `calc(100% - 5px - ${props.step * 15}px)`};
  min-width: 150px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: ${(props) => props.step * 15}px;
  padding-left: 5px;
  border-radius: 5px;
  opacity: ${(props) => (props.$isDragging ? 0 : 1)};
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    background-color: #3c3c3c;
    cursor: pointer;
  }
  & .extendIcon {
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    color: lightgray;
  }
`;

const DraggableTreeItem = ({
  moveItem,
  node,
  step,
  label,
  nodeType,
  treeId,
  gridId, //그리드 일때만 사용
  ...props
}) => {
  const { component: activedComponent, treeNodeIds } = useSelector(
    (state) => state.activedUIComponent
  );
  const output = useSelector((state) => state.outputUI.output);
  const componentRef = useRef(null);
  const [isExtend, setIsExtend] = useState(false);
  const [hoverDirection, setHoverDirection] = useState("");
  const { component: componentContext } = useContext(AppContext);

  useEffect(() => {
    if (treeNodeIds.length > 0) {
      setIsExtend(treeNodeIds.indexOf(treeId || node.compId) > -1);
    }
  }, [treeNodeIds]);

  const templateComponents =
    UITemplateHelper.getTemplateComponents(componentContext);

  const acceptType = () => {
    //그리드는 그리드 내부에서만 움직이도록 조정
    let acceptArr;
    if (
      StringUtils.equalsIgnoreType(nodeType, Enums.ComponentType.GRID_COLUMN)
    ) {
      acceptArr = gridId;
    } else {
      acceptArr = Object.keys(Enums.ComponentType)
        .map((key) => Enums.ComponentType[key])
        .filter((value) => StringUtils.indexOf(value, "grid.") === -1);
    }
    return acceptArr;
  };

  /**
   * DND 이벤트
   * @param {*} dragTarget
   * @param {*} hoverTarget
   * @returns {[dragTarget,hoverTarget,direction]}
   */
  const CalculateDnd = (dragTarget, dragTargetOffset) => {
    if (!componentRef.current) return null;
    if (dragTarget.compId === node.compId) return null;
    const hoverBoundingRect = ReactDOM.findDOMNode(
      componentRef.current
    ).getBoundingClientRect();

    if (!dragTargetOffset) return null;

    /**
     * 1. hover 타겟 중앙기준
     * 2. 왼쪽에 놓으면 위치이동
     * 3. 오른쪽에 놓으면 안으로 이동
     * 4. 그리드 안에서는 inside 없음 무조건 upside
     * **/
    if (StringUtils.indexOf(nodeType, "grid.") > -1) {
      return [dragTarget, node, "upside"];
    } else {
      const inputIntoHoverTargetZone = hoverBoundingRect.right - 30;
      const direction =
        inputIntoHoverTargetZone < dragTargetOffset.x ? "inside" : "upside";
      return [dragTarget, node, direction];
    }
  };

  /**
   * sidebar에서 DND가 이루어졌을 때 Editor 용으로 변화 시켜줘 야함
   * @param {*} item
   * @returns
   */
  const generateComponentForSidebarItem = (item) => {
    const nodeObj = {
      node: {
        compId: item.compId,
        type: item.type,
        ...generateUiComponent({
          componentItem: item.component,
          output: output,
        }),
      },
    };
    return nodeObj;
  };

  const placeComponent = (item, clientOffset) => {
    const CalcDND = CalculateDnd(item.node, clientOffset);
    if (!CalcDND) {
      return null;
    } else {
      const [dragTarget, hoverTarget, direction] = CalcDND;
      //hover 타겟 중앙기준 왼쪽에 놓으면 위치이동, 오른쪽에 놓으면 안으로 이동
      if (moveItem) moveItem(dragTarget, hoverTarget, direction);
    }
  };

  /**
   * 콜백 받으려고 프로미스로 인위적으로 생성
   * @param {*} item
   * @returns
   */
  const promiseUITemplateHandler = (item) => {
    const { component } = item;
    let preHandleOptions =
      (typeof component.preHandleOptions === "string"
        ? JsonUtils.parseJson(component.preHandleOptions)
        : component.preHandleOptions) || {};
    return new Promise((resolve, reject) => {
      UITemplateHandler.call(
        this,
        {},
        output,
        item,
        preHandleOptions,
        templateComponents,
        (newItem) => {
          resolve(newItem);
        }
      );
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: acceptType(),
    drop: async (item, monitor) => {
      const monitorClientOffset = monitor.getClientOffset();
      if (item.type === Enums.ComponentType.SIDEBAR_ITEM) {
        const { component } = item;
        let node = null;
        if (component.componentType === Enums.ComponentType.TEMPLATE) {
          //템플릿은 팝업에서 세부설정 후 추가
          //클라이언트 오프셋이 프로미스 후 사라지기 때문에 변수에 담음
          node = await promiseUITemplateHandler(item);
          placeComponent({ node }, monitorClientOffset);
        } else if (component.componentType === Enums.ComponentType.SERVICE) {
          //서비스 컴포넌트
          node = JSON.parse(component.componentOutput);
          JsonUtils.updateCompId(node);
          placeComponent({ node }, monitorClientOffset);
        } else {
          const component = generateComponentForSidebarItem(item).node;
          const isFromElementTab = component.viewerAttr?.isDataStudioElement;

          if (isFromElementTab && component.type === "form") {
            const defaultValues = JSON.parse(
              item.component.preHandleOptions
            )?.defaultValues;

            Popup.open(
              <FormTypeSelectPopup
                componentInfo={component}
                defaultValues={defaultValues}
                componentContext={componentContext}
                templateComponents={templateComponents}
                output={output}
                callbackFn={(formData) => {
                  placeComponent({ node: formData }, monitorClientOffset);
                }}
              />,
              { style: { content: { width: "50%", minWidth: "1200px" } } }
            );
          } else {
            placeComponent({ node: component }, monitorClientOffset);
          }
        }
      } else {
        placeComponent(item, monitorClientOffset);
      }
    },
    canDrop: () => true,
    hover: (item, monitor) => {
      //사이드바에서 바로 가져오는 경우
      if (item.type === Enums.ComponentType.SIDEBAR_ITEM) {
        item = generateComponentForSidebarItem(item);
      }
      const CalcDND = CalculateDnd(item.node, monitor.getClientOffset());
      if (!CalcDND) {
        return null;
      } else {
        const [, , direction] = CalcDND;
        setHoverDirection(direction);
        if (StringUtils.equalsIgnoreCase(direction, "inside") && !isExtend) {
          setIsExtend(true);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    //grid Column의 경우 gridID로 type을 제한하여 같은 그리드 안에서만 이동되도록 한다.
    type: StringUtils.equalsIgnoreType(
      nodeType,
      Enums.ComponentType.GRID_COLUMN
    )
      ? gridId
      : nodeType,
    item: {
      node: node,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging && isExtend) {
      setIsExtend(false);
    }
  }, [isDragging]);

  useEffect(() => {
    if (!isOver) {
      setHoverDirection("");
    }
  }, [isOver]);

  const onClickExpandArrow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExtend(!isExtend);
  };

  const applyRef = useCallback((node) => {
    componentRef.current = node;
    drag(drop(node));
  }, []);

  return (
    <div
      style={{
        borderTop: StringUtils.equalsIgnoreCase(hoverDirection, "upside")
          ? "4px solid lightgray"
          : "",
      }}
    >
      <TreeItem
        ref={applyRef}
        style={{
          ...props.style,
        }}
        $selected={StringUtils.equalsIgnoreType(
          activedComponent.compId,
          node.compId
        )}
        $isDragging={isDragging}
        step={step}
        {...props}
      >
        <span onClick={onClickExpandArrow} style={{ cursor: "pointer" }}>
          {props.children &&
            (isExtend ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />)}
        </span>
        <span>{label}</span>

        {StringUtils.indexOf(nodeType, "grid.") === -1 &&
          StringUtils.equalsIgnoreCase(hoverDirection, "inside") && (
            <div className="extendIcon">
              <CgChevronDoubleDownR />
            </div>
          )}
      </TreeItem>
      {isExtend && props.children}
    </div>
  );
};

export default DraggableTreeItem;
