import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";

import UIEditorDNDListContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import * as Enums from "components/builder/BuilderEnum";
import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
  NumberUtils,
  JsonUtils,
} from "components/common/utils/CommonUtils";
import MobileEditorListColumn from "components/builder/mobile/editor/render/MobileEditorListColumn";
import ListLayout from "components/builder/mobile/mobileComponents/layout/list/ListLayout";
import ListHeader from "components/builder/mobile/mobileComponents/layout/list/ListHeader";
import ListCell from "components/builder/mobile/mobileComponents/layout/list/ListCell";
import { useNavigate } from "react-router-dom";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { updateActivateProps } from "components/builder/ui/reducers/UIBuilderAction";
import { GiConsoleController } from "react-icons/gi";

const style = {};
const MobileEditorListLayout = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;

  const ref = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activedComponent = useSelector((state) => state.activedUIComponent);
  const output = useSelector((state) => state.outputUI.output);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDListContainer;

  let listColumns =
    !ObjectUtils.isEmpty(item.propertyValue) &&
    !ObjectUtils.isEmpty(item.propertyValue.templateDetail) &&
    !ArrayUtils.isEmpty(item.propertyValue.templateDetail.columns)
      ? item.propertyValue.templateDetail.columns
      : [];

  let templateDetail =
    !ObjectUtils.isEmpty(item.propertyValue) &&
    !ObjectUtils.isEmpty(item.propertyValue.templateDetail)
      ? item.propertyValue.templateDetail
      : {};

  /**
   * wild character with 에 대한 계산
   * @param {*} width
   * @returns
   */
  const getWildNumber = (width) => {
    let wildNumber = 1;
    let colWildWidth = StringUtils.substringBefore(width, "*");
    if (NumberUtils.isNumeric(colWildWidth, "5")) {
      if (Number(colWildWidth) > 5) {
        wildNumber = 5;
      } else {
        wildNumber = Number(colWildWidth);
      }
    }
    return wildNumber;
  };

  // wildcharater(*)에 대한 width를 계산한다.
  let wildWidth = 0;
  if (!ArrayUtils.isEmpty(listColumns)) {
    //width가 '*'인 column count
    //분할 가능한 width
    let wildWidthCnt = 0;
    let divisibleWidth = 100;
    listColumns.map((column, index) => {
      if (column.visible !== false) {
        if (StringUtils.lastChar(column.width) === "*") {
          if (column.width.length === 1) {
            wildWidthCnt++;
          } else {
            wildWidthCnt = wildWidthCnt + getWildNumber(column.width);
          }
        } else if (StringUtils.lastChar(column.width) === "%") {
          let width = StringUtils.substringBefore(column.width, "%");
          if (
            NumberUtils.isNumeric(width, "5") &&
            Number(width) < 100 &&
            Number(width) < divisibleWidth
          ) {
            divisibleWidth = divisibleWidth - Number(width);
          } else {
            wildWidthCnt++;
          }
        }
      }
    });
    if (divisibleWidth > 0 && wildWidthCnt > 0) {
      wildWidth = divisibleWidth / wildWidthCnt;
    }
  }

  /**
   * Editor에서 Property속성 변경시 propertyValue 값 update
   * Grid 더블클릭 후 변경사항 적용에 사용함
   * @param {*} propertyValue
   */
  const updateProperty = (propertyValue) => {
    const compId = item.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    UIReduxHelper.updateOutput(changedOutput, dispatch);
    dispatch(
      updateActivateProps({
        ...activedComponent.propertyValue,
        ...propertyValue,
      })
    );
  };

  return (
    <div ref={ref} className="whole-wrap">
      <ListLayout
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
        navigate={navigate}
        fn={{ updateProperty: updateProperty }}
        output={output}
      >
        <div className="editor-grid-wrap pass-focus">
          {!StringUtils.isEmpty(templateDetail.showHeader) &&
          templateDetail.showHeader > 1 ? (
            <React.Fragment>
              <div
                style={{ width: "40px", minWidth: "40px" }}
                className={"editor-grid-columns"}
              >
                <ListHeader
                  componentInfo={{
                    templateDetail: templateDetail,
                    editorAttr: {
                      className: "grid-row-header",
                      headerType: "colvis",
                    },
                    propertyValue: {
                      colvis: templateDetail.colvis,
                    },
                  }}
                  event="renderEditor"
                />
                <ListCell
                  componentInfo={{
                    templateDetail: templateDetail,
                    propertyValue: {
                      name: `${templateDetail.numbering ? 1 : ""}`,
                    },
                    editorAttr: { className: "grid-row-header" },
                  }}
                  style={{ justifyContent: "center" }}
                  event="renderEditor"
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {StringUtils.isEmpty(templateDetail.selectionType) ||
          templateDetail.selectionType === "N" ? (
            ""
          ) : (
            <React.Fragment>
              <div
                style={{ width: "40px", minWidth: "40px" }}
                className={"editor-grid-columns"}
              >
                <ListHeader
                  componentInfo={{
                    templateDetail: templateDetail,
                    editorAttr: {
                      checkbox: true,
                      className: "grid-row-header",
                    },
                  }}
                  event="renderEditor"
                />
                <ListCell
                  componentInfo={{
                    templateDetail: templateDetail,
                    editorAttr: {
                      checkbox: true,
                      className: "grid-row-header",
                    },
                  }}
                  style={{ justifyContent: "center" }}
                  event="renderEditor"
                />
              </div>
            </React.Fragment>
          )}

          {!ArrayUtils.isEmpty(listColumns)
            ? listColumns.map((column, index) => {
                const currentPath = `${path}-${index}`;
                let columnData = { propertyValue: column };
                columnData.type = Enums.ComponentType.LIST_COLUMN;
                columnData.name = column.name;
                let columnWidth;
                let columnMinWidth;

                if (column.visible === false) {
                  columnWidth = "80px";
                  columnMinWidth = "80px";
                } else {
                  if (StringUtils.lastChar(column.width) === "*") {
                    columnWidth = getWildNumber(column.width) * wildWidth + "%";
                    columnMinWidth =
                      "calc( 100px * " + getWildNumber(column.width) + ")";
                  } else {
                    columnWidth = column.width + "px";
                    columnMinWidth = column.width + "px";
                  }
                }

                let columnStyle = {
                  width: columnWidth,
                  minWidth: columnMinWidth,
                };
                return (
                  <React.Fragment key={index}>
                    {/* <DNDContainer
                      data={{
                        location: Enums.ComponentType.LIST_LAYOUT,
                        rootLocation: rootLocation,
                        path: currentPath,
                        childrenCount: listColumns.length,
                      }}
                      onDrop={handleDrop}
                      templateComponents={templateComponents}
                      className="horizontal-drag"
                    /> */}
                    <MobileEditorListColumn
                      className={`editor-grid-columns draggable ${
                        index === listColumns.length - 1 ? "last-column" : ""
                      }`}
                      style={columnStyle}
                      data={columnData}
                      path={currentPath}
                      rootLocation={rootLocation}
                      templateDetail={templateDetail}
                    />
                  </React.Fragment>
                );
              })
            : ""}
          {/* <DNDContainer
            data={{
              location: Enums.ComponentType.LIST_LAYOUT,
              rootLocation: rootLocation,
              path: `${path}-${listColumns.length}`,
              childrenCount: listColumns.length,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
            className={`horizontal-drag ${
              ArrayUtils.isEmpty(listColumns) ? "horizontal-drag-full" : ""
            }`}
          /> */}
        </div>
      </ListLayout>
    </div>
  );
};
export default React.memo(MobileEditorListLayout);
