import { Button as MButton } from "@mui/material";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import produce from "immer";
import React, { Fragment } from "react";
import {
  Accordion,
  Button,
  FormLabel,
  InputGroup,
  Overlay,
  Popover,
} from "react-bootstrap";
import * as Fa from "react-icons/fa";
import styled from "styled-components";

import * as Enums from "components/builder/BuilderEnum";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/editor/theme/common/UIComponentStyle";
import FormComponent from "components/builder/ui/editor/theme/common/form/FormComponent";
import Popup from "components/common/Popup";
import USelectbox from "components/common/element/USelectbox";
import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";

import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import ExtendPopup from "page/popup/ExtendPopup";
import GridToolbarBtnPopup from "page/popup/GridToolbarBtnPopup";

import UPopover from "components/common/element/UPopover";
import GridNumberingFormatPopup from "page/popup/GridNumberingFormatPopup";
import IconPopup from "page/popup/IconPopup";
import MesFunTabPopup from "page/popup/MesFunTabPopup";
import PopupHandleConfigPopup from "page/popup/PopupHandleConfigPopup";

const ListStyled = styled.div`
  /* ---------Toolbar Tab Style----------- */
  .nav-item {
    width: 120px;
  }
  #toolbarOptions_buttons .btn-group.el-title {
    display: none;
  }
  .w-12p {
    width: 12% !important;
  }
  .w-18p {
    width: 18% !important;
  }
  .w-23p {
    width: 23% !important;
  }
  .w-35p {
    width: 35% !important;
  }
  .w-37p {
    width: 37% !important;
  }
`;
let navigate;

class ListLayout extends FormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      targets: [],
      groupInfo: [{}],
    };

    this.btnChange = this.btnChange.bind(this); //BootstrapSwitchButton Change Event
    this.onChangeTemplateDetail = this.onChangeTemplateDetail.bind(this); //on Change GridOptions
    this.onChangeAdditionalOptions = this.onChangeAdditionalOptions.bind(this); //on Change AdditionalOptions
    // this.onAddLevelRowIcons = this.onAddLevelRowIcons.bind(this);
    // this.onDeleteLevelRowIcons = this.onDeleteLevelRowIcons.bind(this);
    // this.onChangeLevelRowIcons = this.onChangeLevelRowIcons.bind(this);
    this.onChangeRowNumberingFormat =
      this.onChangeRowNumberingFormat.bind(this);
    this.openPopupSetButton = this.openPopupSetButton.bind(this); //툴바 버튼 설정의 "상세"버튼 클릭시 popup연결
    this.openPopupEvent = this.openPopupEvent.bind(this); //Event 설정의 "Eveent 상세"버튼 클릭시 popup연결
    this.openPopupIcon = this.openPopupIcon.bind(this);
    this.openNumberingFormatPopup = this.openNumberingFormatPopup.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickParam = this.handleClickParam.bind(this);
    this.handleClickScrollY = this.handleClickScrollY.bind(this);
    // this.openListEditPopup = this.openListEditPopup.bind(this);
  }
  static contextType = AppContext;

  popoverRef = React.createRef();
  popoverRefParam = React.createRef();
  popoverRefScrollY = React.createRef();

  componentDidMount = () => {
    if (this.props.navigate) {
      navigate = this.props.navigate;
    }
  };

  /**
   * Grid toolbar buttons
   */
  GRID_TOOLBAR_DEFAULT_BUTTONS = {
    add: {
      icon: "fa-plus-square",
      name: "Add Row",
    },
    clone: {
      icon: "fa-copy",
      name: "Copy Row",
    },
    remove: {
      icon: "fa-trash",
      name: "Delete Row",
    },
    undo: {
      icon: "fa-times-circle",
      name: "Cancel",
    },
    downExcelFromServer: {
      icon: "fa-file-excel", //"fa-file-excel-o",
      name: "Download Excel",
    },
    downExcelFromLocal: {
      icon: "fa-file-excel", //"fa-file-excel-o",
      name: "Download Excel",
    },
    pivot: {
      icon: "fa-sync-alt", //"fa-refresh",
      name: "pivot",
    },
  };

  /**
   * BootstrapSwitchButton Change Event
   * @param {*} pId
   * @param {*} pValue
   */
  btnChange = (pId, pValue) => {
    this.onChangeIdValue(pId, pValue);
  };

  /**
   * type에 따른 change option 지정
   * @param {*} pId
   * @param {*} pVal
   * @param {*} pType
   */
  onChangeIdValue = (pId, pVal, pType) => {
    if (pType === "additionalOptions") {
      this.onChangeAdditionalOptions({ target: { id: pId, value: pVal } });
    } else {
      this.onChangeTemplateDetail({ target: { id: pId, value: pVal } });
    }
  };

  /**
   * on Change templateDetail
   * @param {Event} event
   */
  onChangeTemplateDetail = (event) => {
    let optionId = event.target.id;
    let optionValue = event.target.value;
    if (
      !StringUtils.isEmpty(optionValue) &&
      event.currentTarget &&
      event.currentTarget.type === "number"
    ) {
      optionValue = Number(optionValue);
    }

    if (
      StringUtils.equalsIgnoreCase(optionId, "id") &&
      StringUtils.isEmpty(optionValue)
    ) {
      optionValue = StringUtils.getUuid();
      Message.alert("List ID cannot be Null.", Enums.MessageType.WARN);
    }
    const templateDetail = this.state.propertyValue.templateDetail;

    if (StringUtils.defaultString(templateDetail[optionId]) !== optionValue) {
      //이벤트 처리는 다르게 진행
      if (
        templateDetail.eventWorkspace &&
        templateDetail.eventWorkspace[optionId]
      ) {
        if (
          templateDetail[optionId].replaceAll("\n", "") !==
          optionValue.replaceAll("\n", "")
        ) {
          this.showEventChangeConfirmMessage(
            () => {
              let newTemplateDetail = this.onDeleteEventWorkspace(
                event.target.id,
                null,
                {
                  eventWorkspacePath: templateDetail,
                }
              );
              const newPropertyValue = produce(
                this.state.propertyValue,
                (draft) => {
                  draft.templateDetail = { ...newTemplateDetail };
                  if (StringUtils.isEmpty(optionValue)) {
                    delete draft.templateDetail[optionId];
                  } else {
                    draft.templateDetail[optionId] = optionValue;
                  }
                }
              );
              this.setState(
                {
                  propertyValue: newPropertyValue,
                },
                () => {
                  //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
                  this.props.fn.updateProperty(this.state.propertyValue);
                }
              );
            },
            () => {}
          );
        }
      } else {
        const newPropertyValue = produce(this.state.propertyValue, (draft) => {
          if (StringUtils.isEmpty(optionValue)) {
            delete draft.templateDetail[optionId];
          } else {
            draft.templateDetail[optionId] = optionValue;
          }

          //groupInfo 등록 및 삭제
          if (optionId === "aggregationType") {
            if (optionValue !== "P") {
              delete draft.templateDetail["groupInfo"];
            }
          }

          if (
            optionId === "id" &&
            !ArrayUtils.isEmpty(draft.templateDetail.columns)
          ) {
            draft.templateDetail.columns.map((column, index) => {
              column.id = optionValue;
            });
          }

          if (optionId === "displayMode") {
            if (!ArrayUtils.isEmpty(draft.templateDetail.columns)) {
              draft.templateDetail.columns.map((column, index) => {
                //fileupload일 경우, Data 편집을 "조회"로 고정한다.
                if (
                  StringUtils.isEmpty(column.dataType) ||
                  column.dataType.indexOf("fileupload") < 0
                ) {
                  column.editType = optionValue === "E" ? optionValue : "R";
                }
              });
            }
            // 조회용 Grid일 때 Grid Button 비활성화
            // if (optionValue === "D") {
            //   const newButtons = {};

            //   [...this.state.buttons].map((button) => {
            //     if (button.eventFnc) {
            //       newButtons[button.id] = button;
            //     }
            //   });
            //   draft.listOptions.additionalOptions.toolbarOptions.buttons = {
            //     ...newButtons,
            //   };
            // }
            // // 입력용 Grid일 때 Grid Button 활성화
            // else if (optionValue === "E") {
            //   const newButtons = {};

            //   [...this.state.buttons].map((button) => {
            //     if (button.eventFnc) {
            //       newButtons[button.id] = button;
            //     }
            //   });

            // for (let i = 0; i < 4; i++) {
            //   newButtons[this.state.buttons[i].id] = this.state.buttons[i];
            // }
            // draft.gridOptions.additionalOptions.toolbarOptions.buttons = {
            //   ...newButtons,
            // };
            // }
          }

          if (optionId === "dataModelEntityId") {
            // 엔티티 타입 정의
            draft.templateDetail.entityType =
              event.target._data.entityType || "TABLE";

            if (
              StringUtils.includesIgnoreCase(draft.templateDetail.entityType, [
                "PROCEDURE",
                "FUNCTION",
              ])
            ) {
              // 엔티티가 프로시져 타입일때 파라미터 이름 정의
              draft.templateDetail.procedureName = event.target._data.tableNm;
              // 파라미터 목록 정의
              draft.templateDetail.insertOption = this.settingInsertOption({
                parameterList: JSON.parse(event.target._data.parameterList),
                procedureName: event.target._data.tableNm,
              });
            } else {
              delete draft.templateDetail.insertOption;
              delete draft.templateDetail.procedureName;
            }
          }
          //autoRowHeights가 true, autoRowHeightType이 활성화 되면 defaltValue 셋팅
          if (
            optionId === "autoRowHeights" &&
            optionValue &&
            !draft.templateDetail.autoRowHeightType
          ) {
            draft.templateDetail.autoRowHeightType = "SYNC";
          }
        });
        if (optionId === "displayMode") {
          let newButtons = [...this.state.buttons];

          // 입력용 Grid 일 때 Grid Button 사용여부 true
          // if (optionValue === "E") {
          //   newButtons = produce(this.state.buttons, (draft) => {
          //     for (let i = 0; i < 4; i++) {
          //       draft[i].useYn = true;
          //     }
          //   });
          // }
          // 조회용 Grid 일 때 Grid Button 사용여부 false
          // else if (optionValue === "D") {
          //   newButtons = produce(this.state.buttons, (draft) => {
          //     draft.map((item) => {
          //       if (!item.eventFnc) {
          //         item.useYn = false;
          //       }
          //     });
          //   });
          // }
          this.setState({
            ...this.state,
            buttons: newButtons,
          });

          let val = [];
          newButtons
            .filter((item) => item.useYn)
            .map((item, index) => {
              val = { ...val, [item.id]: item };
            });
        }
        this.setState(
          {
            ...this.state.propertyValue,
            propertyValue: newPropertyValue,
          },
          () => {
            //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
            this.props.fn.updateProperty(this.state.propertyValue);
          }
        );
      }
    }
  };

  /**
   * on Change AdditionalOptions
   * @param {Event} event
   */
  onChangeAdditionalOptions = (event) => {
    let optionId = event.target.id;
    let optionValue = event.target.value;
    let pPropertyId = "";
    let pPropertyValue = "";
    const onBlurCallback = () => {
      if (!ObjectUtils.isEmpty(event.property)) {
        pPropertyId = event.property.id;
        pPropertyValue = event.property.value;
      }
      let strArr = [];

      if (!StringUtils.isEmpty(optionId)) {
        strArr = optionId.split("_");
        optionId = strArr[strArr.length - 1];
      }

      const newPropertyValue = produce(
        this.state.propertyValue.templateDetail,
        (draft) => {
          if (StringUtils.isEmpty(pPropertyValue)) {
            delete draft[pPropertyId];
          } else {
            draft[pPropertyId] = pPropertyValue;
          }

          if (
            ArrayUtils.isArray(optionValue) &&
            optionValue.length === 1 &&
            ObjectUtils.isEmpty(optionValue[0])
          ) {
            //빈값이 들어왔을 경우
            if (strArr[0] === "toolbarOptions") {
              delete draft.additionalOptions.toolbarOptions[optionId];

              if (ObjectUtils.isEmpty(draft.additionalOptions.toolbarOptions)) {
                delete draft.additionalOptions.toolbarOptions;
              }
            } else {
              if (ObjectUtils.isEmpty(draft.additionalOptions[optionId])) {
                delete draft.additionalOptions[optionId];
              }
            }
            if (ObjectUtils.isEmpty(draft.additionalOptions)) {
              delete draft.additionalOptions;
            }
          } else {
            if (ObjectUtils.isEmpty(draft["additionalOptions"])) {
              draft["additionalOptions"] = {};
            }
            //toolbarOptions._ 일 경우
            if (strArr[0] === "toolbarOptions") {
              if (
                ObjectUtils.isEmpty(draft.additionalOptions["toolbarOptions"])
              ) {
                draft["additionalOptions"]["toolbarOptions"] = {};
              }
              if (
                StringUtils.defaultString(
                  draft.additionalOptions["toolbarOptions"][optionId]
                ) !== optionValue
              ) {
                draft.additionalOptions.toolbarOptions[optionId] = optionValue;
              }
            } else if (
              StringUtils.defaultString(draft.additionalOptions[optionId]) !==
              optionValue
            ) {
              draft.additionalOptions[optionId] = optionValue;
            }
          }
        }
      );

      this.setStateTemplateDetail(newPropertyValue);
    };
    // optionId === id 인경우 커스텀 buttons의 ID가 바뀐것 -> 이벤트 워크스페이스를 검색해서 해당 ID의 워크스페이스를 삭제.
    if (
      optionId === "toolbarOptions_customButton" &&
      !ObjectUtils.isEmpty(this.props.componentInfo.editorAttr.eventWorkspace)
    ) {
      //기존 커스텀과 비교
      let willDeleteId = [];
      const customButtons =
        this.state.propertyValue.templateDetail.additionalOptions.toolbarOptions
          .customButton || [];
      for (const btn of customButtons) {
        if (!StringUtils.isEmpty(btn.id)) {
          //기존 커스텀 버튼과 새로 들어온 커스텀 버튼을 비교해서 신규 배열에서 없는 버튼 아이디를 별도로 수집함
          const willStoreButton = optionValue.find((nb) => nb.id === btn.id);
          if (!willStoreButton) {
            willDeleteId.push(btn.id);
          }
        }
      }

      if (!ArrayUtils.isEmpty(willDeleteId)) {
        //없는 아이디를 이벤트 워크스페이스에서 찾음
        const isDeleteFncDo = willDeleteId.reduce(
          (ac, cu) =>
            this.state.editorAttr.eventWorkspace[cu] ? true : false && ac,
          false
        );
        //찾는게 있으면 삭제 명령 실행
        if (isDeleteFncDo) {
          this.showEventChangeConfirmMessage(() => {
            const newEditorAttr = produce(this.state.editorAttr, (draft) => {
              for (const btnId of willDeleteId) {
                delete draft.eventWorkspace[btnId];
              }
            });
            this.props.fn.updateEditorAttr(this.props.compId, newEditorAttr);
            onBlurCallback();
          });
        } else {
          onBlurCallback();
        }
      } else {
        onBlurCallback();
      }
    } else {
      onBlurCallback();
    }
  };

  /**
   * 번호 Numbering format
   * @param {*} value
   */
  onChangeRowNumberingFormat = (value) => {
    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      draft.templateDetail.rowNumberingFormat = value;
    });

    this.setState(
      {
        ...this.state.propertyValue,
        propertyValue: newPropertyValue,
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   * 공통부분 - this.setState
   * @param {*} newPropertyValue
   */
  setStateTemplateDetail = (newPropertyValue) => {
    this.setState(
      {
        propertyValue: {
          ...this.state.propertyValue,
          templateDetail: newPropertyValue,
        },
      },
      () => {
        //propertyValue.compId 로 target component node를 찾아 json정보를 update한다.
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  /**
   *
   * @param {*} e
   * @param {*} title
   */
  openOptionsPopup = (e, title) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    const id = e.currentTarget.formTarget;
    const val = document.getElementById(id).value;

    Popup.open(
      <ExtendPopup
        title={title}
        fieldType="json"
        defaultValue={val}
        callbackFnc={(popVal) => this.callbackFnc(id, popVal)}
      />,
      options
    );
  };

  callbackFnc = (id, val) => {
    const jsonVal = JsonUtils.parseJson(val);
    this.setElementValue("input", id, val);
    this.onChangeTemplateDetail({ target: { id: id, value: jsonVal } });
  };

  /**
   * Event빌더 팝업 열기위한 프롭스
   * @param {*} type toolbar || event
   * @param {*} item 이벤트 정보 컨테이너
   * @param {String} 툴바 버튼 타입 -> 툴바일때만 입력
   * @returns
   */
  setEventBuilderProps = (type, item, pId) => {
    const eventType = item?.eventType || item?.id;
    let eventWorkspace = item.eventWorkspace || {};

    if (StringUtils.equalsIgnoreCase(type, "toolbar")) {
      eventWorkspace = !ObjectUtils.isEmpty(eventWorkspace)
        ? eventWorkspace
        : {};

      const getEventInfo = (eventType, eventInfo) => {
        return {
          eventWorkspace: eventWorkspace[eventType],
          compId: this.props.componentInfo.compId,
          eventType:
            Enums.EventHandlerEventType[eventType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          componentEventType: eventInfo.id || eventInfo.eventHandleCd,
          builderEventType: eventType,
          eventCd: eventInfo.eventCd,
          targetType: "listLayout",
          eventCategory: type,
          buttonId: item.id,
          buttonEventType: pId,
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };

      return {
        getEventInfo,
        onClickEventBuilder: (_type, eventInfo) => {
          if ((item.useYn != null || item.useYn !== undefined) && !item.useYn)
            return Message.alert(
              "Events can only be added to buttons that are in use.",
              Enums.MessageType.WARN
            );
          else {
            this.props.fn.onClickEventBuilder(getEventInfo(_type, eventInfo));
          }
        },
      };
    } else if (StringUtils.equalsIgnoreCase(type, "event")) {
      let eventInfo = this.props.componentInfo.componentEvent.find(
        (event, index) => event.eventHandleCd === eventType
      );
      let targetType = "";
      let targetObject;
      if (this.state.propertyValue.templateDetail.event) {
        targetObject = this.state.propertyValue.templateDetail.event.find(
          (e) => e.eventType === eventType
        );
        if (!targetObject) {
          const newPropertyValue = produce(
            this.state.propertyValue.templateDetail.event,
            (draft) => {
              const newItem = {
                eventType: eventType,
              };
              if (eventType === "LIST_LOAD") {
                newItem.target = this.state.propertyValue.templateDetail.id;
                newItem.type = "listLayout";
              } else if (
                eventType === "LIST_ROW_CLICK_U" ||
                eventType === "LIST_ROW_DBCLICK_U"
              ) {
                newItem.target = "none";
              }
              draft.push(newItem);
              targetObject = { ...newItem };
            }
          );
          this.onChangeIdValue("event", newPropertyValue);
        }
      } else {
        const newItem = {
          eventType: eventType,
        };
        if (eventType === "LIST_LOAD") {
          newItem.target = this.state.propertyValue.templateDetail.id;
          newItem.type = "listLayout";
        } else if (
          eventType === "LIST_ROW_CLICK_U" ||
          eventType === "LIST_ROW_DBCLICK_U"
        ) {
          newItem.target = "none";
        }
        this.onChangeIdValue("event", [newItem]);
        targetObject = { ...newItem };
      }

      if (targetObject) targetType = targetObject.type;

      const getEventInfo = (eventType) => {
        return {
          eventWorkspace: eventWorkspace[eventType],
          compId: this.props.componentInfo.compId,
          eventType:
            Enums.EventHandlerEventType[eventType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          builderEventType: item.eventType || eventType,
          eventCd: eventInfo.eventCd,
          targetType: targetType || "listLayout",
          componentEventType: eventType,
          eventCategory: type,
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };

      return {
        eventWorkspace: !ObjectUtils.isEmpty(eventWorkspace)
          ? eventWorkspace
          : null,
        getEventInfo,
        onClickEventBuilder: (_type) => {
          this.props.fn.onClickEventBuilder(getEventInfo(_type));
        },
      };
    }
  };

  /**
   * 툴바 버튼 이벤트 상세 설정 팝업 오픈
   * @param {*} pId
   * @param {*} e
   */
  openPopupSetButton = (pId, e) => {
    // const targetId = e.currentTarget.formTarget || e.currentTarget.id;
    // const additionalOptions =
    //   this.state.propertyValue.gridOptions["additionalOptions"] || {};
    // const id = StringUtils.substringBefore(targetId, "_");
    // const index = StringUtils.substringAfter(targetId, "_");
    // let items = [{}];
    // if (pId === "buttons") {
    //   items = [...this.state.buttons];
    // } else if (
    //   !ObjectUtils.isEmpty(additionalOptions) &&
    //   !ObjectUtils.isEmpty(additionalOptions.toolbarOptions[pId])
    // ) {
    //   let list =
    //     this.state.propertyValue.listOptions.additionalOptions.toolbarOptions[
    //       pId
    //     ];
    //   if (pId === "customButton") {
    //     const customOffButton =
    //       this.state.propertyValue.listOptions.additionalOptions.toolbarOptions[
    //         "customOffButton"
    //       ];
    //     if (customOffButton) {
    //       list = [
    //         ...this.state.propertyValue.gridOptions.additionalOptions
    //           .toolbarOptions[pId],
    //         ...customOffButton,
    //       ].sort((a, b) => {
    //         if (a.index && b.index) {
    //           return a.index - b.index;
    //         } else if (a.index) {
    //           return 1;
    //         } else if (b.index) {
    //           return -1;
    //         } else {
    //           return 0;
    //         }
    //       });
    //     }
    //   }
    //   items = list.slice();
    // }
    // let item = items[index];
    // const options = {
    //   keyDownEvent: false,
    //   effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
    //   style: {
    //     content: {
    //       width: "50%",
    //       height: "calc(auto + 300px)", //"680px",
    //     },
    //   },
    // };
    // Popup.open(
    //   <GridToolbarBtnPopup
    //     title="Toolbar Button Setting"
    //     type={pId}
    //     data={item}
    //     componentInfo={this.props.componentInfo}
    //     dataModelId={this.getDataModel()}
    //     entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
    //     workspace={this.context.workspace}
    //     navigate={navigate}
    //     edit={this.props.fn.updateService}
    //     codeList={this.context.code}
    //     callbackFnc={(backItems) => {
    //       const parentId = "toolbarOptions_" + pId;
    //       //input box에 값 지정
    //       if (pId === "customButton") {
    //         let parent = document.getElementById(parentId);
    //         let el = parent.querySelector("#id_" + index);
    //         el.value = backItems["id"];
    //         this.doInputChangeEvent(el, "input");
    //         el = parent.querySelector("#name_" + index);
    //         el.value = backItems["name"];
    //         this.doInputChangeEvent(el, "input");
    //       }
    //       /**
    //        * 그리드 데이터 적용시 공통된 함수적용
    //        */
    //       const applyResult = (data) => {
    //         items[index] = data;
    //         if (pId === "buttons") {
    //           let val = {};
    //           this.setState({ ...this.state, buttons: items });
    //           items
    //             .filter((item) => item.useYn)
    //             .map((item, index) => {
    //               val = { ...val, [item.id]: item };
    //             });
    //           this.onChangeAdditionalOptions({
    //             target: { id: parentId, value: val },
    //           });
    //         } else if (pId === "customButton") {
    //           this.onChangeIdValue(parentId, items, "additionalOptions");
    //         }
    //       };
    //       /**
    //        * Event Workspace 제거 로직
    //        */
    //       if (item.eventWorkspace) {
    //         let eventNames = [];
    //         for (const key of Object.keys(backItems)) {
    //           if (item.eventWorkspace[key] && item[key] !== backItems[key]) {
    //             eventNames.push(key);
    //           }
    //           //빈값으로 들어온 backItem 삭제
    //           if (StringUtils.isEmpty(backItems[key])) {
    //             delete item[key];
    //           } else {
    //             item = { ...item, [key]: backItems[key] };
    //           }
    //         }
    //         if (!ArrayUtils.isEmpty(eventNames)) {
    //           this.showEventChangeConfirmMessage(() => {
    //             //이벤트 워크스페이스 제거
    //             const newItem = produce(item, (draft) => {
    //               for (const eName of eventNames) {
    //                 delete draft.eventWorkspace[eName];
    //               }
    //             });
    //             applyResult(newItem);
    //           });
    //         } else {
    //           applyResult(item);
    //         }
    //       } else {
    //         //빈값으로 들어온 backItem 삭제
    //         Object.keys(backItems).map((key, index) => {
    //           if (StringUtils.isEmpty(backItems[key])) {
    //             delete item[key];
    //           } else {
    //             // if (pId !== "customButton")
    //             item = { ...item, [key]: backItems[key] };
    //           }
    //         });
    //         applyResult(item);
    //       }
    //     }}
    //     {...this.setEventBuilderProps("toolbar", item, pId)}
    //   />,
    //   options
    // );
  };

  /**
   * Event 상세 Popup 연결
   * @param {*} e
   */
  openPopupEvent = (e) => {
    const targetId = e.currentTarget.formTarget || e.currentTarget.id;
    const id = StringUtils.substringBefore(targetId, "_");
    const index = StringUtils.substringAfter(targetId, "_");

    let templateDetail = this.state.propertyValue.templateDetail;
    let items = !ObjectUtils.isEmpty(templateDetail.event)
      ? templateDetail.event.slice()
      : [{}];
    let item = items[index];
    if (StringUtils.isEmpty(item.eventType)) {
      return Message.alert(
        "Event is a required field.",
        Enums.MessageType.ERROR
      );
    }

    /**
     * 팝업 데이터 콜백 함수
     * PopupHandleConfigPopup , MesFunTabPopup
     * 전처리 후처리가 탭으로 나뉘어진 팝업에 사용
     * 이벤트 워크스페이스를 삭제하는 로직을 공유함
     * @param {*} backItems - 콜백 값(파라미터)
     * @param {*} target - 대상 컴포넌트
     * @returns
     */
    const popupCallback = (backItems, target) => {
      return new Promise((resolve, reject) => {
        let data = { ...target, ...backItems };
        JsonUtils.cleanup(data); //"" 공백값 제거
        if (
          !ObjectUtils.isEmpty(backItems.eventWorkspace) &&
          ((target["beforeSubmit"] &&
            target["beforeSubmit"] !== backItems.beforeSubmit) ||
            (target["afterSubmit"] &&
              target["afterSubmit"] !== backItems.afterSubmit))
        ) {
          this.showEventChangeConfirmMessage(
            () => {
              data = produce(data, (draft) => {
                if (backItems.beforeSubmit)
                  delete draft.eventWorkspace.beforeSubmit;
                if (backItems.afterSubmit)
                  delete draft.eventWorkspace.afterSubmit;
              });
              resolve(data);
            },
            () => resolve()
          );
        } else {
          resolve(data);
        }
      });
    };

    if (
      item.eventType === "LIST_ROW_DBCLICK_P" ||
      item.eventType === "LIST_ROW_CLICK_P"
    ) {
      const sizeList = this.context.code.getCodeList("Z0021");
      const positionList = this.context.code.getCodeList("Z0013");

      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <PopupHandleConfigPopup
          workspace={this.context.workspace.Info}
          title="Open Popup Setting"
          id={id}
          fieldType="json"
          item={item}
          data={{
            size: sizeList,
            position: positionList,
          }}
          entityId={this.state.propertyValue.templateDetail.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("event", items);
              }
            });
          }}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    } else if (item.eventType === "LIST_ROW_CLICK_Q") {
      const options = {
        keyDownEvent: false,
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      let eventInfo = this.props.componentInfo.componentEvent.filter(
        (event, index) => event.eventHandleCd === "LIST_ROW_CLICK_Q"
      );

      Popup.open(
        <MesFunTabPopup
          title="ListLayout Row Click Setting"
          fieldType="json"
          item={item}
          eventInfo={eventInfo[0]}
          entityId={this.state.propertyValue.templateDetail.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("event", items);
              }
            });
          }}
          codeList={this.context.code}
          isShowOperator={true}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    } else {
      const options = {
        keyDownEvent: false,
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
            height: "calc(auto + 100px)",
          },
        },
      };

      Popup.open(
        <GridToolbarBtnPopup
          title="Event Detail Setting"
          type="event"
          data={item}
          componentInfo={this.props.componentInfo}
          componentDtlId={this.props.componentInfo.componentDtlId}
          entityId={this.state.propertyValue.gridOptions.dataModelEntityId}
          codeList={this.context.code}
          callbackFnc={(backItems) => {
            //빈값으로 들어온 backItem 삭제
            Object.keys(backItems).map((key, index) => {
              if (StringUtils.isEmpty(backItems[key])) {
                delete backItems[key];
              }
            });
            //eventWorkspace가 있는 경우 & 함수가 임의 변경된 경우 EventWorspace 삭제
            if (
              !ObjectUtils.isEmpty(item.eventWorkspace) &&
              ((item["beforeSubmit"] &&
                item["beforeSubmit"] !== backItems.beforeSubmit) ||
                (item["eventFnc"] && item["eventFnc"] !== backItems.eventFnc) ||
                (item["afterSubmit"] &&
                  item["afterSubmit"] !== backItems.afterSubmit))
            ) {
              this.showEventChangeConfirmMessage(() => {
                const changeEventNames = [];
                if (item["beforeSubmit"] !== backItems.beforeSubmit)
                  changeEventNames.push("beforeSubmit");
                if (item["eventFnc"] !== backItems.eventFnc)
                  changeEventNames.push("eventFnc");
                if (item["afterSubmit"] !== backItems.afterSubmit)
                  changeEventNames.push("afterSubmit");

                if (!ArrayUtils.isEmpty(changeEventNames)) {
                  backItems.eventWorkspace = produce(
                    backItems.eventWorkspace,
                    (draft) => {
                      changeEventNames.map((key) => delete draft[key]);
                    }
                  );
                }
                items[index] = { ...backItems };
                this.onChangeIdValue("event", items);
              });
            } else {
              items[index] = { ...backItems };
              this.onChangeIdValue("event", items);
            }
          }}
          {...this.setEventBuilderProps("event", item)}
        />,
        options
      );
    }
  };

  /**
   * Icon Popup Open
   * @param {*} e
   */
  openPopupIcon = (icon, index) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };

    Popup.open(
      <IconPopup
        title="Search Icon"
        defaultValue={icon}
        callbackFnc={(icon) => {
          this.onChangeLevelRowIcons(index, "icon", icon);
        }}
      />,
      popOptions
    );
  };

  /**
   * Header 번호 매기기 포맷 선택 popup 창 오픈
   */
  openNumberingFormatPopup = () => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "800px",
          maxHeight: "700px",
        },
      },
    };
    Popup.open(
      <GridNumberingFormatPopup
        title="Grid Progress Bar Cell Template Guide"
        defaultValue={
          this.state.propertyValue.templateDetail.rowNumberingFormat
        }
        callbackFnc={(value) => {
          this.onChangeRowNumberingFormat(value);
        }}
      />,
      options
    );
  };

  handleClick = (event) => {
    this.setState({
      popoverShow: !this.state.popoverShow,
      popoverTarget: event.target,
    });
  };

  handleClickParam = (event) => {
    this.setState({
      popoverShowParam: !this.state.popoverShowParam,
      popoverTargetParam: event.target,
    });
  };

  handleClickScrollY = (event) => {
    this.setState({
      popoverShowScrollY: !this.state.popoverShowScrollY,
      popoverTargetScrollY: event.target,
    });
  };

  onClickAddGroupInfo = (e, index) => {
    const newParams = produce(this.state.groupInfo, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    this.setState({ groupInfo: newParams });
  };

  onClickDeleteGroupInfo = (e, index) => {
    const newParams = produce(this.state.groupInfo, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    this.setState({ groupInfo: newParams });

    let newGroupInfo = [];
    newParams.map((data, i) => {
      newGroupInfo.push(data.value);
    });
    this.onChangeTemplateDetail({
      target: {
        id: "groupInfo",
        value: newGroupInfo,
      },
    });
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const templateDetail = this.state.propertyValue.templateDetail || {};
    const additionalOptions = templateDetail.additionalOptions || {};

    //Event 설정 - Target Combo Item List
    let newTargets = [{ id: "none", text: "None Data" }];
    newTargets = newTargets.concat(
      this.getEventTargetNode(
        [Enums.ComponentType.FORM, Enums.ComponentType.GRID],
        false,
        [Enums.FormType.SEARCH]
      )
    );

    // newTargets.unshift({ id: "", text: "선택" }); //첫째 항목으로 null값 추가
    if (JSON.stringify(this.state.targets) !== JSON.stringify(newTargets)) {
      this.setState({ targets: newTargets });
    }

    //기타 설정 - 그룹 기준
    let groupInfo = !ObjectUtils.isEmpty(templateDetail.groupInfo)
      ? templateDetail.groupInfo
      : null;

    let newGroupInfo = [];
    const stateGorupInfo = JSON.stringify(this.state.groupInfo);
    if (!ArrayUtils.isEmpty(groupInfo)) {
      groupInfo.map((item, index) => {
        newGroupInfo.push({ value: item });
      });

      if (
        !ObjectUtils.isEmpty(groupInfo) &&
        stateGorupInfo !== JSON.stringify(newGroupInfo)
      ) {
        let flag = false;
        if (
          !ObjectUtils.isEmpty(this.state.groupInfo) &&
          stateGorupInfo !== JSON.stringify([{}])
        ) {
          this.state.groupInfo.map((data, i) => {
            if (!StringUtils.isEmpty(data.value)) {
              flag = data.value !== newGroupInfo[i].value;
            }
          });
        } else {
          flag = true;
        }

        //실제 데이터 설정
        if (flag) {
          this.setState({ groupInfo: newGroupInfo });
        }
      }
    }
    return (
      <ListStyled>
        <React.Fragment>
          {/* Title */}
          {this.renderComponentTitle("List")}
          <Accordion defaultActiveKey={[0, 1, 3]} alwaysOpen>
            <Accordion.Item eventKey={0}>
              <Accordion.Header>Basic Info</Accordion.Header>
              <Accordion.Body>
                <PropertyLable $requried="true">ID</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="id"
                    defaultValue={StringUtils.defaultString(templateDetail.id)}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeTemplateDetail}
                  />
                </PropertyValue>
                <PropertyLable $requried="true">Description</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="description"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.description
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeTemplateDetail}
                  />
                </PropertyValue>
                <PropertyLable $requried="true">Data Entity</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="search"
                    id="dataModelEntityId"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.dataModelEntityId
                    )}
                    onChange={this.onChangeTemplateDetail}
                    url="/datamodel/getDataModelEntityList"
                    params={{
                      dataModelId: this.getDataModel(),
                    }}
                    options={{
                      // isChoose: false,
                      matchId: "id",
                      matchNm: "text",
                      beforeChkFn: () => {
                        if (StringUtils.isEmpty(this.getDataModel())) {
                          // Message.alert(
                          //   "Page에 Data Model을 먼저 등록해주세요.",
                          //   Enums.MessageType.ERROR
                          // );
                          return false;
                        }
                        return true;
                      },
                    }}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey={1}>
              <Accordion.Header>Toolbar Setting</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Title</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="title"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.title
                    )}
                    className="form-control form-control-sm"
                    onBlur={(e) => {
                      let changeData = {
                        target: {
                          id: "toolbarOptions_showTitle",
                          value: false,
                        },
                        property: {
                          id: e.target.id,
                          value: e.target.value,
                        },
                      };

                      if (!StringUtils.isEmpty(e.target.value)) {
                        changeData.target.value = true;
                      }

                      this.onChangeAdditionalOptions(changeData);
                    }}
                  />
                </PropertyValue>
                <PropertyLable>Help</PropertyLable>
                <PropertyValue>
                  <input
                    type="text"
                    id="helpText"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.helpText
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChangeTemplateDetail}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* 일반 설정 */}
            <Accordion.Item eventKey={2}>
              <Accordion.Header>Default Setting</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Data Initial Load</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="dataInitialLoad"
                    checked={StringUtils.defaultString(
                      templateDetail.dataInitialLoad
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("dataInitialLoad", value)
                    }
                  />
                </PropertyValue>
                <PropertyLable>Page Count</PropertyLable>
                <PropertyValue>
                  <InputGroup size="sm">
                    <BootstrapSwitchButton
                      id="pageCount"
                      checked={templateDetail.pageCount !== 0}
                      size="sm"
                      width={80}
                      onstyle="primary"
                      offstyle="dark"
                      onlabel="TRUE"
                      offlabel="FALSE"
                      onChange={(checked) => {
                        if (!checked) {
                          this.onChangePropertyValue("pageCount", 0);
                        } else {
                          this.onChangePropertyValue("pageCount", 20);
                        }
                      }}
                    />
                    {this.state.propertyValue.pageCount !== 0 ? (
                      <Fragment>
                        <InputGroup.Text style={{ marginLeft: "10px" }}>
                          Page Count
                        </InputGroup.Text>
                        <input
                          style={{ marginRight: "10px" }}
                          type="number"
                          id="pageCount"
                          defaultValue={templateDetail.pageCount}
                          onBlur={this.onChange}
                          className="form-control form-control-sm"
                        />
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </PropertyValue>
                <PropertyLable>No Data Message</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="showEmptyMessage"
                    checked={StringUtils.defaultString(
                      this.state.propertyValue.showEmptyMessage
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("showEmptyMessage", value)
                    }
                  />
                </PropertyValue>
                <PropertyLable>Progress</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="progress"
                    checked={StringUtils.defaultString(
                      this.state.propertyValue.progress
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("progress", value)}
                  />
                </PropertyValue>

                <PropertyLable>Show Header</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0007"
                    id="showHeader"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.showHeader
                    )}
                    onChange={(e) => {
                      this.onChangeTemplateDetail(e);
                    }}
                  />
                </PropertyValue>

                <PropertyLable>Header Line break</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="lineBreak"
                    checked={StringUtils.defaultString(
                      templateDetail.lineBreak
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("lineBreak", value)}
                  />
                </PropertyValue>

                <PropertyLable>Context Menu</PropertyLable>
                <PropertyValue className="align-self-center">
                  <InputGroup>
                    <BootstrapSwitchButton
                      id="useCtxMenu"
                      checked={StringUtils.defaultString(
                        templateDetail.useCtxMenu,
                        true
                      )}
                      size="sm"
                      width={60}
                      // height={40}
                      onstyle="primary"
                      offstyle="dark"
                      onlabel="Yes"
                      offlabel="No"
                      onChange={(value) => this.btnChange("useCtxMenu", value)}
                    />
                    {!StringUtils.isEmpty(templateDetail.useCtxMenu) ? (
                      templateDetail.useCtxMenu === false ? (
                        <FormLabel className="ms-2 text-danger">
                          List Setting/DrillDown Unable
                        </FormLabel>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </InputGroup>
                </PropertyValue>

                {/* <PropertyLable>Prograss Bar</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="processing"
                    checked={StringUtils.defaultString(
                      templateDetail.processing
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("processing", value)}
                  />
                </PropertyValue> */}
                <PropertyLable>Height</PropertyLable>
                <PropertyValue>
                  <InputGroup>
                    <input
                      type="text"
                      id="scrollY"
                      defaultValue={StringUtils.defaultString(
                        templateDetail.scrollY
                      )}
                      onBlur={this.onChangeTemplateDetail}
                      className="form-control form-control-sm"
                    />
                    <div ref={this.popoverRefScrollY}>
                      <Button
                        variant="outline-secondary"
                        onClick={this.handleClickScrollY}
                        size="sm"
                        className={"light-font-color"}
                      >
                        Help
                      </Button>

                      <Overlay
                        show={this.state.popoverShowScrollY}
                        target={this.state.popoverTargetScrollY}
                        placement="bottom"
                        container={this.popoverRefScrollY}
                        containerPadding={20}
                      >
                        <Popover id="popover-contained">
                          <Popover.Header as="h3">
                            Advanced example
                          </Popover.Header>
                          <Popover.Body>
                            <ul>
                              <li>
                                <strong>▣ e.g.</strong>
                                <br></br>
                                ㆍauto : Auto Height Setting
                                <br />
                                ㆍFixed height like 100px, 200
                              </li>
                            </ul>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </div>
                  </InputGroup>
                </PropertyValue>
                <PropertyLable>Auto Height</PropertyLable>
                <PropertyValue>
                  <BootstrapSwitchButton
                    id="scrollCollapse"
                    checked={StringUtils.defaultString(
                      templateDetail.scrollCollapse
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("scrollCollapse", value)
                    }
                  />
                </PropertyValue>
                {/* <PropertyLable>Cell Selection Type</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0048"
                    id="selectionMode"
                    defaultValue={StringUtils.defaultString(
                      additionalOptions ? additionalOptions.selectionMode : "",
                      "MultiRange"
                    )}
                    onChange={this.onChangeAdditionalOptions}
                  />
                </PropertyValue> */}
              </Accordion.Body>
            </Accordion.Item>

            {this.state.propertyValue.templateDetail.dataInitialLoad ===
            true ? (
              <Accordion.Item eventKey={3} alwaysOpen>
                <Accordion.Header>
                  Template Data Mapping and Pre/Post-processing
                </Accordion.Header>
                <Accordion.Body>
                  <PropertyLable $requried="true">Data Load Type</PropertyLable>
                  <PropertyValue>
                    <USelectbox
                      id="searchTp"
                      onChange={this.onChange}
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.templateDetail.searchTp,
                        "N"
                      )}
                      items={[
                        { id: "N", text: "Do not Load" },
                        { id: "ENTITY", text: "Data Model Entity" },
                        { id: "WORKFLOW", text: "Workflow" },
                        { id: "URL", text: "Enter URL" },
                      ]}
                      options={{ matchCd: "id", matchNm: "text" }}
                    />
                  </PropertyValue>

                  {this.state.propertyValue.templateDetail.searchTp ===
                  "URL" ? (
                    <React.Fragment>
                      <PropertyLable $requried="true">URL</PropertyLable>
                      <PropertyValue>
                        <input
                          type="text"
                          id="url"
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.templateDetail.url
                          )}
                          onBlur={this.onChange}
                          className="form-control form-control-sm"
                        />
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.templateDetail.searchTp ===
                    "ENTITY" ? (
                    <React.Fragment>
                      <PropertyLable $requried="true">Entity</PropertyLable>
                      <PropertyValue $requried="true">
                        <USelectbox
                          type="search"
                          id="entityId"
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.templateDetail.entityId
                          )}
                          onChange={this.onChange}
                          url="/datamodel/getDataModelEntityList"
                          params={{
                            dataModelId: this.getDataModel(),
                          }}
                          options={{
                            matchId: "id",
                            matchNm: "text",
                          }}
                        />
                      </PropertyValue>
                    </React.Fragment>
                  ) : this.state.propertyValue.searchTp === "WORKFLOW" ? (
                    <React.Fragment>
                      <PropertyLable $requried="true">Workflow</PropertyLable>
                      <PropertyValue $requried="true">
                        <InputGroup>
                          <input
                            type="text"
                            id="serviceName"
                            value={StringUtils.defaultString(
                              this.state.propertyValue.serviceName
                            )}
                            className="form-control form-control-sm"
                            onChange={() => {}}
                            onClick={this.onWorkflowSettingClick}
                            readOnly
                            aria-readonly
                            placeholder="Workflow Name"
                          />
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className={"light-font-color"}
                            onClick={this.onWorkflowSettingClick}
                          >
                            select
                          </Button>
                          {this.state.propertyValue.serviceUid && (
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className={"light-font-color"}
                              onClick={this.onWorkflowDetailClick}
                            >
                              details
                            </Button>
                          )}
                        </InputGroup>
                      </PropertyValue>
                    </React.Fragment>
                  ) : (
                    ""
                  )}

                  <PropertyLable style={{ width: "70%" }}>
                    1.Pre-processing before Data Load
                  </PropertyLable>
                  <PropertyValue style={{ width: "30%" }}>
                    <InputGroup>
                      {this.renderEventTextArea(
                        "beforeSubmit",
                        "Pre-processing before Data Load",
                        {
                          jsonValidation: "javascript",
                          rows: 1,
                          eventCd: "widget.data",
                          eventType: "before",
                        }
                      )}
                      <UPopover
                        title="Description of Pre-processing before Data Load"
                        style={{ minWidth: "47px" }}
                        isMobileEditor={this.props.mobile.isMobileEditor}
                      >
                        <ul>
                          <li>
                            <font color="red">
                              Function executes before loading Template Data.
                            </font>
                          </li>
                          <li>
                            <strong>▣ Usage Example </strong>
                          </li>
                          <li>1. To change Parameters</li>
                          <li>2. To change API URL based on conditions. </li>
                        </ul>
                      </UPopover>
                    </InputGroup>
                  </PropertyValue>
                  <PropertyLable style={{ width: "70%" }}>
                    2.Post-processing after Load
                  </PropertyLable>
                  <PropertyValue style={{ width: "30%" }}>
                    <InputGroup>
                      {this.renderEventTextArea(
                        "afterSubmit",
                        "Post-processing after Load Data",
                        {
                          jsonValidation: "javascript",
                          rows: 1,
                          eventCd: "widget.data",
                          eventType: "after",
                        }
                      )}
                      <UPopover
                        title="Description of Post-processing after Load Data"
                        style={{ minWidth: "47px" }}
                        isMobileEditor={this.props.mobile.isMobileEditor}
                      >
                        <ul>
                          <li>
                            <font color="red">
                              Function that executes after loading Template
                              data.
                            </font>
                          </li>
                          <li>
                            <strong>▣ Usage Example</strong>
                          </li>
                          <li>
                            1. Addition and modification of loaded Template data
                          </li>
                        </ul>
                      </UPopover>
                    </InputGroup>
                  </PropertyValue>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}

            {/* Column 설정 */}
            <Accordion.Item eventKey={4}>
              <Accordion.Header>Column Setting</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Frozen Columns</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="search"
                    id="frozenColumns"
                    defaultValue={StringUtils.defaultString(
                      additionalOptions ? additionalOptions.frozenColumns : ""
                    )}
                    onChange={this.onChangeAdditionalOptions}
                    url="/datamodel/getDataBindingList"
                    params={{
                      entityId: templateDetail.dataModelEntityId,
                    }}
                    extIds={["none"]}
                    options={{
                      matchId: "id",
                      matchNm: "text",
                      beforeChkFn: () => {
                        if (
                          StringUtils.isEmpty(templateDetail.dataModelEntityId)
                        ) {
                          // Message.alert(
                          //   "Grid에 Data Entity을 선택해주세요.",
                          //   Enums.MessageType.ERROR
                          // );
                          return false;
                        }
                        return true;
                      },
                    }}
                  />
                </PropertyValue>

                <PropertyLable className="w-33p">
                  Show FrozenColumns
                </PropertyLable>
                <PropertyLable className="w-33p">Show Filter</PropertyLable>
                <PropertyLable className="w-33p">
                  Show Column Setting
                </PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="pinning"
                    checked={StringUtils.defaultString(templateDetail.pinning)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("pinning", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="isFilter"
                    checked={StringUtils.defaultString(templateDetail.isFilter)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("isFilter", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="colvis"
                    checked={StringUtils.defaultString(templateDetail.colvis)}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("colvis", value)}
                  />
                </PropertyValue>

                <PropertyLable className="w-33p">Move Column</PropertyLable>
                <PropertyLable className="w-33p">Sort Column</PropertyLable>
                <PropertyLable className="w-33p">Resize Column</PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="colReorder"
                    checked={StringUtils.defaultString(
                      templateDetail.colReorder
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("colReorder", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="orderable"
                    checked={StringUtils.defaultString(
                      templateDetail.orderable
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("orderable", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="resizable"
                    checked={StringUtils.defaultString(
                      templateDetail.resizable
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("resizable", value)}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>

            {/* Row 설정 */}
            <Accordion.Item eventKey={5}>
              <Accordion.Header>Row Setting</Accordion.Header>
              <Accordion.Body>
                <PropertyLable className="w-33p">
                  Row Height Auto Setting
                </PropertyLable>
                <PropertyLable className="w-33p">Move Row</PropertyLable>
                <PropertyLable className="w-33p">
                  Display Row Order
                </PropertyLable>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="autoRowHeights"
                    checked={StringUtils.defaultString(
                      templateDetail.autoRowHeights
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) =>
                      this.btnChange("autoRowHeights", value)
                    }
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="rowReorder"
                    checked={StringUtils.defaultString(
                      templateDetail.rowReorder
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("rowReorder", value)}
                  />
                </PropertyValue>
                <PropertyValue className="w-33p">
                  <BootstrapSwitchButton
                    id="numbering"
                    checked={StringUtils.defaultString(
                      templateDetail.numbering
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(value) => this.btnChange("numbering", value)}
                  />
                </PropertyValue>
                {templateDetail.autoRowHeights === true ? (
                  <Fragment>
                    <PropertyLable>Row Height Adjustment</PropertyLable>
                    <PropertyValue>
                      <USelectbox
                        id="autoRowHeightType"
                        type="common"
                        mstCd="Z0052"
                        onChange={this.onChangeTemplateDetail}
                        defaultValue={StringUtils.defaultString(
                          templateDetail.autoRowHeightType,
                          "SYNC"
                        )}
                      />
                    </PropertyValue>
                  </Fragment>
                ) : (
                  ""
                )}
                <PropertyLable>Header Height</PropertyLable>
                <PropertyValue>
                  <input
                    type="number"
                    id="headerHeight"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.headerHeight
                    )}
                    placeholder="default 38"
                    className="form-control form-control-sm"
                    onBlur={this.onChangeTemplateDetail}
                  />
                </PropertyValue>
                <PropertyLable>Row Height</PropertyLable>
                <PropertyValue>
                  <input
                    type="number"
                    id="rowHeight"
                    defaultValue={StringUtils.defaultString(
                      templateDetail.rowHeight
                    )}
                    placeholder="default 40"
                    className="form-control form-control-sm"
                    onBlur={this.onChangeTemplateDetail}
                  />
                </PropertyValue>
                <PropertyLable>Row Selection Type</PropertyLable>
                <PropertyValue>
                  <USelectbox
                    type="common"
                    mstCd="Z0022"
                    id="selectionType"
                    defaultValue={
                      templateDetail.displayMode === "D"
                        ? "N"
                        : StringUtils.defaultString(
                            templateDetail.selectionType
                          )
                    }
                    onChange={this.onChangeTemplateDetail}
                    options={{
                      isChoose: false,
                    }}
                  />
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </React.Fragment>
      </ListStyled>
    );
  };

  renderListToolbar = (templateDetail, toolbarOptions) => {
    const buttons = toolbarOptions.buttons || [];
    const customButton = toolbarOptions.customButton || [];

    return (
      <div className="editor-grid-toolbar">
        {!StringUtils.isEmpty(templateDetail.title) ||
        !ObjectUtils.isEmpty(buttons) ||
        !ArrayUtils.isEmpty(customButton) ? (
          <div
            className={`editor-grid-title ${
              !StringUtils.isEmpty(templateDetail.helpText) ? "whole-wrap" : ""
            }`}
          >
            <h5
              className={`${
                !StringUtils.isEmpty(templateDetail.title) ? "tit-grid" : ""
              }`}
            >
              {templateDetail.title}
              {templateDetail.showToggle &&
              templateDetail.showToggle !== "none" ? (
                <Fa.FaCaretDown />
              ) : (
                ""
              )}
            </h5>
          </div>
        ) : (
          ""
        )}

        <div className="editor-gird-toolbar-buttons">
          {!ArrayUtils.isEmpty(buttons)
            ? buttons.map((button, index) => {
                //['add', 'clone'] 형식
                let buttonInfo = this.GRID_TOOLBAR_DEFAULT_BUTTONS[button];
                if (ObjectUtils.isEmpty(buttonInfo)) return false;

                let IconTag =
                  Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                return (
                  <MButton color="primary" variant="contained" key={index}>
                    {IconTag ? <IconTag className="mr-5" /> : ""}
                    {buttonInfo.name}
                  </MButton>
                );
              })
            : Object.keys(buttons)
                .sort((a, b) => {
                  return buttons[a].sortIndex - buttons[b].sortIndex;
                })
                .map((key, index) => {
                  //{add: {}, clone: {}} 형식
                  let buttonInfo = this.GRID_TOOLBAR_DEFAULT_BUTTONS[key];
                  if (ObjectUtils.isEmpty(buttonInfo)) return false;

                  let IconTag =
                    Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                  return (
                    <MButton color="primary" variant="contained" key={index}>
                      {IconTag ? <IconTag className="mr-5" /> : ""}
                      {buttonInfo.name}
                    </MButton>
                  );
                })}

          {!ArrayUtils.isEmpty(customButton)
            ? customButton.map((buttonInfo, index) => {
                let IconTag =
                  Fa[StringUtils.convertKebabToPascal(buttonInfo.icon)];

                if (buttonInfo.useYn || buttonInfo.useYn === undefined) {
                  return (
                    <MButton color="primary" variant="contained" key={index}>
                      {IconTag ? <IconTag className="mr-5" /> : ""}
                      {buttonInfo.name}
                    </MButton>
                  );
                }
              })
            : ""}
        </div>
      </div>
    );
  };

  renderListShowHeader = (templateDetail) => {
    return (
      <div className="grid-show-header">
        <div></div>
      </div>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<List editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    const templateDetail =
      this.props.componentInfo.propertyValue.templateDetail || {};
    const toolbarOptions = templateDetail.additionalOptions
      ? templateDetail.additionalOptions.toolbarOptions || {}
      : {};

    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={this.props.style}
        className={`editor-base draggable editor-grid ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-grid"
        )} ${this.state.propertyValue.className}`}
      >
        {!StringUtils.isEmpty(templateDetail.title) ||
        !ObjectUtils.isEmpty(toolbarOptions)
          ? this.renderListToolbar(templateDetail, toolbarOptions)
          : ""}

        {!StringUtils.isEmpty(templateDetail.showHeader)
          ? this.renderListShowHeader(templateDetail)
          : ""}
        {this.props.children}
        {/* {gridOptions.info ? (
          <div className="editor-grid-footer">
            Total : 1
            <Fa.FaFileExcel className="grid-footer-icon" />
          </div>
        ) : (
          ""
        )} */}
      </UIComponentSection>
    );
  };
}

export default ListLayout;
