import { memo, useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ServiceComponentsTab from "../ui/ServiceComponentsTab";
import MobileComponentsTab from "./MobileComponentsTab";
import MobileElementComponentTab from "./MobileElementComponentTab";
import MobileTree from "./tree/MobileTree";
import { debounce } from "components/common/utils/InputUtils";

const BuilderLeftMenuPanel = memo((props) => {
  // const output = useSelector((state) => state.outputUI.output);
  const [activedTabKey, setActivedTabKey] = useState("BasicComponents");

  const treeRef = useRef();
  const componentRef = useRef();
  const resizeRef = useRef();
  const wrapperRef = useRef();
  const [componentHeight, setComponentHeight] = useState(400);

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  useEffect(() => {
    handleTabSelect("BasicComponents");
  }, []);

  const HEADER_HEIGHT = 50;
  const onResizeSidebar = (e) => {
    e.stopPropagation();
    // debounceScroll(e);
    scrollEvent(e);
  };

  const debouceSetHeight = debounce((height) => {
    setComponentHeight(height - 30);
  }, 200);

  const scrollEvent = (e) => {
    const wrapperHegith = wrapperRef.current.offsetHeight;
    const height = e.pageY;
    const minimumHeight = 40;

    let treeHeight = height - HEADER_HEIGHT;
    if (treeHeight < minimumHeight) {
      treeHeight = minimumHeight;
    }
    let componentHeight = wrapperHegith - treeHeight;
    if (componentHeight < minimumHeight) {
      componentHeight = minimumHeight;
      treeHeight = wrapperHegith - componentHeight;
    }
    treeRef.current.style = `height:${treeHeight}px`;
    componentRef.current.style = `height:${componentHeight}px`;
    debouceSetHeight(componentHeight);
  };

  // const debounceScroll = debounce(scrollEvent, 200);

  return (
    <div className={`tree-component-wrapper`} ref={wrapperRef}>
      <MobileTree treeRef={treeRef} />
      <div
        className="tree-component-resizer"
        draggable
        onDragStart={(e) => {
          e.stopPropagation();
        }}
        onDrag={onResizeSidebar}
        onDragEnd={onResizeSidebar}
        ref={resizeRef}
      >
        <div className="resizer-bar" />
      </div>
      <div
        className="control-sidebar"
        style={{ height: "431px" }}
        ref={componentRef}
      >
        <Tabs
          fill
          activeKey={activedTabKey}
          id="controlled-tab"
          onSelect={handleTabSelect}
          className=""
        >
          <Tab
            eventKey="BasicComponents"
            title={
              <span>
                <span className="tab-name">Component</span>
              </span>
            }
          >
            <MobileComponentsTab height={componentHeight} />
          </Tab>
          <Tab
            eventKey="ServiceComponents"
            title={
              <span>
                <span className="tab-name">Service Component</span>
              </span>
            }
          >
            <ServiceComponentsTab height={componentHeight} />
          </Tab>
          <Tab
            eventKey="Model Components"
            title={
              <span>
                <span className="tab-name">Model Component</span>
              </span>
            }
          >
            <MobileElementComponentTab height={componentHeight} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});

export default BuilderLeftMenuPanel;
