import * as Enums from "components/builder/BuilderEnum";
import { InputPopupStyle } from "components/common/element/UInputPopup";
import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";

export const EntityEditorPanel = styled.div`
  height: calc(100vh - ${Enums.Style.HEADER_HEIGHT}px - 4px - 30px);
`;

export const EntityTabProps = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
`;

export const PropertySelect = styled.select`
  width: 100%;
  height: 25px;
  border-radius: 5px;
  padding: 5px 8px;

  ::before {
    content: "";
  }

  :disabled {
    background-color: lightgray;
  }
`;

export const EInputPopup = ({
  id,
  value,
  onChange,
  onClick,
  className,
  style,
  onBlur,
  disabled,
}) => {
  const [pValue, setpValue] = useState("");
  useEffect(() => {
    setpValue(value);
  }, [value]);

  return (
    <InputPopupStyle className={className} style={style}>
      <div className="input-container">
        <input
          type="text"
          id={id}
          value={pValue}
          className="form-control form-control-sm"
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="btn-span">
          <button
            className="btn btn-sm"
            onClick={onClick}
            formTarget={id}
            disabled={disabled}
          >
            <BsThreeDots />
          </button>
        </span>
      </div>
    </InputPopupStyle>
  );
};
