import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import withDataModelSavePopup from "./withDataModelSavePopup";

function DataModelDeployPopup(properties) {
  const {
    renderDmPath,
    renderDmSaveConfig,
    onChangePropertValue,
    getDmSaveBody,
    renderReleaseCommentCheckbox,
    isSaving,
    setIsSaving,
    connection,

    ...props
  } = properties;
  const [commitComment, setCommitComment] = useState("");

  const onClickDeploy = () => {
    const saveBody = getDmSaveBody();
    saveBody.newDataModel = props.output.dataModelId ? "N" : "Y";
    saveBody.commitComment = commitComment;
    setIsSaving(true);
    if (props.onDeploy)
      props.onDeploy(saveBody, () => {
        setIsSaving(false);
      });
  };

  return (
    <Modal>
      <Modal.Header title="Deploy Data Model" />
      <Modal.Body>
        <UmodalTemplate>
          {renderDmPath()}
          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Connection Name</Form.Label>
              <Form.Control
                defaultValue={connection.connectionNm}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Connection HOST</Form.Label>
              <Form.Control defaultValue={connection.host} disabled={true} />
            </Form.Group>
          </Row>
          {renderDmSaveConfig()}
          <Form>
            <Form.Group className="mt-3">
              <Form.Label className="required">Data Model Comment</Form.Label>
              <input
                type="text"
                id="programComment"
                name="programComment"
                className="form-control"
                max={120}
                placeholder={"Up to 120 characters"}
                value={commitComment}
                onChange={(e) => setCommitComment(e.target.value)}
              />
            </Form.Group>
            {renderReleaseCommentCheckbox()}
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          onClick={onClickDeploy}
          doing={isSaving}
          doingText={"Deploying.."}
          variant={"success"}
        >
          Deploy
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default withDataModelSavePopup(DataModelDeployPopup);
