import * as Enums from "components/builder/BuilderEnum";
import { NumberUtils, StringUtils } from "components/common/utils/CommonUtils";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

export const MobileEditorPanel = styled.div`
  height: 100%;
`;

export const InputGropuWithLabel = (props) => {
  const { propertyValue, children, defaultLabel } = props;

  let labelStyle = {};
  let inputStyle = {};
  //label 직접 입력일 경우 css로 width추가
  if (propertyValue.isLabelWidthCustom === true) {
    labelStyle.width = propertyValue.customLabelWidth + "%";
    inputStyle.width = 100 - propertyValue.customLabelWidth + "%";
  }

  let labelWidth =
    propertyValue.layoutType === "N"
      ? 0
      : StringUtils.defaultString(
          propertyValue.labelWidth,
          Enums.Style.MOBILE_LABEL_DEFAULT
        );
  let inputWidth = NumberUtils.isNumeric(labelWidth, "1")
    ? 12 - Number(labelWidth)
    : 4;

  let labelClass = "";
  if (propertyValue.layoutType === "E") labelClass = "save-form-label";
  else if (propertyValue.layoutType === "D") labelClass = "search-form-label";
  else if (propertyValue.layoutType === "F") labelClass = "filter-form-label";
  else labelClass = "form-depend-label";

  if (propertyValue.isRequired === true) labelClass += " required";

  return (
    <Form.Group as={Row} className="form-group">
      {propertyValue.layoutType !== "N" ? (
        <Form.Label
          className={labelClass + " col-" + labelWidth}
          style={labelStyle}
        >
          {StringUtils.defaultString(propertyValue.formLabel, defaultLabel)}
        </Form.Label>
      ) : (
        ""
      )}
      <Col className={`input-col col-${inputWidth}`} style={inputStyle}>
        {children}
      </Col>
    </Form.Group>
  );
};
