import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User, { default as UserUtils } from "components/common/utils/UserUtils";
import "css/builder/programDesign/technicalSpecification.css";
import produce from "immer";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import { setDesign } from "../reducer/ProgramDesignAction";

export const ScrollDiv = styled.div`
  max-height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;
const OtherDefinitions = ({
  designMst,
  flag,
  setDesignMst,
  findMultilang,
  ...props
}) => {
  const editor = useRef([]);

  const [designMultilang, setDesignMultilang] = useState([]);
  const [editorContent, setEditorContent] = useState({});
  const [tdTestNote, setTdTestNote] = useState({});
  const [tdOthers, setTdOthers] = useState({});
  const dispatch = useDispatch();

  const OTHER = {
    TEST_NOTE: "td_test_note",
    OTHERS: "td_others",
  };

  const keyList = ["", "td_test_note", "td_others"];

  useEffect(() => {
    if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
      setDesignMultilang(designMst.designMultilang || []);
      setTdOthers(
        designMst.designMultilang.find((item) =>
          item.multilangCd.includes(OTHER.OTHERS)
        )
      );
      setTdTestNote(
        designMst.designMultilang.find((item) =>
          item.multilangCd.includes(OTHER.TEST_NOTE)
        )
      );
    } else {
      setTdTestNote({});
      setTdOthers({});

      editor.current.forEach((item) => {
        if (typeof item !== "undefined") {
          item.setContents("");
        }
      });
    }
  }, [designMst]);

  useEffect(() => {
    if (editorContent !== undefined) {
      onChangeDesignMultilang(editorContent);
    }
  }, [editorContent]);

  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };

  const newItem = (key) => {
    const data = {
      multilangCd: keyList[key],
      langCd: User.getLanguage().toLowerCase(),
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    return data;
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (contentItem) => {
    if (!ObjectUtils.isEmpty(contentItem)) {
      let tmpItem = {};
      switch (contentItem.idx) {
        case 1:
          tmpItem = { ...tdTestNote };
          break;
        case 2:
          tmpItem = { ...tdOthers };
          break;
        default:
          break;
      }
      if (ObjectUtils.isEmpty(tmpItem)) {
        tmpItem = newItem(contentItem.idx);
      }
      tmpItem.multilangText = contentItem.data;
      const tmpDesignMultiLang = [
        ...designMultilang.filter(
          (item) => item.multilangCd !== tmpItem.multilangCd
        ),
        tmpItem,
      ];

      setDesignMultilang(tmpDesignMultiLang);
      onChangeDesignMst("designMultilang", tmpDesignMultiLang);
    }
  };
  /**
   * editor blur
   * @param {*} e
   */
  const onBlurEditor = (e, idx) => {
    const body = {
      idx: idx,
      data: editor.current[idx]?.getContents(),
    };
    setEditorContent(body);
  };

  const getSunEditorInstance = (sunEditor, idx) => {
    editor.current[idx] = sunEditor;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="content-title">Other Definitions</div>
        </Col>
      </Row>
      <ScrollDiv>
        <div>
          <Row>
            <Col>
              {" "}
              <div className="detail-content-title">Test notes</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SunEditor
                id={tdTestNote?.multilangCd}
                lang={UserUtils.getLanguage().toLowerCase()}
                setContents={tdTestNote?.multilangText || ""}
                onBlur={(e) => {
                  onBlurEditor(e, 1);
                }}
                getSunEditorInstance={(sunEditor) =>
                  getSunEditorInstance(sunEditor, 1)
                }
                setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 200px; max-height : 55vh"
                setOptions={{
                  font: [
                    "Noto Sans KR",
                    "Arial",
                    "Comic Sans MS",
                    "Courier New",
                    "Impact",
                    "Georgia",
                    "tahoma",
                    "Trebuchet MS",
                    "Verdana",
                  ],
                  fontSize: [
                    8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36,
                    48, 72,
                  ],
                  buttonList: [
                    ["font", "fontSize", "formatBlock"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "fontColor",
                      "hiliteColor",
                    ],
                  ],
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <div className="detail-content-title">Others</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SunEditor
                id={tdOthers?.multilangCd}
                lang={UserUtils.getLanguage().toLowerCase()}
                setContents={tdOthers?.multilangText || ""}
                onBlur={(e) => {
                  onBlurEditor(e, 2);
                }}
                getSunEditorInstance={(sunEditor) =>
                  getSunEditorInstance(sunEditor, 2)
                }
                setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 200px; max-height : 55vh"
                setOptions={{
                  font: [
                    "Noto Sans KR",
                    "Arial",
                    "Comic Sans MS",
                    "Courier New",
                    "Impact",
                    "Georgia",
                    "tahoma",
                    "Trebuchet MS",
                    "Verdana",
                  ],
                  fontSize: [
                    8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36,
                    48, 72,
                  ],
                  buttonList: [
                    ["font", "fontSize", "formatBlock"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "fontColor",
                      "hiliteColor",
                    ],
                  ],
                }}
              />
            </Col>
          </Row>
        </div>
      </ScrollDiv>
    </React.Fragment>
  );
};

export default OtherDefinitions;
