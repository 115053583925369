import React from "react";
import withDataModelSavePopup from "./withDataModelSavePopup";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Form } from "react-bootstrap";

function DataModelSavePopup({
  renderDmPath,
  renderDmSaveConfig,
  onChangePropertValue,
  getDmSaveBody,
  renderReleaseCommentCheckbox,
  isSaving,
  setIsSaving,
  ...props
}) {
  const onClickSave = () => {
    const saveBody = getDmSaveBody();
    setIsSaving(true);
    if (props.onSave)
      props.onSave(saveBody, () => {
        setIsSaving(false);
      });
  };

  return (
    <Modal>
      <Modal.Header title="Save Data Model" />
      <Modal.Body>
        <UmodalTemplate>
          <Form>
            {renderDmPath()}
            {renderDmSaveConfig()}
            {renderReleaseCommentCheckbox()}
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          onClick={onClickSave}
          doing={isSaving}
          doingText={"Saving..."}
        >
          Save
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default withDataModelSavePopup(DataModelSavePopup);
