import Api from "components/common/Api";

class LayoutSettingService {
  static requestMapping = "/mobile";

  /**
   * 상단 Nav Tmplate 목록 호출 - Preset & 현재 app custom
   * data = workspace
   * @param {*} data
   * @param {*} successCallback
   * @param {*} errCallback
   */
  static getTmplList = (data, successCallback, errCallback) => {
    Api.post(
      this.requestMapping + "/getLayoutPreset",
      data,
      successCallback,
      errCallback
    );
  };

  /**
   * App Layout 저장
   * DB Table 기준으로 데이터 주입
   * @param {*} data
   * @param {*} successCallback
   * @param {*} errCallback
   */
  static saveAppLayout = (data, successCallback, errCallback) => {
    Api.post(
      this.requestMapping + "/saveMAppLayout",
      data,
      successCallback,
      errCallback
    );
  };

  /**
   * 모바일 레이아웃 디플로이
   * @param {*} data
   * @param {*} successCallback
   * @param {*} errCallback
   */
  static deploy = (data, successCallback, errCallback) => {
    Api.post(this.requestMapping + "", data, successCallback, errCallback);
  };
}
export default LayoutSettingService;
