import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaThList, FaCoins } from "react-icons/fa";

import EntityPropertiesTab from "components/builder/entity/entityPropertiesTab/EntityPropertiesTab";

import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import "css/builder/main.css";
import "css/builder/entity.css";
import "css/builder/settings.css";

// import EntityTablesTab from "components/builder/entity/tableTab/EntityTablesTab";
import EntityTablesTab from "components/builder/entity/flow/EntityTablesTab";
import { ReactFlowProvider } from "reactflow";
import EntityFlowEditor from "components/builder/entity/flow/EntityFlowEditor";
import BuilderControlSideBar from "components/builder/BuilderControlSideBar";

const EntityBuilderMain = () => {
  const activedComponent = {};
  const output = useSelector((state) => state.outputENT.output);
  const [activedTabKey, setActivedTabKey] = useState("Tables");
  useEffect(() => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      handleTabSelect("Properties");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activedComponent]);

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  return (
    <React.Fragment>
      <ReactFlowProvider>
        <EntityFlowEditor handleTabSelect={handleTabSelect} />
        <BuilderControlSideBar className="entity">
          <Tabs
            fill
            activeKey={activedTabKey}
            id="controlled-tab"
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="Properties"
              title={
                <span>
                  <FaThList size="14" />
                  <span className="tab-name">Properties</span>
                </span>
              }
            >
              <EntityPropertiesTab />
            </Tab>
            <Tab
              eventKey="Tables"
              title={
                <span>
                  <FaCoins size="14" />
                  <span className="tab-name">
                    {StringUtils.equalsIgnoreCase(output.dataModelType, "d")
                      ? "Tables"
                      : "Entity"}
                  </span>
                </span>
              }
            >
              <EntityTablesTab />
            </Tab>
          </Tabs>
        </BuilderControlSideBar>
      </ReactFlowProvider>
    </React.Fragment>
  );
};
export default EntityBuilderMain;
