/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import * as Enums from "components/builder/BuilderEnum";

import UModalTemplate from "components/common/modal/UModalTemplate";
import PopupHandleComponent from "./PopupHandleComponent";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * PopupOpenConfigPopup : Popup 전처리
 *
 * 2022.04.28: init: donghoon
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 *
 * @param {*} props
 * @returns value
 */
const PopupPreHandleConfigPopup = (props) => {
  const {
    title,
    callbackFnc,
    item,
    entityId,
    data,
    workspace,
    onClickEventBuilder,
  } = props;
  const [popItems, setPopItems] = useState({});

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    const _closePopup = () => {
      callbackFnc.call(this, popItems);
      Popup.close();
    };

    _closePopup();
  };

  const setRetItems = (id, value) => {
    let items = {};
    console.log(id + " : " + value);
    items = { ...popItems, [id]: value };
    setPopItems(items);
  };

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UModalTemplate>
            <PopupHandleComponent
              callbackFnc={callbackFnc}
              item={item}
              entityId={entityId}
              data={{
                size: data.size,
                position: data.position,
                builderType: data.builderType
                  ? data.builderType
                  : Enums.BuilderType.UI,
              }}
              setRetItems={setRetItems}
              workspace={workspace}
              onClickEventBuilder=""
              getEventInfo={props.getEventInfo}
            />
          </UModalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default PopupPreHandleConfigPopup;
