import React, { CSSProperties, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Enums from "components/builder/BuilderEnum";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { AppContext } from "components/common/AppContextProvider";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";

import {
  RiDeleteBin6Line,
  RiDragMoveFill,
  RiFileCopyLine,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
} from "react-icons/ri";

import { setBuilderTreeNodeIds } from "components/builder/ui/reducers/UIBuilderAction";
import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import { generateNodeTreeIds } from "../tree/treeUtils";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import produce from "immer";

/**
 *
 * @param {object} props
 * @param {object} props.item
 * @param {CSSProperties} props.style
 * @param {String} props.className
 * @param {String} props.id
 * @param {String} props.title
 * @param {Boolean} props.focusDelegation
 * @param {Function} props.onDoubleClick
 * @param {Boolean} props.noneToolbarTitle
 * @param {Boolean} props.noneCollapse
 * @returns
 */
const UIComponentSection = (props) => {
  const { item, style, className, children, id } = props;

  const output = useSelector((state) => state.outputUI.output);
  const mobileInfo = useSelector((state) => state.mobile);

  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const treeNodeIds = useSelector(
    (state) => state.activedUIComponent.treeNodeIds
  );

  const { isMobileEditor } = useSelector((state) => state.mobile);
  const context = useContext(AppContext);
  const components = useContext(AppContext).component.getComponentList("B");
  const pageCompnent = useContext(AppContext).component.getPageComponent();
  const componentRef = useRef();
  const [toolbarStyle, setToolbarStyle] = useState({
    right: "-2px",
    minWidth: "153px",
  });

  const dispatch = useDispatch();

  let collapse =
    !ObjectUtils.isEmpty(item.editorAttr) && item.editorAttr.collapse === true
      ? true
      : false;

  //title
  let title = StringUtils.defaultString(
    props.title,
    StringUtils.toUpperCase(item.type)
  );

  let itemCompId = StringUtils.defaultString(activedComponent.compId);

  //component wapper div 의 style class
  let componentClass = className;

  if (itemCompId === item.compId) {
    componentClass += " component-select";
  }
  if (props.focusDelegation === true) componentClass += " focus-delegation";

  if (collapse === true) componentClass += " layout-collapse";
  else componentClass += " layout-expand";

  /**
   * Component 클릭시 해당 Component를 activation 시키다.
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    //focus (Click event)를 component로 위임했을 경우 click event를 실행하지 않는다.
    if (props.focusDelegation === true) {
      Event.stopEvent(e);
      return;
    }

    let targetId = item.compId;
    const { type } = item;
    if (
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID_CELL) ||
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID_HEADER) ||
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.LIST_CELL) ||
      StringUtils.equalsIgnoreCase(type, Enums.ComponentType.LIST_HEADER)
    ) {
      targetId = item.compId.split("-")[1];
    }
    Event.clickComponent(e, item, components, activedComponent, dispatch);

    let nodeIds;
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      nodeIds = generateNodeTreeIds(output.page.filter.page, targetId);
    } else {
      nodeIds = generateNodeTreeIds(output.page, targetId);
    }
    dispatch(setBuilderTreeNodeIds(nodeIds));
  };

  /**
   * 컴포넌트 더블클릭 이벤트
   * @param {*} e
   */
  const onDoubleClick = (e) => {
    Event.stopEvent(e);
    if (props.onDoubleClick) {
      props.onDoubleClick();
    }
  };

  /**
   * event 발생지가 Mobile editor 이고 filter popup 화면인지 여부
   * mobile page는 fileter > page > pageType : Enums.ComponentType.FILTER 로 고정됨
   * @returns
   */
  const isMobileFilter = () => {
    return (
      mobileInfo &&
      mobileInfo.isMobileEditor &&
      !StringUtils.isEmpty(mobileInfo.filterProgram) &&
      output.page.filter.page.pageType === Enums.ComponentType.FILTER
    );
  };

  /**
   * 선택된 Component 를 복사한후 이전 Node에 삽입
   * @param {Event} e
   */
  const insertBeforeNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.insertNode(e, "B", item, output.page.filter, components, dispatch);
    } else {
      Event.insertNode(e, "B", item, output, components, dispatch);
    }
  };

  /**
   * 선택된 Component 를 복사한후 이후 Node에 삽입
   * @param {Event} e
   */
  const insertAfterNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.insertNode(e, "A", item, output.page.filter, components, dispatch);
    } else {
      Event.insertNode(e, "A", item, output, components, dispatch);
    }
  };

  /**
   * 선택된  Node(Component)를 삭제한다.
   * @param {Event} e
   */
  const removeNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.removeNode(
        e,
        item,
        output.page.filter,
        components,
        pageCompnent,
        dispatch
      );
    } else {
      Event.removeNode(e, item, output, components, pageCompnent, dispatch);
    }
  };

  /**
   * 복사 event
   * @param {Evnet} e
   */
  const copyNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.copyNode(e, activedComponent, output.page.filter, dispatch);
    } else {
      Event.copyNode(e, activedComponent, output, dispatch);
    }
  };

  /**
   * 잘라내기
   * @param {*} e
   */
  const cutNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.cutNode(
        e,
        activedComponent,
        output.page.filter,
        treeNodeIds,
        context,
        dispatch
      );
    } else {
      Event.cutNode(
        e,
        activedComponent,
        output,
        treeNodeIds,
        context,
        dispatch
      );
    }
  };

  /**
   * Component collapse, expand event
   * @param {Event} e
   */
  const callaseNode = (e) => {
    //event 발생지가 Mobile editor 이고 filter popup 화면인 경우
    if (isMobileFilter()) {
      Event.callaseNode(e, item, output.page.filter, collapse, dispatch);
    } else {
      Event.callaseNode(e, item, output, collapse, dispatch);
    }
  };

  useEffect(() => {
    if (componentRef.current) {
      const position = componentRef.current.getBoundingClientRect();
      const distanceFromRight = window.innerWidth - position.right;
      // 뷰포트 오른쪽에서 160px ( 실제 153px ) 미만으로 떨어진
      // 케이스는 right 포지션으로 0으로 해서 뷰포트 내에서만 보이도록 함
      if (distanceFromRight < 160) {
        setToolbarStyle(
          produce(toolbarStyle, (draft) => {
            draft.right = 0;
          })
        );
      }
    }
  }, []);

  return (
    <div
      data-id={item.compId}
      style={{ ...style }}
      className={componentClass}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      id={id}
      ref={componentRef}
    >
      {props.noneToolbarTitle === true || isMobileEditor === true ? (
        ""
      ) : (
        <div
          className={`comp-title ${
            props.focusDelegation === true ? "focus-delegation" : ""
          }`}
        >
          {props.noneCollapse !== true &&
          item.rootLocation !== Enums.ComponentType.FOOTER ? (
            <span className="collapse-button" onClick={callaseNode}>
              {collapse ? (
                <BsArrowsExpand size={16} />
              ) : (
                <BsArrowsCollapse size={16} />
              )}
            </span>
          ) : (
            ""
          )}
          <span>
            {StringUtils.defaultString(props.title, title)}
            {/* {StringUtils.isEmpty(item.id) ? "" : "-" + item.id} */}
          </span>
        </div>
      )}
      {props.noneToolbar !== true ? (
        <div className="comp-toolbar" style={{ ...toolbarStyle }}>
          {isMobileEditor !== true ? (
            <span className="comp-move-icon">
              <RiDragMoveFill size={16} />
            </span>
          ) : (
            ""
          )}
          <span onClick={copyNode}>
            <RiFileCopyLine size={16} />
          </span>
          {item.type === Enums.ComponentType.COLUMN ||
          item.type === Enums.ComponentType.GRID_CELL ||
          item.type === Enums.ComponentType.GRID_HEADER ||
          item.type === Enums.ComponentType.LIST_CELL ||
          item.type === Enums.ComponentType.LIST_HEADER ? (
            <React.Fragment>
              <span onClick={insertBeforeNode}>
                <RiInsertColumnLeft size={16} />
              </span>
              <span onClick={insertAfterNode}>
                <RiInsertColumnRight size={16} />
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span onClick={insertBeforeNode}>
                <RiInsertRowTop size={16} />
              </span>
              <span onClick={insertAfterNode}>
                <RiInsertRowBottom size={16} onClick={insertAfterNode} />
              </span>
            </React.Fragment>
          )}
          <span onClick={removeNode}>
            <RiDeleteBin6Line size={16} />
          </span>
        </div>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};
export default React.memo(UIComponentSection);
