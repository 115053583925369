import React from "react";

/**
 * 미리보기 사용 보류
 * @param {*} props
 * @returns
 */
const MobileBuilderPreview = (props) => {
  return <></>;
  // const statOutput = useSelector((state) => state.outputUI.output);
  // /**
  //  * Page Penal Class
  //  * @returns
  //  */
  // const getPagePanelClass = () => {
  //   let pageClass = "page-container";

  //   if (
  //     !ObjectUtils.isEmpty(statOutput.page) &&
  //     !ObjectUtils.isEmpty(statOutput.page.propertyValue) &&
  //     statOutput.page.propertyValue.programType ===
  //       Enums.ProgramType.POPUP_PROGRAM
  //   ) {
  //     const size = ObjectUtils.isEmpty(
  //       statOutput.page.propertyValue.popupOptions
  //     )
  //       ? "xl"
  //       : StringUtils.defaultString(
  //           statOutput.page.propertyValue.popupOptions.size,
  //           "xl"
  //         );
  //     pageClass += " popup-container" + (size !== "fl" ? " modal-" + size : "");
  //   }
  //   return pageClass;
  // };

  // /**
  //  * filter Page Penal Class
  //  * @returns
  //  */
  // const getPageFilterPanelClass = () => {
  //   let pageClass = "mobile-filter-panel-area page-container";

  //   if (!ObjectUtils.isEmpty(statOutput.page.propertyValue)) {
  //     pageClass +=
  //       " page-type-" +
  //       StringUtils.defaultString(
  //         statOutput.page.propertyValue.programType,
  //         Enums.ProgramType.MENU_PROGRAM
  //       );
  //     // }
  //   }
  //   return pageClass;
  // };

  // //Main page
  // const pageChildCnt =
  //   ObjectUtils.isEmpty(statOutput["page"]) ||
  //   ArrayUtils.isEmpty(statOutput["page"]["child"])
  //     ? 0
  //     : statOutput["page"]["child"].length;

  // //footer
  // const filterChildCnt =
  //   ObjectUtils.isEmpty(statOutput["page"]) ||
  //   ObjectUtils.isEmpty(statOutput["page"]["filter"]) ||
  //   ArrayUtils.isEmpty(statOutput["page"]["filter"]["child"])
  //     ? 0
  //     : statOutput["page"]["filter"]["child"].length;

  // //footer
  // const footerChildCnt =
  //   ObjectUtils.isEmpty(statOutput["page"]) ||
  //   ObjectUtils.isEmpty(statOutput["page"]["footer"]) ||
  //   ArrayUtils.isEmpty(statOutput["page"]["footer"]["child"])
  //     ? 0
  //     : statOutput["page"]["footer"]["child"].length;

  // const pagePropertyValue = !ObjectUtils.isEmpty(statOutput["page"])
  //   ? statOutput["page"].propertyValue || {}
  //   : {};
  // const programType = StringUtils.defaultString(
  //   pagePropertyValue.programType,
  //   "M"
  // );

  // return (
  //   <React.Fragment>
  //     <DndProvider backend={HTML5Backend}>
  //       {/* <div id="editPanel" className="mobile-edit-panel preview-panel"> */}
  //       <EditorPanel
  //         // className={`mobile-edit-panel-area ${getPagePanelClass()}`}
  //         className={getPagePanelClass()}
  //         style={props.style}
  //         programType={programType}
  //         pagePropertyValue={pagePropertyValue}
  //         id="editPage"
  //       >
  //         <Page event="renderEditor" componentInfo={statOutput["page"]}>
  //           <div className="page none-lineDisplay none-dropZoneDisplay none-titleDisplay none-toolbarDisplay none-watermarkDisplay">
  //             {pageChildCnt > 0
  //               ? statOutput["page"]["child"].map((row, index) => {
  //                   return (
  //                     <React.Fragment key={index}>
  //                       <UIEditorChildRender
  //                         data={row}
  //                         dndContainer={UIViewerDNDContainer}
  //                       />
  //                     </React.Fragment>
  //                   );
  //                 })
  //               : ""}
  //           </div>
  //         </Page>
  //         <div className="modal-overlay" id="modalOverlay" style={props.style}>
  //           <div className="modal-wrapper" id="modalWrapper" tabIndex="-1">
  //             <FilterPanel
  //               className={getPageFilterPanelClass()}
  //               // style={{ width: screenWidth * 0.8, height: screenHeight }}
  //               id="filterPanel"
  //               tabIndex="0"
  //             >
  //               {" "}
  //               <Page
  //                 event="renderEditor"
  //                 componentInfo={statOutput["page"]["filter"]}
  //               >
  //                 <div
  //                   id="filterHeader"
  //                   className={`mobile-filter-pannel-header`}
  //                 >
  //                   <button
  //                     className={`mobile-filter-pannel-hidden`}
  //                     onClick={(e) => {
  //                       const modalOverlay =
  //                         document.getElementById("modalOverlay");
  //                       const modalWrapper =
  //                         document.getElementById("modalWrapper");

  //                       // filterPanel.style.display = 'none';
  //                       modalOverlay.style.visibility = "hidden";
  //                       modalWrapper.style.visibility = "hidden";
  //                       // filterPanel.classList.add("Animate");
  //                     }}
  //                   >
  //                     <BiArrowBack /> Filter
  //                   </button>
  //                 </div>
  //                 <div className="page none-lineDisplay none-dropZoneDisplay none-titleDisplay none-toolbarDisplay none-watermarkDisplay">
  //                   {filterChildCnt > 0
  //                     ? statOutput["page"]["filter"]["child"].map(
  //                         (row, index) => {
  //                           return (
  //                             <React.Fragment key={index}>
  //                               <UIEditorChildRender
  //                                 data={row}
  //                                 dndContainer={UIViewerDNDContainer}
  //                               />
  //                             </React.Fragment>
  //                           );
  //                         }
  //                       )
  //                     : ""}
  //                 </div>
  //               </Page>
  //             </FilterPanel>
  //           </div>
  //         </div>
  //       </EditorPanel>
  //       {footerChildCnt > 0 ? (
  //         <UIEditorFooter
  //           pagePropertyValue={pagePropertyValue}
  //           dndContainer={UIViewerDNDContainer}
  //           child={statOutput["page"]["footer"]["child"]}
  //         />
  //       ) : (
  //         ""
  //       )}
  //       {/* </div> */}
  //     </DndProvider>
  //   </React.Fragment>
  // );
};
export default MobileBuilderPreview;
