import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/editor/theme/common/UIComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import React, { Component, useEffect, useState } from "react";
import { Accordion, Button, Form, Overlay, Popover } from "react-bootstrap";
import styled from "styled-components";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import { useDispatch, useSelector } from "react-redux";
import Popup from "components/common/Popup";
import produce from "immer";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { debounce } from "components/common/utils/InputUtils";
import Message from "components/common/Message";
import { useRef } from "react";
import { Enums } from "components/builder/BuilderEnum";
import RelationSetPopup from "page/popup/dataModel/RelationSetPopup";

class Entity extends Component {}
class EntityEditor extends Component {
  render() {
    return <div>Entity editor</div>;
  }
}

export const RelationTable = styled.div`
  width: 100%;
  height: 200px;
  overflow: auto;
  background: white;
  border-radius: 5px;
  color: black;

  &:hover {
    box-shadow: 1px 1px 5px #75b9ff;
  }
  & .relation-row {
    display: flex;
    height: 30px;
  }
  & .relation-header {
    border-bottom: 1px solid lightgray;
    & div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  & .columnNm {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    width: 30%;
  }
`;

const EntityProperties = (props) => {
  const dispatch = useDispatch();

  const component = useSelector((state) => state.activedENTComponent);
  const { dataModelType, dataModelEntities } = useSelector(
    (state) => state.outputENT.output
  );
  const { output } = useSelector((state) => state.outputENT);

  const [physEntityNm, setPhysEntityNm] = useState("");
  const [logEntityNm, setLogEntityNm] = useState("");
  const [tableNm, setTableNm] = useState("");
  const [relation, setRelation] = useState([]);
  const [insertableYn, setInsertableYn] = useState("");
  const [updatableYn, setUpdatableYn] = useState("");
  const [deletableYn, setDeletableYn] = useState("");
  const [whereJoinTable, setWhereJoinTable] = useState([]);
  const [orderby, setOrderby] = useState([]);
  const [isDynamicApi, setIsDynamicApi] = useState(
    StringUtils.equalsIgnoreCase(dataModelType, "D")
  );
  const [isServiceApi, setIsServiceApi] = useState(
    StringUtils.equalsIgnoreCase(dataModelType, "S")
  );
  const [isEntityApi, setIsEntityApi] = useState(
    StringUtils.equalsIgnoreCase(dataModelType, "E")
  );
  const [isTable, setIsTable] = useState();

  const [helperPopup, setHelperPopup] = useState({
    popoverShow: false,
    popoverTarget: null,
    popoverContents: <></>,
  });

  const PhyEntityNmRef = useRef();

  useEffect(() => {
    setIsTable(
      !StringUtils.equalsIgnoreCase(component.entityType, "PROCEDURE") &&
        !StringUtils.equalsIgnoreCase(component.entityType, "FUNCTION")
    );
    setPhysEntityNm(component.physEntityNm);
    setLogEntityNm(component.logEntityNm);
    setTableNm(component.tableNm);
    setRelation(component.relation || []);
    setInsertableYn(component.insertableYn || "Y");
    setUpdatableYn(component.updatableYn || "Y");
    setDeletableYn(component.deletableYn || "Y");
    setWhereJoinTable(component.whereJoinTable || []);
    setOrderby(component.orderby || []);
  }, [component]);

  const debounceType = debounce((newEntity) => {
    //output update
    EntityReduxHelper.updateEntity(dispatch, output, newEntity);
    //actived component update
    EntityReduxHelper.activateComponent(dispatch, newEntity);
  }, 200);

  const onChangePropertValue = (e) => {
    const {
      target: { value, id },
    } = e;
    const newItem = { ...component };
    newItem[id] = value;
    debounceType(newItem);
  };

  const onSaveRelation = (newRelation) => {
    //active component update
    const newComponent = produce(component, (draft) => {
      const updtIdx = draft.relation.findIndex((_relation) =>
        StringUtils.equalsIgnoreCase(
          _relation.targetEntity,
          newRelation.targetEntity
        )
      );
      draft.relation[updtIdx] = newRelation;
    });

    //Data Model update
    EntityReduxHelper.activateComponent(dispatch, newComponent);
    EntityReduxHelper.updateEntity(dispatch, output, newComponent);
  };

  /**
   * 참조 삭제
   * @param {*} relation
   */
  const onRemoveRelation = (relation) => {
    //active component update
    const newComponent = produce(component, (draft) => {
      const removeIdx = draft.relation.findIndex((_relation) =>
        StringUtils.equalsIgnoreCase(
          _relation.targetEntity,
          relation.targetEntity
        )
      );
      const newRelatiosn = [...draft.relation];
      newRelatiosn.splice(removeIdx, 1);

      draft.relation = newRelatiosn;
    });
    EntityReduxHelper.activateComponent(dispatch, newComponent);
    EntityReduxHelper.updateEntity(dispatch, output, newComponent);
  };

  const onOpenRelationDetail = (e, _relation) => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%",
        },
      },
    };
    const targetEntity = dataModelEntities.find(
      (e) => e.physEntityNm === _relation.targetEntity
    );

    Popup.open(
      <RelationSetPopup
        sourceEntity={component}
        targetEntity={targetEntity}
        relation={_relation}
        onSaveRelation={onSaveRelation}
        onRemoveRelation={() => onRemoveRelation(_relation)}
      />,
      options
    );
  };

  const onAddWhereJoin = (e) => {
    e.preventDefault();
    setWhereJoinTable([...whereJoinTable, ""]);
  };

  const onChangeWhereJoin = (e, idx) => {
    const newWhereJoin = [...whereJoinTable];
    newWhereJoin[idx] = e.currentTarget.value;
    setWhereJoinTable(newWhereJoin);

    const result = {
      target: {
        value: newWhereJoin,
        id: "whereJoinTable",
      },
    };
    onChangePropertValue(result);
  };
  const onRemoveWhereJoin = (e, idx) => {
    const newJoin = [...whereJoinTable];
    newJoin.splice(idx, 1);
    setWhereJoinTable(newJoin);
    const result = {
      target: {
        value: newJoin,
        id: "whereJoinTable",
      },
    };
    onChangePropertValue(result);
  };

  const onAddOrderby = () => {
    setOrderby([...orderby, {}]);
  };
  const onRemoveOrderBy = (e, idx) => {
    const newOrder = [...orderby];
    newOrder.splice(idx, 1);
    setOrderby(newOrder);

    const result = {
      target: {
        value: newOrder,
        id: "orderby",
      },
    };
    onChangePropertValue(result);
  };

  const onChangeOrderColumnNm = (e, idx) => {
    const { value } = e.currentTarget;
    const newOrder = [...orderby];
    const prevColumnNm = Object.keys(newOrder[idx])[0];
    const prevDirection = newOrder[idx][prevColumnNm] || "ASC";
    const obj = {};
    obj[value] = prevDirection;
    newOrder[idx] = obj;

    setOrderby(newOrder);

    const result = {
      target: {
        value: newOrder,
        id: "orderby",
      },
    };
    onChangePropertValue(result);
  };

  const onSelectOrderDirection = (e, idx) => {
    const { value } = e.target;
    const newOrder = [...orderby];

    const prevColumnNm = Object.keys(newOrder[idx])[0];

    newOrder[idx] = produce(newOrder[idx], (draft) => {
      draft[prevColumnNm] = value;
    });
    setOrderby(newOrder);

    const result = {
      target: {
        value: newOrder,
        id: "orderby",
      },
    };
    onChangePropertValue(result);
  };

  const onBlurPhysEntityNm = (e) => {
    /*
    물리명 바꾸는 것은 기존이름으로 연결된 다른 릴레이션에 대해서도 물리명 업데이트 하기 때문에
    onBlur를 통해 교체
    */

    if (StringUtils.equalsIgnoreType(component.physEntityNm, physEntityNm))
      return false;
    //1. 기존 엔티티 중에서 겹치는 물리명 가지고 있는지 확인
    const dupModel = dataModelEntities.find((dm) =>
      StringUtils.equalsIgnoreType(dm.physEntityNm, e.target.value)
    );
    if (dupModel) {
      Message.alert(
        "This physical name is already in use by another entity.",
        Enums.MessageType.ERROR
      );
      setPhysEntityNm(component.physEntityNm);
    } else {
      //update relation targetPhysEntityName
      EntityReduxHelper.updateRelationTargetPhysEntityNm(dispatch, output, {
        prevTargetName: component.physEntityNm,
        newTargetName: e.target.value,
      });
      const newItem = { ...component };
      newItem[e.target.id] = e.target.value;
      //output dataModel update
      EntityReduxHelper.updateEntity(dispatch, output, newItem);
      const { dataModelEntityFields, ...entity } = newItem;
      //actived component update
      EntityReduxHelper.activateComponent(dispatch, entity);
    }
  };

  const onHelperClick = (e, popoverContents) => {
    if (helperPopup.popoverShow && helperPopup.popoverTarget === e.target) {
      setHelperPopup({
        popoverShow: false,
        popoverTarget: e.target,
        popoverContents: <></>,
      });
    } else {
      setHelperPopup({
        popoverShow: true,
        popoverTarget: e.target,
        popoverContents,
      });
    }
  };

  return (
    <div className="entity-sidebar">
      <PropertiesHeader>{physEntityNm}</PropertiesHeader>
      <Accordion defaultActiveKey={[0, 1, 2, 5]} alwaysOpen>
        <Accordion.Item key={0} eventKey={0}>
          <Accordion.Header>Entity Definition</Accordion.Header>
          <Accordion.Body>
            {isDynamicApi && (
              <>
                <PropertyLable>Table Name</PropertyLable>
                <PropertyValue>
                  <input
                    className="form-control form-control-sm"
                    id="tableNm"
                    defaultValue={tableNm}
                    disabled
                  />
                </PropertyValue>
              </>
            )}

            <PropertyLable>Entity Physical Name</PropertyLable>
            <PropertyValue>
              <input
                id="physEntityNm"
                className="form-control form-control-sm"
                value={physEntityNm}
                onBlur={onBlurPhysEntityNm}
                onChange={(e) => setPhysEntityNm(e.target.value)}
                ref={PhyEntityNmRef}
              />
            </PropertyValue>

            <PropertyLable>Entity Logical Name</PropertyLable>
            <PropertyValue>
              <input
                id="logEntityNm"
                className="form-control form-control-sm"
                value={logEntityNm}
                onChange={(e) => {
                  onChangePropertValue(e);
                  setLogEntityNm(e.currentTarget.value);
                }}
              />
            </PropertyValue>
            <PropertyLable>Entity Type</PropertyLable>
            <PropertyValue>
              <input
                id="entityType"
                className="form-control form-control-sm"
                value={component.entityType}
                readOnly
                disabled
                onChange={() => {}}
              />
            </PropertyValue>
          </Accordion.Body>
        </Accordion.Item>
        {!isTable && (
          <Accordion.Item key={5} eventKey={5}>
            <Accordion.Header>Parameter List</Accordion.Header>
            <Accordion.Body>
              <RelationTable>
                <div className="relation-row relation-header">
                  <div className="columnNm">Parameter Name</div>
                  <div className="button">Parameter Type</div>
                  <div className="columnNm">Parameter Value</div>
                </div>
                {component.parameterList?.map((parameter) => {
                  return (
                    <div
                      className="relation-row relation-header"
                      key={parameter.parameterName}
                    >
                      <div className="columnNm">{parameter.parameterName}</div>
                      <div className="button">{parameter.parameterType}</div>
                      <div className="columnNm">{parameter.parameterValue}</div>
                    </div>
                  );
                })}
              </RelationTable>
            </Accordion.Body>
          </Accordion.Item>
        )}

        {isTable && isDynamicApi && (
          <>
            <Accordion.Item key={1} eventKey={1}>
              <Accordion.Header>Entity Options</Accordion.Header>
              <Accordion.Body>
                <PropertyLable>Insert status</PropertyLable>
                <PropertyValue>
                  <Form.Select
                    size="sm"
                    id="insertableYn"
                    onChange={(e) => {
                      onChangePropertValue(e);
                      setInsertableYn(e.target.value);
                    }}
                    value={insertableYn}
                  >
                    <option value={""}>Select</option>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                  </Form.Select>
                </PropertyValue>

                <PropertyLable>Update Status</PropertyLable>
                <PropertyValue>
                  <Form.Select
                    size="sm"
                    id="updatableYn"
                    onChange={(e) => {
                      onChangePropertValue(e);
                      setUpdatableYn(e.target.value);
                    }}
                    value={updatableYn}
                  >
                    <option value={""}>Select</option>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                  </Form.Select>
                </PropertyValue>

                <PropertyLable>Delete Status</PropertyLable>
                <PropertyValue>
                  <Form.Select
                    size="sm"
                    id="deletableYn"
                    onChange={(e) => {
                      setDeletableYn(e.target.value);
                      onChangePropertValue(e);
                    }}
                    value={deletableYn}
                  >
                    <option value={""}>Select</option>
                    <option value={"Y"}>Yes</option>
                    <option value={"N"}>No</option>
                  </Form.Select>
                </PropertyValue>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item key={2} eventKey={2}>
              <Accordion.Header>Data Sort Order</Accordion.Header>
              <Accordion.Body>
                <PropertyLable></PropertyLable>
                <PropertyValue
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outline-secondary"
                    className="light-font-color"
                    size="sm"
                    onClick={onAddOrderby}
                  >
                    Add Align Rule
                  </Button>
                  <div>
                    <Button
                      variant="outline-secondary"
                      onClick={(e) =>
                        onHelperClick(
                          e,
                          <ul>
                            <strong>▣ Data Sort Order </strong>
                            <li>1. Select Column</li>
                            <li>2. Select Sort Order</li>
                          </ul>
                        )
                      }
                      size="sm"
                      className="light-font-color"
                    >
                      Help
                    </Button>
                  </div>
                </PropertyValue>
                {orderby.map((order, idx) => {
                  const colunmNM = Object.keys(order)[0];
                  const direction = order[colunmNM];
                  return (
                    <PropertyValue
                      key={idx}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "3px",
                        width: "100%",
                      }}
                    >
                      <Form.Select
                        size="sm"
                        value={colunmNM || ""}
                        placeholder={"Column Name"}
                        onChange={(e) => onChangeOrderColumnNm(e, idx)}
                      >
                        <option value="">Select</option>
                        {component.dataModelEntityFields?.map((field) => {
                          return (
                            <option
                              key={field.columnNm}
                              value={field.physFieldNm}
                            >
                              {field.logFieldNm} - {field.columnNm}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Select
                        size="sm"
                        value={direction}
                        onChange={(e) => onSelectOrderDirection(e, idx)}
                      >
                        <option value={"ASC"}>ASC</option>
                        <option value={"DESC"}>DESC</option>
                      </Form.Select>
                      <PropertyLable
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AiOutlineMinusCircle
                          size={20}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onRemoveOrderBy(e, idx)}
                        />
                      </PropertyLable>
                    </PropertyValue>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item key={3} eventKey={3}>
              <Accordion.Header>Data Search Condition</Accordion.Header>
              <Accordion.Body>
                <PropertyLable></PropertyLable>
                <PropertyValue
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outline-secondary"
                    className="light-font-color"
                    size="sm"
                    onClick={onAddWhereJoin}
                  >
                    Add Search Condition
                  </Button>
                  <div>
                    <Button
                      variant="outline-secondary"
                      onClick={(e) =>
                        onHelperClick(
                          e,
                          <ul>
                            <strong>▣ Search Condition </strong>
                            <li>
                              [Entity Physical Name].[Column Name] Condition(=,
                              {"<>"}, / , Like etc..) value{" "}
                            </li>
                            <li>Example : UserInfo.user_address = 'Seoul'</li>
                          </ul>
                        )
                      }
                      size="sm"
                      className="light-font-color"
                    >
                      Help
                    </Button>
                  </div>
                </PropertyValue>

                {whereJoinTable.map((wJoin, idx) => {
                  return (
                    <PropertyValue
                      key={idx}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "3px",
                        width: "100%",
                      }}
                    >
                      <input
                        className="form-control form-control-sm"
                        id="whereJoinTable"
                        value={wJoin}
                        placeholder="Enter Search Condition"
                        onChange={(e) => onChangeWhereJoin(e, idx)}
                      />

                      <PropertyLable
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AiOutlineMinusCircle
                          size={20}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => onRemoveWhereJoin(e, idx)}
                        />
                      </PropertyLable>
                    </PropertyValue>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item key={4} eventKey={4}>
              <Accordion.Header>Relation Attribute</Accordion.Header>
              <Accordion.Body>
                <RelationTable>
                  <div className="relation-row relation-header">
                    <div className="columnNm">From</div>
                    <div className="columnNm">To</div>
                    <div className="button" />
                  </div>
                  {relation.map((_relation) => {
                    return (
                      <div
                        className="relation-row"
                        key={_relation.targetEntity}
                      >
                        <div className="columnNm">
                          <span>{physEntityNm}</span>
                        </div>
                        <div className="columnNm">
                          <span>{_relation.targetEntity}</span>
                        </div>
                        <div className="button">
                          <Button
                            size="sm"
                            onClick={(e) => onOpenRelationDetail(e, _relation)}
                          >
                            Details
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </RelationTable>
              </Accordion.Body>
            </Accordion.Item>
            <Overlay
              show={helperPopup.popoverShow}
              target={helperPopup.popoverTarget}
              placement="bottom"
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Header as="h3">Help</Popover.Header>
                <Popover.Body>{helperPopup.popoverContents}</Popover.Body>
              </Popover>
            </Overlay>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default Object.assign(Entity, {
  Editor: EntityEditor,
  Properties: EntityProperties,
});
