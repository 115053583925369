import { CodeService } from "@alpha/com.bizentro.daaf.front.framework";
import { AppContext } from "components/common/AppContextProvider";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import TrdTableField from "entity/TrdTableField.entity";
import produce from "immer";
import { camelCase } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DataModelService from "services/datamodel/DataModelService";
import ElementService from "services/trd/ElementService";
import TrdService from "services/trd/TrdService";
import WorkflowService from "services/workflow/WorkflowService";
import { Enums } from "../BuilderEnum";
import { Accordion } from "react-bootstrap";
import { BiFolder, BiFolderOpen } from "react-icons/bi";
import DraggableComponent from "./DraggableComponent";

const withElementComponentTab = (Component) => {
  /**
   * 불러올 컴포넌트 호출번호
   */
  const COMPONENT_NUM = {
    gridData: 61,
    searchFormData: 60,
    inputFormData: 62,
    textComponent: 80,
    formComponent: 69,
    selectData: 71,
    inputData: 70,
    buttonComponent: 73,
    textAreaComponent: 72,
    textEditorComponent: 146,
    checkBoxComponent: 74,
    radioButtonComponent: 75,
    singleDatePickerComponent: 76,
    dateRangePickerComponent: 77,
    fileUploadComponent: 78,
  };

  const ElementComponentListAccodion = ({
    elementTableData,
    index,
    ...props
  }) => {
    const [selected, setSelected] = useState(false);

    const onClickAccodion = (e) => {
      setSelected(!selected);
      e.stopPropagation();
    };

    return (
      <Accordion.Item
        eventKey={
          "dataModel" +
          elementTableData.targetCompId +
          elementTableData.tableNm +
          "ElementList"
        }
        key={elementTableData.targetCompId + index}
        onClick={onClickAccodion}
      >
        <Accordion.Header>
          {selected ? <BiFolderOpen size={20} /> : <BiFolder size={20} />}
          {`Component List ${selected ? "Fold" : "Expand"}`}
        </Accordion.Header>
        <Accordion.Body>
          {elementTableData.elementList.map((eData, _index) => {
            return <DraggableComponent key={_index} data={eData} />;
          })}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  return (props) => {
    const { component } = useContext(AppContext);
    const [trdTableList, setTrdTableList] = useState([]); // datamodel list trigger
    const [dmElementComponentList, setDMElementComponentList] = useState([]); //datamodel render

    const [workflowEntityList, setWorkflowEntityList] = useState([]); // workflow list trigger
    const [workflowTableList, setWorkflowTableList] = useState([]); //workflow render

    const dataModelEntityFieldRef = useRef({});
    const workflowEntityRef = useRef([]);

    const fromComponentRef = useRef({});
    const prevWorkflowUidList = useRef([]); // workflow가 변경된지 확인하기 위한 ref
    const prevTableListRef = useRef(["none"]); // 이전 trdTableList와 비교해서 달라진게 있는지 판별하기 위한 ref (최초 조회를 위회 임시값 none 세팅)

    const dataTypeRef = useRef([]);
    const inputFormOptionRef = useRef({});

    const init = async () => {
      const result = await CodeService.getCodeCombo({ codeMstCd: "D0001" });
      dataTypeRef.current = result.data;

      inputFormOptionRef.current = component.getComponentInfo(
        "B",
        COMPONENT_NUM.inputFormData
      ).preHandleOptions;
    };

    const getDataStudioElementByDataModel = (output) => {
      /**
       * 1. 데이터 모델 ID 확인 (UI Builder server)
       * 2. 데이터 모델 내 확인된 엔티티 호출 (UI Builder server)
       * 3. 데이터 모델 내 엔티티 내의 TrdTableId 확인 후 조회 (Studio Server)
       * 4. 각 필드를 조회하여 -> 2번에 적용된 필드만 추려낸다.
       * 5. Element Tab에 적용할 항목만 목록화 하여 return
       */
      if (ArrayUtils.isEmpty(dmElementComponentList)) {
        prevTableListRef.current = undefined;
      }
      const dataModelId = output.page.propertyValue.dataModelId;
      // 데이터모델이 달라졌을때만 불러오도록
      if (prevTableListRef.current !== dataModelId) {
        // 데이터 모델 ID 존재 여부 확인
        if (dataModelId !== null && dataModelId !== undefined) {
          // 데이터 모델내 엔티티 호출
          DataModelService.getDataModel({ dataModelId }, async (res) => {
            const tableMstIdList = [];
            const { dataModelEntities } = res.data;
            if (!ArrayUtils.isEmpty(dataModelEntities)) {
              dataModelEntities.forEach((entity) => {
                const remark = JSON.parse(entity.remark);
                tableMstIdList.push(remark.tableMstId);
                //데이터 모델의 Entity명을 key 값으로 해서 필드를 Ref에 담는다.
                dataModelEntityFieldRef.current = {
                  ...dataModelEntityFieldRef.current,
                  [entity.physEntityNm]: entity,
                  dataModelId: entity.dataModelId,
                };
              });
            }
            if (!ArrayUtils.isEmpty(tableMstIdList)) {
              const result = await TrdService.getTableListInfo({
                tableMstIdList,
              });
              if (!ArrayUtils.isEmpty(result.data)) {
                setTrdTableList(result.data);
                prevTableListRef.current = dataModelId;
              }
            }
          });
        } else {
          setDMElementComponentList([]);
        }
      }
    };

    /**
     * 데이터 모델 필드를 사용해서
     * 사용가능한 TrdField를 리턴한다.
     */
    const filterAvailableTrdFieldListByDataModelField = (
      dataModelEntity,
      trdTable
    ) => {
      //엔티티 물리명과 Trd Table의 Table명은 같다고 판단한다.
      const entityField = dataModelEntity.dataModelEntityFields;
      if (ArrayUtils.isEmpty(entityField)) {
        return trdTable.trdTableField;
      } else {
        //가능한 필드
        const availableFieldList = [];
        for (const efield of entityField) {
          let existField = trdTable.trdTableField.find(
            (f) => efield.physFieldNm === camelCase(f.element.elementCd)
          );
          if (existField) availableFieldList.push(existField);
        }
        return availableFieldList;
      }
    };

    /**
     * 현재 컴포넌트가 button Component인지 체크
     * @param {Object} form
     * @returns
     */
    const isButtonComp = (parentsNode) => {
      if (ObjectUtils.isEmpty(parentsNode)) {
        return false;
      }
      return (
        StringUtils.equalsIgnoreCase(
          parentsNode.editorAttr?.componentClass,
          "form/Button"
        ) &&
        StringUtils.equalsIgnoreCase(parentsNode.viewerAttr?.object, "button")
      );
    };

    /**
     *  UI Builder에서 사용된 Workflow ServiceUid를 filtering 하는 함수
     *  @return Button Component serviceUid List
     */
    const getDataStudioElementByWorkflow = async (output) => {
      let workflowUidList = []; // UIbuilder Json에서 찾은 워크플로우 리스트
      fromComponentRef.current = {};
      const findServiceUid = (item, parentsNode) => {
        if (item !== null && ObjectUtils.isObject(item)) {
          // Object 유무 확인
          if (
            !StringUtils.isEmpty(item.serviceUid) &&
            isButtonComp(parentsNode)
          ) {
            // Object 내의 workflow 유무, 해당 컴포넌트가 button 컴포넌트인지 체크
            fromComponentRef.current[`${item.id}*${item.serviceUid}`] = {
              serviceUid: item.serviceUid,
              compId: parentsNode.compId,
            };
            if (!workflowUidList.includes(item.serviceUid)) {
              // 중복체크
              workflowUidList.push(item.serviceUid);
            }
          }
          Object.values(item).forEach((obj) => {
            // Object 내의 다른 item에서 find workflow
            findServiceUid(obj, item);
          });
        } else if (ArrayUtils.isArray(item)) {
          // array 내의 object에서 find workflow
          item.forEach((obj) => findServiceUid(obj));
        }
      };
      findServiceUid(output);

      if (!ArrayUtils.isEmpty(workflowUidList))
        getWorkflowServiceList(workflowUidList);
    };

    /**
     * Workflow의 Entity Data를 가져오고 해당 EntityFieldList 에 element를 세팅해주는 함수
     */
    const getWorkflowServiceList = (workflowIdList) => {
      // workflow service Uid가 존재하고 이전 workflow service Uid와 같지 않은 경우
      if (
        !StringUtils.equalsIgnoreCase(
          JSON.stringify(workflowIdList.sort()),
          JSON.stringify(prevWorkflowUidList.current.sort())
        )
      ) {
        workflowEntityRef.current = [];
        prevWorkflowUidList.current = workflowIdList;

        WorkflowService.getElementTabServiceList(
          { serviceUidList: workflowIdList },
          async (res) => {
            if (!ArrayUtils.isEmpty(res.data)) {
              let elementIdList = new Set(); // 중복제거 리스트
              let refFieldIdList = new Set(); // 중복제거 리스트
              res.data.forEach((item) => {
                const service = JSON.parse(item.serviceContent);
                const endProcess = service.service.child.process?.find(
                  (p) => p.processType === Enums.WorkflowProcessType.END_PROCESS
                );
                let wfRetObject = [];
                if (
                  service &&
                  endProcess.propertyValue.returnObject?.length > 0
                ) {
                  wfRetObject.push(...endProcess.propertyValue.returnObject);
                }
                wfRetObject.forEach((entity) => {
                  let entityFieldList = entity.entityFieldList || [];
                  if (
                    entity.processType ===
                    Enums.WorkflowProcessType.ENTITY_DEFINITION
                  ) {
                    const process = JsonUtils.findNode(
                      service,
                      "compId",
                      entity.definitionCompId
                    );
                    if (!ObjectUtils.isEmpty(process)) {
                      entityFieldList = process.propertyValue.entityFieldList;
                    }
                  }
                  entity.entityFieldList = entityFieldList?.map((field) => {
                    field.serviceUid = item.serviceUid;
                    if (field.elementId) {
                      elementIdList.add(field.elementId);
                    }
                    if (!StringUtils.isEmpty(field.refFieldId)) {
                      refFieldIdList.add(field.refFieldId);
                    }
                    return field;
                  });

                  const fromComponent = getFromComponent(item.serviceUid);
                  entity.fromComponent = fromComponent.fromId;
                  entity.compId = fromComponent.compId;
                  entity.serviceUid = item.serviceUid;
                  entity.serviceId = item.serviceId;
                  entity.serviceName = item.serviceName;
                  workflowEntityRef.current.push(entity);
                });
              });

              // 참조하는 field 데이터가 있는 경우
              let refFieldList = [];
              await TrdService.getTableFieldList({
                tableFieldIdList: Array.from(refFieldIdList) || [],
                isWorkflow: true,
              }).then((res) => {
                if (!res.isError) {
                  refFieldList = res.data;
                }
              });
              await ElementService.getElementListByElementIds({
                elementIds: Array.from(elementIdList),
              }).then((res) => {
                const elementList = res.data;
                const mappingData = workflowEntityRef.current.map((entity) => {
                  // 엔티티의 entityFieldList에 element 데이터 매핑
                  if (entity.entityFieldList) {
                    entity.entityFieldList = entity.entityFieldList.map(
                      (field) => {
                        const fromComponent = getFromComponent(
                          field.serviceUid
                        );
                        const refField = refFieldList.find((item) =>
                          StringUtils.equalsIgnoreCase(
                            item.fieldId,
                            field.refFieldId
                          )
                        );
                        let refFieldData = {};
                        if (!ObjectUtils.isEmpty(refField)) {
                          refFieldData = {
                            refTableId: refField.trdTableMst?.tableMstId,
                            refFieldId: refField.fieldId,
                            refFieldTableNm:
                              refField.trdTableMst?.tablePhysicalNm,
                            refFieldNm: refField.element?.elementCd,
                            currencyCodeColumn: camelCase(
                              refField.element?.elementCd
                            ),
                          };
                        }
                        if (field.elementId) {
                          field.element = {
                            ...elementList.find(
                              (element) => element.elementId === field.elementId
                            ),
                            callFrom: fromComponent.fromId,
                            compId: fromComponent.compId,
                            ...refFieldData,
                          };
                        } else {
                          field.element = {};
                        }

                        return field;
                      }
                    );
                  }

                  return entity;
                });
                setWorkflowEntityList(mappingData);
              });
            }
          }
        );
      }
    };

    /**
     * ServiceUid가 같은지 확인하고 fromComponentRef에서 해당 ServiceUid가 있는 Object의 compId와 id 를 반환하는 함수
     * element가 target의 compId를 가지고 있어야해서 추가한 함수
     * @param {String} serviceUid
     * @returns
     */
    const getFromComponent = (serviceUid) => {
      if (ObjectUtils.isEmpty(fromComponentRef.current)) {
        return null;
      } else {
        let returnComponentId = null;
        for (const componentId in fromComponentRef.current) {
          if (fromComponentRef.current[componentId].serviceUid === serviceUid) {
            returnComponentId = {
              fromId: componentId.split("*")[0],
              compId: fromComponentRef.current[componentId].compId,
            };
            break;
          }
        }
        return returnComponentId;
      }
    };

    /**
     * DataModel / Workflow 의 EntityFieldList를 사용할 수 있는 Component로 변환하는 함수
     * @param {*} componentNum
     * @param {*} field
     * @param {*} identifier
     * @param {*} fieldInfo
     * @returns
     */
    const addElement = (componentNum, field, identifier, fieldInfo) => {
      const componentData = component.getComponentInfo("B", componentNum);
      const compoenent = produce(componentData, (draft) => {
        if (
          ObjectUtils.isEmpty(field) ||
          (!field.element && !field.elementId)
        ) {
          if (fieldInfo) {
            draft.defaultProperty = JSON.stringify({
              id: fieldInfo.fieldId,
              formLabel: fieldInfo.fieldNm,
              dataBinding: fieldInfo.fieldId,
              placeholder: fieldInfo.fieldNm,
              isDataStudioElement: false,
              identifier: identifier,
            });
            draft.componentDtlNm = `${fieldInfo.fieldId} (${draft.componentDtlNm})`;
            draft.fromComponent = field.callFrom;
          }
        } else {
          if (ObjectUtils.isEmpty(draft.defaultProperty))
            draft.defaultProperty = {};
          let prevDefaultData = {};
          //datepicker의 경우 defaultProperty가 string 형태로 존재하고 있기 때문에, 파싱하여 재사용 한다.
          if (
            componentData.defaultProperty &&
            typeof componentData.defaultProperty === "string"
          ) {
            prevDefaultData = JSON.parse(componentData.defaultProperty);
          }
          // element, domain에서 possibleEntry 보유시 defaultProperty Setting
          let isDm = false; //데이터모델인 경우 field.element === element , workflow인 경우 field === element
          if (!isNaN(identifier)) {
            isDm = true;
          }
          let possibleEntryData = [];
          let refData = {};

          /**
           * element를 우선으로 possibleEntry와 참조 table 정보를 세팅하는 로직
           * @param {*} fData = field or field.element | field === wfField, field.element === dmField
           */
          const entrySetting = (fData) => {
            possibleEntryData =
              fData?.possibleEntryList || fData.domain?.possibleEntryList;
            if (!possibleEntryData) return false;
            let isElement = false;
            if (!StringUtils.isEmpty(fData.entryRefTable)) {
              isElement = true;
            }

            const fromDomain = fData.domain ? fData.domain : fData;

            refData = {
              comboUrl: "/common/builder/runtime/tableRef/readCombo.do",
              entryRefTable: fromDomain.entryRefTable,
              entryRefWhere: fromDomain.entryRefWhere,
              idColumn: fromDomain.entryRefKey,
              textColumn: fromDomain.entryRefValue,
              comboType: "basic",
              searchTp: "REF",
            };
          };
          if (isDm) {
            entrySetting(field.element);
          } else {
            entrySetting(field);
          }

          // dm과 wf를 구분하고 entryType을 element 우선으로 entryType을 정하는 로직
          let entryType = null;
          let comboItems = [];
          if (isDm) {
            entryType = field.element.entryType;
            if (!entryType && field.element.domain) {
              entryType = field.element.domain.entryType;
            }
          } else {
            entryType = field.entryType;
            if (!entryType && field.domain) {
              entryType = field.domain.entryType;
            }
          }
          if (entryType) {
            comboItems =
              possibleEntryData &&
              possibleEntryData.map((item) => {
                return { id: item.entryValue, text: item.entryText };
              });

            // entryType에 따라 defaultProperty에 들어갈 데이터 세팅
            if (StringUtils.equalsIgnoreCase(entryType, "entry")) {
              prevDefaultData = {
                ...prevDefaultData,
                idColumn: "id",
                textColumn: "text",
                comboType: "basic",
                searchTp: "STATIC",
                comboItems: comboItems,
              };
            } else if (StringUtils.equalsIgnoreCase(entryType, "table")) {
              prevDefaultData = {
                ...prevDefaultData,
                ...refData,
              };
            }
          }
          draft.defaultProperty = {
            ...prevDefaultData,
            default: field.fieldDefaultValue,
            id:
              camelCase(field.elementCd) || camelCase(field.element.elementCd),
            formLabel: field.elementNm || field.element.elementNm,
            dataBinding:
              camelCase(field.elementCd) || camelCase(field.element.elementCd),
            placeholder: field.elementNm || field.element.elementNm,
            isDataStudioElement: true,
            identifier: identifier,
          };
          if (!StringUtils.isEmpty(field.dataLength)) {
            draft.defaultProperty.length = field.dataLength;
          }
          if (StringUtils.equalsIgnoreCase(field.notNullYn, "Y")) {
            draft.defaultProperty.isRequired = true;
          }
          // Input Component인 경우 type을 지정해주고 refTableId가 있는경우 ref data 추가
          if (
            StringUtils.equalsIgnoreCase(componentNum, COMPONENT_NUM.inputData)
          ) {
            const dataType = Enums.convertBuilderDataType(
              field.domain?.dataType || field.element.domain?.dataType
            );
            draft.defaultProperty.type = dataType;
            if (dataType === "number") {
              // formtype이 설정된 경우만 세팅
              if (
                StringUtils.includes(field.domain?.formType, [
                  "0", //금액
                  "1", //수량
                  "2", //단가
                  "3", //환율
                ])
              ) {
                draft.defaultProperty.numberType = field.domain.formType;
                draft.defaultProperty.integerPart =
                  parseInt(field.domain.dataLength) -
                  parseInt(field.domain.decimals);
                draft.defaultProperty.decimalPart = field.domain.decimals;
              } else if (
                StringUtils.includes(field.element?.domain?.formType, [
                  "0", //금액
                  "1", //수량
                  "2", //단가
                  "3", //환율
                ])
              ) {
                draft.defaultProperty.numberType =
                  field.element.domain.formType;
                draft.defaultProperty.integerPart =
                  parseInt(field.element.domain.dataLength) -
                  parseInt(field.element.domain.decimals);
                draft.defaultProperty.decimalPart =
                  field.element.domain.decimals;
              }
            }
            if (!StringUtils.isEmpty(field.refFieldId)) {
              draft.defaultProperty.refFieldId = field.refFieldId;
              draft.defaultProperty.refTableId = field.refTableId;
              draft.defaultProperty.refFieldTableNm = field.refFieldTableNm;
              draft.defaultProperty.refFieldNm = field.refFieldNm;
              draft.defaultProperty.currencyCodeColumn =
                field.currencyCodeColumn;
            }
          }
          draft.defaultProperty = JSON.stringify(draft.defaultProperty);

          //컴포넌트 명 수정
          draft.componentDtlNm = `${
            field.elementCd || field.element.elementCd
          } (${draft.componentDtlNm})`;
          draft.fromComponent = field.callFrom;
          /**
           * ViewerAttr 또는 editorAttr 에 tableMstId , tableFieldId 넣을예정
           */
        }
      });
      return compoenent;
    };

    /**
     * Element List 에 폼 항목 추가
     * @param {*} param0
     */
    const addFormComponent = ({ elementList, table }) => {
      const formComponent = component.getComponentInfo(
        "B",
        COMPONENT_NUM.formComponent
      );

      const formComponentWithTrdTableMstId = produce(formComponent, (draft) => {
        //폼은 UIEditorEventHandler에서 별도의 팝업없이 생성되는 컴포넌트이기 때문에
        //필요한 사항은 defaultPropery에 Json String으로 넣음
        //UIEditorEventHandler에서 parse 하기 때문에
        draft.defaultProperty = JSON.stringify({
          // 식별자
          identifier: table.identifier,
          // trdTable 사용시 trdTableId
          tableMstId: table.tableMstId,
          // 데이터 모델 엔티티가 설정된 경우
          dataModelEntityId: table.dataModelEntityId,
          // target component
          fromCompId: table.fromCompId,
          //entityVariable 추가
          entityVariable: table.entityVariable,
        });
        // Workflow 엔티티가 설정된 경우
        // DS Element 인지 여부
        draft.editorAttr = JSON.stringify({
          ...JSON.parse(draft.editorAttr),
          entityFieldList: table.entityFieldList,
        });
        draft.viewerAttr = JSON.stringify({
          ...JSON.parse(draft.viewerAttr),
          isDataStudioElement: true,
        });

        // 입력 Form일때 option data
        draft.preHandleOptions = inputFormOptionRef.current;
      });
      elementList.push(formComponentWithTrdTableMstId);
    };

    /**
     * Grid Component를 생성하여 파라미터로 넘어온 리스트에 push 함
     * @param {*} elementList
     */
    const addGridComponent = ({ elementList, table }) => {
      const gridComponent = component.getComponentInfo(
        "B",
        COMPONENT_NUM.gridData
      );
      /**
       * 1. 그리드 타이틀 설정 -> 엔티티 논리명 || 물리명
       * 2. Viewer Attr 설정에 TableMstId 넣어두기
       * 3. 팝업 설정 -> 엔티티 선택하는 부분 삭제
       */
      const gridComponentWithTrdTableMstId = produce(gridComponent, (draft) => {
        if (!draft.editorAttr) draft.editorAttr = {};
        if (!draft.viewerAttr) draft.viewerAttr = {};

        // dm, wf 공통
        draft.viewerAttr.tableMstId = table.tableMstId; // dm or workflow trd mst id
        draft.viewerAttr.isDataStudioElement = true;
        // data model의 경우
        draft.viewerAttr.entityId = table.entityId; // dm identifier
        draft.viewerAttr.dataModelId = table.dataModelId; // dm datamodel Id
        draft.editorAttr.entityElementList = table.entityFieldList; // dm element list
        // workflow의 경우
        // service Uid와 entity Variable은 빌더에서 해당 필드 추적용으로만 쓰일 것이라 판단하여 editorAttr에 넣는다.
        // Runtime에서 사용될 결우 viewerAttr에 넣어서 가져갈것. 현재 그리드의 각 컬럼 필드가 elementID를 가지고 있는 상태
        draft.editorAttr.serviceUid = table.serviceUid; // workflow service Uid
        draft.editorAttr.entityVariable = table.entityVariable; // workflow identifier
        draft.editorAttr.workflowEntityFieldList = table.entityFieldList; // workflow fieldListData
        draft.editorAttr.fromCompId = table.compId; // workflow fieldListData
      });
      //그리드 항목 추가
      elementList.push(gridComponentWithTrdTableMstId);
    };

    /**
     * Datamodel Element component 세팅
     *
     * @returns
     */
    const setDatamodelElementComponent = () => {
      const TableElementList = [];
      // Data Model테이블 순회
      for (const table of trdTableList) {
        const trdTableField = filterAvailableTrdFieldListByDataModelField(
          dataModelEntityFieldRef.current[table.tablePhysicalNm],
          table
        );
        //DataModel에 정의된 필드만 리턴시킨다.
        const tableObj = {
          tableNm: table.tablePhysicalNm,
          layoutComponents: [],
          elementList: [],
        };

        //그리드 추가
        addGridComponent({
          elementList: tableObj.layoutComponents,
          table: {
            ...table,
            entityId:
              dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId,
            dataModelId:
              dataModelEntityFieldRef.current[table.tablePhysicalNm]
                .dataModelId,
            entityFieldList: trdTableField,
          },
        });

        //폼항목 추가
        addFormComponent({
          elementList: tableObj.layoutComponents,
          table: {
            ...table,
            identifier:
              dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId,
            tableMstId: table.tableMstId,
            dataModelEntityId: tableObj.tablePhysicalNm,
          },
        });

        if (!ArrayUtils.isEmpty(trdTableField)) {
          for (const field of trdTableField) {
            const tableField = new TrdTableField(field);
            const element = tableField.getElement();
            const domain = element.getDomain();

            //데이터 type이 날짜 유형인경우
            if (
              StringUtils.includesIgnoreCase(domain.getDataType(), [
                "date",
                "dateTime",
                "timestamp",
              ])
            ) {
              const element = addElement(
                COMPONENT_NUM.singleDatePickerComponent,
                field,
                dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId
              );
              tableObj.elementList.push(element);
            } else if (
              StringUtils.equalsIgnoreCase(
                element.getEntryDisType() || domain.getEntryDisType(),
                "I"
              )
            ) {
              tableObj.elementList.push(
                addElement(
                  COMPONENT_NUM.inputData,
                  field,
                  dataModelEntityFieldRef.current[table.tablePhysicalNm]
                    .entityId
                )
              );
            } else if (
              StringUtils.equalsIgnoreCase(
                element.getEntryDisType() || domain.getEntryDisType(),
                "C"
              )
            ) {
              tableObj.elementList.push(
                addElement(
                  COMPONENT_NUM.selectData,
                  field,
                  dataModelEntityFieldRef.current[table.tablePhysicalNm]
                    .entityId
                )
              );
            }
          }
        }
        TableElementList.push(tableObj);
      }

      return TableElementList;
    };

    /**
     * Workflow Element Component 세팅
     * @returns
     */
    const setWorkflowElementComponent = () => {
      const TableElementList = [];
      for (const table of workflowEntityList) {
        const tableObj = {
          tableNm: table.entityVariable,
          elementList: [],
          layoutComponents: [],
          fromComponent: table.fromComponent,
          targetCompId: table.compId,
          serviceUid: table.serviceUid,
        };
        // Grid
        addGridComponent({
          elementList: tableObj.layoutComponents,
          table: {
            ...table,
            tableMstId: table.tableMstId,
            entityVariable: table.entityVariable,
            fromCompId: tableObj.targetCompId,
            entityVariable: table.entityVariable,
          },
        });

        // Form
        addFormComponent({
          elementList: tableObj.layoutComponents,
          table: {
            ...table,
            identifier: table.entityVariable,
            entityFieldList: table.entityFieldList,
            fromCompId: tableObj.targetCompId,
            entityVariable: table.entityVariable,
          },
        });
        if (!ArrayUtils.isEmpty(table.entityFieldList)) {
          for (const field of table.entityFieldList) {
            // dataType이 날짜인경우
            if (
              StringUtils.includesIgnoreCase(field.element?.domain?.dataType, [
                "date",
                "dateTime",
                "timestamp",
              ])
            ) {
              const element = addElement(
                COMPONENT_NUM.singleDatePickerComponent,
                field.element,
                table.entityVariable
              );
              tableObj.elementList.push(element);
            } else if (
              StringUtils.equalsIgnoreCase(
                field.element?.entryDisType ||
                  field.element?.domain?.entryDisType,
                "I"
              )
            ) {
              tableObj.elementList.push(
                addElement(
                  COMPONENT_NUM.inputData,
                  field.element,
                  table.entityVariable,
                  field
                )
              );
            } else if (
              StringUtils.equalsIgnoreCase(
                field.element?.entryDisType ||
                  field.element?.domain?.entryDisType,
                "C"
              )
            ) {
              tableObj.elementList.push(
                addElement(
                  COMPONENT_NUM.selectData,
                  field.element,
                  table.entityVariable,
                  field
                )
              );
            } else {
              //WF에서 임의로 추가된 컬럼들
              tableObj.elementList.push(
                addElement(
                  COMPONENT_NUM.inputData,
                  field.element,
                  table.entityVariable,
                  field
                )
              );
            }
          }
        }
        TableElementList.push(tableObj);
      }

      return TableElementList;
    };

    return (
      <Component
        {...props}
        trdTableList={trdTableList}
        dmElementComponentList={dmElementComponentList}
        workflowEntityList={workflowEntityList}
        workflowTableList={workflowTableList}
        dataModelEntityFieldRef={dataModelEntityFieldRef}
        workflowEntityRef={workflowEntityRef}
        fromComponentRef={fromComponentRef}
        prevWorkflowUidList={prevWorkflowUidList}
        prevTableListRef={prevTableListRef}
        dataTypeRef={dataTypeRef}
        inputFormOptionRef={inputFormOptionRef}
        ElementComponentListAccodion={ElementComponentListAccodion}
        addGridComponent={addGridComponent}
        addElement={addElement}
        addFormComponent={addFormComponent}
        COMPONENT_NUM={COMPONENT_NUM}
        filterAvailableTrdFieldListByDataModelField={
          filterAvailableTrdFieldListByDataModelField
        }
        getDataStudioElementByDataModel={getDataStudioElementByDataModel}
        getDataStudioElementByWorkflow={getDataStudioElementByWorkflow}
        setDatamodelElementComponent={setDatamodelElementComponent}
        setDMElementComponentList={setDMElementComponentList}
        setWorkflowElementComponent={setWorkflowElementComponent}
        setWorkflowTableList={setWorkflowTableList}
        init={init}
      />
    );
  };
};

export default withElementComponentTab;
