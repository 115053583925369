import { Accordion } from "react-bootstrap";
import withElementComponentTab from "./BaseElementComponentTab";
import DraggableComponent from "./DraggableComponent";
import { PropertiesHeader } from "./editor/theme/common/UIComponentStyle";
import { Scrollbar } from "smooth-scrollbar-react";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ElementComponentsTab = ({
  trdTableList,
  dmElementComponentList,
  workflowEntityList,
  workflowTableList,
  dataModelEntityFieldRef,
  workflowEntityRef,
  fromComponentRef,
  prevWorkflowUidList,
  prevTableListRef,
  dataTypeRef,
  inputFormOptionRef,
  ElementComponentListAccodion,
  init,
  getDataStudioElementByDataModel,
  getDataStudioElementByWorkflow,
  setDatamodelElementComponent,
  setDMElementComponentList,
  setWorkflowElementComponent,
  setWorkflowTableList,
  ...props
}) => {
  const { output } = useSelector((state) => state.outputUI);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    //output이 바뀔때마다 데이터모델과 Workflow 확인
    if (!ObjectUtils.isEmpty(output)) {
      if (!ObjectUtils.isEmpty(output.page.propertyValue)) {
        getDataStudioElementByDataModel(output);
      }
      getDataStudioElementByWorkflow(output);
    }
  }, [output]);

  useEffect(() => {
    //데이터 모델이 확인되어 tableList가 바뀌면 엔티티를 불러와 세팅
    const datamodelElementObjList = setDatamodelElementComponent();
    setDMElementComponentList(datamodelElementObjList);
  }, [trdTableList]);

  useEffect(() => {
    // 워크플로우가 추가 및 변경되어 return entity가 바뀌면 새로 세팅
    const workflowElementObjList = setWorkflowElementComponent();
    setWorkflowTableList(workflowElementObjList);
  }, [workflowEntityList]);

  return (
    // <>스크롤바</> -> removeEventListner 때문에 사용 X
    <div className="service-component-panel">
      <PropertiesHeader></PropertiesHeader>
      <div className="element-component-list-wrapper custom-scroll">
        <Accordion
          defaultActiveKey={[0, 1, 2, 3]}
          alwaysOpen
          style={{
            height: `calc(100vh - 50px - 34px - 42px)`,
          }}
        >
          {dmElementComponentList.map((elementTableData, index) => {
            return (
              <Accordion.Item
                eventKey={"dataModel" + elementTableData.tableNm}
                key={index}
              >
                <Accordion.Header>
                  {"Data Model - " + elementTableData.tableNm}
                </Accordion.Header>
                <Accordion.Body>
                  {elementTableData.layoutComponents.map((eData, _index) => {
                    return <DraggableComponent key={_index} data={eData} />;
                  })}

                  <ElementComponentListAccodion
                    elementTableData={elementTableData}
                    index={index}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
          {workflowTableList.map((elementTableData, index) => {
            return (
              <Accordion.Item
                eventKey={
                  "workflow" +
                  elementTableData.targetCompId +
                  elementTableData.tableNm
                }
                key={index}
              >
                <Accordion.Header>
                  {elementTableData.fromComponent +
                    " :  [WF] " +
                    elementTableData.serviceUid +
                    " > " +
                    elementTableData.tableNm}
                </Accordion.Header>
                <Accordion.Body>
                  {elementTableData.layoutComponents.map((eData, _index) => {
                    return <DraggableComponent key={_index} data={eData} />;
                  })}
                  <ElementComponentListAccodion
                    elementTableData={elementTableData}
                    index={index}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default withElementComponentTab(ElementComponentsTab);
