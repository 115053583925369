import * as Enums from "components/builder/BuilderEnum";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { UserShortKey } from "components/setting/section/shortKey/ShortKeyConfig";
import produce from "immer";
import { setDataModelEditable } from "page/dataModel/DataModelList";
import DataModelDeployPopup from "page/popup/dataModel/DataModelDeployPopup";
import DataModelListPopup from "page/popup/dataModel/DataModelListPopup";
import DataModelSavePopup from "page/popup/dataModel/DataModelSavePopup";
import { ImFileEmpty } from "react-icons/im";
import { connect } from "react-redux";
import LocalStorageService from "services/common/LocalService";
import DataModelService from "services/datamodel/DataModelService";

class EntityHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.setDropdownEvent = this.setDropdownEvent.bind(this);
    this.openNewBlank = this.openNewBlank.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.openSave = this.openSave.bind(this);
    this.onSaveDataModel = this.onSaveDataModel.bind(this);
    this.onChangeOnSavePopup = this.onChangeOnSavePopup.bind(this);
    this.openDeployPopup = this.openDeployPopup.bind(this);
    this.onDeploy = this.onDeploy.bind(this);
    this.state = {
      newModelShowDropdown: false,
      saveDropdown: false,
    };
    this.open = this.open.bind(this);
  }
  static contextType = AppContext;

  componentDidMount() {
    this.headerInit();
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.altKey && event.keyCode === 78) {
      /* alt + N  ==> 새로운 화면*/
      this.openNewBlank();
    } else if (event.altKey && event.keyCode === 83) {
      /* alt + s ==> 저장*/
      this.openSave();
    }
  };

  //신규 모델 생성
  openNewBlank() {
    const initDtm = () => {
      // this.props.removeDataModel();
      this.setState({ newModelShowDropdown: false });
      const newDm = {
        dataModelNm: "",
        moduleCd: this.props.workspace.moduleCd,
        description: "",
        dataModelType: "D",
        apiUrl: "",
        entity: "",
        compId: StringUtils.getUuid(),
        type: Enums.EntityComponentType.DATA_MODEL,
        dataModelEntities: [],
        useYn: "Y",
      };
      this.props.createDataModel(newDm);
    };

    if (
      !ObjectUtils.isEmpty(this.props.output) &&
      this.props.output.dataModelEntities.length > 0
    ) {
      Message.confirm("Unsaved changes will be deleted. Continue?", () => {
        initDtm();
      });
    } else {
      initDtm();
    }
  }
  //저장
  onChangeOnSavePopup(id, value) {
    this.props.updateDataModelProperty(this.props.output, id, value);
    if (this.props.activeComponent.compId === this.props.output.compId) {
      this.props.updateActivedComponent(id, value);
    }
  }
  onSaveDataModel(body, cb, saveAs) {
    if (saveAs) body.newDataModel = "Y";
    else body.newDataModel = "N";
    DataModelService.saveDataModel(
      body,
      (res) => {
        if (!res.isError) {
          Popup.close();
          Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
          this.localStorageSave(res.data);
          const dataModel = setDataModelEditable(res.data);
          this.props.updateDataModel(dataModel);
          cb();
        }
      },
      (err) => {
        cb();
      }
    );
  }

  /**
   * 불러오기 버튼 클릭
   * @param {Event} event
   */
  open = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;
    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */
    if (event) event.preventDefault();
    //팝업창 열기
    const popupTitle = <>Load datamodelList</>;
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "70%",
        },
      },
    };

    Popup.open(
      <DataModelListPopup
        workspace={this.context.workspace.Info}
        title={popupTitle}
        callbackFnc={this.callbackLoadFnc}
      />,
      options
    );
  };

  /**
   * 불러오기 Popup Callback function
   * @param {*} args editor-data
   * **/
  callbackLoadFnc = (args) => {
    try {
      DataModelService.getDataModel(
        args,
        (res) => {
          const dataModel = DataModelService.setData(res.data);
          this.props.updateDataModel(dataModel);
          if (window.location.pathname.indexOf("/choice") > -1) {
            //신규 데이터 모델 생성 화면일 경우 navigate로 이동 필요.
            this.props.navigate(
              Enums.BuilderPath.ENTITY.MAIN +
                "/" +
                Enums.BuilderPath.ENTITY.CREATE
            );
          }
          Message.alert(
            `Model number${dataModel.dataModelId} has been loaded.`,
            Enums.MessageType.SUCCESS
          );
        },
        () => {
          throw new Error("Error occured while loading program.");
        }
      );
    } catch (e) {
      Message.alert(e, Enums.MessageType.ERROR);
    }
    Popup.close();
  };

  getButtonClass = () => {
    if (this.getNowScreenEditor()) {
      return this.getEditorMode() === Enums.EditMode.PREVIEW ? "disabled" : "";
    } else {
      return "disabled";
    }
  };

  /**
   * 현재 보고 있는 화면이 에디터용 화면인지 확인
   * **/
  getNowScreenEditor() {
    return window.location.pathname.indexOf("/entity") > -1;
  }

  /**
   * editor mode , editor, preview
   * @returns
   */
  getEditorMode = () => {
    return window.location.pathname === Enums.BuilderPath.PREVIEW
      ? Enums.EditMode.PREVIEW
      : Enums.EditMode.EDITOR;
  };

  /**
   * toolbar button event controll
   * - editor mode에만 허용된 기능
   * - output data가 있을 경우에만 허용되는 기능
   * @param {*} restric
   * @returns
   */
  controllEvent = (validation) => {
    if (!this.getNowScreenEditor()) return false;
    let isValid = true;
    for (const key in validation) {
      if (key === "mode") {
        if (validation[key] !== this.getEditorMode()) {
          isValid = false;
          break;
        }
      } else if (key === "checkOutput") {
        if (
          ObjectUtils.isEmpty(this.props.output) ||
          ObjectUtils.isEmpty(this.props.output.page)
        ) {
          Message.alert("No Data Exists..", Enums.MessageType.ERROR);
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  openSave(event, saveAs = false) {
    const { dataModelEntities, dataModelType } = this.props.output;
    if (dataModelEntities.length === 0) {
      return Message.alert(
        "There are no registered Entites.",
        Enums.MessageType.INFO
      );
    }
    //서비스 API일 경우 Input이 필수적으로 들어가야함
    if (StringUtils.equalsIgnoreCase(dataModelType, "S")) {
      if (
        !dataModelEntities.find((entity) =>
          StringUtils.equalsIgnoreCase(entity.serviceInout, "input")
        )
      ) {
        return Message.alert(
          "Input Entity is a required element.",
          Enums.MessageType.WARN
        );
      }
    }
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <DataModelSavePopup
        output={this.props.output}
        onPopupInput={this.onChangeOnSavePopup}
        onSave={(body, cb) => this.onSaveDataModel(body, cb, saveAs)}
        workspace={this.context.workspace.Info}
      />,
      options
    );
    if (event) event.preventDefault();
  }

  setDropdownEvent(_state, _buttonId, editorScreenOnly = true) {
    /**
     * @param {Event} event
     * @returns
     */
    const showDropdown = (event) => {
      event.preventDefault();

      const newState = {
        newModelShowDropdown: false,
        saveDropdown: false,
      };
      newState[_state] = !this.state[_state];

      if (newState[_state]) {
        this.setState(newState, () => {
          document.addEventListener("click", closeDropdown);
        });
      } else {
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };
    /**
     * dropdown menu close event
     * @param {Event} event
     */
    const closeDropdown = (event) => {
      const {
        target: {
          dataset: { buttonId },
        },
      } = event;
      if (
        buttonId !== _buttonId &&
        this.dropdownMenu != null &&
        !this.dropdownMenu.contains(event.target)
      ) {
        const newState = produce(this.state, (draft) => {
          draft[_state] = false;
        });
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };

    return showDropdown;
  }

  componentWillUnmount() {
    this.unmountListener();
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  /**
   * 데이터 모델 배포
   * @param {*} body
   * @param {*} cb
   */
  onDeploy(body, cb) {
    const deployConnection = User.getConnection(
      this.props.workspace.tenantMstId
    );
    if (!deployConnection?.token) {
      if (cb) cb();
      return Message.alert(
        "Authentication server token is not validated.\n Please reconnect.",
        Enums.MessageType.ERROR
      );
    }
    //userId가 deploy서버 connectionID랑 param 명이 같기에 별도로 분리
    const { userId, ...connectionInfo } = deployConnection;

    // 배포 및 저장되는 tenantId,coCd는 workspace 기준이어야해서 배포전 cocd 갱신
    connectionInfo.tenantId = this.props.workspace.tenantId;
    connectionInfo.coCd = this.props.workspace.coCd;

    if (
      StringUtils.equalsIgnoreCase(deployConnection.connectionType, "direct")
    ) {
      body = {
        ...body,
        ...connectionInfo,
        connectionUserId: userId,
      };
      //먼저 저장 후 디플로이
      DataModelService.saveDataModel(
        body,
        (res) => {
          const directSaveBody = {
            dataModelId: res.data.dataModelId,
            dataModelNm: res.data.dataModelNm,
            dataModelType: res.data.dataModelType,
            description: res.data.description,
            resourceDm: JSON.stringify({
              dataModel: [{ ...body }],
            }),
            tenantId: res.data.tenantId,
            coCd: res.data.coCd,
            userId,
            requestor: User.getId(),
            connectionInfo,
          };
          DataModelService.deployDirectDataModel(
            directSaveBody,
            (res) => {
              Popup.close();
              Message.alert(
                "Program has been deployed and saved successfully.",
                Enums.MessageType.SUCCESS
              );
              cb();
            },
            cb
          );
        },
        cb
      );
    } else {
      body = {
        ...body,
        ...connectionInfo,
        connectionUserId: userId,
      };
      DataModelService.deployDataModel(
        body,
        (res) => {
          if (!res.isError) {
            Popup.close();
            Message.alert(
              "Program has been deployed and saved successfully.",
              Enums.MessageType.SUCCESS
            );
            const dataModel = setDataModelEditable(res.data);
            this.props.updateDataModel(dataModel);
            cb();
          }
        },
        (err) => {
          cb();
        }
      );
    }
  }

  /**
   * 배포 팝업 오픈
   * @param {*} event
   * @returns
   */
  openDeployPopup(event) {
    if (ObjectUtils.isEmpty(this.context.connection.Info)) {
      Message.alert(
        "Setting up connections before deployment.",
        Enums.MessageType.INFO
      );
      return this.context.connection.openPopup();
    }

    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <DataModelDeployPopup
        output={this.props.output}
        onDeploy={this.onDeploy}
        onPopupInput={this.onChangeOnSavePopup}
        workspace={this.context.workspace.Info}
        connection={this.context.connection.Info}
      />,
      options
    );
    if (event) event.preventDefault();
  }

  /**
   * 로컬 스토리지 저장
   * @param {*} datamodel
   */
  localStorageSave(datamodel) {
    const thisTarget = {
      appReleaseId: this.props.workspace.appReleaseId,
      dataModelId: datamodel.dataModelId,
      dataModelNm: datamodel.dataModelNm,
    };

    const saveAsData = () => {
      const body = {
        userId: User.getId(),
        list: [thisTarget],
      };
      LocalStorageService.set(Enums.LocalStorageName.DATAMODEL_HISTORY, body);
    };

    const localProgramHistStorage = LocalStorageService.get(
      Enums.LocalStorageName.DATAMODEL_HISTORY
    );
    if (localProgramHistStorage) {
      if (localProgramHistStorage.list.length > 10)
        localProgramHistStorage.list.splice(10);
      //기존에 동일한 프로그램 아이디가 저장되어있으면 해당 인덱스 삭제
      const isDupIndex = localProgramHistStorage.list.findIndex(
        (p) => p.dataModelId === thisTarget.dataModelId
      );
      if (isDupIndex > -1) localProgramHistStorage.list.splice(isDupIndex, 1);
      localProgramHistStorage.list.splice(0, 0, thisTarget);
      LocalStorageService.set(
        Enums.LocalStorageName.DATAMODEL_HISTORY,
        localProgramHistStorage
      );
    } else {
      saveAsData();
    }
  }

  renderHeader() {
    const newDropdownEvent = this.setDropdownEvent(
      "newModelShowDropdown",
      "empty"
    );
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");

    return (
      <>
        <li>
          <BuilderToolbarButton
            icon={<ImFileEmpty size={18} />}
            text=" New Data Model"
            caret={true}
            buttonId="empty"
            onClick={newDropdownEvent}
            style={this.toolbarStyle}
            iconOnly={this.state.mini}
            tooltipTitle={"New Data Model"}
          />
          {this.state.newModelShowDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openNewBlank}
                  style={{ width: "250px" }}
                >
                  New Model
                  <span>Alt + N</span>
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            className={this.getButtonClass()}
            buttonId="open"
            onClick={this.open}
            iconOnly={this.state.mini}
            tooltipTitle={`Open ${UserShortKey(Enums.ShortKeyDefine.LOAD)}`}
          />
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"Edit"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openSave}
                  style={{ width: "200px" }}
                >
                  Save
                  <span>Alt + S</span>
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={(e) => this.openSave(e, true)}
                  style={{ width: "200px" }}
                >
                  Save as
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="deploy"
            text=" Deploy DataModel"
            onClick={this.openDeployPopup}
            iconOnly={this.state.mini}
            tooltipTitle={"Deploy DataModel"}
          />
        </li>
      </>
    );
  }
}

const dispatchNewBlankPage = (dispatch) => {
  return {
    updateDataModel: (dataModel) => {
      EntityReduxHelper.updateDataModel(dispatch, dataModel);
    },
    removeDataModel: () => {
      EntityReduxHelper.removeDataModel(dispatch);
    },
    createDataModel: (newDataModel) => {
      EntityReduxHelper.openNewModel(dispatch, newDataModel);
    },
    updateDataModelProperty: (output, id, value) => {
      EntityReduxHelper.updateDataModelProperty(dispatch, output, {
        id,
        value,
      });
    },
    updateActivedComponent: (id, value) => {
      EntityReduxHelper.activateComponentProperty(dispatch, { id, value });
    },
    ...HeaderMapDispatchToProps(dispatch),
  };
};

export default connect((state) => {
  return {
    output: state.outputENT.output,
    activeComponent: state.activedENTComponent,
    workspace: state.workspace,
    ...HeaderMapStateToProps(state),
  };
}, dispatchNewBlankPage)(EntityHeader);
