import React, { Component } from "react";
import PropTypes from "prop-types";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "../BuilderHeader";
import { connect } from "react-redux";
import BuilderToolbarButton from "../BuilderToolbarButton";
import { TbDeviceMobileCog } from "react-icons/tb";
import ObjectUtils from "components/common/utils/ObjectUtils";

class MobileBuilderHeaderComponent extends BuilderHeader {
  constructor(props) {
    super(props);

    this.renderMobileWorkspace = this.renderMobileWorkspace.bind(this);
  }

  renderMobileWorkspace = () => {
    let iconText = " Mobile Workspace";
    if (!ObjectUtils.isEmpty(this.props.mobile.workspace)) {
      iconText += ` - ${this.props.mobile.workspace.mobileAppNm}`;
    }

    return (
      <li>
        <BuilderToolbarButton
          style={this.toolbarStyle}
          buttonId="mobileWorkspace"
          text={iconText}
          icon={<TbDeviceMobileCog />}
          onClick={() => this.props.openMobileWorkspacePopup()}
          iconOnly={this.state.mini}
          tooltipTitle={"Mobile Workspace Setting"}
        />
      </li>
    );
  };

  /**
   * 왼쪽 메뉴
   * @returns
   */
  renderLeftSide() {
    return (
      <div className="navbar-left" ref={this.leftSideRef}>
        <ul className={`nav navbar-nav`}>
          {this.renderHeader()}
          {this.renderLeftCommonMenu()}
          {this.renderMobileWorkspace()}
        </ul>
      </div>
    );
  }
}

MobileBuilderHeaderComponent.propTypes = {};

const mobileState = (state) => {
  return {
    ...HeaderMapStateToProps(state),
    mobile: state.mobile,
  };
};

export default connect(
  mobileState,
  HeaderMapDispatchToProps
)(MobileBuilderHeaderComponent);
