import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineFunction } from "react-icons/ai";
import Popup from "components/common/Popup";
import EventSearchResultPopup from "page/popup/EventSearchResultPopup";
import { AppContext } from "components/common/AppContextProvider";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import { Enums } from "components/builder/BuilderEnum";
import UCodeMirrorButton from "components/common/element/UCodeMirrorButton";
import StringUtils from "components/common/utils/StringUtils";
import ExtendTextEditorPopup from "page/popup/ExtendTextEditorPopup";
import ExtendPopup from "page/popup/ExtendPopup";

/**
 * Page 이벤트 :
 * 1. onLoad
 * 2. unLoad
 *
 * Button 이벤트 :
 * 1. {eventType: "BTN_SCH", beforeSubmit, afterDataLoad, afterSubmit}
 * 2. {eventType: "BTN_SAVE", beforeSubmit, afterSubmit}
 * 3. {eventType: "BTN_DELETE". beforeSubmit, afterSubmit}
 * 4. {eventType: "BTN_RESET", beforeSubmit, afterSubmit}
 * 5. {eventType: "BTN_NEW_DATA", beforeSubmit, afterSubmit}
 * 6. {eventType: "BTN_USR_EVENT", usrEventFn}
 * 7. {eventType: "BTN_USR_TNX", beforeSubmit, afterSubmit}
 * 8. {eventType: "BTN_OPEN_POPUP", beforeSubmit, afterSubmit}
 * 9. {eventType: "BTN_CLOSE_POPUP", beforeSubmit, afterSubmit}
 * 10. {eventType: "BTN_WORKFLOW", beforeSubmit, afterSubmit}
 * 11. {eventType: "BTN_DROPDOWN_EVENT", beforeSubmit, afterSubmit}
 *
 * Grid Toolbar Button 이벤트 :
 * 1. {eventType: "BTN_SAVE", beforeSubmit, afterSubmit}
 * 2. {eventType: "BTN_USR_TNX", beforeSubmit, afterSubmit}
 * 3. {eventType: "BTN_OPEN_POPUP", beforeSubmit, afterSubmit}
 * 4. {eventType:  "BTN_WORKFLOW", beforeSubmit, afterSubmit}
 * 5. {eventType: "BTN_USR_EVENT", eventFnc}
 *
 * Grid 이벤트 :
 * 1. {eventType: "GRID_LOAD", beforeSubmit, afterSubmit}
 * 2. {eventType: "GRID_ROW_CLICK_B", befoerSubmit, afterSubmit}
 * 3. {eventType: "GRID_ROW_CLICK_Q", beforeSubmit, afterSubmit}
 * 4. {eventType: "GRID_ROW_CLICK_P", beforeSubmit, afterSubmit}
 * 5. {eventType: "GRID_ROW_CLICK_C", beforeSubmit}
 * 6. {eventType: "GRID_ROW_CLICK_F", eventFnc}
 * 7. {eventType: "GRID_ROW_CLICK_U", eventFnc}
 * 8. {eventType: "GRID_ROW_DBCLICK_P", beforeSubmit, afterSubmit}
 * 9. {eventType: "GRID_ROW_DBCLICK_C", beforeSubmit}
 * 10. {eventType: "GRID_ROW_DBCLICK_U", eventFnc}
 *
 * Grid Column Edit 이벤트 :
 * 1. beginningEdit
 * 2. cellReadOnly
 * 3. cellEditEnding
 * 4. cellEditEnded
 * 5. cellColorFormatter
 * 6. columnHeaderClickEvent
 * 7. selectionChanged
 * 8. validationRule
 * 9. formatItem
 * 10. checkboxChanged
 *
 * Grid Column 이벤트 :
 * 1. render
 * 2. createdCell
 * 3. cellTemplate
 *
 * Select 이벤트 :
 * 1. beforeComboCall
 * 2. afterComboCall
 * 3. beforeChange
 * 4. afterChange
 *
 * Checkbox 이벤트 :
 * 1. eventClick
 * 2. eventChange
 *
 * Input 이벤트 :
 * 1. eventBlur
 * 2. eventChange
 * 3. eventKeyDown
 * 4. eventKeyPress
 * 5. eventKeyUp
 * 6. eventFocus
 * 7. eventClick
 *
 * TextArea 이벤트 :
 * 1. eventBlur
 * 2. eventChange
 * 3. eventKeyDown
 * 4. eventKeyPress
 * 5. eventKeyUp
 * 6. eventFocus
 * 7. eventClick
 *
 * Radio Button 이벤트 :
 * 1. eventClick
 * 2. eventChange
 *
 * Single Date Picker 이벤트 :
 * 1. eventChange
 * 2. eventBlur
 *
 * Range Date Picker 이벤트 :
 * 1. show
 * 2. hide
 * 3. apply
 * 4. cancel
 *
 * File upload 이벤트 :
 * 1. afterSubmit
 *
 * Widget Box 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 * 5. userFunction
 *
 * Numeric Metric 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 *
 * Text Widget 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 *
 * Form Widget 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 *
 * List 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 *
 * Chart 이벤트 :
 * 1. beforeRender
 * 2. beforeSubmit
 * 3. afterRender
 * 4. afterSubmit
 *
 * Tree 이벤트 :
 * 1. {eventType "TREE_LOAD", eventFn}
 * 2. {eventType: "TREE_CLICK_B", beforeSubmit, afterSubmit}
 * 3. {eventType: "TREE_CLICK_P", beforeSubmit, afterSubmit}
 * 4. {eventType: "TREE_CLICK_C", eventFn}
 * 5. {eventType: "TREE_CLICK_Q", beforeSubmit, afterSubmit}
 * 6. {eventType: "TREE_DRAG_S", eventFn}
 * 7. {eventType: "TREE_DRAG_O", eventFn}
 * 8. {eventType: "TREE_DRAG_D", eventFn}
 *
 * Report 이벤트 :
 * 1. beforeSubmit
 * 2. afterSubmit
 *
 * Column 이벤트 :
 * 1. {eventType: "BTN_USR_EVENT", usrEventFn}
 * 2. {eventType: "BTN_OPEN_POPUP", beforeSubmit, afterSubmit}
 * 3. {eventType: "BTN_SCH", beforeSubmit, afterSubmit}
 *
 * 이벤트 처리 종류 :
 * 1. beforeSubmit
 * 2. afterSubmit
 * 3. eventFnc
 * 4. afterDataLoad
 * 5. beginningEdit
 * 6. cellReadOnly
 * 7. cellEditEnding
 * 8. cellEditEnded
 * 9. cellColorFormatter
 * 10. columnHeaderClickEvent
 * 11. selectionChanged
 * 12. validationRule
 * 13. formatItem
 * 14. checkboxChanged
 * 15. render
 * 16. createdCell
 * 17. cellTemplate
 * 18. beforeComboCall
 * 19. afterComboCall
 * 20. beforeChange
 * 21. afterChange
 * 22. eventClick
 * 23. eventChange
 * 24. eventBlur
 * 25. show
 * 26. hide
 * 27. apply
 * 28. cancel
 * 29. beforeRender
 * 30. afterRender
 * 31. eventFn
 * 32. onLoad
 * 33. unLoad
 * 34. usrEventFn
 * 35. userFunction
 * 36. eventKeyDown
 * 37. eventKeyPress
 * 38. eventKeyUp
 * 39. eventFocus
 * 40. eventClick
 *
 * @returns
 */
const EventTab = (props) => {
  const output = useSelector((state) => state.outputUI.output);
  const [allComponentEvent, setAllComponentEvent] = useState({});
  const dispatch = useDispatch();
  const components = useContext(AppContext).component.getComponentList("B");
  const compEventList = useContext(AppContext).eventBuilder.compEventList;
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );

  const [allComponentEvent2, setAllComponentEvent2] = useState({});

  // 찾고자 하는 event
  const eventProcessTypes = [
    "beforeSubmit",
    "afterSubmit",
    "eventFnc",
    "afterDataLoad",
    "beginningEdit",
    "cellReadOnly",
    "cellEditEnding",
    "cellEditEnded",
    "cellColorFormatter",
    "columnHeaderClickEvent",
    "selectionChanged",
    "validationRule",
    "formatItem",
    "checkboxChanged",
    "render",
    "createdCell",
    "cellTemplate",
    "beforeComboCall",
    "afterComboCall",
    "beforeChange",
    "afterChange",
    "eventClick",
    "eventChange",
    "eventBlur",
    "show",
    "hide",
    "apply",
    "cancel",
    "beforeRender",
    "afterRender",
    "eventFn",
    "onLoad",
    "unLoad",
    "usrEventFn",
    "userFunction",
    "eventKeyDown",
    "eventKeyPress",
    "eventKeyUp",
    "eventFocus",
    "eventClick",
  ];

  const eventInfos = [
    {
      eventNm: "onLoad",
      onLoad: "Page onLoad",
    },
    {
      eventNm: "unLoad",
      unLoad: "Page unLoad",
    },
    {
      eventType: "BTN_SCH",
      beforeSubmit: "Pre-Processing Before Data Search",
      afterDataLoad: "Processing After Data Is Loaded",
      afterSubmit: "Post-Processing After Data Search",
      eventNm: "Button Click > Retrieve Data",
    },
    {
      eventType: "BTN_SAVE",
      beforeSubmit: "Pre-Processing Before Data Save",
      afterSubmit: "Post-Processing After Data Save",
      eventNm: "Button Click > Save Data",
    },
    {
      eventType: "BTN_DELETE",
      beforeSubmit: "Pre-Processing Before Data Delete",
      afterSubmit: "Post-Processing After Data Delete",
      eventNm: "Button Click > Delete Data",
    },
    {
      eventType: "BTN_RESET",
      beforeSubmit: "Pre-Processing Before Form Data Reset",
      afterSubmit: "Post-Processing After Form Data Reset",
      eventNm: "Button Click > Form Reset",
    },
    {
      eventType: "BTN_NEW_DATA",
      beforeSubmit: "Pre-Processing Before Form Data Creation",
      afterSubmit: "Post-Processing After Form Data Creation",
      eventNm: "Button Click > New Form Data",
    },
    {
      eventType: "BTN_USR_EVENT",
      usrEventFn: "User Defined Event",
      eventFnc: "User Defined Event",
      eventNm: "Button Click > User Defined Event",
    },
    {
      eventType: "BTN_USR_TNX",
      beforeSubmit: "Pre-Processing Event for User-Defined Transaction",
      afterSubmit: "Post-Processing Event for User-Defined Transaction",
      eventNm: "Button Click > User Defined Transaction",
    },
    {
      eventType: "BTN_OPEN_POPUP",
      beforeSubmit: "Pre-Processing Before Open Popup",
      afterSubmit: "Post-Processing After Open Popup",
      eventNm: "Button Click > Open Popup",
    },
    {
      eventType: "BTN_CLOSE_POPUP",
      beforeSubmit: "Pre-Processing Before Close Popup",
      afterSubmit: "Post-Processing After Close Popup",
      eventNm: "Button Click > Close Popup",
    },
    {
      eventType: "BTN_WORKFLOW",
      beforeSubmit: "Pre-Processing Event for Workflow Execution",
      afterSubmit: "Post-Processing Event for Workflow Execution",
      eventNm: "Button Click > Execute Workflow",
    },
    {
      eventType: "BTN_DROPDOWN_EVENT",
      beforeSubmit: "Pre-Processing Before Drop Down",
      afterSubmit: "Post-Processing After Drop Down",
      eventNm: "Button DropDown",
    },
    {
      eventType: "GRID_LOAD",
      beforeSubmit: "Pre-Processing Before Grid Load",
      afterSubmit: "Post-Processing After Grid Load",
      eventNm: "Grid initial Data Load",
    },
    {
      eventType: "GRID_ROW_CLICK_B",
      beforeSubmit:
        "Pre-Processing Event for Row Data Binding to Target on Row Click",
      afterSubmit:
        "Post-Processing Event for Row Data Binding to Target on Row Click",
      eventNm: "Grid Row Click(Binding)",
    },
    {
      eventType: "GRID_ROW_CLICK_Q",
      beforeSubmit:
        "Pre-Processing Event for Querying Target Grid Data on Row Click",
      afterSubmit:
        "Post-Processing Event for Querying Target Grid Data on Row Click",
      eventNm: "Grid Row Click(Query)",
    },
    {
      eventType: "GRID_ROW_CLICK_P",
      beforeSubmit: "Pre-Processing Before Open Popup on Row Click",
      afterSubmit: "Post-Processing After Open Popup on Row Click",
      eventNm: "Grid Row Click(Open Popup)",
    },
    {
      eventType: "GRID_ROW_CLICK_C",
      beforeSubmit: "Pre-Processing Before Close Popup on Row Click",
      eventNm: "Grid Row Click(Select row data & Close Popup)",
    },
    {
      eventType: "GRID_ROW_CLICK_F",
      eventFnc: "Event for Filtering Target Grid Data",
      eventNm: "Grid Row Click(Grid Data Filter)",
    },
    {
      eventType: "GRID_ROW_CLICK_U",
      eventFnc: "User Defined Event on Row Click",
      eventNm: "Grid Row Click(User Defined)",
    },
    {
      eventType: "GRID_ROW_DBCLICK_P",
      beforeSubmit: "Pre-Processing Before Open Popup on Row Double Click",
      afterSubmit: "Post-Processing After Open Popup on Row Double Click",
      eventNm: "Grid Row Db Click(Open Popup)",
    },
    {
      eventType: "GRID_ROW_DBCLICK_C",
      beforeSubmit: "Pre-Processing Before Close Popup on Row Double Click",
      eventNm: "Grid Row Db Click(Select row data & Close Popup)",
    },
    {
      eventType: "GRID_ROW_DBCLICK_U",
      eventFnc: "User Defined Event on Row Double Click",
      eventNm: "Grid Row Db Click(User Defined)",
    },
    {
      eventNm: "BeginningEdit",
      beginningEdit: "Processing Event When Entering a Cell",
    },
    {
      eventNm: "CellReadOnly",
      cellReadOnly: "Processing Event for Read-Only Cells",
    },
    {
      eventNm: "CellEditEnding",
      cellEditEnding: "Processing Event While Inputting in Cell",
    },
    {
      eventNm: "CellEditEnded",
      cellEditEnded: "Processing Event After Cell Input is Completed",
    },
    {
      eventNm: "CellColorFormatter",
      cellColorFormatter: "Processing Event for Cell Color Change",
    },
    {
      eventNm: "ColumnHeaderClickEvent",
      columnHeaderClickEvent: "Processing Event when Grid Header Clicked",
    },
    {
      eventNm: "SelectionChanged",
      selectionChanged: "Processing Event After Cell Selection",
    },
    {
      eventNm: "ValidationRule",
      validationRule: "Processing Event for Cell Verification",
    },
    {
      eventNm: "FormatItem",
      formatItem: "Processing Event for Change Cell Style or Content",
    },
    {
      eventNm: "CheckboxChanged",
      checkboxChanged: "Processing Event when Checkbox is Changed",
    },
    {
      eventNm: "FormatItem",
      render: "Processing Event for Change Cell Style or Content",
    },
    {
      eventNm: "ItemFormatter",
      createdCell: "Processing Event for Change Cell Display Format ",
    },
    {
      eventNm: "CellTemplate",
      cellTemplate: "Processing Event for Change Cell Style By HTML",
    },
    {
      eventNm: "Before Combo Render",
      beforeComboCall: "Processing Event Before the Combo is Rendered",
    },
    {
      eventNm: "After Combo Data Retrieve",
      afterComboCall: "Processing Event After the Combo Item Searched",
    },
    {
      eventNm: "Before Change Combo Item",
      beforeChange: "Processing Event when Trying to Change Combo Item",
    },
    {
      eventNm: "After Change Combo Item",
      afterChange: "Processing Event when Combo Item Changed",
    },
    {
      eventNm: "Click",
      eventClick: "Processing Event when the Component is Clicked",
    },
    {
      eventNm: "Change",
      eventChange: "Processing Event when Value is Changed",
    },
    {
      eventNm: "Blur",
      eventBlur: "Processing Event when the Focus of the Component is lost",
    },
    {
      eventNm: "KeyDown",
      eventKeyDown: "Processing Event when Keyboard is Pressed",
    },
    {
      eventNm: "KeyPress",
      eventKeyPress: "Processing Event when Keyboard is Pressed",
    },
    {
      eventNm: "KeyUp",
      eventKeyUp: "Processing Event when Pressed Key is Released",
    },
    {
      eventNm: "Focus",
      eventFocus: "Processing Event on Focus",
    },
    {
      eventNm: "Show",
      show: "Processing Event when Date Picker Shows",
    },
    {
      eventNm: "Hide",
      hide: "Processing Event when Date Picker Closes",
    },
    {
      eventNm: "Apply",
      apply: "Processing Event when [Apply] button in the Date Picker Clicked",
    },
    {
      eventNm: "Cancel",
      cancel:
        "Processing Event when [Cancel] button in the Date Picker Clicked",
    },
    {
      eventNm: "Pre-processing",
      beforeSubmit: "Processing Event After Component Render",
    },
    {
      eventNm: "Post-processing",
      afterSubmit: "Processing Event After Component Render",
    },
    {
      eventNm: "Pre-processing before Execution",
      beforeRender: "Processing Event before Template Execution",
    },
    {
      eventNm: "Post-processing after Render",
      afterRender: "Processing Event after Data Load",
    },
    {
      eventNm: "Widget Toolbar Button Event",
      userFunction: "User Defined Function for Widget Toolbar Button",
    },
    {
      eventType: "TREE_LOAD",
      eventFn: "Processing Event after Tree Load",
      eventNm: "Tree Data Load",
    },
    {
      eventType: "TREE_CLICK_B",
      beforeSubmit: "Pre-Processing before Data Binding",
      afterSubmit: "Post-Processing after Data Binding",
      eventNm: "Tree Click(Binding)",
    },
    {
      eventType: "TREE_CLICK_P",
      beforeSubmit: "Pre-Processing before Open Popup",
      afterSubmit: "Post-Processing after Open Popup",
      eventNm: "Tree Click(Open Popup)",
    },
    {
      eventType: "TREE_CLICK_C",
      eventFn: "User Defined Event",
      eventNm: "Tree Click(User Defined)",
    },
    {
      eventType: "TREE_CLICK_Q",
      beforeSubmit: "Pre-Processing before Data Search",
      afterSubmit: "Post-Processing after Data Search",
      eventNm: "Tree Click(Query)",
    },
    {
      eventType: "TREE_DRAG_S",
      eventFn: "Processing Event when Tree Drag Start",
      eventNm: "Tree Drag Start",
    },
    {
      eventType: "TREE_DRAG_O",
      eventFn: "Processing Event when Tree Drag Over",
      eventNm: "Tree Drag Over",
    },
    {
      eventType: "TREE_DRAG_D",
      eventFn: "Processing Event when Tree Drag Drop",
      eventNm: "Tree Drag Drop",
    },
  ];

  useEffect(() => {
    sortComponentEvent();
  }, [output]);

  useEffect(() => {
    setAllComponentEvent2(
      JsonUtils.findAllComponentEvent2(output.page, compEventList)
    );
  }, [output, compEventList]);

  /**
   * 현재 Builder output page에 있는 모든 event를 가지고 와서 before, after 순서대로 정렬렬
   */
  const sortComponentEvent = () => {
    let newAllComponentEvent = JsonUtils.findAllComponentEvent(
      output.page,
      eventProcessTypes,
      eventInfos
    );
    const processSort = [
      ["beforeSubmit", "afterSubmit"],
      ["beforeComboCall", "afterComboCall"],
      ["beforeRender", "afterRender"],
      ["beforeChange", "afterChange"],
    ];
    Object.keys(newAllComponentEvent).forEach((compId) => {
      newAllComponentEvent[compId].sort((a, b) => {
        if (a.eventType > b.eventType) {
          return 1;
        }
        if (a.eventType < b.eventType) {
          return -1;
        }

        for (var i = 0; i < processSort.length; i++) {
          if (
            a.eventProcessType === processSort[i][0] &&
            b.eventProcessType === processSort[i][1]
          ) {
            return -1;
          }
          if (
            a.eventProcessType === processSort[i][1] &&
            b.eventProcessType === processSort[i][0]
          ) {
            return 1;
          }
        }
      });
    });
    setAllComponentEvent(newAllComponentEvent);
  };

  /**
   * title 만들기
   * @param {*} eventItem
   * @param {*} eventInfo
   * @param {*} eventProcessType
   * @returns
   */
  const makeEventTitle = (eventItem, eventInfo, eventProcessType) => {
    let eventTitle;

    const compInfo = eventItem.compInfo;

    if (eventItem.isGridColumn) {
      eventTitle = `${compInfo.gridId} > ${compInfo.name}`;
    } else if (eventItem.isGridButton) {
      eventTitle = `${compInfo.propertyValue?.gridOptions?.gridId} > ${eventItem.gridButtonId}`;
    } else {
      // compId가 있는 경우
      if (compInfo.compId) {
        eventTitle = `${compInfo.compId}`;
        // page인 경우
        if (compInfo.propertyValue?.programId) {
          eventTitle = "Page";
        }
      }
      // Grid 인 경우
      if (compInfo.propertyValue?.gridOptions?.gridId) {
        eventTitle = `${compInfo.propertyValue?.gridOptions?.gridId}`;
      }
      // Id가 있는 경우
      if (compInfo.propertyValue?.id) {
        eventTitle = `${compInfo.propertyValue?.id}`;
      }
    }
    eventTitle += ` > ${eventInfo["eventNm"]} (${eventInfo[eventProcessType]})`;

    return eventTitle;
  };

  /**
   * 이벤트 메서드 기입하는 컴포넌트 생성자
   * @param {String} eventId 이벤트 타입
   * @param {String} popTitle 팝업 타이틀
   * @returns
   */
  const renderEventTextArea = (eventItem) => {
    const eventInfo = {
      compId: eventItem.compInfo.compId,
      eventType: eventItem.eventInfo.eventType,
      eventCd: eventItem.eventInfo.eventCd,
      builderEventType: eventItem.eventInfo.eventAttrName,
      originalOutput: eventItem.codeValue,
      programType: output.page.propertyValue?.programType || "M",
      targetType: eventItem.eventInfo.targetType,
    };

    const popTitle = "View Event";

    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    let PopupEditor;
    const fieldType = "javaScript";

    if (fieldType === "html") {
      PopupEditor = ExtendTextEditorPopup;
    } else {
      PopupEditor = ExtendPopup;
    }
    Popup.open(
      <PopupEditor
        title={popTitle}
        defaultValue={StringUtils.defaultString(eventItem.codeValue)}
        fieldType={fieldType}
        eventInfo={eventInfo}
        isEventBuilder={true}
      />,
      options
    );
  };

  /**
   * 이벤트 버튼 클릭
   * @param {*} e
   * @param {*} eventInfo
   * @param {*} eventItem
   */
  const onClickEventButton = (e, compId, eventInfo, eventItem) => {
    const eventProcessType = eventItem["eventProcessType"];
    const title = eventInfo[eventProcessType];

    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    let eventTitle = makeEventTitle(eventItem, eventInfo, eventProcessType);

    Popup.open(
      <EventSearchResultPopup
        title={title}
        values={[eventItem[eventProcessType]]}
        eventTitles={[eventTitle]}
        fieldType={"javascript"}
        readOnly={true}
      />,
      options
    );
  };

  /**
   * View All 버튼 클릭
   * @param {*} e
   */
  const onClickViewAllButton = (e) => {
    const title = "View All Events";
    let codeValues = [];
    let eventTitles = [];

    Object.keys(allComponentEvent).forEach((compId) => {
      allComponentEvent[compId].forEach((eventItem) => {
        const eventProcessType = eventItem.eventProcessType;
        const codeValue = eventItem[eventProcessType];
        const eventInfo = eventItem.eventInfo;
        codeValues.push(codeValue);

        let eventTitle = makeEventTitle(eventItem, eventInfo, eventProcessType);

        eventTitles.push(eventTitle);
      });
    });

    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp,
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    Popup.open(
      <EventSearchResultPopup
        title={title}
        values={codeValues}
        eventTitles={eventTitles}
        fieldType={"javascript"}
        readOnly={true}
      />,
      options
    );
  };

  /**
   * View All 버튼 클릭
   * @param {*} e
   */
  const onClickViewAllButtonInComp = (e, compId) => {
    const title = "View All Events In " + compId;
    let codeValues = [];
    let eventTitles = [];

    allComponentEvent[compId].forEach((eventItem) => {
      const eventProcessType = eventItem.eventProcessType;
      const codeValue = eventItem[eventProcessType];
      const eventInfo = eventItem.eventInfo;

      codeValues.push(codeValue);

      let eventTitle = makeEventTitle(eventItem, eventInfo, eventProcessType);

      eventTitles.push(eventTitle);
    });

    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp,
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };

    Popup.open(
      <EventSearchResultPopup
        title={title}
        values={codeValues}
        eventTitles={eventTitles}
        fieldType={"javascript"}
        readOnly={true}
      />,
      options
    );
  };

  useEffect(() => {
    console.log(allComponentEvent);
  }, [allComponentEvent]);

  /**
   * Activate UI 변경
   * @param {*} e
   * @param {*} comp
   */
  const changeActivateUI = (e, eventItem, isGridColumn) => {
    if (isGridColumn) {
      Event.clickComponent(
        e,
        eventItem.cellItem,
        components,
        activedComponent,
        dispatch
      );
    } else {
      Event.clickComponent(
        e,
        eventItem.compInfo,
        components,
        activedComponent,
        dispatch
      );
    }
    props.setActivedTabKey("propertyTab");
  };

  return (
    <>
      <div
        style={{
          marginRight: "0.5rem",
          display: "flex",
          justifyContent: "flex-end",
          margin: "0.5rem 0.5rem 0.5rem 0",
        }}
      >
        {!ObjectUtils.isEmpty(allComponentEvent2) && (
          <Button
            size="sm"
            variant="outline-secondary"
            style={{ fontSize: "10px", color: "#fff" }}
            onClick={(e) => onClickViewAllButton(e)}
          >
            View All
          </Button>
        )}
      </div>
      <Accordion>
        {Object.keys(allComponentEvent2).map((compId, index) => {
          let headerTitle;

          const currentComp = allComponentEvent2[compId][0].compInfo;

          if (currentComp.compId) {
            headerTitle = currentComp.compId;
            if (currentComp.propertyValue?.programId) {
              headerTitle = "Page";
            }
          }
          if (currentComp.propertyValue?.gridOptions?.gridId) {
            headerTitle = currentComp.propertyValue?.gridOptions?.gridId;
          }
          if (currentComp.propertyValue?.id) {
            headerTitle = currentComp.propertyValue?.id;
          }
          if (allComponentEvent2[compId][0].isGridColumn) {
            headerTitle = `${currentComp.gridId} > ${currentComp.name}`;
          }

          return (
            <Accordion.Item key={`accordion${index}`} eventKey={index}>
              <Accordion.Header>{headerTitle}</Accordion.Header>
              <Accordion.Body>
                <div style={{ margin: ".5rem 0" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "0 0.5rem 0.5rem 0",
                    }}
                  >
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      style={{
                        fontSize: "10px",
                        color: "#fff",
                      }}
                      onClick={(e) => onClickViewAllButtonInComp(e, compId)}
                    >
                      View All
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      style={{
                        fontSize: "10px",
                        color: "#fff",
                      }}
                      onClick={(e) =>
                        changeActivateUI(
                          e,
                          allComponentEvent2[compId][0],
                          allComponentEvent2[compId][0].isGridColumn
                        )
                      }
                    >
                      Go to Component
                    </Button>
                  </div>
                  {allComponentEvent2[compId].map((eventItem, index) => {
                    let eventInfo = eventItem.eventInfo;

                    let eventNm;
                    // Grid Button인 경우
                    if (eventItem.isGridButton) {
                      eventNm = `${eventItem.gridButtonId} > ${eventInfo?.eventNm}`;
                    } else {
                      eventNm = `${eventInfo?.eventNm}`;
                    }

                    return (
                      <div key={`${compId}${index}`}>
                        {!ObjectUtils.isEmpty(eventInfo) && (
                          <Row
                            style={{
                              marginRight: "0.5rem",
                              marginLeft: 0,
                              paddingTop: "10px",
                              padding: "0.5rem 1rem",
                              border: "1px solid #595555",
                            }}
                          >
                            <Col xl={10}>
                              <Row>{eventInfo.eventName}</Row>
                              <Row style={{ opacity: 0.5 }}>
                                {eventInfo.eventHandleNm}
                              </Row>
                            </Col>
                            <Col xl={2}>
                              <Button
                                size={"sm"}
                                onClick={(e) => renderEventTextArea(eventItem)}
                              >
                                <AiOutlineFunction className="mr-5" />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export default EventTab;
