import { AppContext } from "components/common/AppContextProvider";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UIReduxHelper from "../editor/helper/UIReduxHelper";
import produce from "immer";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { FindNodeFromPage, generateNodeTreeIds } from "./treeUtils";
import {
  setBuilderTreeNodeIds,
  setTreeOpen,
} from "../reducers/UIBuilderAction";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import DraggableTree from "./DraggableTree";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import ArrayUtils from "components/common/utils/ArrayUtils";

function UITree({ ...props }) {
  const {
    output: { page: outputPage },
  } = useSelector((state) => state.outputUI);
  const {
    component: activedComponent,
    treeNodeIds,
    treeOpen,
  } = useSelector((state) => state.activedUIComponent);

  const { component } = useContext(AppContext);
  const dispatch = useDispatch();
  //알수없는 이유로 인해서 JSON 딥카피
  const [page, setPage] = useState(_.cloneDeep(outputPage));

  const [components, setComponents] = useState([]);

  useEffect(() => {
    setPage(JSON.parse(JSON.stringify(outputPage)));
  }, [outputPage]);

  useEffect(() => {
    if (component) {
      setComponents(component.getComponentList("B"));
    }
  }, [component]);

  /**
   *
   * @param {*} from  옮길 노드
   * @param {*} to 대상 노드
   * @param {*} direction inside : child에 넣기 , upside 같은 레벨의 앞에 놓기
   * @returns
   */
  const moveItem = (from, to, direction) => {
    const _page = { ...page };
    //to의 앞단계로 이동함
    //1. from 삭제
    JsonUtils.removeNode(_page, "compId", from.compId);
    if (StringUtils.equalsIgnoreCase(direction, "inside")) {
      //1-1. 타겟 노드 child에 추가
      let newChild = to.child ? [...to.child] : [];
      newChild.push(from);
      //1-2. 업데이트
      const targetNode = JsonUtils.overrideNode(
        _page,
        "compId",
        to.compId,
        "child",
        newChild
      );
    } else if (StringUtils.equalsIgnoreCase(direction, "upside")) {
      //2. to 위치 레벨 위에 추가
      //2-1 to 부모 아이디 확인
      const [toNode, toIdList = []] = FindNodeFromPage(_page, to.compId);
      if (ArrayUtils.isEmpty(toIdList)) return false;
      const toParentsCompId = toIdList.at(-2);
      // 2-2 부모 노드 추출
      const toParents = JsonUtils.findNode(_page, "compId", toParentsCompId);
      // 2-3 to 인덱스 구하기
      const { child: toParentsChildren } = toParents;
      const toIndex = toParentsChildren.findIndex(
        (c) => c.compId === to.compId
      );
      // 2-4 to 인덱스 앞에 추가하기
      toParents.child.splice(toIndex, 0, from);
      // 2-5 업데이트
      const targetNode = JsonUtils.overrideNode(
        _page,
        "compId",
        toParentsCompId,
        "child",
        toParents.child
      );
    }
    setPage(_page);
    UIReduxHelper.updateOutput({ page: _page }, dispatch);
  };

  /**
   * 그리드 내부에서 컬럼 이동
   * 이동방향은 무조건 upside
   * @param {*} from
   * @param {*} to
   */
  const moveColumn = (from, to) => {
    const _page = { ...page };
    //1.컬럼 삭제
    JsonUtils.removeNode(_page, "compId", from.compId);
    //2. 그리드 찾기
    const grid = JsonUtils.findNode(_page, "compId", to.gridCompId);
    //3. 타겟 위치 확인
    const {
      propertyValue: {
        gridOptions: { columns },
      },
    } = grid;
    const toIndex = columns.findIndex((c) =>
      StringUtils.equalsIgnoreType(c.compId, to.compId)
    );
    //4. 타겟 위치 위에 올리기
    columns.splice(toIndex, 0, from);
    //5. 업데이트
    const currentPropertyValue = { ...grid.propertyValue };
    const newPropertyValue = produce(currentPropertyValue, (draft) => {
      draft.gridOptions.columns = [...columns];
    });

    const changedOutput = produce(_page, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        grid.compId,
        "propertyValue",
        newPropertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    UIReduxHelper.updateOutput({ page: changedOutput }, dispatch);
  };

  /**
   * 클릭 이벤트
   * @param {*} e
   * @param {*} child
   */
  const onClickTreeItem = (e, child) => {
    const newTreeNodeIds = [...treeNodeIds];
    const nodeIndex = newTreeNodeIds.indexOf(child.compId);

    let nodeIds = [];
    if (nodeIndex > -1) {
      // 이미 있으면 해당 접음(배열에서 삭제)
      newTreeNodeIds.splice(nodeIndex, 1);
    } else {
      nodeIds = generateNodeTreeIds(outputPage, child.compId);
    }
    nodeIds = [...newTreeNodeIds, ...nodeIds];
    setReduxState(e, [...new Set(nodeIds)], child);
  };
  /**
   * 그리드 클릭 이벤트
   * @param {*} e
   * @param {*} child
   * @param {*} parentsId
   */
  const onClickGridChild = (e, child, parentsId) => {
    const nodeIds = generateNodeTreeIds(outputPage, parentsId);
    nodeIds.push(child.compId);
    setReduxState(e, nodeIds, child);
  };

  /**
   * redux 스테이트 적용
   * @param {*} e
   * @param {*} nodeIds
   * @param {*} child
   */
  const setReduxState = (e, nodeIds, child) => {
    Event.clickComponent(e, child, components, activedComponent, dispatch);
    dispatch(setBuilderTreeNodeIds(nodeIds));
  };

  return (
    <div className={`builderTree-container ${!treeOpen ? "collapsed" : ""}`}>
      <DraggableTree
        moveColumn={moveColumn}
        moveItem={moveItem}
        onClickGridChild={onClickGridChild}
        onClickTreeItem={onClickTreeItem}
        data={page}
      />
    </div>
  );
}

export default UITree;
