import { EditorType, Enums } from "components/builder/BuilderEnum";
import {
  setShowPropertiesTab,
  setViewportScale,
} from "components/builder/mobile/reducers/MobileAction";
import { activateComponent } from "components/builder/ui/reducers/UIBuilderAction";
import { debounce } from "components/common/utils/InputUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FiMove } from "react-icons/fi";
import { IoMdSquareOutline } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

function MobileDevice({ style, ...props }) {
  const dispatch = useDispatch();
  const {
    showPropTab,
    viewportDirection,
    os,
    buttonShow,
    emulator: showEmulator,
    scale,
  } = useSelector((state) => state.mobile);
  const { tenantMstId } = useSelector((state) => state.workspace);

  const editorRef = useRef();
  const scaleRef = useRef(scale);

  const frameRef = useRef();
  const emulatorFrameRef = useRef();
  const [position, setPosition] = useState({ top: 20, left: 30 });
  const [emulatorPosition, setEmulatorPosition] = useState({
    top: 20,
    right: 30,
  });
  const positionChangeRef = useRef({ top: 20, left: 30 });
  const emulatorPositionChangeRef = useRef({ top: 20, right: 30 });

  const [drgProps, dragRef] = useDrag(() => ({
    type: EditorType.MOBILE,
    item: {
      style,
      scale,
      direction: viewportDirection,
      os,
      type: EditorType.MOBILE,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  }));

  const [edrgProps, edragRef] = useDrag(() => ({
    type: EditorType.EMULATOR,
    item: {
      style,
      scale,
      direction: viewportDirection,
      os,
      type: EditorType.EMULATOR,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  }));

  // preview(getEmptyImage(), { captureDraggingState: true });

  const [collectedProps, dropZoneRef] = useDrop(() => ({
    accept: [EditorType.MOBILE, EditorType.EMULATOR],
    drop: (item, monitor) => {
      const differPosition = monitor.getDifferenceFromInitialOffset(); // 드롭된 위치 좌표, 현위치 기준 바뀐 위치를 업데이트함
      if (item.type === EditorType.MOBILE) {
        positionChangeRef.current = {
          top: positionChangeRef.current.top + differPosition.y,
          left: positionChangeRef.current.left + differPosition.x,
        };
        //최소값 지정
        if (positionChangeRef.current.top < 20) {
          positionChangeRef.current.top = 20;
        }
        if (positionChangeRef.current.left < 30) {
          positionChangeRef.current.left = 30;
        }
        //call by Value 현상으로 ref에서 값을 조절하고 해당 값을 set 함
        setPosition(positionChangeRef.current);
      } else {
        emulatorPositionChangeRef.current = {
          top: emulatorPositionChangeRef.current.top + differPosition.y,
          right: emulatorPositionChangeRef.current.right - differPosition.x,
        };
        //최소값 지정
        if (emulatorPositionChangeRef.current.top < 20) {
          emulatorPositionChangeRef.current.top = 20;
        }
        if (emulatorPositionChangeRef.current.right < 30) {
          emulatorPositionChangeRef.current.right = 30;
        }
        //call by Value 현상으로 ref에서 값을 조절하고 해당 값을 set 함
        setEmulatorPosition(emulatorPositionChangeRef.current);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(), // 드롭 영역 위에 있는지 여부
    }),
  }));

  const connection = User.getConnection(tenantMstId);

  const renderDevicebtn = () => {
    if (!buttonShow) return <></>;
    if (StringUtils.equalsIgnoreCase(os, Enums.MobileOsType.ANDROID)) {
      return (
        <div className={`device-bottom ${os}`}>
          <div>
            <IoMenu />
          </div>
          <div>
            <IoMdSquareOutline />
          </div>
          <div>
            <MdKeyboardArrowLeft />
          </div>
        </div>
      );
    } else {
      return (
        <div className={`device-bottom ${os}`}>
          <div></div>
          <div>
            <div className="ios-underbar"></div>
          </div>
          <div></div>
        </div>
      );
    }
  };

  const debouceScroll = debounce((_scale) => {
    dispatch(setViewportScale(_scale));
  }, 100);

  return (
    <div
      className="mobile-device-canvas"
      onClick={(e) => {
        if (showPropTab) {
          dispatch(setShowPropertiesTab(false));
          dispatch(activateComponent({}));
        }
      }}
      onWheel={(e) => {
        if (e.deltaY > 0) {
          scaleRef.current = scaleRef.current - 3;
        } else {
          scaleRef.current = scaleRef.current + 3;
        }
        if (e.nativeEvent.shiftKey) {
          debouceScroll(scaleRef.current);
        }
      }}
      // draggable
      ref={dropZoneRef}
      // onDragStart={(e) => {
      //   e.stopPropagation();
      //   console.log("canvas drag");
      // }}
      // onDrag={(e) => {
      //   e.stopPropagation();
      //   console.log("canvas dragging");
      // }}
      // onDragEnd={(e) => {
      //   console.log("canvas drag ENd");
      // }}
    >
      <div
        className="device-frame"
        style={{
          transform: `scale(${scale / 100})`,
          width: style.width + 10,
          height: style.height + 5,
          ...position,
        }}
        ref={frameRef}
      >
        <div className="viewport-toolbar" ref={dragRef}>
          <FiMove size={14} /> Viewport
        </div>
        <div
          className={`mobile-device-wrapper ${viewportDirection}`}
          style={{ ...style }}
          ref={editorRef}
        >
          <div className={`device-top ${os}`}></div>
          <div className="divice-middle">{props.children}</div>

          {renderDevicebtn()}
        </div>
      </div>
      {connection && showEmulator && connection.runtimeHost && (
        <div
          className="device-frame"
          style={{
            transform: `scale(${scale / 100})`,
            width: style.width + 10,
            height: style.height + 5,
            ...emulatorPosition,
          }}
          ref={emulatorFrameRef}
        >
          <div className="viewport-toolbar" ref={edragRef}>
            <FiMove size={14} /> Emulator Viewport
          </div>
          <div
            scale={1}
            style={{ ...style }}
            // scale={scale / 100}
            className={`mobile-device-wrapper  ${viewportDirection}`}
          >
            <div className={`device-top ${os}`}></div>
            <div className="divice-middle">
              <iframe
                src={`${connection.runtimeProtocol}://${connection.runtimeHost}`}
                style={
                  StringUtils.equalsIgnoreCase(
                    viewportDirection,
                    Enums.MobileDirection.VERTICAL
                  )
                    ? {
                        width: "100%",
                        height: "99%",
                      }
                    : { width: "105%", height: "98%" }
                }
                title="Sirius"
              ></iframe>
            </div>
            {renderDevicebtn()}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileDevice;
