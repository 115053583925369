/* eslint-disable jsx-a11y/alt-text */
import { TextField as MInput } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import StringUtils from "components/common/utils/StringUtils";
import * as Fa from "react-icons/fa";
// import * as Di from "images/icon";
import { faBrandIcon } from "components/builder/BrandIcons";
import { debounce } from "lodash";
import { Tab, Tabs } from "react-bootstrap";

const IconPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
  .btn-icon:hover {
    border: 3px solid #0d6efd !important;
  }
`;

const DaafIconList = [
  "di-zip",
  "di-admin",
  "di-angle-double-down",
  "di-angle-double-left",
  "di-angle-double-right",
  "di-angle-double-up",
  "di-angle-down",
  "di-angle-left-b",
  "di-angle-right-b",
  "di-angle-up",
  "di-anniversary",
  "di-arrow-down",
  "di-arrow-down-fill",
  "di-arrow-right-fill",
  "di-arrow-up",
  "di-auth",
  "di-bell",
  "di-bi",
  "di-board-divide",
  "di-board-list",
  "di-board-topdivide",
  "di-button-active",
  "di-calender",
  "di-calender-big",
  "di-cancel-small-yellow",
  "di-cancle",
  "di-clipboard-gray",
  "di-clock",
  "di-close",
  "di-comm",
  "di-company",
  "di-confirm",
  "di-contact",
  "di-crm",
  "di-current",
  "di-customer",
  "di-delete",
  "di-delete-small-red",
  "di-delivery",
  "di-document",
  "di-dollar",
  "di-donut-chart",
  "di-dot",
  "di-download",
  "di-employees",
  "di-erp",
  "di-erp-logo",
  "di-export",
  "di-export-box",
  "di-fi",
  "di-file",
  "di-file-arrow-up",
  "di-files",
  "di-file-search",
  "di-filled-01",
  "di-filled-02",
  "di-filled-03",
  "di-filled-04",
  "di-flag-china",
  "di-flag-jap",
  "di-flag-kor",
  "di-flag-spanish",
  "di-flag-usa",
  "di-folder",
  "di-folder-big",
  "di-folder-open",
  "di-form",
  "di-graph",
  "di-guide",
  "di-guide-",
  "di-help",
  "di-home",
  "di-home-small",
  "di-hr",
  "di-im",
  "di-img-red",
  "di-list",
  "di-list-up",
  "di-mail",
  "di-mail-fill",
  "di-mail-send",
  "di-mail-send-schedule",
  "di-mm",
  "di-moving",
  "di-noresults",
  "di-notice",
  "di-office",
  "di-office-excel",
  "di-office-ppt",
  "di-office-ppt-purple",
  "di-office-ppt-red",
  "di-office-word",
  "di-open-main-big",
  "di-paperclip",
  "di-papers",
  "di-pdf",
  "di-pencil",
  "di-personal",
  "di-plus",
  "di-plus-circle_bright",
  "di-preview",
  "di-primary",
  "di-print",
  "di-quick-mail",
  "di-quick-organization",
  "di-register",
  "di-resend-big",
  "di-save",
  "di-sd",
  "di-search-big",
  "di-select-caret",
  "di-service",
  "di-settings",
  "di-share",
  "di-star",
  "di-star-blue-fill",
  "di-state-failed",
  "di-state-success",
  "di-statistics",
  "di-status-work",
  "di-tiimeline",
  "di-timeline-order",
  "di-timeline-product",
  "di-timeline-ship",
  "di-trame",
  "di-tree-group",
  "di-user",
  "di-vacation",
  "di-vacation-pen",
  "di-vertical-dot_blue",
  "di-view-cube",
];

/**
 * IconPopup : textarea을 확장해서 입력할 Popup
 *
 * 2022.03.11: init: songe.park
 * 2022.03.18: json, java등 다양한 언어 사용 가능하도록 수정
 *
 * @param {*} props
 * @returns value
 */
const IconPopup = (props) => {
  const { title, callbackFnc, defaultValue } = props;
  const [selectedTab, setSelectedTab] = useState("FaIcon");
  const [DiIconList, setDiIconList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchIconList, setSearchIconList] = useState(
    Object.keys(Fa).filter((item, index) => {
      if (!faBrandIcon.includes(item)) {
        return item;
      }
    })
  );

  useEffect(() => {
    initPopup();
  }, []);

  //initPopup: popup open 하고 실행
  const initPopup = () => {
    //아이콘 세팅
    const svgs = importDiIcon(
      require.context("images/daafIcon", false, /\.svg$/)
    );

    setDiIconList(svgs);
  };

  // importAll 함수로 특정 경로의 모든 SVG를 로드
  function importDiIcon(folderFiles) {
    let images = folderFiles.keys().map((item, index) => {
      return {
        name: item.replace("./", "").split("/").pop().replace(".svg", ""),
        src: folderFiles(item),
      };
    });

    //중복제거 & 정렬
    return [...new Set(images.map(JSON.stringify))]
      .map(JSON.parse)
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
  }

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let value = e.target.id || e.currentTarget.id;
    if (StringUtils.isEmpty(value)) value = null;
    if (String(value).startsWith("di-")) {
      value = `di ${value}`;
    }
    if (callbackFnc != null) {
      callbackFnc.call(this, value);
    }

    // Popup.textChange
    Popup.close();
  };

  // 검색할 때 debounce를 사용
  const debounceSearchIcon = useMemo(
    () =>
      debounce((value) => {
        setSearchTerm(value);
      }, 300),
    []
  );

  const onChange = (e) => {
    //setsearchText(e.target.value);
    debounceSearchIcon(e.target.value);
  };

  return (
    <IconPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="h-full">
          <div className="row mb-3" style={{ display: "flex" }}>
            <div className="col">
              <MInput
                id="iconId"
                label="Icon ID"
                type="text"
                fullWidth
                size="small"
                className="xmall-input"
                color="primary"
                placeholder="Icon ID"
                onChange={onChange}
                // onKeyPress={onKeyPress}
              />
            </div>
          </div>

          <Tabs
            defaultActiveKey={"FaIcon"}
            value={selectedTab}
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
          >
            <Tab eventKey="FaIcon" title={"Font Awsome Icon"}>
              <div className="row icon-popup-body">
                <IconsButtonWrapper value={"No Icon"}>
                  <button
                    id={""}
                    onClick={closePopup}
                    className="btn btn-icon"
                  ></button>
                </IconsButtonWrapper>
                {searchIconList.map((icon, index) => {
                  //let iconKey = Object.keys(Fa)[index];
                  let IconTag = Fa[icon];
                  let value = icon
                    .replace(/([a-z])([0-9])/g, "$1-$2") //소문자 숫자   사이에 "-" ex) fa500px > fa-500px
                    .replace(/([a-z])([A-Z])/g, "$1-$2") //소문자 대문자 사이에 "-" ex) faSearch > fa-search
                    .replace(/[\s_]+/g, "-")
                    .toLowerCase();
                  if (!String(value).includes(searchTerm)) {
                    return <></>;
                  }
                  return (
                    <IconsButtonWrapper key={value} value={value}>
                      <button
                        id={value}
                        onClick={closePopup}
                        className="btn btn-icon"
                      >
                        <h2>{<IconTag />}</h2>
                      </button>
                    </IconsButtonWrapper>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="DiIcon" title={"DaaF Icon"}>
              <div className="row icon-popup-body">
                <IconsButtonWrapper value={"No Icon"}>
                  <button
                    id={""}
                    onClick={closePopup}
                    className="btn btn-icon"
                  ></button>
                </IconsButtonWrapper>
                {DaafIconList.map((iconNm, index) => {
                  return (
                    <IconsButtonWrapper key={index} value={iconNm}>
                      <button
                        // className={`btn btn-icon icon icon-${svg.name}`}
                        className={`btn btn-icon icon di ${iconNm}`}
                        onClick={closePopup}
                        key={index}
                        id={iconNm}
                        style={{ fontSize: "42px" }}
                      ></button>
                    </IconsButtonWrapper>
                  );
                })}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="fixed"></Modal.Footer>
      </Modal>
    </IconPopupStyle>
  );
};

export default IconPopup;

const IconsButtonWrapper = ({ value, ...props }) => {
  return (
    <div
      className="col col-1"
      key={StringUtils.getUuid()}
      style={{
        textAlign: "center",
      }}
    >
      {props.children}
      <p>{value}</p>
    </div>
  );
};
