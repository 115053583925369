import React, { useContext, useEffect, useState } from "react";

import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
import DraggableComponent from "../ui/DraggableComponent";
import withElementComponentTab from "../ui/BaseElementComponentTab";
import TrdTableField from "entity/TrdTableField.entity";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import { AppContext } from "components/common/AppContextProvider";
import UITemplateHelper from "../ui/editor/helper/UITemplateHelper";
import produce from "immer";
import { MdSearch } from "react-icons/md";
import { PropertiesHeader } from "../ui/editor/theme/common/UIComponentStyle";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { useSelector } from "react-redux";

const MobileElementComponentTab = ({
  trdTableList,
  workflowEntityList,
  dataModelEntityFieldRef,
  workflowEntityRef,
  fromComponentRef,
  prevWorkflowUidList,
  prevTableListRef,
  dataTypeRef,
  inputFormOptionRef,
  ElementComponentListAccodion,
  COMPONENT_NUM,
  addGridComponent,
  addFormComponent,
  addElement,
  filterAvailableTrdFieldListByDataModelField,
  init,
  getDataStudioElementByDataModel,
  getDataStudioElementByWorkflow,
  ...props
}) => {
  const appContext = useContext(AppContext);

  const { output } = useSelector((state) => state.outputUI);

  const [dmElementComponentList, setDMElementComponentList] = useState([]); //datamodel render
  const [workflowTableList, setWorkflowTableList] = useState([]); //workflow render

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    //output이 바뀔때마다 데이터모델과 Workflow 확인
    if (!ObjectUtils.isEmpty(output)) {
      if (!ObjectUtils.isEmpty(output.page.propertyValue)) {
        getDataStudioElementByDataModel(output);
      }
      getDataStudioElementByWorkflow(output);
    }
  }, [output]);

  useEffect(() => {
    //데이터 모델이 확인되어 tableList가 바뀌면 엔티티를 불러와 세팅
    const datamodelElementObjList = setDatamodelElementComponent();
    setDMElementComponentList(datamodelElementObjList);
  }, [trdTableList]);

  useEffect(() => {
    // 워크플로우가 추가 및 변경되어 return entity가 바뀌면 새로 세팅
    const workflowElementObjList = setWorkflowElementComponent();
    setWorkflowTableList(workflowElementObjList);
  }, [workflowEntityList]);

  /**
   * 카드레이아웃 컴포넌트 생성 함수
   * @param {Object} param
   * @param {Array} param.elementList
   * @param {Object} param.table
   * @param {String} param.type
   */
  const addCardLayoutComponent = ({
    elementList,
    table,
    type = "datamodel",
  }) => {
    const cardLayoutComponent = UITemplateHelper.getComponentInfo(
      appContext,
      "layout/CardLayout"
    );
    const entityCardLayoutTemplate = produce(cardLayoutComponent, (draft) => {
      const defaultProperty = {
        ...JSON.parse(cardLayoutComponent.defaultProperty),
      };
      if (StringUtils.equalsIgnoreCase(type, "datamodel")) {
        defaultProperty.searchTp = "ENTITY";
        defaultProperty.entityId = table.entityId;
        defaultProperty.dataInitialLoad = true;
        defaultProperty.identifier = table.entityId;
      } else if (StringUtils.equalsIgnoreCase(type, "workflow")) {
        defaultProperty.searchTp = "WORKFLOW";
        defaultProperty.serviceUid = table.serviceUid;
        defaultProperty.serviceId = table.serviceId;
        defaultProperty.serviceName = table.serviceName;
        defaultProperty.entityId = table.entityId;
        defaultProperty.dataInitialLoad = true;
        defaultProperty.identifier = table.entityVariable;
      }

      defaultProperty.fromCompId = table.fromCompId;
      //entityVariable 추가
      defaultProperty.entityVariable = table.entityVariable;

      draft.defaultProperty = JSON.stringify(defaultProperty);

      // Workflow 엔티티가 설정된 경우
      // DS Element 인지 여부
      draft.editorAttr = JSON.stringify({
        ...JSON.parse(draft.editorAttr),
        entityFieldList: table.entityFieldList,
      });
      draft.viewerAttr = JSON.stringify({
        ...JSON.parse(draft.viewerAttr),
        isDataStudioElement: true,
      });
    });
    //카드레이아웃 항목 추가
    elementList.push(entityCardLayoutTemplate);
  };

  /**
   * Datamodel Element component 세팅
   * @override
   * @returns
   */
  const setDatamodelElementComponent = () => {
    const TableElementList = [];
    // Data Model테이블 순회
    for (const table of trdTableList) {
      const trdTableField = filterAvailableTrdFieldListByDataModelField(
        dataModelEntityFieldRef.current[table.tablePhysicalNm],
        table
      );
      //DataModel에 정의된 필드만 리턴시킨다.
      const tableObj = {
        tableNm: table.tablePhysicalNm,
        layoutComponents: [],
        elementList: [],
      };

      //카드 레이어 추가
      addCardLayoutComponent({
        elementList: tableObj.layoutComponents,
        table: {
          ...table,
          entityId:
            dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId,
          dataModelId:
            dataModelEntityFieldRef.current[table.tablePhysicalNm].dataModelId,
          entityFieldList: trdTableField,
        },
      });

      //폼항목 추가
      addFormComponent({
        elementList: tableObj.layoutComponents,
        table: {
          ...table,
          identifier:
            dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId,
          tableMstId: table.tableMstId,
          dataModelEntityId: tableObj.tablePhysicalNm,
        },
      });

      if (!ArrayUtils.isEmpty(trdTableField)) {
        for (const field of trdTableField) {
          const tableField = new TrdTableField(field);
          const element = tableField.getElement();
          const domain = element.getDomain();

          //데이터 type이 날짜 유형인경우
          if (
            StringUtils.includesIgnoreCase(domain.getDataType(), [
              "date",
              "dateTime",
              "timestamp",
            ])
          ) {
            const element = addElement(
              COMPONENT_NUM.singleDatePickerComponent,
              field,
              dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId
            );
            tableObj.elementList.push(element);
          } else if (
            StringUtils.equalsIgnoreCase(
              element.getEntryDisType() || domain.getEntryDisType(),
              "I"
            )
          ) {
            const element = addElement(
              COMPONENT_NUM.inputData,
              field,
              dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId
            );
            tableObj.elementList.push(element);
          } else if (
            StringUtils.equalsIgnoreCase(
              element.getEntryDisType() || domain.getEntryDisType(),
              "C"
            )
          ) {
            tableObj.elementList.push(
              addElement(
                COMPONENT_NUM.selectData,
                field,
                dataModelEntityFieldRef.current[table.tablePhysicalNm].entityId
              )
            );
          }
        }
      }
      TableElementList.push(tableObj);
    }

    return TableElementList;
  };

  /**
   * Workflow Element Component 세팅
   * @returns
   */
  const setWorkflowElementComponent = () => {
    const TableElementList = [];
    for (const table of workflowEntityList) {
      const tableObj = {
        tableNm: table.entityVariable,
        elementList: [],
        layoutComponents: [],
        fromComponent: table.fromComponent,
        targetCompId: table.compId,
        serviceUid: table.serviceUid,
      };

      //카드 레이어 추가
      addCardLayoutComponent({
        elementList: tableObj.layoutComponents,
        table: {
          ...table,
          identifier: table.entityVariable,
          entityFieldList: table.entityFieldList,
          fromCompId: tableObj.targetCompId,
        },
        type: "workflow",
      });

      // Form
      addFormComponent({
        elementList: tableObj.layoutComponents,
        table: {
          ...table,
          identifier: table.entityVariable,
          entityFieldList: table.entityFieldList,
          fromCompId: tableObj.targetCompId,
        },
      });
      if (!ArrayUtils.isEmpty(table.entityFieldList)) {
        for (const field of table.entityFieldList) {
          // dataType이 날짜인경우
          if (
            StringUtils.includesIgnoreCase(field.element?.domain?.dataType, [
              "date",
              "dateTime",
              "timestamp",
            ])
          ) {
            const element = addElement(
              COMPONENT_NUM.singleDatePickerComponent,
              field.element,
              table.entityVariable
            );
            tableObj.elementList.push(element);
          } else if (
            StringUtils.equalsIgnoreCase(
              field.element?.entryDisType ||
                field.element?.domain?.entryDisType,
              "I"
            )
          ) {
            tableObj.elementList.push(
              addElement(
                COMPONENT_NUM.inputData,
                field.element,
                table.entityVariable,
                field
              )
            );
          } else if (
            StringUtils.equalsIgnoreCase(
              field.element?.entryDisType ||
                field.element?.domain?.entryDisType,
              "C"
            )
          ) {
            tableObj.elementList.push(
              addElement(
                COMPONENT_NUM.selectData,
                field.element,
                table.entityVariable,
                field
              )
            );
          } else {
            //WF에서 임의로 추가된 컬럼들
            tableObj.elementList.push(
              addElement(
                COMPONENT_NUM.inputData,
                field.element,
                table.entityVariable,
                field
              )
            );
          }
        }
      }
      TableElementList.push(tableObj);
    }

    return TableElementList;
  };

  const renderSearchHeader = () => {
    return (
      <PropertiesHeader>
        <InputGroup style={{ margin: "0 10px" }} size="sm">
          <Form.Control placeholder="검색어를 입력하세요" />
          <Button className="light-font-color" onClick={() => {}}>
            <MdSearch />
          </Button>
        </InputGroup>
      </PropertiesHeader>
    );
  };

  return (
    // <>스크롤바</>
    <div
      className="service-component-panel component-panel"
      style={{ height: props.height, overflow: "auto" }}
    >
      {/* {renderSearchHeader()} */}
      <div className="element-component-list-wrapper custom-scroll">
        <Accordion
          defaultActiveKey={[0, 1, 2, 3]}
          alwaysOpen
          style={{
            height: `calc(100vh - 50px - 34px - 42px)`,
          }}
        >
          {dmElementComponentList?.map((elementTableData, index) => {
            return (
              <Accordion.Item
                eventKey={"dataModel" + elementTableData.tableNm}
                key={index}
              >
                <Accordion.Header>
                  {"Data Model - " + elementTableData.tableNm}
                </Accordion.Header>
                <Accordion.Body>
                  {elementTableData.layoutComponents.map((eData, _index) => {
                    return <DraggableComponent key={_index} data={eData} />;
                  })}

                  <ElementComponentListAccodion
                    elementTableData={elementTableData}
                    index={index}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
          {workflowTableList?.map((elementTableData, index) => {
            return (
              <Accordion.Item
                eventKey={
                  "workflow" +
                  elementTableData.targetCompId +
                  elementTableData.tableNm
                }
                key={index}
              >
                <Accordion.Header>
                  {elementTableData.fromComponent +
                    " :  [WF] " +
                    elementTableData.serviceUid +
                    " > " +
                    elementTableData.tableNm}
                </Accordion.Header>
                <Accordion.Body>
                  {elementTableData.layoutComponents.map((eData, _index) => {
                    return <DraggableComponent key={_index} data={eData} />;
                  })}
                  <ElementComponentListAccodion
                    elementTableData={elementTableData}
                    index={index}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default withElementComponentTab(MobileElementComponentTab);
