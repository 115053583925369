import React, { memo } from "react";
import { useRef } from "react";
import { Handle, Position } from "reactflow";

const TrackingNode = memo(({ data }) => {
  const elementRef = useRef();

  return (
    <>
      <div
        ref={elementRef}
        style={{
          width: "30px",
          height: "5px",
        }}
      />
      <Handle type="target" position={Position.Right} />
      <Handle type="source" position={Position.Right} />
    </>
  );
});

export default TrackingNode;
