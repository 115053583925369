import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";

import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AppService from "services/common/AppService";

function AppModuleRegisterPopup({
  appList,
  selectedAppId,
  callBack,
  module,
  ...props
}) {
  const [appId, setAppId] = useState(selectedAppId || "");
  const [moduleCd, setModuleCd] = useState("");
  const [moduleNm, setModuleNm] = useState("");
  const [moduleOptionIn, setModuleOptionIn] = useState("*");
  const [moduleOptionNotIn, setModuleOptionNotIn] = useState("");
  const [isSharedYn, setIsSharedYn] = useState("N");

  useEffect(() => {
    if (module) {
      const mOption = JSON.parse(module.moduleOption);
      setModuleCd(module.moduleCd);
      setModuleNm(module.moduleNm);
      setModuleOptionIn(mOption.in.join(","));
      setModuleOptionNotIn(mOption.notIn.join(","));
      setIsSharedYn(module.isSharedYn);
      setAppId(module.appId);
    }
  }, [module]);

  const onSaveAppModule = (e) => {
    if (e) e.preventDefault();

    if (StringUtils.isEmpty(appId))
      return Message.alert(
        "Please Select Application.",
        Enums.MessageType.INFO
      );
    if (StringUtils.isEmpty(moduleCd))
      return Message.alert("Please enter module code.", Enums.MessageType.INFO);
    if (StringUtils.isEmpty(moduleNm))
      return Message.alert("Please enter module name.", Enums.MessageType.INFO);
    if (
      module?.moduleCd &&
      !StringUtils.equalsIgnoreCase(moduleCd, module.moduleCd)
    ) {
    }
    let _in = moduleOptionIn;
    let _notIn = moduleOptionNotIn;
    if (StringUtils.isEmpty(_in)) {
      _in = "*";
    }
    let moduleOption = {
      in: _in.split(","),
      notIn: _notIn.split(","),
    };

    const body = {
      appId,
      moduleCd,
      moduleNm,
      moduleOption: JSON.stringify(moduleOption),
      isSharedYn,
    };

    if (
      module?.moduleCd &&
      !StringUtils.equalsIgnoreCase(moduleCd, module.moduleCd)
    ) {
      Message.confirm(
        "The module code has been updated. The module will be saved with the new module code.",
        () => {
          AppService.saveAppModule(body, (res) => {
            Message.alert(res.message, Enums.MessageType.SUCCESS);
            Popup.close();
            if (callBack) callBack();
          });
        }
      );
    } else {
      AppService.saveAppModule(body, (res) => {
        Message.alert(res.message, Enums.MessageType.SUCCESS);
        Popup.close();
        if (callBack) callBack();
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title="Add New Module" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Group className="mb-3">
            <Form.Label className="required">Select Application</Form.Label>
            <Form.Select
              value={appId}
              onChange={(e) => setAppId(e.currentTarget.value)}
            >
              <option value="">Select</option>
              {appList.map((app) => {
                return (
                  <option value={app.appId} key={app.appId}>
                    {app.appNm}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">Module Code</Form.Label>
            <Form.Control
              value={moduleCd}
              onChange={(e) => setModuleCd(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">Module Name</Form.Label>
            <Form.Control
              value={moduleNm}
              onChange={(e) => setModuleNm(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">
              DataModel Search Prefix{" "}
            </Form.Label>
            <Row className="gy-1">
              <Col xs={4}>
                <Form.Control disabled defaultValue={"Include"} />
              </Col>
              <Col xs={8}>
                <Form.Control
                  value={moduleOptionIn}
                  onChange={(e) => setModuleOptionIn(e.currentTarget.value)}
                  placeholder={"Z,P,H ... / Enter * will search all "}
                />
              </Col>
              <Col xs={4}>
                <Form.Control disabled defaultValue={"Exclude"} />
              </Col>
              <Col xs={8}>
                <Form.Control
                  value={moduleOptionNotIn}
                  onChange={(e) => setModuleOptionNotIn(e.currentTarget.value)}
                  placeholder={"Z,P,H ... "}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">Share Module Status</Form.Label>
            <Row className="gy-1">
              <Col xs={8}></Col>
              <Col xs={4}>
                <BootstrapSwitchButton
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  width={"80"}
                  checked={isSharedYn === "Y" ? true : false}
                  id={"dataLimit"}
                  onChange={(flag) => {
                    setIsSharedYn(flag ? "Y" : "N");
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSaveAppModule}>
          Save
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppModuleRegisterPopup;
