import { TextField as MInput } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import NamingUtils from "components/common/utils/NamingUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import Element from "entity/Element.entity";
import { camelCase } from "lodash";
import { useEffect, useState } from "react";
import { Button, Col, Form, ModalFooter, Row, Table } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { MdDelete } from "react-icons/md";
import DataModelService from "services/datamodel/DataModelService";
import TrdService from "services/trd/TrdService";
import styled from "styled-components";

const ScrollTBody = styled.tbody`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const CardLayoutSettingPopup = ({
  componentInfo,
  defaultValues,
  componentContext,
  templateComponents,
  output,
  ...props
}) => {
  const [inputValue, setInputValue] = useState({
    row: 1,
    col: 3,
  });
  const [fieldList, setFieldList] = useState([]); //받아온 원본 데이터
  const [dropTableData, setDropTableData] = useState([]); // 실재 그려질 2차원배열 테이블 형식의 데이터

  const [filteredList, setFilteredList] = useState([]); // 왼쪽 엔티티 리스트로 그릴 데이터

  // init
  useEffect(() => {
    // 데이터모델인 경우 identifier는 숫자데이터
    if (!isNaN(componentInfo.propertyValue.identifier)) {
      const dataModelEntityId = componentInfo.propertyValue.identifier;
      // 데이터 모델인 경우 entity field들을 조회해서 세팅
      DataModelService.getDataModelEntityFieldList(
        { entityId: dataModelEntityId },
        async (result) => {
          if (ArrayUtils.isEmpty(result.data)) {
            Message.alert(
              "There is no column information for the selected Data Entity.",
              Enums.MessageType.ERROR
            );
          } else {
            const elementIdObj = await getDataModelTrdFieldList();

            result.data.forEach((field) => {
              field.elementId = elementIdObj[field.columnNm].elementId;
              field.fieldDefaultValue =
                elementIdObj[field.columnNm].fieldDefaultValue;
              field.element = elementIdObj[field.columnNm].element;
              // 참조 currency 정보가 있는경우에 추가
              if (elementIdObj[field.columnNm].refFieldId) {
                field.refFieldId = elementIdObj[field.columnNm].refFieldId;
                field.refTableId = elementIdObj[field.columnNm].refTableId;
              }
            });
            setFieldList(result.data);
            setFilteredList(result.data);
          }
        }
      );
    } else if (!ArrayUtils.isEmpty(componentInfo.editorAttr.entityFieldList)) {
      // workflow의 경우 propertyValue의 entityFieldList를 참조해서 세팅
      // 참조 fieldId가 있는 경우 해당 entity에는 refFieldId, refTableId를 추가로 넣어주는 로직을 추가해야함. tableFieldIdList : []
      const refFieldIdList = componentInfo.editorAttr.entityFieldList.map(
        (item) => {
          return item.refFieldId;
        }
      );
      TrdService.getTableFieldList({
        tableFieldIdList: refFieldIdList,
        isWorkflow: true,
      }).then((res) => {
        if (res.isError) {
          Message.alert(
            "Can not found Reference List.",
            Enums.MessageType.ERROR
          );
          setFieldList(componentInfo.editorAttr.entityFieldList);
          setFilteredList(componentInfo.editorAttr.entityFieldList);
        } else {
          if (!ArrayUtils.isEmpty(res.data)) {
            const hasRefFieldIds = res.data;
            componentInfo.editorAttr.entityFieldList.forEach((item) => {
              const trdField = hasRefFieldIds.find((field) =>
                StringUtils.equalsIgnoreCase(field.fieldId, item.refFieldId)
              );
              if (!ObjectUtils.isEmpty(trdField)) {
                item.refTableId = trdField.trdTableMst.tableMstId;
                item.refFieldNm = trdField.element.elementCd;
                item.currencyCodeColumn = camelCase(trdField.element.elementCd);
                item.refFieldTableNm = trdField.trdTableMst.tablePhysicalNm;
              }
            });
            setFieldList(componentInfo.editorAttr.entityFieldList);
            setFilteredList(componentInfo.editorAttr.entityFieldList);
          } else {
            setFieldList(componentInfo.editorAttr.entityFieldList);
            setFilteredList(componentInfo.editorAttr.entityFieldList);
          }
        }
      });
    }
    tableDataSetting(inputValue.row, inputValue.col);
  }, []);

  /**
   * elementCd : elementId 의 keyValue Obj로 뽑아내는 함수
   */
  const getDataModelTrdFieldList = async () => {
    const tableMstId = componentInfo.propertyValue.tableMstId;
    const trdTable = await TrdService.getTableInfo({
      tableMstId,
    });
    let elementIdList = {};
    for (const tableField of trdTable.data.trdTableField) {
      elementIdList[tableField.element.elementCd] = {
        elementId: tableField.element.elementId,
        fieldDefaultValue: tableField.fieldDefaultValue,
        element: tableField.element,
        refFieldId: tableField.refFieldId,
        refTableId: tableField.refTableId,
      };
    }
    return elementIdList;
  };

  /**
   * row 와 col 길이를 입력받아 table데이터를 세팅하는 함수
   * @param {Number} row
   * @param {Number} col
   */
  const tableDataSetting = (row, col) => {
    const table = [...dropTableData];
    let settingTable = Array.from({ length: row }, () =>
      Array.from({ length: col })
    );
    for (let rowIdx = 0; rowIdx < row; rowIdx++) {
      for (let colIdx = 0; colIdx < col; colIdx++) {
        if (table.length > rowIdx) {
          if (table[0].length > colIdx) {
            settingTable[rowIdx][colIdx] = table[rowIdx][colIdx];
          }
        }
      }
    }
    setDropTableData(settingTable);
  };

  /**
   * table에서 삭제버튼 이벤트
   */
  const deleteTableData = (rowIdx, colIdx) => {
    const settingTable = [...dropTableData];
    settingTable[rowIdx][colIdx] = "";
    setDropTableData(settingTable);
  };

  const closePopup = () => {
    if (props.callbackFn) {
      const cardLayout = createFormComponent();
      props.callbackFn(cardLayout);
      Popup.close();
    }
  };

  /**
   * Popup에 설정한 option을 기준으로 그릴 Form Component를 생성하는 함수
   */
  const createFormComponent = () => {
    /**
     * 1. form layout type : 검색 or 입력 form
     * 1.1. 입력 form 일때 has title, has button
     */
    let newCardLayout =
      UITemplateHelper.getCardLayoutTemplate(templateComponents);
    // 하위 component dnd 인식을 위해 indentifier, isDataStudioElement 추가
    newCardLayout.propertyValue.identifier =
      componentInfo.propertyValue.identifier;
    newCardLayout.propertyValue.isDataStudioElement =
      componentInfo.viewerAttr.isDataStudioElement;
    // dataModel로 생성된 form인 경우 데이터모델 세팅
    if (!isNaN(componentInfo.propertyValue.identifier))
      newCardLayout.propertyValue.dataModelEntityId =
        componentInfo.propertyValue.identifier;

    newCardLayout.propertyValue.formType = Enums.FormType.SAVE;
    newCardLayout.propertyValue.id = NamingUtils.getComponentId(
      Enums.ComponentType.CARD_LAYOUT,
      output.page,
      newCardLayout.propertyValue
    );
    // 2. row, col : 선택한 그리드 row를 순서대로 col에 세팅
    createInputFormRowCol(newCardLayout);

    return newCardLayout;
  };

  /**
   * 입력 form의 상세데이터를 파라미터로 받은 newForm에 추가하는 함수
   * @param {Form} newForm
   */
  const createInputFormRowCol = (newForm) => {
    //container
    let newContainer =
      UITemplateHelper.getContainerTemplate(templateComponents);
    newContainer.propertyValue.className = "alpha-grid border";

    //Row
    for (let i = 0; i < inputValue.row; i++) {
      let newRow = UITemplateHelper.getRowTemplate(templateComponents);
      for (let j = 0; j < inputValue.col; j++) {
        let newCol = UITemplateHelper.getColTemplate(templateComponents);
        const data = { ...dropTableData[i][j] };
        if (!ObjectUtils.isEmpty(data)) {
          const newComponent = dataTemplate(data);
          let newInput = UITemplateHelper.getInputTemplate(templateComponents);
          newInput.propertyValue.layoutType = "E"; //입력 form : E , 검색 form : D
          newInput.propertyValue.labelWidth = "4";
          newCol.child.push(newComponent);
        }
        newRow.child.push(newCol);
      } //end of col

      newContainer.child.push(newRow);
    } //end of row
    newForm.child.push(newContainer);
  };

  /**
   * Data Type에 따라 input, select, singleDatePicker 중 하나로 세팅
   * @param {Object} data  table[row][col] data
   * @returns {component}
   */
  const dataTemplate = (data) => {
    let component = null;
    // domain에서 combo 데이터로 삽입하겠다고 정의시
    if (StringUtils.equalsIgnoreCase(data.element?.domain?.entryDisType, "C")) {
      component = UITemplateHelper.getSelectTemplate(templateComponents);
      component.propertyValue.idColumn = "id";
      component.propertyValue.textColumn = "text";
      component.propertyValue.comboType = "basic";

      const element = new Element(data.element);
      const entryType = element.getEntryType();
      // entryType이 entry = possibleEntry / table = table참조
      if (entryType === "entry") {
        component.propertyValue.searchTp = "STATIC";
        const comboMapping = (possibleEntryList) => {
          const data = possibleEntryList.map((item) => {
            return { id: item.entryValue, text: item.entryText };
          });
          return !ArrayUtils.isEmpty(data) && data;
        };
        const possibleEntryList = comboMapping(element.getPossibleEntryList());
        component.propertyValue.comboItems = possibleEntryList;
      } else if (entryType === "table") {
        component.propertyValue.searchTp = "REF";
        component.propertyValue.entryRefTable = element.getEntryRefTable();
        component.propertyValue.entryRefWhere = element.getEntryRefWhere();
        component.propertyValue.idColumn = element.getEntryRefKey();
        component.propertyValue.textColumn = element.getEntryRefValue();
        component.propertyValue.comboUrl =
          "/common/builder/runtime/tableRef/readCombo.do";
      }
    } else if (
      StringUtils.equalsIgnoreCase(data.option?.dataType, "date") ||
      StringUtils.equalsIgnoreCase(data.columnType, "date")
    ) {
      // dataType이 date 인 경우 singleDatePicker 템플릿
      const datePickerInfo = componentContext.getComponentInfo("B", 76);
      const datePickerComponent = {
        compId: "",
        type: "",
      };
      datePickerComponent.compId = `${StringUtils.substringAfter(
        datePickerInfo.componentClass,
        "/"
      )}-${StringUtils.getUuid()}`;
      datePickerComponent.type = datePickerInfo.componentType;
      datePickerComponent.baseCompId = datePickerInfo.componentDtlId;
      datePickerComponent.propertyValue =
        JsonUtils.parseJson(datePickerInfo.defaultProperty) || {};
      datePickerComponent.editorAttr = JsonUtils.parseJson(
        datePickerInfo.editorAttr
      );
      datePickerComponent.viewerAttr = JsonUtils.parseJson(
        datePickerInfo.viewerAttr
      );

      if (NamingUtils.hasIdNamingRule(datePickerInfo.componentType)) {
        datePickerComponent.propertyValue.id = NamingUtils.getComponentId(
          datePickerComponent.type,
          output.page,
          datePickerComponent.propertyValue
        );
      }
      component = datePickerComponent;
    } else {
      component = UITemplateHelper.getInputTemplate(templateComponents);
      convertDataType(component.propertyValue, data);
      if (
        (data.option?.columnType?.includes("(") ||
          data.columnType?.includes("(")) &&
        StringUtils.isEmpty(component.propertyValue.numberType)
      ) {
        component.propertyValue.maxlength =
          data.option?.columnType.slice(
            data.option.columnType.indexOf("(") + 1,
            data.option.columnType.indexOf(")")
          ) ||
          data.columnType?.slice(
            data.columnType.indexOf("(") + 1,
            data.columnType.indexOf(")")
          );
      }
    }
    component.propertyValue.labelWidth = "4";
    component.propertyValue.id = data.fieldId || data.physFieldNm;
    component.propertyValue.formLabel = data.fieldNm || data.logFieldNm;
    // workflow return data에서 updatableYn은 N일때 readonly
    component.propertyValue.isReadonly =
      (data.option?.updatableYn &&
        !StringUtils.equalsIgnoreCase(data.option?.updatableYn, "Y")) ||
      StringUtils.equalsIgnoreCase(data.readonlyYn, "Y");
    component.propertyValue.isRequired =
      StringUtils.equalsIgnoreCase(data.option?.requiredYn, "Y") ||
      StringUtils.equalsIgnoreCase(data.requiredYn, "Y");

    component.propertyValue.dataBinding = data.id;
    component.propertyValue.default = data.value || data.fieldDefaultValue;
    component.propertyValue.elementId = data.elementId;
    component.propertyValue.refTableId = data.refTableId;
    component.propertyValue.refFieldId = data.refFieldId;
    component.propertyValue.refFieldNm = data.refFieldNm;
    component.propertyValue.currencyCodeColumn = data.currencyCodeColumn;
    component.propertyValue.refFieldTableNm = data.refFieldTableNm;

    return component;
  };

  const convertDataType = (field, data) => {
    // type : text or number or date
    // numberType : 숫자일때만 들어가는 것 === formType
    // element 내부속성이 모두 있는 경우
    if (
      !ObjectUtils.isEmpty(data.element) &&
      !ObjectUtils.isEmpty(data.element.domain) &&
      !ObjectUtils.isEmpty(data.element.domain.dataType)
    ) {
      const dataType = Enums.convertBuilderDataType(
        data.element.domain.dataType
      );
      if (
        StringUtils.equalsIgnoreCase(dataType, "number") &&
        StringUtils.includes(data.element.domain.formType, [
          "0", //금액
          "1", //수량
          "2", //단가
          "3", //환율
        ])
      ) {
        field.numberType = data.element.domain.formType.toString();
        field.integerPart =
          parseInt(data.element.domain.dataLength) -
          parseInt(data.element.domain.decimals);
        field.decimalPart = data.element.domain.decimals;
      }
      field.type = dataType;
    } else {
      // 임의 생성 column의 경우
      if (StringUtils.equalsIgnoreCase(data.option.dataType, "number")) {
        field.type = "number";
      } else if (StringUtils.equalsIgnoreCase(data.option.dataType, "date")) {
        field.type = "datetime";
      } else {
        field.type = data.option.dataType || "text";
      }
    }
  };

  /**
   * Drag flag
   */
  const draggableItem = { FIELD: "field", IN_TABLE: "table" };

  /**
   * Drag 할 셀 랜더
   * @param {*} param0
   * @returns
   */
  const DraggableCell = ({ data, filedJson }) => {
    const [{ isDragging }, drag] = useDrag({
      type: draggableItem.FIELD,
      item: { data, type: draggableItem.FIELD }, // drag 로 넘기는 데이터
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });
    return (
      <td
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          width: "100%",
        }}
      >
        {data.fieldId || data.physFieldNm}
      </td>
    );
  };

  /**
   * Drop 될 셀 랜더
   * @param {*} param0
   * @returns
   */
  const DroppableCell = ({ data, onDrop, rowIndex, colIndex }) => {
    const [{ isOver }, drop] = useDrop({
      accept: [draggableItem.FIELD, draggableItem.IN_TABLE], // drop을 허용하는 요소
      drop: (item) => {
        if (item.type === draggableItem.FIELD) {
          // 외부에서 테이블로 드래그 앤 드롭
          onDrop(item.data, rowIndex, colIndex);
        } else if (item.type === draggableItem.IN_TABLE) {
          // 테이블 내부 셀 간 드래그 앤 드롭
          if (item.rowIndex !== rowIndex || item.colIndex !== colIndex) {
            moveCell(item.rowIndex, item.colIndex, rowIndex, colIndex);
            item.rowIndex = rowIndex;
            item.colIndex = colIndex;
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });
    const [{ isDragging }, drag] = useDrag({
      type: draggableItem.IN_TABLE,
      item: { rowIndex, colIndex, type: draggableItem.IN_TABLE },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <td
        ref={(node) => drag(drop(node))}
        style={{
          backgroundColor: isOver && "lightblue",
          verticalAlign: "center",
          minHeight: "40px",
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Col xs={10}>{data}</Col>
          <Col xs={2}>
            {!StringUtils.isEmpty(data) && (
              <Button
                variant="outline-danger"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
                size="sm"
                onClick={() => deleteTableData(rowIndex, colIndex)}
              >
                <MdDelete />
              </Button>
            )}
          </Col>
        </div>
      </td>
    );
  };

  /**
   * Drop시 이벤트
   * @param {Object} droppedData
   * @param {Index} rowIdx
   * @param {Index} colIdx
   */
  const handleDrop = (droppedData, rowIdx, colIdx) => {
    const tempTable = [...dropTableData];
    tempTable[rowIdx][colIdx] = droppedData;
    setDropTableData(tempTable);
  };

  /**
   * 테이블 내부에서 dnd시 이벤트
   * @param {*} fromRow
   * @param {*} fromCol
   * @param {*} toRow
   * @param {*} toCol
   */
  const moveCell = (fromRow, fromCol, toRow, toCol) => {
    const newData = [...dropTableData];
    const temp = newData[fromRow][fromCol];
    newData[fromRow][fromCol] = newData[toRow][toCol];
    newData[toRow][toCol] = temp;

    setDropTableData(newData);
  };

  const filterField = (e) => {
    const filteredString = e.target.value;
    if (StringUtils.isEmpty(filteredString)) {
      setFilteredList(fieldList);
    } else {
      const filter = fieldList.filter((item) =>
        item.fieldId.toLowerCase().includes(filteredString.toLowerCase())
      );
      setFilteredList(filter);
    }
  };

  return (
    <Modal>
      <Modal.Header title="Form Type Select" />
      <Modal.Body>
        <Row className="p-10">
          <Col>
            Please enter the number of rows and columns to configure the Form
            Layout.
          </Col>
        </Row>
        <Row className="row p-10">
          <Col>
            <MInput
              id="row"
              label="Row"
              size="small"
              type="number"
              className="xmall-input"
              value={inputValue.row}
              onChange={(e) => {
                setInputValue({ ...inputValue, row: e.target.value });
                tableDataSetting(e.target.value, inputValue.col);
              }}
            />
          </Col>
        </Row>
        <Row className="p-10">
          <Col>
            <MInput
              id="col"
              label="Column"
              size="small"
              type="number"
              className="xmall-input"
              value={inputValue.col}
              onChange={(e) => {
                setInputValue({ ...inputValue, col: e.target.value });
                tableDataSetting(inputValue.row, e.target.value);
              }}
            />
          </Col>
        </Row>
        {/* 입력 Form 일때만 활성화 + 입력 Form일때만 has 설정을 세팅 */}
        <DndProvider backend={HTML5Backend}>
          <Row className="p-10">
            <Row className="mb-3">
              <Col xs={3}>
                <Form.Label htmlFor="dataModelEntityField">
                  Data Entity Field List
                </Form.Label>
                <Form.Control
                  style={{ marginBottom: "3px", maxWidth: "75%" }}
                  placeholder="Search Field ID"
                  onChange={(e) => filterField(e)}
                />
                {/* form component가 가지고 있는 data */}
                <Table
                  striped
                  hover
                  bordered
                  style={{
                    tableLayout: "fixed",
                    marginBottom: "0px",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    maxWidth: "75%",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <th>Field ID</th>
                    </tr>
                  </thead>
                  <ScrollTBody
                    style={{
                      display: "block",
                      maxHeight: "30vh",
                      overflow: "auto",
                    }}
                  >
                    {filteredList.map((item, idx) => {
                      return (
                        <tr
                          style={{
                            display: "table",
                            borderTop: "0px",
                            width: "100%",
                          }}
                          key={idx}
                        >
                          <DraggableCell data={item} />
                        </tr>
                      );
                    })}
                  </ScrollTBody>
                </Table>
              </Col>
              <Col xs={9}>
                <Form.Label htmlFor="dataModelEntityField">
                  Field List to Apply
                </Form.Label>
                {/* 그려질 form 컴포넌트 table */}
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                    marginBottom: "0px",
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                  }}
                >
                  <tbody>
                    {!ArrayUtils.isEmpty(dropTableData) &&
                      dropTableData.map((row, rowIdx) => {
                        return (
                          <tr key={rowIdx} style={{ height: "40px" }}>
                            {row.map((col, colIdx) => {
                              return (
                                <DroppableCell
                                  key={`${rowIdx}-${colIdx}`}
                                  data={col?.fieldId || col?.physFieldNm}
                                  rowIndex={rowIdx}
                                  colIndex={colIdx}
                                  onDrop={handleDrop}
                                />
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Row>
        </DndProvider>
      </Modal.Body>
      <ModalFooter>
        <Button onClick={(e) => closePopup()}>Confirm</Button>
        <Button className="btn-default" onClick={(e) => Popup.close()}>
          close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CardLayoutSettingPopup;
