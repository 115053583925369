import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { useContext, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import * as Fa from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import { MobileLayoutContext } from "page/mobile/appConfiguration";

function MobileLayoutPreview() {
  const { tnb, renderTopNav, appLayout, tnbTmplList, bnbTmpl } =
    useContext(MobileLayoutContext);
  const navigate = useNavigate();
  const workspace = useSelector((state) => state.workspace);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileOS, setMobileOS] = useState("android");

  const renderTop = () => {
    if (ObjectUtils.isEmpty(tnb)) {
      return <></>;
    }
    if (tnb.tmplType === "C") {
      return renderTopNav(tnb.customContent);
    } else {
      const tnbTarget = tnbTmplList.find(
        (tmp) => tmp.layoutPresetId === tnb.layoutPresetId
      );

      return renderTopNav(tnbTarget.presetContent);
    }
  };

  const onClickOS = (e) => {
    setMobileOS(e.target.id);
  };

  const onClickEditor = (e) => {
    navigate(
      Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
    );
  };

  const onDeployMobileLayout = () => {
    setIsLoading(true);
    Message.confirm(
      "deploy Mobile Application Layout?",
      async () => {
        const connection = User.getConnection(workspace.tenantMstId);
        if (connection?.expired) {
          setIsLoading(false);
          return Message.alert("Token Expired.", Enums.MessageType.ERROR);
        }
        if (!connection?.token) {
          setIsLoading(false);
          return Message.alert(
            "Authentication server token is not validated.\n Please reconnect.",
            Enums.MessageType.ERROR
          );
        }

        const { gnbId, mnuTmplId, bnbId, themeId } = appLayout;

        //tmp 데이터들을 함께 물고 있어야함
        return false;
        const gnbInfo = await promiseCallback(LayoutSettingService.getGnbInfo, {
          gnbId,
        });

        const mnuTmplInfo = await promiseCallback(
          LayoutSettingService.getMnuTmplInfo,
          { mnuTmplId }
        );

        const bnbInfo = await promiseCallback(LayoutSettingService.getBnbInfo, {
          bnbId,
        });
        const themeInfo = await promiseCallback(
          LayoutSettingService.getThemeInfo,
          { themeId }
        );

        const { gnbTmplContent } = gnbInfo;
        const { mnuType, mnuGroup, mnuTmplContent } = mnuTmplInfo;
        const { bnbTmplContent } = bnbInfo;
        const { themeGroup, themeType, themeCss } = bnbInfo;

        const deployBody = {
          gnbId,
          gnbTmplContent,
          mnuTmplId,
          mnuType,
          mnuGroup,
          mnuTmplContent,
          bnbId,
          bnbTmplContent,
          themeId,
          themeGroup,
          themeType,
          themeCss,
          ...connection,
        };

        LayoutSettingService.deploy(deployBody, (res) => {
          console.log(res);
          if (!res.isError) {
          }
        });
      },
      () => setIsLoading(false)
    );
  };

  const promiseCallback = (method, data, cb) => {
    return new Promise((resolve, reject) => {
      method(
        data,
        (res) => {
          resolve(res.data);
        },
        reject
      );
    });
  };

  return (
    <div className="ls-preview-wrapper">
      <div>
        <ButtonGroup>
          <Button
            id={"android"}
            size="sm"
            onClick={onClickOS}
            variant={mobileOS === "android" ? "secondary" : "outline-secondary"}
          >
            Android
          </Button>
          <Button
            id={"ios"}
            size="sm"
            onClick={onClickOS}
            variant={mobileOS === "ios" ? "secondary" : "outline-secondary"}
          >
            IOS
          </Button>
        </ButtonGroup>
      </div>
      <div className="preview-part">
        {/* 우측 미리보기 화면 */}

        <div className={`ls-preview ${mobileOS}`}>
          <div className="top-nav-template-box">
            {!ObjectUtils.isEmpty(tnb) && renderTop(tnb)}
          </div>
          {bnbTmpl.length !== 0 ? (
            <div className="ls-bottom-nav">
              {bnbTmpl.map((item, idx) => {
                let IconTag = "";
                if (!StringUtils.isEmpty(item.icon)) {
                  IconTag = Fa[StringUtils.convertKebabToPascal(item.icon)];
                }
                return (
                  <div className="nav-item" key={"bottom" + idx}>
                    {IconTag !== "" ? (
                      <div className="nav-icon">
                        <IconTag />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="nav-label"> {item.label}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="preview-remote-part">
        <Button onClick={onDeployMobileLayout} variant="outline-primary">
          Apply Layout
        </Button>
        <Button onClick={(e) => onClickEditor()} variant="outline-success">
          Go to Mobile UI Editor
        </Button>
      </div>
    </div>
  );
}

export default MobileLayoutPreview;
