import React from "react";

import * as Enums from "components/builder/BuilderEnum";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";

const MobileEditorNodeRender = (props) => {
  const pageChild = props.pageChild || [];
  const pageChildCnt = pageChild == null ? 0 : pageChild.length;

  return (
    <React.Fragment>
      {pageChild.map((row, index) => {
        const currentPath = `${index}`;
        return (
          <React.Fragment key={index}>
            <UIEditorDNDContainer
              data={{
                rootLocation:
                  props.isMobileFilter === true
                    ? Enums.ComponentType.FILTER
                    : Enums.ComponentType.PAGE,
                location: Enums.ComponentType.PAGE,
                path: currentPath,
                childrenCount: pageChildCnt,
              }}
              onDrop={props.handleDrop}
              path={currentPath}
              templateComponents={props.templateComponents}
            />
            <UIEditorChildRender
              key={row.compId}
              data={row}
              handleDrop={props.handleDrop}
              templateComponents={props.templateComponents}
              path={currentPath}
              childrenCount={pageChildCnt}
              rootLocation={
                props.isMobileFilter === true
                  ? Enums.ComponentType.FILTER
                  : Enums.ComponentType.PAGE
              }
              // onKeyDown={onKeyDownEsc}
            />
          </React.Fragment>
        );
      })}
      <UIEditorDNDContainer
        data={{
          rootLocation:
            props.isMobileFilter === true
              ? Enums.ComponentType.FILTER
              : Enums.ComponentType.PAGE,
          location: Enums.ComponentType.PAGE,
          path: `${pageChildCnt}`,
          childrenCount: pageChildCnt,
        }}
        className={`${pageChildCnt === 0 ? "empty-child" : ""}`}
        onDrop={props.handleDrop}
        templateComponents={props.templateComponents}
      />
    </React.Fragment>
  );
};
export default React.memo(MobileEditorNodeRender);
