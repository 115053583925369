import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { MobileLayoutContext } from "page/mobile/appConfiguration";
import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import MobileTopNavCustomEditor from "./MobileTopNavCustomEditor";

const MobileTopNavSetting = ({
  initial,
  nextCallback,
  prevCallback,
  ...props
}) => {
  const {
    tnb,
    setTnb,
    renderTopNav,
    saveLayout,
    mobileAppMst,
    tnbTmplList,
    setMobileAppMst,
  } = useContext(MobileLayoutContext);

  const [isCustomCreate, setIsCustomCreate] = useState(false);
  const onOpenTnbCustomPopup = (e) => {
    stopEvent(e);
    setIsCustomCreate(!isCustomCreate);
  };

  /**
   * 선택후 저장 버튼
   */
  const onConfirmTopNav = () => {
    let layout = {
      ...tnb,
      layoutPresetId: tnb.layoutPresetId,
      tmplType: tnb.tmplType,
      layoutType: "T",
    };

    saveLayout(layout);
    if (initial && nextCallback) {
      nextCallback();
    }
  };

  /**
   * 템플릿 선택
   * @param {*} tmp
   */
  const onClickTmpl = (tmp) => {
    let nTnb = { ...tnb };
    nTnb.tmplType = tmp.tmplType;
    if (tmp.tmplType === "P") {
      nTnb.layoutPresetId = tmp.layoutPresetId;
    }

    setTnb(nTnb);
  };

  const callbackCustomEdit = (customContent) => {
    let newTnb = { ...tnb, customContent, tmplType: "C" };
    setTnb(newTnb);
    saveLayout(newTnb);
  };

  return (
    <>
      <Row className="ls-container">
        <Col sm={2}>
          <div className="ls-header">Set Top Navigation </div>
          <div className="ls-description">
            Set up the top navigation.
            <br /> You can create a custom set if needed.
          </div>
        </Col>
        <Col sm={10}>
          <div className="ls-content">
            <Row>
              <Col xs={12} as={Row}>
                {tnbTmplList.map((tmp) => {
                  const isPreset = tnb?.tmplType === "P";
                  return (
                    <Col xs={6} key={tmp.layoutPresetId} className="mb-3">
                      <div
                        className={`top-nav-template-box-wrapper ${
                          isPreset && tnb?.layoutPresetId === tmp.layoutPresetId
                            ? "selected"
                            : ""
                        }`}
                        onClick={(e) =>
                          onClickTmpl({
                            layoutPresetId: tmp.layoutPresetId,
                            tmplType: "P",
                          })
                        }
                      >
                        <div className="top-nav-template-box">
                          {renderTopNav(tmp.presetContent)}
                        </div>
                      </div>
                    </Col>
                  );
                })}
                {!ObjectUtils.isEmpty(tnb?.customContent) && (
                  <Col xs={6} className="mb-3">
                    <div
                      className={`top-nav-template-box-wrapper ${
                        tnb.tmplType === "C" ? "selected" : ""
                      }`}
                      onClick={(e) =>
                        onClickTmpl({
                          tmplType: "C",
                        })
                      }
                    >
                      <div className="top-nav-template-box">
                        {renderTopNav(tnb.customContent)}
                      </div>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={9} />
              <Col xs={3}>
                {initial ? (
                  <div
                    div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button onClick={onConfirmTopNav} variant="link">
                      Save & Next
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!isCustomCreate && (
                      <>
                        <Button onClick={onConfirmTopNav}>Save</Button>
                        <Button
                          variant="outline-success"
                          onClick={onOpenTnbCustomPopup}
                        >
                          Custom Edit
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            {isCustomCreate && (
              <MobileTopNavCustomEditor
                structure={tnb.customContent}
                setIsCustomCreate={setIsCustomCreate}
                callbackFnc={callbackCustomEdit}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MobileTopNavSetting;
