import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import {
  redo,
  setClipboard,
  undo,
} from "components/builder/ui/reducers/CommandAction";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import { updateConnectorFromTo } from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import {
  setCsServiceLog,
  updateWorkflow,
} from "components/builder/workflow/reducer/WorkflowAction";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { UserShortKey } from "components/setting/section/shortKey/ShortKeyConfig";
import produce from "immer";
import SaveQuestPopup from "page/popup/workflow/SaveQuestPopup";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import {
  AiFillBug,
  AiOutlineNodeExpand,
  AiOutlineSelect,
} from "react-icons/ai";
import {
  BiCodeBlock,
  BiCommentX,
  BiCut,
  BiPaste,
  BiTrash,
} from "react-icons/bi";
import { FaMousePointer, FaRedo, FaStop, FaUndo } from "react-icons/fa";
import { GrBundle, GrResume } from "react-icons/gr";
import {
  MdAlignHorizontalCenter,
  MdAlignVerticalCenter,
  MdConnectingAirports,
  MdOutlineShortText,
} from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import {
  VscActivateBreakpoints,
  VscDebugAltSmall,
  VscDebugConsole,
  VscDebugStart,
  VscDebugStepInto,
  VscDebugStepOut,
  VscDebugStepOver,
  VscWindow,
} from "react-icons/vsc";
import { connect } from "react-redux";
import LocalStorageService from "services/common/LocalService";
import WorkflowService from "services/workflow/WorkflowService";
import CommandButton from "../../CommandButton";
import { stopEvent } from "../../ui/editor/handler/UIEditorEventHandler";
import { setWFIsDebugging } from "../reducer/WorkflowDebugAction";

class WorkflowCommandButton extends CommandButton {
  constructor(props) {
    super(props);
    this.getSelectedNode = this.getSelectedNode.bind(this);
    this.isDebuggingMode = this.isDebuggingMode.bind(this);
    this.onClickOpenLog = this.onClickOpenLog.bind(this);
    this.state = {
      undoDisabled: false,
      redoDisabled: false,
      tabType: "E",
    };
  }

  /**
   * 선택 노드 및 관련된 커넥터 반환
   * @returns {{process:Array, connector:Array}}
   */
  getSelectedNode() {
    const _nodes = this.props.nodes;
    const _edges = this.props.edges;
    const selectedNode = _nodes.filter(
      (n) =>
        StringUtils.includes(n.type, [
          Enums.WorkflowNodeType.PROCESS,
          Enums.WorkflowNodeType.ITERATOR,
          Enums.WorkflowNodeType.SERVICE,
          Enums.WorkflowNodeType.CODE,
        ]) && n.selected
    );
    if (ArrayUtils.isEmpty(_nodes)) {
      Message.alert("There are no valid Nodes.", Enums.MessageType.WARN);
      return false;
    }
    if (ArrayUtils.isEmpty(selectedNode)) {
      Message.alert(
        "There are no selected process nodes.",
        Enums.MessageType.WARN
      );
      return false;
    }
    let copyConnector = [];
    let copyEdge = [];
    // 복사할 커넥터 또는 엣지
    if (selectedNode.length > 1) {
      //커넥터
      copyConnector = _nodes.filter(
        (n) =>
          n.type === Enums.WorkflowNodeType.CONNECTOR &&
          selectedNode.find((sn) => sn.id === n.data.connector.processFrom) &&
          selectedNode.find((sn) => sn.id === n.data.connector.processTo)
      );

      //엣지
      copyEdge = _edges.filter(
        (f) =>
          f.type === Enums.WorkflowNodeType.NO_CONNECTOR &&
          selectedNode.find((sn) => sn.id === f.source) &&
          selectedNode.find((sn) => sn.id === f.target)
      );
    }
    let _process = selectedNode.map((s) => s.data.process);
    let _connector = copyConnector.map((c) =>
      JsonUtils.findNode(this.props.workflow, "compId", c.id)
    );
    let _noConnector = copyEdge.map((c) =>
      JsonUtils.findNode(this.props.workflow, "compId", c.id)
    );

    return {
      process: _process,
      connector: [..._connector, ..._noConnector],
    };
  }

  onCut() {
    const _cutNode = this.getSelectedNode();
    if (_cutNode) {
      this.props.deleteProcess(
        _cutNode.process.map((p) => p.compId),
        this.props.workflow
      );
      this.props.setClipboard(_cutNode);
    }
  }

  /**
   * 복사
   */
  onCopy() {
    const _copyNode = this.getSelectedNode();
    if (_copyNode) {
      this.props.setClipboard(_copyNode);
    }
  }

  /**
   * 붙여넣기
   * @returns
   */
  onPaste() {
    /**
     * 선택 노드가 있을때는 노드가 iterator인지 확인
     *  - iterator인 경우 내부에 Paste
     * 1개 이상의 노드가 선택된 채면 최상단 레벨에 Paste
     * 선택 노드 기준으로 포지션 X : +300 y : +200
     */
    const selectedNode = this.props.nodes.filter(
      (n) =>
        StringUtils.includes(n.type, [
          Enums.WorkflowNodeType.PROCESS,
          Enums.WorkflowNodeType.ITERATOR,
          Enums.WorkflowNodeType.SERVICE,
        ]) && n.selected
    );
    //clipboard의 요소들 compId 업데이트
    const clipboard = produce(this.props.command.clipboard, (draft) => {
      const [, compIdMap] = JsonUtils.updateCompIdAndGetPrevData(draft.process);
      JsonUtils.updateCompId(draft.connector);
      updateConnectorFromTo(draft.process, compIdMap);
      updateConnectorFromTo(draft.connector, compIdMap);
    });
    /**
     * 타겟(parent)의 child를 업데이트 하는 로직
     * @param {*} parent
     * @returns
     */
    const pasteChildren = (parent) => {
      const basePosition = parent.position;
      let positionUpdate = () => {};
      if (parent.processType === Enums.WorkflowNodeType.ITERATOR) {
        positionUpdate = (nodes) => {
          const result = nodes.map((sn) =>
            produce(sn, (draft) => {
              draft.position = {
                x: basePosition.x + 300,
                y: basePosition.y + 200,
              };
              if (draft.position.x > parent.style.width)
                draft.position.x = parent.style.width - 300;
              else if (draft.position.x < 0) draft.position.x = 30;
              if (draft.position.y > parent.style.height)
                draft.position.y = parent.style.height - 200;
              else if (draft.position.y < 0) draft.position.y = 50;
            })
          );
          return result;
        };
      } else if (basePosition) {
        positionUpdate = (nodes) =>
          nodes.map((sn) => {
            sn.position = {
              x: basePosition.x + 300,
              y: basePosition.y + 200,
            };
            return sn;
          });
      } else {
        //포지션이 없는 경우는 최상단 레벨이기 때문에 자기 포지션에 더해줌
        positionUpdate = (nodes) => {
          return nodes.map((sn) =>
            produce(sn, (draft) => {
              draft.position = {
                x: sn.position.x + 300,
                y: sn.position.y + 200,
              };
            })
          );
        };
      }
      return produce(parent, (draft) => {
        draft.child.process = draft.child.process.concat(
          positionUpdate(clipboard.process)
        );
        draft.child.connector = draft.child.connector.concat(
          positionUpdate(clipboard.connector)
        );
      });
    };
    let newWorkflow;
    if (!ArrayUtils.isEmpty(selectedNode)) {
      const process = selectedNode.map((n) => n.data.process);
      if (
        process.length === 1 &&
        process[0].processType === Enums.WorkflowNodeType.ITERATOR
      ) {
        const grandParentNode = this.props.nodes.find(
          (n) => n.id === selectedNode[0].parentNode
        );
        if (
          grandParentNode &&
          this.props.nodes.find((n) => n.id === grandParentNode.id) &&
          clipboard.process.find(
            (p) => p.processType === Enums.WorkflowNodeType.ITERATOR
          )
        ) {
          return Message.alert(
            "Iterator can be nested up to a maximum of 2 levels.",
            Enums.MessageType.WARN
          );
        }

        //Iterator 내부
        const newIterator = pasteChildren(process[0]);

        newWorkflow = produce(this.props.workflow, (draft) => {
          JsonUtils.overrideNode(
            draft,
            "compId",
            newIterator.compId,
            "child",
            newIterator.child
          );
        });
      } else {
        //최상단
        newWorkflow = produce(this.props.workflow, (draft) => {
          draft.output.service = pasteChildren(draft.output.service);
        });
      }
    } else {
      newWorkflow = produce(this.props.workflow, (draft) => {
        draft.output.service = pasteChildren(draft.output.service);
      });
    }

    this.props.updateWorkflow(newWorkflow, this.props.workflow);
  }

  undo() {
    const { prev, undoIndex } = this.props.command;
    const workflow = { ...prev.at(undoIndex) };
    this.props.undo(workflow);
  }
  redo() {
    const { undoIndex, latest } = this.props.command;
    const workflow = { ...latest.at(undoIndex + 1) };
    this.props.redo(workflow);
  }
  onChangeTabType(e) {
    const tabType = e.target.value;
    this.setState({
      tabType: tabType,
    });
    this.props.setTabType(tabType);
  }

  /**
   * 이전 서비스로 이동
   * @param {*} e
   */
  onClickPrev(ps, idx) {
    const moveToPrev = (serviceUid, idx) => {
      WorkflowService.getService({ serviceUid }, (res) => {
        const service = WorkflowService.setData(res.data);
        this.props.moveToPrevService(service, this.props.workflow);
      });
    };
    WorkflowService.getService(
      { serviceUid: this.props.workflow.serviceInfo.serviceUid },
      (res) => {
        const prevService = WorkflowService.setData(res.data);
        //viewport는 다른경우가 많기 때문에 빼고 비교
        const prev = produce(prevService.serviceContent, (draft) => {
          JsonUtils.removeNode(draft, "viewport");
        });
        const next = produce(this.props.workflow.output, (draft) => {
          JsonUtils.removeNode(draft, "viewport");
        });
        const prevMemo = prevService.memo;
        const nextMemo = this.props.workflow.memo;

        if (
          JSON.stringify(prev) !== JSON.stringify(next) ||
          JSON.stringify(prevMemo) !== JSON.stringify(nextMemo)
        ) {
          /**
           * 저장 후 진행
           */
          const callback = () => {
            const body = {
              ...this.props.workflow.serviceInfo,
              serviceContent: this.props.workflow.output,
              serviceComment: this.props.workflow.serviceComment,
              serviceMemo: this.props.workflow.serviceMemo,
              useYn: "Y",
              commitComment: "",
              releaseCommentYn: "N",
              ...this.props.workspace,
            };
            WorkflowService.saveService(body, (res) => {
              WorkflowService.localStorageSave(body);
              Popup.close();
              moveToPrev(ps.serviceUid, idx + 1);
            });
          };
          const showPopup = () => {
            Popup.open(
              <SaveQuestPopup
                callback={callback}
                closeCallback={() => moveToPrev(ps.serviceUid, idx + 1)}
              />,
              {
                effect: {
                  ...Popup.ScaleUp,
                  end: {
                    top: "30%",
                    opacity: 1,
                  },
                },
                style: { content: { width: "400px", top: "400px" } },
              }
            );
          };

          const autoSaveInfo = LocalStorageService.get(
            Enums.LocalStorageName.WORKFLOW_AUTOSAVE
          );
          if (autoSaveInfo) {
            if (
              autoSaveInfo.userId === User.getId() &&
              autoSaveInfo.autoSave === "Y"
            ) {
              //자동 저장
              callback();
            } else {
              LocalStorageService.remove(
                Enums.LocalStorageName.WORKFLOW_AUTOSAVE
              );
              showPopup();
            }
          } else {
            showPopup();
          }
        } else {
          // moveNext();
          moveToPrev(ps.serviceUid, idx + 1);
        }
      }
    );
  }

  /**
   * 서비스간 종속 관계를 broadcump로 만드는 로직
   * @returns
   */
  renderDataNm() {
    let prevServices = this.props.workflow.prevService.map((ps, idx) => {
      return (
        <div
          className="program-id-label prev-service"
          key={idx}
          onClick={(e) => this.onClickPrev(ps, idx)}
        >
          <span className="service-name">{ps.serviceName} </span>
          <span>{"▶"}</span>
        </div>
      );
    });
    const _rightPanel = (children) => {
      if (!ArrayUtils.isEmpty(this.props.debug.trace)) {
        return (
          <>
            <div className="edit-button-group">
              <Tooltip title={`Prev Debug Trace`} placement="bottom">
                <ToggleButton
                  key={2}
                  id="debugTrace"
                  name="debugTrace"
                  variant="outline-dark"
                  value="debugTrace"
                  size="sm"
                  type="checkbox"
                  checked={this.props.debug.isDebugging === true}
                  disabled={this.props.debug.inCommunication}
                  onChange={(e) => {
                    if (e.target.checked)
                      this.props.setDebugExpressionMode("breakpoint");
                    else this.props.setDebugExpressionMode("");
                    this.props.setIsDebugging(e.target.checked);
                  }}
                  style={{ fontSize: "smaller" }}
                >
                  <AiFillBug size="14" />
                </ToggleButton>
              </Tooltip>
            </div>
            {children ? children : <></>}
          </>
        );
      } else {
        return <>{children}</>;
      }
    };
    const curServiceName = this.props.workflow.serviceInfo.serviceName;
    const currentComponent = curServiceName ? (
      <>
        <div className="program-id-label current-service">
          {`${
            this.props.workflow.serviceInfo.serviceUid
              ? `${this.props.workflow.serviceInfo.serviceUid} - `
              : ""
          }<${this.props.workflow.serviceInfo.serviceId}> `}
          {curServiceName}
        </div>
      </>
    ) : (
      <></>
    );

    return _rightPanel(
      <>
        {prevServices}
        {currentComponent}
      </>
    );
  }

  /**
   * 정렬시 기준점 확보하는 로직
   * 선택된 노드, 기준노드,  child일 때 부모 ID
   * @returns {[selectedNode:Array,minNode:Object, parentNodeId:String]}
   */
  getControlPoint(direction = "horizontal") {
    // 1. 선택된 노드 추출
    // 1-1 선택된 노드가 없는 경우 전체 정렬으로 한다.
    let willArrangeNodes = this.props.nodes.filter(
      (n) =>
        n.selected &&
        !StringUtils.includes(n.type, [Enums.WorkflowNodeType.MEMO])
    );
    if (ArrayUtils.isEmpty(willArrangeNodes)) {
      willArrangeNodes = this.props.nodes.filter(
        (n) => !StringUtils.includes(n.type, [Enums.WorkflowNodeType.MEMO])
      );
    }

    //선택된것이 단일이고 이터레이터 이면 내부를 정리한다.
    let isIteratorArrage = false; //이터레이터 내부 정렬 플래그
    if (
      willArrangeNodes.length === 1 &&
      willArrangeNodes[0].type === Enums.WorkflowNodeType.ITERATOR
    ) {
      willArrangeNodes = this.props.nodes.filter(
        (n) => n.parentNode === willArrangeNodes[0].id
      );
      isIteratorArrage = true;
    }

    // 2. 이터레이터 내부 노드인지 아닌지 구분하기
    // 2-1 이터레이터 부모 노드에 속한 노드들인지 확인하기
    let parentNodeId = null;
    //최상위 요소가 선택되었는지 확인. 전체 선택해서 최상위와 이터레이터 자녀가 함께 걸린경우 최상위 노드 정렬을 우선으로 한다.
    const isRootNodeSelected = willArrangeNodes.reduce(
      (ac, cu) => (cu.parentNode ? false : true) || ac,
      false
    );
    /**
     * parentNode 가 없는 경우는 최상단 노드들 정렬의 경우이다.
     * 즉 0 번째에 parentsNode가 없는 것은 외부 정렬을 한다고 가정한다.
     * 이터레이터 내부에서 정렬하는 경우는 모두 같은 parentNode를 가지고 있다.
     */
    if (!isRootNodeSelected) {
      for (const childNode of willArrangeNodes) {
        if (
          !StringUtils.isEmpty(parentNodeId) &&
          childNode.parentNode !== parentNodeId
        ) {
          return Message.alert(
            "Nodes from different categories cannot be sorted.",
            Enums.MessageType.WARN
          );
        } else if (childNode.parentNode !== parentNodeId) {
          parentNodeId = childNode.parentNode;
        }
      }
    }

    /**
     * 2-2 최좌측 노드 구하기
     *    -   수평 정렬은 X 좌표를 우선으로 한다.
     *    -   X좌표가 동일한 경우 Y 좌표로 상단을 우선으로 함 (커넥터 제외)
     *    -   수직 정렬일 경우에는 Y 좌표를 우선으로한다.
     *    -   시작 노드가 포함된 경우는 시작노드를 minNode로 한다.
     */
    let minNode = null;
    const isStartNode = willArrangeNodes.find(
      (n) =>
        StringUtils.equalsIgnoreCase(
          n.type,
          Enums.WorkflowNodeType.PROCESS_EDGE
        ) &&
        n.data.process.processType === Enums.WorkflowProcessType.START_PROCESS
    );
    if (isStartNode) {
      minNode = { ...isStartNode };
    } else if (isIteratorArrage) {
      /**
       *  이터레이터 내부 정렬인 경우 출발 하는 노드를 찾아야 한다.
       *  출발노드는 커넥터 중에 from에만 있고 to에는 없는 노드 이다.
       */
      const iteratorConnector = willArrangeNodes.filter(
        (n) => n.type === Enums.WorkflowNodeType.CONNECTOR
      );
      for (const node of willArrangeNodes) {
        const toNode = iteratorConnector.find(
          (n) => n.data.connector.processTo === node.id
        );
        if (!toNode) {
          minNode = node;
          break;
        }
      }
    } else {
      for (const node of willArrangeNodes) {
        if (!parentNodeId && node.parentNode) continue;
        if (node.type !== Enums.WorkflowNodeType.CONNECTOR) {
          if (!minNode) {
            minNode = { ...node };
          } else if (StringUtils.equalsIgnoreCase(direction, "horizontal")) {
            if (minNode.position.x === node.position.x) {
              if (minNode.position.y > node.position.y) minNode = { ...node };
            } else if (minNode.position.x > node.position.x)
              minNode = { ...node };
          } else if (StringUtils.equalsIgnoreCase(direction, "vertical")) {
            if (minNode.position.y === node.position.y) {
              if (minNode.position.x > node.position.x) minNode = { ...node };
            } else if (minNode.position.y > node.position.y)
              minNode = { ...node };
          }
        }
      }
    }
    return [willArrangeNodes, minNode, parentNodeId];
  }

  /**
   * style이 없는 노드들에게 기본 Style 주입
   * 이터레이터를 제외한 각 노드들은 사이즈가 없기 때문에
   * 사이즈를 붙여서 위치를 조정 할수 있도록 한다.
   * @param {Object} tNode
   * @returns {Object}
   */
  setNodeStyle = (tNode, direction) => {
    if (!tNode) return null;
    const node = produce(tNode, (draft) => {
      if (!draft.style) {
        draft.style = {};
        if (
          StringUtils.includes(draft.type, [
            Enums.WorkflowNodeType.PROCESS,
            Enums.WorkflowNodeType.BUNDLE,
            Enums.WorkflowNodeType.CODE,
            Enums.WorkflowNodeType.SERVICE,
          ])
        ) {
          if (
            draft.processType === Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
          ) {
            draft.style.width = 280;
            draft.style.height = 81;
          } else {
            draft.style.width = 323;
            draft.style.height = 111;
          }
        } else if (draft.type === Enums.WorkflowNodeType.CONDITION) {
          draft.style.width = 263;
          draft.style.height = 142;
        } else if (draft.type === Enums.WorkflowNodeType.CONNECTOR) {
          if (StringUtils.equalsIgnoreCase(direction, "horizontal")) {
            draft.style.width = 173;
            draft.style.height = 51;
          } else {
            if (draft.data.connector.propertyValue.connectorNm) {
              draft.style.width = 173;
              draft.style.height = 51;
            } else {
              draft.style.width = 83;
              draft.style.height = 51;
            }
          }
        } else if (draft.type === Enums.WorkflowNodeType.PROCESS_EDGE) {
          draft.style.width = 173;
          draft.style.height = 51;
        } else if (draft.type === Enums.WorkflowNodeType.CONDITION) {
          draft.style.width = 263;
          draft.style.height = 142;
        }
      }
    });

    return node;
  };

  /**
   * 정리 후 중복 노드를 정리된 배열 리턴
   * @param {Array} nodes
   * @returns {Array}
   */
  arrangeDuplicateNode = (nodes) => {
    /**
     * BFS 방식이라 배열의 제일 뒤부분이 적절한 노드 업데이트
     * 배열을 뒤집어서 중복되지 않는 노드만 담아서 넘기기
     */
    const willUpdateNodes = [];
    const reversedResult = nodes.reverse();
    for (const node of reversedResult) {
      if (!willUpdateNodes.find((n) => n.compId === node.compId)) {
        willUpdateNodes.push(node);
      }
    }
    return willUpdateNodes;
  };

  /**
   * 노드 타입마다 들어간 오브젝트 명이 달라서
   * 한번에 불러오도록 함
   * @param {*} tNode
   * @returns
   */
  getData = (tNode) => {
    if (tNode.type === Enums.WorkflowNodeType.CONNECTOR) {
      return tNode.data.connector;
    } else {
      return tNode.data.process;
    }
  };

  /**
   * 노드 목록을 Iterator 내부에서 확인해서 업데이트
   * @param {*} nodeList 업데이트할 노드 목록
   * @param {*} parentNodeId Iterator CompId
   */
  updateIteratorArray = (nodeList, parentNodeId) => {
    //부모 노드가 있는 경우는 해당 노드의 프로세스를 수정해서 업데이트함
    let pNode = JsonUtils.findNode(
      this.props.workflow.output,
      "compId",
      parentNodeId
    );

    //내부 값 변경
    pNode = produce(pNode, (draft) => {
      for (const node of nodeList) {
        let pIdx = pNode.child.process.findIndex(
          (p) => p.compId === node.compId
        );
        if (pIdx > -1) {
          draft.child.process[pIdx] = { ...node };
        } else {
          pIdx = pNode.child.connector.findIndex(
            (p) => p.compId === node.compId
          );
          if (pIdx > -1) {
            draft.child.connector[pIdx] = { ...node };
          }
        }
      }
    });

    //X,Y 좌표 최대값 구하기
    const max = pNode.child.process.reduce(
      (ac, cu) => {
        if (ac.x < cu.position.x) ac.x = cu.position.x;
        if (ac.y < cu.position.y) ac.y = cu.position.y;
        return ac;
      },
      { x: 0, y: 0 }
    );
    //Iterator 크기 정리
    pNode = produce(pNode, (draft) => {
      draft.style.height = max.y + 300;
      draft.style.width = max.x + 400;
    });

    return pNode;
  };

  /**
   * 번들 내부 노드들의 포지션을 재정렬 하는 함수
   * @param {*} bundle 노드 그룹
   * @param {string} direction 방향
   */
  updateBundleChild = (bundle, direction) => {
    return null;
    const mapProcessCompIdToBundle = {};
    bundle.bundle.propertyValue.nodeList.forEach((node) => {
      mapProcessCompIdToBundle[node.compId] = true;
    });
    const willArrangeNodeList = this.props.nodes.filter(
      (n) => mapProcessCompIdToBundle[n.id]
    );
    const connectorList = willArrangeNodeList.filter(
      (n) => n.type === Enums.WorkflowNodeType.CONNECTOR
    );
    const processList = willArrangeNodeList.filter(
      (n) => n.type !== Enums.WorkflowNodeType.CONNECTOR
    );

    const find = (con, direction) => {
      const theOtherSide = StringUtils.equalsIgnoreCase(
        direction,
        "processFrom"
      )
        ? "processTo"
        : "processFrom";
      const nextProcess = processList.find(
        (process) => process.id === con.data.connector[direction]
      );
      if (nextProcess) {
        //이후 커넥터로 재귀
        const nextCon = connectorList.find(
          (con) => nextProcess.id === con.data.connector[theOtherSide]
        );
        if (nextCon) {
          return find(nextCon, direction);
        } else {
          return nextProcess;
        }
      } else {
        //이전 프로세스 전달
        return processList.find(
          (process) => process.id === con.data.connector[theOtherSide]
        );
      }
    };

    const findFirst = (con) => find(con, "processFrom");
    const findEnd = (con) => find(con, "processTo");

    const firstProcess = findFirst(connectorList[0]);
    const endProcess = findEnd(connectorList[0]);

    //첫번째 프로세스부터 위치 선정
    //2차원 배열로 위치 조정
    const processPath = [];
    const nodeList = [];

    const arrange = (process, row, col) => {
      //등록 구간 정리
      if (!processPath[row]) {
        processPath[row] = [];
      }
      if (!processPath[row][col]) {
        processPath[row][col] = null;
      }
      process = this.setNodeStyle(process, direction);

      //노드 리스트에 이미 등록된 노드는 패스
      const isExist = nodeList.find((node) => node.compId === process.compId);
      if (!isExist) {
        let prevNode = null;
        if (direction === "horizontal") {
          if (col - 1 > 0) {
            prevNode = processPath[row][col - 1];
            if (prevNode) process.position.x = prevNode.style.width + 150;
          }
        } else {
          if (row - 1 > 0) {
            prevNode = processPath[row - 1][col];
            if (prevNode) process.position.y = prevNode.style.height + 150;
          }
        }
        processPath[row][col] = process;
        nodeList.push(process);
      }

      const connectorFromProcess = connectorList.filter(
        (con) => this.getData(con).processFrom === process.compId
      );

      if (!ArrayUtils.isEmpty(connectorFromProcess)) {
        connectorFromProcess.forEach((con, index) => {
          let nextRow = row + index;
          let nextCol = col + 1;
          con = this.getData(con);
          con = this.setNodeStyle(con, direction);
          if (direction === "horizontal") {
            con.position.x = process.style.width + 150;
            processPath[row + index][col + 1] = con;
            nextRow = row + index;
            nextCol = col + 1 + 1;
          } else {
            con.position.y = process.style.height + 150;
            processPath[row + 1][col + index] = con;
            nextRow = row + 1 + 1;
            nextCol = col + index;
          }
          nodeList.push(con);
          const nextProcess = processList.find((p) => p.id === con.processTo);
          arrange(nextProcess, nextRow, nextCol);
        });
      }
    };

    arrange(firstProcess, 0, 0);
  };

  /**
   * 주어진 노드의 X,Y 좌표의 중간값을 가져온다
   * @param {Object} nodes
   * @return {Object}
   */
  getAvgPosition = (nodes) => {
    let x = [];
    let y = [];
    for (const n of nodes) {
      const p = this.getData(n).position;
      x.push(p.x + n.style.width / 2);
      y.push(p.y + n.style.height / 2);
    }
    const getAvg = (arr) => arr.reduce((a, c) => a + c, 0) / arr.length;
    return {
      x: getAvg(x),
      y: getAvg(y),
    };
  };

  /**
   * BFS 방식 정렬
   * @param {*} e
   */
  onClickArrange = (e, direction = "horizontal") => {
    const bundleMap = {};

    const mapProcessCompIdToBundle = {};
    const currentBundle = this.props.workflow.output.bundle;
    if (currentBundle) {
      currentBundle.forEach((bundle) => {
        bundleMap[bundle.compId] = {
          bundle,
          updated: bundle.propertyValue.expand, // 번들이 전개되어있을때만 update가 필요하기 때문에 플래그를 넣는다.
        };
        bundle.propertyValue.nodeList.forEach((node) => {
          mapProcessCompIdToBundle[node.compId] = bundleMap[bundle.compId];
        });
      });
    }

    /**
     *  1. 선택된 노드 찾기
     *  2. 시작 노드 찾기
     *    2-1. 시작 노드의 위치를 기준으로 계산
     *  3. 현재 노드의 자녀를 큐에 담기
     *  4. 큐에 담긴 녀석들을 일괄적으로 정렬하기
     *    4 - 1 . 순회 하면서 자녀들 다시 담기
     *  5. 이미 좌표를 지정했음에도 다른 노드의 간섭이 있다면 간섭이 있는 노드의 위치 까지 포지션 정렬하기
     */
    stopEvent(e);
    const [selectedNode, minNode, parentNodeId] =
      this.getControlPoint(direction);
    if (!minNode) return false;

    //작업된 노드
    let arrangedNodes = [];
    //시작노드의 자녀들을 큐에 담기
    let queue = [];
    let positionSetNodes = [];
    let cNode = this.setNodeStyle(minNode, direction);
    /**
     * 자녀 구하는 로직
     * @param {*} node
     * @returns
     */
    const getNodeChildren = (node) => {
      const edges = this.props.edges.filter(
        (e) =>
          e.source === node.id && selectedNode.find((sn) => sn.id === e.target)
      );

      const nodes = [];

      for (const e of edges) {
        const sn = selectedNode.find((n) => n.id === e.target);
        if (!nodes.find((n) => n.id === e.target)) {
          nodes.push({
            ...this.setNodeStyle(sn),
            parentNodeId: node.id,
          });
        }
      }
      return nodes;
    };

    //최초 노드로 큐 세팅
    queue.push(cNode);
    //이전노드
    let prevNode = {};

    /**
     * 정리된 노드 추가하는 로직
     * 이미 정리된거면 해당 데이터 업데이트 하는 형태
     * @param {*} node
     */
    const addArrageNode = (node, direction) => {
      let nodeData = { ...node };
      if (node.type === Enums.WorkflowNodeType.CONNECTOR) {
        if (StringUtils.equalsIgnoreCase(direction, "horizontal")) {
          nodeData = produce(node, (draft) => {
            const data = this.getData(draft);
            data.edgeDetailInfo.from.sourceHandle = "c";
            data.edgeDetailInfo.from.targetHandle = "b";
            data.edgeDetailInfo.to.sourceHandle = "c";
            data.edgeDetailInfo.to.targetHandle = "b";
          });
        } else {
          nodeData = produce(node, (draft) => {
            const data = this.getData(draft);
            data.edgeDetailInfo.from.sourceHandle = "d";
            data.edgeDetailInfo.from.targetHandle = "a";
            data.edgeDetailInfo.to.sourceHandle = "d";
            data.edgeDetailInfo.to.targetHandle = "a";
          });
        }
      }
      const nodeIndex = arrangedNodes.findIndex((n) => n.id === nodeData.id);
      if (nodeIndex > -1) {
        arrangedNodes[nodeIndex] = nodeData;
      } else {
        arrangedNodes.push(nodeData);
      }
    };

    while (!ArrayUtils.isEmpty(queue)) {
      //작업할 노드
      let node = ArrayUtils.poll(queue);
      if (positionSetNodes.indexOf(node.id) > -1) continue;

      //정렬노드
      /**
       * 이전 노드의 부모노드가 현재 노드의 부모와 다른 경우
       *  - 현재 노드 X좌표는 부모노드와 같도록 하고, Y값만 증가시키다.
       *  -
       *
       * 이전 노드의 부모노드와 현재 노드의 부모와 같은 경우
       *  - Y 좌표는 고정하고 X 좌표만 고정 시킨다.
       *  */

      if (!ObjectUtils.isEmpty(prevNode)) {
        if (prevNode.parentNodeId !== node.parentNodeId) {
          //이전에 진행했던 노드랑 부모가 다를떄
          //현재노드의 부모 찾기
          let parentsNode = arrangedNodes.find(
            (n) => n.id === node.parentNodeId
          );
          const pData = this.getData(parentsNode);

          let newPosition = {};
          if (StringUtils.equalsIgnoreCase(direction, "vertical")) {
            newPosition = {
              x:
                pData.position.x +
                parentsNode.style.width / 2 -
                node.style.width / 2,
              y: pData.position.y + parentsNode.style.height + 100,
            };
            //이전 처리했던 노드랑 겹치는 경우 간격을 더 벌림
            //이전 노드와 지금 노드가 같은 경우는 처리 하지 않음
            if (
              prevNode.id !== node.id &&
              this.getData(prevNode).position.y === newPosition.y
            ) {
              newPosition.x =
                this.getData(prevNode).position.x + prevNode.style.width + 100;
            }
          } else {
            newPosition = {
              x: pData.position.x + parentsNode.style.width + 100,
              y:
                pData.position.y +
                parentsNode.style.height / 2 -
                node.style.height / 2,
            };
            // if (prevNode.type === Enums.WorkflowNodeType.ITERATOR) {
            //   newPosition.y += prevNode.style.height / 2;
            // }
            //이전 처리했던 노드랑 겹치는 경우 간격을 더 벌림
            if (
              prevNode.id !== node.id &&
              this.getData(prevNode).position.x === newPosition.x
            ) {
              newPosition.y =
                this.getData(prevNode).position.y + prevNode.style.height + 100;
            }
          }

          node = produce(node, (draft) => {
            if (node.type === Enums.WorkflowNodeType.CONNECTOR) {
              draft.data.connector.position = newPosition;
            } else {
              draft.data.process.position = newPosition;
            }
          });
        } else if (prevNode.parentNodeId === node.parentNodeId) {
          //이전에 진행했던 노드랑 부모가 같을때
          let newPosition = {};

          if (StringUtils.equalsIgnoreCase(direction, "vertical")) {
            newPosition = {
              x: this.getData(prevNode).position.x + prevNode.style.width + 200,
              y: this.getData(prevNode).position.y,
            };
          } else {
            newPosition = {
              x: this.getData(prevNode).position.x,
              y:
                this.getData(prevNode).position.y + prevNode.style.height + 200,
            };
          }

          node = produce(node, (draft) => {
            if (node.type === Enums.WorkflowNodeType.CONNECTOR) {
              draft.data.connector.position = newPosition;
            } else {
              draft.data.process.position = newPosition;
            }
          });
        }
        positionSetNodes.push(node.id);
      }
      //작업할 노드의 자녀들을 다시 큐에 채워 넣음
      //이전 노드와 지금노드가 다를때만 추가함(중복방지)

      if (prevNode.id !== node.id) {
        const tNodes = getNodeChildren(node);
        queue = queue.concat(tNodes);
      }
      prevNode = node;
      addArrageNode(node, direction);
    }
    if (parentNodeId) {
      let willUpdateChildNodes = arrangedNodes.map((node) =>
        this.getData(node)
      );
      if (bundleMap[parentNodeId]) {
        console.log("Bundle Organization");
        //번들 내부 정리
      } else {
        let pNode = this.updateIteratorArray(
          willUpdateChildNodes,
          parentNodeId
        );
        this.props.updateNodes([pNode], this.props.workflow);
      }
    } else {
      const willUpdateNodes = arrangedNodes.map((node) => this.getData(node));
      this.props.updateNodes(willUpdateNodes, this.props.workflow);
    }
  };

  isDebuggingMode() {
    return this.props.debug.inCommunication && this.props.debug.isDebugging;
  }

  onClickOpenLog = (e) => {
    this.props.setConvertLog(!this.props.workflow.CSFileLogViewMode);
  };

  renderCutCopyPaste() {
    return (
      <div className="edit-button-group">
        <Tooltip title={`Cut (${UserShortKey(Enums.ShortKeyDefine.CUT)})`}>
          <div
            key={"cut"}
            id="cut"
            name="cut"
            variant="outline-dark"
            size="sm"
            onClick={this.isDebuggingMode() ? () => {} : this.onClickCut}
            className={this.isDebuggingMode() ? "disabled" : "able"}
          >
            <BiCut size={14} />
          </div>
        </Tooltip>
        <Tooltip title={`Copy (${UserShortKey(Enums.ShortKeyDefine.COPY)})`}>
          <div
            key={"copy"}
            id="copy"
            name="copy"
            variant="outline-dark"
            size="sm"
            onClick={this.isDebuggingMode() ? () => {} : this.onClickCopy}
            className={this.isDebuggingMode() ? "disabled" : "able"}
          >
            <RiFileCopyLine size={14} />
          </div>
        </Tooltip>
        <Tooltip title={`Paste (${UserShortKey(Enums.ShortKeyDefine.PASTE)})`}>
          <div
            key={"paste"}
            id="paste"
            name="paste"
            size="sm"
            onClick={
              this.isDebuggingMode()
                ? () => {}
                : ObjectUtils.isEmpty(this.props.command.clipboard)
                ? () => {}
                : this.onClickPaste
            }
            disabled={ObjectUtils.isEmpty(this.props.command.clipboard)}
            className={
              this.isDebuggingMode()
                ? "disabled"
                : ObjectUtils.isEmpty(this.props.command.clipboard)
                ? "disabled"
                : "able"
            }
          >
            <BiPaste size={14} />
          </div>
        </Tooltip>
      </div>
    );
  }

  renderUndoRedo() {
    return (
      <>
        <div className="edit-button-group">
          <Tooltip title={`Undo (${UserShortKey(Enums.ShortKeyDefine.UNDO)})`}>
            <div
              key={"undo"}
              id="undo"
              name="undo"
              onClick={this.isDebuggingMode() ? () => {} : this.onClickUndo}
              className={
                this.isDebuggingMode()
                  ? "disabled"
                  : this.props.command.prev.length +
                      this.props.command.undoIndex >
                    -1
                  ? "able"
                  : "disabled"
              }
              ref={this.undoRef}
            >
              <FaUndo />
            </div>
          </Tooltip>
          <Tooltip title={`Redo (${UserShortKey(Enums.ShortKeyDefine.REDO)})`}>
            <div
              key={"redo"}
              id="redo"
              name="redo"
              onClick={this.isDebuggingMode() ? () => {} : this.onClickRedo}
              className={
                this.isDebuggingMode()
                  ? "disabled"
                  : this.props.command.undoIndex < -1
                  ? "able"
                  : "disabled"
              }
              ref={this.redoRef}
            >
              <FaRedo />
            </div>
          </Tooltip>
        </div>
      </>
    );
  }

  renderAdditionalButton() {
    //컨버트 로그가 있는지 여부 확인 -> 로그 오픈 버튼에 사용
    const isExistConvertLog = ArrayUtils.isArray(
      this.props.workflow.serviceInfo.convertLog
    );
    return (
      <>
        <ButtonGroup>
          <ToggleButton
            key={1}
            id="select"
            name="pointerMode"
            type="radio"
            variant="outline-dark"
            value="E"
            size="sm"
            checked={this.props.builderMode === "select"}
            onChange={
              this.isDebuggingMode()
                ? () => {}
                : (e) => this.props.setBuilderMode("select")
            }
            style={{ fontSize: "smaller" }}
            disabled={this.isDebuggingMode()}
          >
            <AiOutlineSelect size="14" /> Select
          </ToggleButton>
          <ToggleButton
            key={2}
            id="edit"
            name="pointerMode"
            type="radio"
            variant="outline-dark"
            value="S"
            size="sm"
            checked={this.props.builderMode === "edit"}
            onChange={
              this.isDebuggingMode()
                ? () => {}
                : (e) => this.props.setBuilderMode("edit")
            }
            disabled={this.isDebuggingMode()}
            style={{ fontSize: "smaller" }}
          >
            <FaMousePointer size="14" /> Edit
          </ToggleButton>
        </ButtonGroup>
        <div className="edit-button-group">
          <Tooltip title={`Comment on selection`} placement="bottom">
            <div
              key={"cut"}
              id="cut"
              name="cut"
              variant="outline-dark"
              size="sm"
              onClick={
                this.isDebuggingMode()
                  ? () => {}
                  : (e) => this.props.onCommentProcess()
              }
              disabled={this.isDebuggingMode()}
              className={this.isDebuggingMode() ? "disabled" : "able"}
            >
              <BiCommentX size={14} />
            </div>
          </Tooltip>
          <Tooltip title={`Delete selection`} placement="bottom">
            <div
              key={"cut"}
              id="cut"
              name="cut"
              disabled={this.isDebuggingMode()}
              onClick={this.props.onDeleteSelectedNode}
              className={this.isDebuggingMode() ? "disabled" : "able"}
            >
              <BiTrash size={14} />
            </div>
          </Tooltip>
        </div>
        <div className="edit-button-group">
          <Tooltip title={`Run Workflow`} placement="bottom">
            <div
              key={"run"}
              id="run"
              name="run"
              variant="outline-dark"
              size="sm"
              disabled={this.props.debug.inCommunication}
              className={this.props.debug.inCommunication ? "disabled" : "able"}
              onClick={this.props.runWorkflow}
            >
              <VscDebugStart size={14} />
            </div>
          </Tooltip>
          <Tooltip title={`Debug Workflow`} placement="bottom">
            <div
              key={"debug"}
              id="debug"
              name="debug"
              disabled={this.props.debug.inCommunication}
              className={this.props.debug.inCommunication ? "disabled" : "able"}
              onClick={this.props.debugWorkflow}
            >
              <VscDebugAltSmall size={14} />
            </div>
          </Tooltip>
        </div>
        {this.isDebuggingMode() ? (
          <>
            <div className="edit-button-group">
              <Tooltip title={`Resume`} placement="bottom">
                <div
                  key={"Resume"}
                  id="Resume"
                  name="Resume"
                  variant="outline-dark"
                  size="sm"
                  onClick={this.props.resume}
                >
                  <GrResume size={14} />
                </div>
              </Tooltip>
              <Tooltip title={`Step Over`} placement="bottom">
                <div
                  key={"stepOver"}
                  id="stepOver"
                  name="stepOver"
                  variant="outline-dark"
                  size="sm"
                  onClick={this.props.stepOver}
                >
                  <VscDebugStepOver size={14} />
                </div>
              </Tooltip>
              <Tooltip title={`Step Into`} placement="bottom">
                <div
                  key={"stepInto"}
                  id="stepInto"
                  name="stepInto"
                  variant="outline-dark"
                  size="sm"
                  onClick={this.props.stepInto}
                >
                  <VscDebugStepInto size={14} />
                </div>
              </Tooltip>
              <Tooltip title={`Step Out`} placement="bottom">
                <div
                  key={"stepOut"}
                  id="stepOut"
                  name="stepOut"
                  variant="outline-dark"
                  size="sm"
                  onClick={this.props.stepOut}
                >
                  <VscDebugStepOut size={14} />
                </div>
              </Tooltip>
              <Tooltip title={`Stop`} placement="bottom">
                <div
                  key={"stop"}
                  id="stop"
                  name="stepOut"
                  variant="outline-dark"
                  size="sm"
                  onClick={this.props.stop}
                >
                  <FaStop size={14} />
                </div>
              </Tooltip>
            </div>
          </>
        ) : (
          <></>
        )}

        <ButtonGroup>
          <Tooltip title={`Console`} placement="bottom">
            <ToggleButton
              id="console"
              name="console"
              type="checkbox"
              variant="outline-dark"
              value="console"
              checked={this.props.debugConsoleMode}
              onChange={(e) =>
                this.props.setDebugConsoleMode(!this.props.debugConsoleMode)
              }
              size="sm"
            >
              <VscWindow size={14} />
            </ToggleButton>
          </Tooltip>
          <Tooltip title={`Debug Breakpoint`} placement="bottom">
            <ToggleButton
              key={1}
              id="breakpoint"
              name="expressionMode"
              type="radio"
              variant="outline-dark"
              value="breakpoint"
              size="sm"
              checked={this.props.expressionMode === "breakpoint"}
              onChange={(e) =>
                e.target.checked
                  ? this.props.debugExprenssion("breakpoint")
                  : ""
              }
              style={{ fontSize: "smaller" }}
            >
              <VscActivateBreakpoints size={14} />
            </ToggleButton>
          </Tooltip>
          {this.props.debug.isDebugging ? (
            <>
              <Tooltip title={`Debug Variables`} placement="bottom">
                <ToggleButton
                  key={1}
                  id="variable"
                  name="expressionMode"
                  type="radio"
                  variant="outline-dark"
                  value="variable"
                  size="sm"
                  checked={this.props.expressionMode === "variable"}
                  onChange={(e) =>
                    e.target.checked
                      ? this.props.debugExprenssion("variable")
                      : ""
                  }
                  style={{ fontSize: "smaller" }}
                >
                  <BiCodeBlock size={14} />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={`Debug Exprenssions`} placement="bottom">
                <ToggleButton
                  key={2}
                  id="expression"
                  name="expressionMode"
                  type="radio"
                  variant="outline-dark"
                  value="expression"
                  size="sm"
                  checked={this.props.expressionMode === "expression"}
                  onChange={(e) =>
                    e.target.checked
                      ? this.props.debugExprenssion("expression")
                      : ""
                  }
                >
                  <VscDebugConsole size="14" />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={`Debug Exprenssions`} placement="bottom">
                <ToggleButton
                  key={2}
                  id="trace"
                  name="expressionMode"
                  type="radio"
                  variant="outline-dark"
                  value="trace"
                  size="sm"
                  checked={this.props.expressionMode === "trace"}
                  onChange={(e) =>
                    e.target.checked ? this.props.debugExprenssion("trace") : ""
                  }
                >
                  <MdConnectingAirports size={14} />
                </ToggleButton>
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </ButtonGroup>

        <div className="edit-button-group">
          <Tooltip title={`Align Horizontal`} placement="bottom">
            <div
              id="hrizontal"
              name="hrizontal"
              variant="outline-dark"
              size="sm"
              onClick={(e) => this.onClickArrange(e, "horizontal")}
            >
              <MdAlignVerticalCenter size={14} />
            </div>
          </Tooltip>
          <Tooltip title={`Align Vertical`} placement="bottom">
            <div
              id="vertical"
              name="vertical"
              variant="outline-dark"
              size="sm"
              onClick={(e) => this.onClickArrange(e, "vertical")}
            >
              <MdAlignHorizontalCenter size={14} />
            </div>
          </Tooltip>
          <Tooltip title={`Node Group`} placement="bottom">
            <div
              className={`${this.props.bundlingMode ? "selected" : ""}`}
              id="vertical"
              name="vertical"
              variant="outline-dark"
              size="sm"
              onClick={this.props.setBundlingMode}
            >
              <GrBundle size={14} />
            </div>
          </Tooltip>
          <Tooltip placement="bottom" title={"Move with Sub Nodes"}>
            <ToggleButton
              key={3}
              id="nextNodeMove"
              name="nextNodeMove"
              type="checkbox"
              variant="outline-dark"
              value="S"
              size="sm"
              checked={this.props.isLowerNodeMove}
              onChange={(e) => this.props.setIsLowerNodeMove(e.target.checked)}
              disabled={this.isDebuggingMode()}
            >
              <AiOutlineNodeExpand size={14} />
            </ToggleButton>
          </Tooltip>
        </div>
        {isExistConvertLog && (
          <div className="edit-button-group">
            <Tooltip title={`Log`} placement="bottom">
              <div
                id="hrizontal"
                name="hrizontal"
                variant="outline-dark"
                size="sm"
                onClick={(e) => this.onClickOpenLog()}
              >
                <MdOutlineShortText size={14} />
              </div>
            </Tooltip>
          </div>
        )}
      </>
    );
  }

  render() {
    return this.renderComponent();
  }
}

export default connect(
  (state) => {
    return {
      command: state.command,
      workspace: state.workspace,
      workflow: state.workflow,
      debug: state.workflowDebug,
    };
  },
  (dispatch) => ({
    undo: (workflow) => {
      dispatch(updateWorkflow(workflow));
      dispatch(undo());
    },
    redo: (workflow) => {
      dispatch(updateWorkflow(workflow));
      dispatch(redo());
    },
    setClipboard: (body) => {
      dispatch(setClipboard(body));
    },
    setIsDebugging: (flag) => {
      dispatch(setWFIsDebugging(flag));
    },
    deleteProcess: (data, prevWorkflow) => {
      WorkflowReduxHelper.deleteProcess(dispatch, data, prevWorkflow);
    },
    updateWorkflow: (workflow, prevWorkflow) => {
      WorkflowReduxHelper.updateWorkflow(dispatch, workflow, prevWorkflow);
    },
    updateNodes: (nodes, prevWorkflow) => {
      WorkflowReduxHelper.updateNodes(dispatch, nodes, prevWorkflow);
    },
    updateIteratorNode: (nodes, iteratorInfo, prevWorkflow) => {
      WorkflowReduxHelper.updateIteratorNode(
        dispatch,
        nodes,
        iteratorInfo,
        prevWorkflow
      );
    },
    moveToPrevService: (service, prevWorkflow) => {
      WorkflowReduxHelper.moveToPrevService(dispatch, service, prevWorkflow);
    },
    saveBundle: (bundle, updatedNodeList, prevWorkflow) => {
      WorkflowReduxHelper.saveBundle(
        dispatch,
        prevWorkflow,
        bundle,
        updatedNodeList
      );
    },
    setConvertLog: (flag) => {
      dispatch(setCsServiceLog(flag));
    },
  })
)(WorkflowCommandButton);
