import { Enums } from "components/builder/BuilderEnum";
import UInputPopup from "components/common/element/UInputPopup";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { MobileLayoutContext } from "page/mobile/appConfiguration";
import IconPopup from "page/popup/IconPopup";
import ProgramListPopup from "page/popup/ProgramListPopup";
import { useEffect } from "react";
import { memo, useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import * as Fa from "react-icons/fa";
import { useSelector } from "react-redux";

const MobileBottomNavSetting = memo(
  ({ initial, nextCallback, prevCallback, ...props }) => {
    const [navNum, setnavNum] = useState(-1); //선택된 하단 nav 번호
    const [selectedNav, setSelectedNav] = useState({}); //선택된 하단 nav card 정보
    const workspace = useSelector((state) => state.workspace);
    const { saveLayout, bnbTmpl, setBnbTmpl, bnb } =
      useContext(MobileLayoutContext);

    const handleDragEnd = (result) => {
      if (!result.destination) return;
      let sIdx = result.source.index;
      let dIdx = result.destination.index;

      if (sIdx !== dIdx) {
        const items = [...bnbTmpl];
        const [reorderedItem] = items.splice(sIdx, 1);
        items.splice(dIdx, 0, reorderedItem);

        for (let i = 0; i < items.length; i++) {
          items[i]["sortSeq"] = i + 1;
        }
        setBnbTmpl(items);
        resetSelectedNav();
        setnavNum(-1);
      }
    };

    /**
     * 하단 네비게이션 설정  우측 데이터값 비우기
     */
    const resetSelectedNav = (num) => {
      const nav = {
        bottomNavId: "",
        label: "",
        icon: "",
        linkUrl: "",
      };

      for (let i = 0; i < bnbTmpl.length; i++) {
        document.getElementById("card" + i).className = "navi-card";
      }

      setSelectedNav(nav);
      if (num) {
        setnavNum(num);
      } else {
        setnavNum(-1);
      }
    };

    const getListStyle = () => ({
      display: "flex",
    });

    /**
     * icon popup 호출
     * @param {*} e
     */
    const openPopupIcon = (e) => {
      //if 선택된 navigation card가 없을 경우 -> 메세지 alert 후 return;
      if (navNum === -1) {
        Message.alert("선택된 네비게이션이 없습니다.", Enums.MessageType.WARN);
        return;
      }

      const popOptions = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            height: "700px",
          },
        },
      };
      Popup.open(
        <IconPopup
          title="Icon 조회"
          callbackFnc={(icon) => {
            let tmpNav = { ...selectedNav };
            tmpNav.icon = icon;
            setSelectedNav(tmpNav);

            let navList = [...bnbTmpl];
            navList[navNum] = tmpNav;
            setBnbTmpl(navList);

            let el = document.getElementById("icon");
            el.value = icon;
          }}
        />,
        popOptions
      );
    };

    /**
     * nav 카드 선택시 event
     * @param {*} idx
     */
    const navClicked = (idx) => {
      if (!ObjectUtils.isEmpty(document.getElementById("card" + idx))) {
        if (!StringUtils.equalsIgnoreType(navNum, "-1")) {
          let navList = [...bnbTmpl];
          navList[navNum] = selectedNav;
          setBnbTmpl(navList);
        }

        setnavNum(idx);
        document.getElementById("card" + idx).className = "navi-card-selected";
        for (let i = 0; i < bnbTmpl.length; i++) {
          if (i !== idx) {
            document.getElementById("card" + i).className = "navi-card";
          }
        }
        setSelectedNav(bnbTmpl[idx]);
      }
    };

    /**
     * nav 카드 삭제 event
     * @param {*} idx
     */
    const deleteCard = (idx) => {
      let navList = [...bnbTmpl];
      navList.splice(idx, 1);
      setBnbTmpl(navList);
      if (navNum === idx) {
        for (let i = 0; i < navList.length; i++) {
          document.getElementById("card" + i).className = "navi-card";
        }
      }

      resetSelectedNav();
    };

    /**
     * bottom nav bar - 카드 추가
     */
    const addCard = () => {
      const nav = {
        bottomNavId: "",
        label: "",
        icon: "",
        linkUrl: "",
        appId: workspace.appId,
        sortSeq: bnbTmpl.length,
      };

      let navList = [...bnbTmpl];
      navList.push(nav);
      setBnbTmpl(navList);
    };

    /**
     * 하단 네비게이션 저장 함수
     */
    const saveBottomNavBar = () => {
      if (!ObjectUtils.isEmpty(bnbTmpl)) {
        saveLayout({
          ...bnb,
          layoutType: "B",
          customContent: bnbTmpl,
          tmplType: "C",
          layoutPresetId: 0,
        });
        if (initial) {
          nextCallback();
        }
      } else {
        Message.alert(
          "At least one bottom button must be set to save.",
          Enums.MessageType.WARN
        );
      }
    };

    const onClickProgramPopup = () => {
      const callback = (program) => {
        const nav = { ...selectedNav };
        nav.linkUrl = program.programId;
        setSelectedNav(nav);
      };

      Popup.open(
        <ProgramListPopup
          callbackFnc={callback}
          params={{ builderType: "M" }}
          workspace={workspace}
          title={"Search Mobile Program"}
        />,
        {
          style: { content: { width: "1200px" } },
        }
      );
    };

    /**
     * 스킵 버튼 이벤트
     */
    const onClickSkip = () => {};

    return (
      <Row className="ls-container">
        <Col sm={2}>
          <div className="ls-header">Set Bottom Navigation</div>
          <div className="ls-description">
            Set up the bottom navigation. <br />
            Configure the icons, labels, and programs to be called.
          </div>
        </Col>
        <Col sm={10}>
          <div className="ls-content">
            <Row className="mb-3">
              <Col sm={8} style={{ display: "flex" }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId={"droppable1"} direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle()}
                      >
                        {bnbTmpl.map((item, idx) => {
                          let IconTag = "";
                          if (!StringUtils.isEmpty(item.icon)) {
                            IconTag =
                              Fa[StringUtils.convertKebabToPascal(item.icon)];
                          }

                          return (
                            <Draggable
                              draggableId={`card-${idx}`}
                              key={`card-${idx}`}
                              index={idx}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    key={`card-${idx}`}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <Card
                                      className="navi-card"
                                      id={"card" + idx}
                                      onClick={(e) => {
                                        navClicked(idx);
                                      }}
                                    >
                                      <Card.Body>
                                        {IconTag !== "" ? (
                                          <div className="navi-card-icon">
                                            <IconTag />
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div className="navi-card-label">
                                          {" "}
                                          {item.label}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                    <div style={{ width: 0 }}>
                                      <div
                                        className="nav-card-delBtn"
                                        onClick={(e) => deleteCard(idx)}
                                      >
                                        <Fa.FaMinus />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {bnbTmpl.length < 5 ? (
                  <Card
                    className="navi-card navi-card-plus"
                    onClick={(e) => addCard()}
                  >
                    <div className="navi-card-icon">
                      <Fa.FaPlus />
                    </div>
                  </Card>
                ) : (
                  ""
                )}
              </Col>
              <Col sm={4}>
                <div className="navi-detail">
                  <Row className="navi-detail-row">
                    <Col sm={2}>
                      <Form.Label>Icon</Form.Label>
                    </Col>
                    <Col sm={10}>
                      <UInputPopup
                        id="icon"
                        defaultValue={StringUtils.defaultString(
                          selectedNav.icon,
                          ""
                        )}
                        // onBlur={onBlur}
                        onClick={openPopupIcon}
                        readonlyTp="input"
                      />
                    </Col>
                  </Row>
                  <Row className="navi-detail-row">
                    <Col sm={2}>
                      <Form.Label>Page</Form.Label>
                    </Col>
                    <Col sm={10}>
                      <InputGroup>
                        <Form.Control
                          className="form-control-sm"
                          value={selectedNav.linkUrl}
                          onChange={(e) => {
                            const nav = { ...selectedNav };
                            nav.linkUrl = e.target.value;
                            setSelectedNav(nav);
                          }}
                          disabled={
                            ObjectUtils.isEmpty(selectedNav) || navNum === -1
                          }
                        />
                        <Button
                          size="sm"
                          onClick={onClickProgramPopup}
                          variant="outline-secondary"
                        >
                          <Fa.FaSearch />
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="navi-detail-row">
                    <Col sm={2}>
                      <Form.Label>Id</Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="form-control-sm"
                        value={selectedNav.bottomNavId}
                        onChange={(e) => {
                          const nav = { ...selectedNav };
                          nav.bottomNavId = e.target.value;
                          setSelectedNav(nav);
                        }}
                        disabled={
                          ObjectUtils.isEmpty(selectedNav) || navNum === -1
                        }
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row className="navi-detail-row">
                    <Col sm={2}>
                      <Form.Label>Label</Form.Label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className="form-control-sm"
                        value={selectedNav.label}
                        onChange={(e) => {
                          const nav = { ...selectedNav };
                          nav.label = e.target.value;
                          setSelectedNav(nav);
                        }}
                        disabled={
                          ObjectUtils.isEmpty(selectedNav) || navNum === -1
                        }
                      ></Form.Control>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              {initial ? (
                <>
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button variant="link" onClick={prevCallback}>
                      Prev
                    </Button>
                    <div>
                      <Button variant="link" onClick={onClickSkip}>
                        Skip
                      </Button>
                      <Button variant="link" onClick={saveBottomNavBar}>
                        Save & Next
                      </Button>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={saveBottomNavBar}>Save</Button>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
);

export default MobileBottomNavSetting;
