import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Message from "components/common/Message";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
  FaUpload,
} from "react-icons/fa";
import * as Fa from "react-icons/fa6";

const MobileLayoutEnums = {
  AreaTypeEnums: {
    TITLE: "title",
    BUTTON: "btn",
    LOGO: "logo",
  },
  ButtonTypeEnums: {
    Menu: "menu",
    User: "user",
    Search: "search",
    GoBack: "goBack",
    // Alery: "alert",
    Config: "config",
    Home: "home",
    Logout: "logout",
  },
  ButtonIcon: {
    menu: "FaBars",
    user: "FaUser",
    search: "FaMagnifyingGlass",
    goBack: "FaAngleLeft",
    alert: "FaRegBell",
    config: "FaGear",
    home: "FaHouse",
    logout: "FaPowerOff",
  },
};

const getEmptyBox = () => {
  return {
    width: "10",
    unit: "%",
    areaType: "btn",
  };
};

const MobileTopNavCustomEditor = ({
  callbackFnc,
  structure,
  setIsCustomCreate,
  ...props
}) => {
  const [topNavStructure, setTopNavStructure] = useState(
    Array(5).fill({ ...getEmptyBox() })
  );
  const [selectedArea, setSelectedArea] = useState(0);
  const widthInputRef = useRef();
  const fileUploadRef = useRef();

  useEffect(() => {
    if (!ObjectUtils.isEmpty(structure)) {
      setTopNavStructure(structure);
    }
  }, []);

  useEffect(() => {
    if (
      !Number.isNaN(selectedArea) &&
      selectedArea > -1 &&
      widthInputRef.current
    ) {
      widthInputRef.current.value = topNavStructure[selectedArea].width;
    }
  }, [selectedArea]);

  const onClickConfirm = (e) => {
    stopEvent(e);

    //메뉴 버튼은 반드시 포함되어야 함
    const menuButton = topNavStructure.find(
      (area) =>
        area.areaType === MobileLayoutEnums.AreaTypeEnums.BUTTON &&
        area.buttonType === MobileLayoutEnums.ButtonTypeEnums.Menu
    );
    if (!menuButton)
      return Message.alert(
        "The menu button must be included.",
        Enums.MessageType.WARN
      );
    if (callbackFnc) {
      setIsCustomCreate(false);
      callbackFnc(topNavStructure);
    }
  };

  /**
   * prop값 변경
   * @param {*} e
   */
  const onChangeAreaPropertyValue = (e) => {
    let value = e.target.value;

    if (e.target.id === "width") {
      value = Number(value);
      if (topNavStructure[selectedArea].unit === "%") {
        if (!value || value < 10) {
          value = 10;
          widthInputRef.current.value = value;
          Message.alert("Min Width 10%", Enums.MessageType.WARN);
        }
      } else {
        if (!value || value < 30) {
          value = 30;
          widthInputRef.current.value = value;
          Message.alert("Min Width 30px", Enums.MessageType.WARN);
        }
      }
    }

    setTopNavStructure(
      produce(topNavStructure, (draft) => {
        if (selectedArea > -1) {
          draft[selectedArea][e.target.id] = value;
          if (
            e.target.id === "buttonType" &&
            value !== MobileLayoutEnums.ButtonTypeEnums.Logo
          ) {
            draft[selectedArea].icon = MobileLayoutEnums.ButtonIcon[value];
          }
        }
      })
    );
  };

  const deleteCard = (e, index) => {
    stopEvent(e);
    setSelectedArea(-1);
    if (topNavStructure.length === 1)
      return Message.alert(
        "At least one menu button is required.",
        Enums.MessageType.INFO
      );
    const n = produce(topNavStructure, (draft) => {
      draft.splice(index, 1);
    });
    setTopNavStructure(n);
  };

  /**
   * bottom nav bar - 카드 추가
   */
  const addCard = () => {
    const nav = getEmptyBox();
    let navList = [...topNavStructure];
    navList.push(nav);
    if (navList.length > 7) {
      return Message.alert(
        "Top Navigation boundary is between 1 to 7",
        Enums.MessageType.INFO
      );
    } else {
      setTopNavStructure(navList);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    let sIdx = result.source.index;
    let dIdx = result.destination.index;
    // debugger;
    if (sIdx !== dIdx) {
      const items = cloneDeep(topNavStructure);
      const [reorderedItem] = items.splice(sIdx, 1);
      items.splice(dIdx, 0, reorderedItem);

      setTopNavStructure(items);
      setSelectedArea(-1);
    }
  };

  const onClickAlign = (align) => {
    setTopNavStructure(
      produce(topNavStructure, (draft) => {
        if (selectedArea > -1) {
          draft[selectedArea].align = align;
        }
      })
    );
  };

  return (
    <Row className="mb-3">
      <Col sm={8}>
        <div className="tnb-custom-wrapper">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={"droppable1"} direction="horizontal">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="tnb-custom"
                >
                  {topNavStructure.map((item, idx) => {
                    const selected = idx === selectedArea;

                    const isTitle =
                      item.areaType === MobileLayoutEnums.AreaTypeEnums.TITLE;
                    const Icon =
                      item.areaType ===
                        MobileLayoutEnums.AreaTypeEnums.BUTTON && item.icon
                        ? Fa[item.icon]
                        : null;
                    const LogoUrl =
                      item.areaType === MobileLayoutEnums.AreaTypeEnums.LOGO &&
                      !StringUtils.isEmpty(item.logo)
                        ? item.logo
                        : "";
                    let align = "";
                    let logoStyle = {};
                    if (
                      item.areaType === MobileLayoutEnums.AreaTypeEnums.LOGO
                    ) {
                      align = "left";
                      switch (item.align) {
                        case "left":
                          align = "flex-start";
                          break;
                        case "center":
                          align = "center";
                          break;
                        case "right":
                          align = "flex-end";
                          break;
                        default:
                          break;
                      }
                      logoStyle.justifyContent = align;
                      logoStyle.display = "flex";
                    }

                    return (
                      <Draggable
                        draggableId={`card-${idx}`}
                        key={`card-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              key={`card-${idx}`}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={{
                                ...provided.draggableProps.style,
                                width: snapshot.isDragging
                                  ? "50px"
                                  : `${item.width}${item.unit}`,
                              }}
                            >
                              <Card
                                className={`tnb-card ${
                                  selected ? "selected" : ""
                                }`}
                                id={"card" + idx}
                                onClick={(e) => setSelectedArea(idx)}
                              >
                                <Card.Body style={logoStyle}>
                                  {isTitle ? (
                                    <span>Application Title</span>
                                  ) : Icon ? (
                                    <Icon />
                                  ) : LogoUrl ? (
                                    <img src={LogoUrl} alt="company logo" />
                                  ) : (
                                    <></>
                                  )}
                                </Card.Body>
                                <div
                                  className="tnb-delBtn"
                                  onClick={(e) => deleteCard(e, idx)}
                                >
                                  <Fa.FaMinus />
                                </div>
                              </Card>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={addCard} variant="outline-primary">
            ADD Column
          </Button>
        </div>
      </Col>
      <Col sm={4}>
        <div className="navi-detail">
          <Row className="navi-detail-row">
            <Form.Label>Area Width</Form.Label>
            <Col xs={8}>
              <Form.Control
                placeholder="Min Width 10"
                onBlur={onChangeAreaPropertyValue}
                id="width"
                ref={widthInputRef}
                type="number"
              />
            </Col>
            <Col xs={4}>
              <Form.Select
                onChange={onChangeAreaPropertyValue}
                id="unit"
                value={topNavStructure[selectedArea]?.unit}
              >
                <option value="px">px</option>
                <option value="%">%</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>Area Type</Form.Label>
              <Form.Select
                onChange={onChangeAreaPropertyValue}
                id="areaType"
                value={topNavStructure[selectedArea]?.areaType}
              >
                {Object.keys(MobileLayoutEnums.AreaTypeEnums).map((key) => {
                  return (
                    <option
                      value={MobileLayoutEnums.AreaTypeEnums[key]}
                      key={key}
                    >
                      {key}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
          {topNavStructure[selectedArea]?.areaType ===
            MobileLayoutEnums.AreaTypeEnums.TITLE && (
            <>
              <Row className="mb-3">
                <Col>
                  <Form.Label>App Name</Form.Label>
                  <Form.Control
                    placeholder="App name will be diplayed"
                    disabled
                  />
                </Col>
              </Row>
            </>
          )}
          {topNavStructure[selectedArea]?.areaType ===
            MobileLayoutEnums.AreaTypeEnums.BUTTON && (
            <>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Button Type</Form.Label>
                  <Form.Select
                    placeholder="auto"
                    value={topNavStructure[selectedArea]?.buttonType || ""}
                    onChange={onChangeAreaPropertyValue}
                    id={"buttonType"}
                  >
                    <option value={""}>Select</option>
                    {Object.keys(MobileLayoutEnums.ButtonTypeEnums).map(
                      (key) => {
                        return (
                          <option
                            value={MobileLayoutEnums.ButtonTypeEnums[key]}
                            key={key}
                          >
                            {key}
                          </option>
                        );
                      }
                    )}
                  </Form.Select>
                </Col>
              </Row>
            </>
          )}
          {topNavStructure[selectedArea]?.areaType ===
            MobileLayoutEnums.AreaTypeEnums.LOGO && (
            <Row className="mb-3">
              <Col xs={7}>
                <Form.Label>Logo</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={topNavStructure[selectedArea]?.logoName}
                    onClick={(e) => fileUploadRef.current.click()}
                    readOnly
                  />
                  <Button onClick={(e) => fileUploadRef.current.click()}>
                    <FaUpload />
                  </Button>
                </InputGroup>

                <input
                  type="file"
                  id="btnFileUpload"
                  name="btnFileUpload"
                  className="upload-input hide"
                  accept="image/*"
                  ref={fileUploadRef}
                  onChange={(e) => {
                    if (ObjectUtils.isEmpty(e.target.files)) return;
                    const reader = new FileReader();
                    const files = e.target.files[0];
                    reader.readAsDataURL(files);
                    reader.onload = (e) => {
                      setTopNavStructure(
                        produce(topNavStructure, (draft) => {
                          draft[selectedArea].logoName = files.name;
                          draft[selectedArea].logo = e.target.result;
                        })
                      );
                    };
                  }}
                />
              </Col>
              <Col xs={5}>
                <Form.Label>Align</Form.Label>
                <FormGroup>
                  <ButtonGroup>
                    <Button
                      variant={
                        topNavStructure[selectedArea]?.align === "left"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      onClick={(e) => onClickAlign("left")}
                    >
                      <FaAlignLeft />
                    </Button>
                    <Button
                      variant={
                        topNavStructure[selectedArea]?.align === "center"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      onClick={(e) => onClickAlign("center")}
                    >
                      <FaAlignJustify />
                    </Button>
                    <Button
                      variant={
                        topNavStructure[selectedArea]?.align === "right"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      onClick={(e) => onClickAlign("right")}
                    >
                      <FaAlignRight />
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
        </div>
        <Row>
          <Col
            className="mt-3"
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <Button onClick={onClickConfirm} variant="success">
              Save Custom Top Nav
            </Button>
            <Button
              onClick={(e) => setIsCustomCreate(false)}
              variant="outline-danger"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MobileTopNavCustomEditor;
