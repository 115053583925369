import React, { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";

const MobileFilterPopup = ({ isOpen, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const closeFilter = (e) => {
    setIsVisible(false);
    onClose(e);
  };

  return (
    <div
      className={`filter-overlay ${isVisible ? "visible" : ""}`}
      onClick={closeFilter}
      role="dialog"
      aria-hidden={!isOpen}
    >
      <div
        className={`filter-container ${isVisible ? "slide-in" : "slide-out"}`}
        onClick={(e) => e.stopPropagation()} // Prevent close on popup content click
      >
        <button
          className="filter-close-button"
          onClick={closeFilter}
          aria-label="Close Popup"
        >
          <FaAngleLeft />
        </button>
        <div className="filter-content">{children}</div>
        <div className="filter-btn-footer">
          <button size="lg" className="filter-btn filter-reset-btn">
            초기화
          </button>
          <button size="lg" className="filter-btn filter-apply-btn">
            적용
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterPopup; //React.memo(MobileFilterPopup);
