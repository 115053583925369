import produce from "immer";
import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";

import * as Enums from "components/builder/BuilderEnum";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import CommonUtils, {
  ArrayUtils,
  NumberUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";

// import { borderRight } from "@mui/system";
import loadable from "@loadable/component";
import classNames from "classnames";
import { useSelector } from "react-redux";

const UIEditorColumn = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation, isLast } =
    props;
  const ref = useRef(null);
  const componentRef = useRef();
  const [isOver, setIsOver] = useState(false);

  if (!componentRef.current) {
    const theme = CommonUtils.getTheme();
    componentRef.current = loadable(() =>
      import(`components/builder/ui/editor/theme/${theme}/layout/Column`)
    );
  }

  const { isMobileEditor, screenWidth } = useSelector((state) => state.mobile);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    componentType: item.componentType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const propertyValue = ObjectUtils.isEmpty(item.propertyValue)
    ? {}
    : item.propertyValue;

  //editor의 column flex layout이기 때문에 상위 div에 같이 반영해줘야함
  let parentStyle = {}; //alignSelf: "center" };
  let columnSyle = { ...opacity };

  //desktop width는 적용해준다.
  // if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
  //   parentStyle.width = (Number(propertyValue.widthLaptop) * 100) / 12 + "%";
  // }

  if (isMobileEditor) {
    if (
      Number(screenWidth) >= 768 &&
      NumberUtils.isNumeric(propertyValue.widthTablet)
    ) {
      parentStyle.width = (Number(propertyValue.widthTablet) * 100) / 12 + "%";
    } else if (
      Number(screenWidth) < 768 &&
      NumberUtils.isNumeric(propertyValue.widthPhone)
    ) {
      parentStyle.width = (Number(propertyValue.widthPhone) * 100) / 12 + "%";
    }
  } else if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
    parentStyle.width = (Number(propertyValue.widthLaptop) * 100) / 12 + "%";
  }

  if (propertyValue.style) {
    //&& propertyValue.style.width) {
    columnSyle = { ...columnSyle, ...propertyValue.style }; //.width = propertyValue.style.width;

    //runtime에 적용되는 flex 관련 설정은 editor에 적용 안되도록 한다.
    //delete columnSyle.width;
    delete columnSyle.minWidth;
    delete columnSyle.maxWidth;
    delete columnSyle.display;
    delete columnSyle.flex;
    delete columnSyle.justifyContent;

    //상위 tag에 width 관련 css를 같이 적용 해준다.
    if (propertyValue.style.width) {
      parentStyle.width = propertyValue.style.width;
    }
    if (propertyValue.style.minWidth) {
      parentStyle.minWidth = propertyValue.style.minWidth;
    }
    if (propertyValue.style.maxWidth) {
      parentStyle.maxWidth = propertyValue.style.maxWidth;
    }
  }
  /*
  if (isLast === true) {
    columnSyle = { ...columnSyle, ...{ borderRight: "1px solid #bcb0b0" } };
  }
  */

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  const handleDragOver = (e) => {
    e.preventDefault();
    if (ref.current && ref.current.contains(e.target)) {
      setIsOver(true);
    }
  };
  //  const target = e.target.closest('.draggable-item');
  //if (e.target.classList.contains('my-class')) {

  const handleDragLeave = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.relatedTarget) &&
      !isPlainDiv(e.relatedTarget)
    ) {
      setIsOver(false); // 드롭 후 색상 복원
    }
  };
  const onhandleDrop = (e) => {
    e.preventDefault();
    setIsOver(false); // 드롭 후 색상 복원
  };

  /**
   * <div></div>를 확인해서 넘어가는 메서드
   * @param {*} element
   * @returns
   */
  const isPlainDiv = (element) => {
    if (!element || typeof element !== "object") {
      return false; // element가 유효하지 않으면 false 반환
    }

    // 1. 태그 이름이 "DIV"인지 확인
    const isDivTag = element.tagName && element.tagName === "DIV";

    // 2. 속성이 없는지 확인
    const hasNoAttributes = element.attributes.length === 0;

    // 3. 클래스와 ID가 없는지 확인
    const hasNoClassOrID = element.classList.length === 0 && !element.id;

    // 4. 자식 요소가 없는지 확인
    const hasNoChildren = element.children.length === 0;

    // 5. 텍스트 콘텐츠가 없는지 확인
    const hasNoTextContent = element.textContent.trim() === "";

    // 모든 조건이 true여야 <div></div>로 간주
    return (
      isDivTag &&
      hasNoAttributes &&
      hasNoClassOrID &&
      hasNoChildren &&
      hasNoTextContent
    );
  };

  return (
    <div
      ref={ref}
      className={classNames("whole-wrap column-over", { active: isOver })}
      style={parentStyle}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={onhandleDrop}
    >
      <componentRef.current
        style={columnSyle}
        isLast={isLast}
        componentInfo={item}
        event="renderEditor"
      >
        {!ArrayUtils.isEmpty(item.child)
          ? item.child.map((child, index) => {
              const currentPath = `${path}-${index}`;
              return (
                <React.Fragment key={index}>
                  <DNDContainer
                    data={{
                      location: Enums.ComponentType.COLUMN,
                      rootLocation: rootLocation,
                      path: currentPath,
                      childrenCount: item.child.length,
                      propertyValue: data.propertyValue,
                      compId: data.compId,
                    }}
                    onDrop={handleDrop}
                    templateComponents={templateComponents}
                  />

                  <UIEditorChildRender
                    key={child.compId}
                    data={child}
                    handleDrop={handleDrop}
                    templateComponents={templateComponents}
                    path={currentPath}
                    dndContainer={DNDContainer}
                    rootLocation={rootLocation}
                  />
                </React.Fragment>
              );
            })
          : ""}
        <DNDContainer
          data={{
            location: Enums.ComponentType.COLUMN,
            rootLocation: rootLocation,
            path: `${path}-${
              ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
            }`,
            propertyValue: data.propertyValue,
            compId: data.compId,
            childrenCount: ArrayUtils.isEmpty(item.child)
              ? 0
              : item.child.length,
          }}
          onDrop={handleDrop}
          templateComponents={templateComponents}
        />
      </componentRef.current>
    </div>
  );
};
export default React.memo(UIEditorColumn);
