import ActionType from "components/builder/BuilderActionType";
import { Enums } from "components/builder/BuilderEnum";
import produce from "immer";

const initialState = {
  isMobileEditor: true,
  showPropTab: true,
  viewportDirection: Enums.MobileDirection.VERTICAL,
  os: Enums.MobileOsType.ANDROID,
  buttonShow: true,
  //에뮬레이터 실행 여부
  emulator: false,
  scale: 100,
  filterProgram: null,
  workspace: {},
};

export default function MobileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.MOBILE.INIT:
      return initialState;
    case ActionType.MOBILE.IS_MOBILE_EDITOR:
      return produce(state, (draft) => {
        draft.isMobileEditor = action.payload;
      });
    case ActionType.MOBILE.SCREEN_WIDTH:
      return produce(state, (draft) => {
        draft.screenWidth = action.payload;
      });
    case ActionType.MOBILE.SCREEN_HEIGHT:
      return produce(state, (draft) => {
        draft.screenHeight = action.payload;
      });
    case ActionType.MOBILE.SET_APP_LAYOUT:
      return produce(state, (draft) => {
        draft.appLayout = action.payload;
      });
    case ActionType.MOBILE.SET_PROP_TAB:
      return produce(state, (draft) => {
        draft.showPropTab = action.payload;
      });
    case ActionType.MOBILE.SET_VIEWPORT_DIRECTION:
      return produce(state, (draft) => {
        draft.viewportDirection = action.payload;
      });
    case ActionType.MOBILE.SET_DEVICE_OS:
      return produce(state, (draft) => {
        draft.os = action.payload;
      });
    case ActionType.MOBILE.SET_DEVICE_BUTTON_SHOW:
      return produce(state, (draft) => {
        draft.buttonShow = action.payload;
      });
    case ActionType.MOBILE.SET_SHOW_EMULATOR:
      return produce(state, (draft) => {
        draft.emulator = action.payload;
      });
    case ActionType.MOBILE.SET_VIEWPORT_SCALE:
      return produce(state, (draft) => {
        draft.scale = action.payload;
      });
    case ActionType.MOBILE.SET_ACTIVE_FILTER:
      return produce(state, (draft) => {
        draft.filterProgram = action.payload;
      });
    case ActionType.MOBILE.SET_MOBILE_APP:
      return produce(state, (draft) => {
        draft.workspace = action.payload;
      });
    default:
      return state;
  }
}
