import ArrayUtils from "components/common/utils/ArrayUtils";
import { debounce } from "components/common/utils/InputUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import produce from "immer";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import SunEditor from "suneditor-react";
import { setDesign, setUploadFile } from "../reducer/ProgramDesignAction";
import User from "components/common/utils/UserUtils";
import "css/builder/programDesign/screenLayout.css";
import { GrDocumentDownload } from "react-icons/gr";
import FileService from "services/programDesignService/FileService";
import FileDropZone from "components/common/FileDropZone";
import ProgramDesignDisplayLayout from "./ProgramDesignDisplayLayout";
import ProgramDesignSelectionLayout from "./ProgramDesignSelectionLayout";
import axios from "axios";
import { Enums } from "components/builder/BuilderEnum";
import config from "components/common/EnvConfig";
import Message from "components/common/Message";
import "css/builder/programDesign/screenLayout.css";

export const ScrollDiv = styled.div`
  max-height: calc(100vh - 200px);
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;
export const ScrollDiv2 = styled.div`
  max-height: 120px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const ProgramDesignScreenLayout = ({
  designMst,
  setDesignMst,
  flag,
  designLayoutColumnList,
  setDesignLayoutColumnList,
  designUploadFile,
  setDesignUploadFile,
}) => {
  const workspace = useSelector((state) => state.workspace);
  const [designMultilang, setDesignMultilang] = useState([]);
  const [editorContent, setEditorContent] = useState();
  const [fileList, setFileList] = useState([]);

  // const technicalDesign = useSelector((state) => state.technicalDesign);

  const dispatch = useDispatch();

  const editor = useRef();

  useEffect(() => {
    setDesignMultilang(designMst.designMultilang || []);
  }, [designMst]);

  useEffect(() => {
    setFileList(designUploadFile || []);
  }, [designUploadFile]);

  useEffect(() => {
    if (editorContent !== undefined) {
      onChangeDesignMultilang("screen_layout", editorContent);
    }
  }, [editorContent]);

  useEffect(() => {
    if (flag) {
      setDesignMst({});
      setDesignMultilang([]);
      setDesignLayoutColumnList([]);
      setDesignUploadFile([]);
    }
  }, [flag]);

  /**
   * multilang text 가져오기
   * @param {*} key
   * @returns
   */
  const getMultilangText = (key) => {
    if (ArrayUtils.isArray(designMultilang)) {
      const findMultilang = designMultilang.filter(
        (item) =>
          item.multilangCd === key &&
          item.langCd === User.getLanguage().toLowerCase()
      );
      if (!ArrayUtils.isEmpty(findMultilang)) {
        return findMultilang[0].multilangText;
      } else {
        return "";
      }
    }
  };

  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      let newDesignMultilang = [...designMultilang];

      const findIndex = designMultilang.findIndex(
        (item) =>
          item.multilangCd === key &&
          item.langCd === User.getLanguage().toLowerCase()
      );

      if (findIndex > -1) {
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft[findIndex].multilangText = value;
        });
      } else {
        const newData = {
          multilangCd: key,
          multilangText: value,
          langCd: User.getLanguage().toLowerCase(),
        };
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft.push(newData);
        });
      }
      onChangeDesignMst("designMultilang", newDesignMultilang);
      setDesignMultilang(newDesignMultilang);
    }
  };

  /**
   * 파일 변경시
   * @param {*} files
   */
  const onChangeFile = (files) => {
    setFileList(files);
    setDesignUploadFile(files);
    dispatch(setUploadFile(files));
  };

  /**
   * 파일 삭제
   * @param {*} fileModel
   */
  const removeFile = (fileModel) => {
    FileService.deleteFile(fileModel, (res) => {
      const newFileList = [...fileList];
      newFileList.splice(
        newFileList.findIndex((file) => file.fileId === fileModel.fileId),
        1
      );
      setFileList(newFileList);
    });
  };

  const fileDownLoad = (e, file) => {
    axios({
      method: "GET",
      url: `${config.baseUrl.server}/file/download/${file.fileId}`,
      responseType: "blob",
    })
      .then(function (response) {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", file.fileOriginalNm); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(function (error) {
        // an occured error
        Message.alert(error.message, Enums.MessageType.ERROR);
      });
  };

  /**
   * Editor blur
   * @param {*} e
   */
  const onBlurEditor = (e) => {
    setEditorContent(editor.current.getContents());
  };

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const renderScreenLayout = () => {
    return (
      <ScrollDiv>
        <Row>
          <Col>
            <FileDropZone
              onChangeFile={onChangeFile}
              fileList={fileList}
              removeFile={removeFile}
            />
          </Col>
          <Col sm={4}>
            <div className="file-list">
              {fileList?.map((file) => (
                <div className="file" onClick={(e) => fileDownLoad(e, file)}>
                  <GrDocumentDownload size={16} />{" "}
                  {file.fileOriginalNm ? file.fileOriginalNm : file.name}
                </div>
              ))}
            </div>
          </Col>
          {/* <Col sm={1}>
            <Button
              size="sm"
              variant="outline-success"
              onClick={() => onSaveFile()}
            >
              file upload
            </Button>
          </Col> */}
        </Row>
        <Row className="mt-3">
          <Col>
            <SunEditor
              lang={"ko"}
              onBlur={onBlurEditor}
              setContents={getMultilangText("screen_layout")}
              getSunEditorInstance={getSunEditorInstance}
              setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
              setOptions={{
                font: [
                  "Noto Sans KR",
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "tahoma",
                  "Trebuchet MS",
                  "Verdana",
                ],
                fontSize: [
                  8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                  72,
                ],
                buttonList: [
                  ["font", "fontSize", "formatBlock"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "fontColor",
                    "hiliteColor",
                  ],
                ],
              }}
            />
          </Col>
        </Row>
      </ScrollDiv>
    );
  };

  const tabContents = [
    {
      key: "screenLayout",
      title: "Screen Image",
      program: renderScreenLayout(),
    },
    {
      key: "selectionLayout",
      title: "Selection Layout",
      program: (
        <ProgramDesignSelectionLayout
          designMst={designMst}
          setDesignMst={setDesignMst}
          designLayoutColumnList={designLayoutColumnList}
          setDesignLayoutColumnList={setDesignLayoutColumnList}
          flag={flag}
        />
      ),
    },
    {
      key: "displayLayout",
      title: "Display Layout",
      program: (
        <ProgramDesignDisplayLayout
          designMst={designMst}
          setDesignMst={setDesignMst}
          designLayoutColumnList={designLayoutColumnList}
          setDesignLayoutColumnList={setDesignLayoutColumnList}
          flag={flag}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {/* <Row>
        <div style={{ margin: "15px 20px 0px 20px" }}>Define Screen Layout</div>
      </Row> */}
      <Row className="mt-3 screen-layout-tab-container">
        <Col>
          <Tabs>
            {tabContents.map((content) => {
              return (
                <Tab
                  key={content.key}
                  eventKey={content.key}
                  title={content.title}
                >
                  {content.program}
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ProgramDesignScreenLayout;
