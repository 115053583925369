import { debounce } from "components/common/utils/InputUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setDesign } from "../reducer/ProgramDesignAction";
import DetailSpecification from "./DetailSpecification";
import Flowchart from "./Flowchart";
import OtherDefinitions from "./OtherDefinitions";
import TableInfo from "./TableInfo";

export const ScrollDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  max-height: calc(100vh - 200px);
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const TechnicalSpecification = ({
  designMst,
  flag,
  setDesignMst,
  findMultilang,
  ...props
}) => {
  // const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState();
  const [sunEditorData, setSunEditorData] = useState();

  const [designMultilang, setDesignMultilang] = useState([]);

  const editor = useRef();

  const workspace = useSelector((state) => state.workspace);

  const dispatch = useDispatch();

  // 에디터 저장 및 업데이트
  useEffect(() => {
    if (sunEditorData !== undefined) {
      onChangeDesignMultilang("flowchart", sunEditorData);
    }
  }, [sunEditorData]);

  useEffect(() => {
    if (flag) {
      setDesignMst({});
      setDesignMultilang([]);
    }
  }, [flag]);

  useEffect(() => {
    designMst.designMultilang && setDesignMultilang(designMst.designMultilang);
  }, [designMst]);

  /**
   * editor blur
   * @param {*} e
   */
  const onBlurEditor = (e) => {
    setSunEditorData(editor.current.getContents());
  };

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      let newDesignMultilang = [...designMultilang];

      const multilangIndex = designMultilang.findIndex(
        (item) =>
          item.multilangCd === key &&
          item.langCd === User.getLanguage().toLowerCase()
      );
      if (multilangIndex > -1) {
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft[multilangIndex].multilangText = value;
        });
      } else {
        const newData = {
          multilangCd: key,
          multilangText: value,
          langCd: User.getLanguage().toLowerCase(),
        };
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft.push(newData);
        });
      }
      onChangeDesignMst("designMultilang", newDesignMultilang);
      setDesignMultilang(newDesignMultilang);
    }
  };
  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  // tab 클릭
  const onClickTab = (e, content) => {
    setActiveTab(content);
  };

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const tabContents = [
    {
      key: "tableInfo",
      title: "Table Info",
      program: (
        <TableInfo
          designMst={designMst}
          flag={flag}
          setDesignMst={setDesignMst}
        />
      ),
    },
    {
      key: "flowchart",
      title: "Flowchart",
      program: (
        <Flowchart
          contents={findMultilang("flowchart", designMultilang)}
          onBlur={(e) => onBlurEditor(e)}
          editor={editor}
          getSunEditorInstance={(editor) => getSunEditorInstance(editor)}
        />
      ),
    },
    {
      key: "detailSpecification",
      title: "Detail Specification",
      program: (
        <DetailSpecification
          designMst={designMst}
          setDesignMst={setDesignMst}
          flag={flag}
        />
      ),
    },
    {
      key: "otherDefinitions",
      title: "Other definitions",
      program: (
        <OtherDefinitions
          designMst={designMst}
          setDesignMst={setDesignMst}
          flag={flag}
        />
      ),
    },
  ];

  return (
    <ScrollDiv>
      {/* 기본 내용 */}
      {/* <Row>
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Technical Specification
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      {/* <Row>
        <div className="content-title">Functional Specification</div>
      </Row> */}

      <Row className="mt-3">
        <Col>
          <Tabs id="technicalTabs" defaultActiveKey={activeTab?.key}>
            {tabContents.map((content) => {
              return (
                <Tab
                  key={content.key}
                  eventKey={content.key}
                  onClick={(e) => onClickTab(e, content)}
                  title={content.title}
                >
                  {content.program}
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </ScrollDiv>
  );
};

export default TechnicalSpecification;
