import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import MobileBuilderHeader from "components/builder/mobile/MobileBuilderHeader";
import MobileBuilderHeaderComponent from "components/builder/mobile/MobileBuilderHeaderComponent";
import { setMobileApp } from "components/builder/mobile/reducers/MobileAction";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import MobileWorkspacePopup from "page/popup/mobile/MobileWorkspacePopup";
import React, { createContext } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import MobileAppService from "services/mobile/MobileAppService";

export const MobileAppContext = createContext({
  checkMobileWorkspace: () => new Promise(),
});

const MobileUiBuilder = ({ ...props }) => {
  const getUIPath = (url) => {
    return Enums.BuilderPath.MOBILE.MAIN + "/" + url;
  };

  const mobile = useSelector((state) => state.mobile);
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkMobileWorkspace = () => {
    return new Promise((resolve, reject) => {
      return resolve(true);
      if (ObjectUtils.isEmpty(mobile.workspace)) {
        const callback = (mobileApp, isCreated) => {
          dispatch(setMobileApp(mobileApp));
          if (isCreated) {
            //최초 생성한 앱은 App Configuration으로 이동
            navigate(
              Enums.BuilderPath.MOBILE.MAIN +
                "/" +
                Enums.BuilderPath.MOBILE.INITIAL_APP_CONFIGURAION
            );
            Popup.close();
          } else {
            resolve(true);
            Popup.close();
          }
        };

        Popup.open(
          <MobileWorkspacePopup
            workspace={workspace}
            callback={callback}
            programId={"mobileWorkspacePopup"}
          />,
          {
            style: { content: { width: "450px" } },
          }
        );
      } else {
        resolve(true);
      }
    });
  };

  const openMobileWorkspacePopup = () => {
    const callback = async (mobileApp, isCreated) => {
      dispatch(setMobileApp(mobileApp));
      const result = await MobileAppService.getMobilApp({
        ...mobile.workspace,
      });
      if (
        !isCreated ||
        !ArrayUtils.isArray(result.data.layout) ||
        ArrayUtils.isEmpty(result.data.layout)
      ) {
        const mMst = produce(result.data, (draft) => {
          draft.layout = result.data.layout.map((layout) => {
            if (layout.customContent) {
              layout.customContent = JSON.parse(layout.customContent);
            }
            return layout;
          });
        });
        dispatch(setMobileApp(mMst));
      } else {
        navigate(
          Enums.BuilderPath.MOBILE.MAIN +
            "/" +
            Enums.BuilderPath.MOBILE.INITIAL_APP_CONFIGURAION
        );
      }
      Popup.close();
    };

    Popup.open(
      <MobileWorkspacePopup
        workspace={workspace}
        callback={callback}
        programId={"mobileWorkspacePopup"}
      />,
      {
        style: { content: { width: "450px" } },
      }
    );
  };

  const contextValue = {
    checkMobileWorkspace,
  };

  return (
    <MobileAppContext.Provider value={contextValue}>
      {StringUtils.includes(window.location.pathname, [
        getUIPath(Enums.BuilderPath.MOBILE.EDITOR),
      ]) ? (
        <MobileBuilderHeader
          {...props.header}
          openMobileWorkspacePopup={openMobileWorkspacePopup}
        />
      ) : (
        <MobileBuilderHeaderComponent
          {...props.header}
          openMobileWorkspacePopup={openMobileWorkspacePopup}
        />
      )}
      <Outlet />
      {/* {ObjectUtils.isEmpty(mobile.workspace) && (
        <div
          className="workspace-empty-alert"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        >
          <div className="alert-msg">Mobile Workspace setting is required.</div>
          <Button onClick={openMobileWorkspacePopup}>Open Popup</Button>
        </div>
      )} */}
    </MobileAppContext.Provider>
  );
};

export default MobileUiBuilder;
