import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/editor/theme/common/UIComponentStyle";
import React, { Component, useEffect } from "react";
import { useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Enums from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";
import { EInputPopup } from "components/builder/entity/entityComponents/EntityComponentStyle";
import Popup from "components/common/Popup";
import CodeMirrorPopup from "page/popup/CodeMirrorPopup";
import { debounce } from "components/common/utils/InputUtils";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import DefaultInputPopup from "page/popup/DefaultInputPopup";
import {
  InsertOptionSPopup,
  InsertOptionUPopup,
} from "page/popup/EntityFieldInsertOptionPopup";

class EntityField extends Component {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }
}
class EntityFieldEditor extends Component {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }
  render() {
    return <div>Entity editor</div>;
  }
}

// class EntityFieldProperties extends Component {
//   constructor(props) {
//     super(props);
//     //불필요하면 constructor 지우세요.
//   }
//   render() {
//     return <div></div>;
//   }
// }

export const contentsNameContext = {
  D: {
    columnNm: () => "Table Column Name",
    physFieldNm: () => "Physical Name",
    logFieldNm: () => "Logical Name",
    uiDisplayType: () => "UI Display",
    virtualYn: () => "Virtual Column ",
    alias: () => "Alias",
    columnType: () => "Data Type",
    autoIncrementYn: () => "Auto Increment ",
    pkYn: () => "PK",
    requiredYn: () => "Required",
    uniqueYn: () => "Unique ",
    readonlyYn: () => "ReadOnly Status",
    formula: () => "Field formula",
    insertableYn: () => "Insert Status",
    insertDefault: () => "Insert Default",
    updatableYn: () => "Update Status",
    updateDefault: () => "Update Default",
    fieldOption: () => "Other Options",
  },
  S: {
    physFieldNm: (inout) => `Physical Name`,
    logFieldNm: (inout) => `Logical Name`,
    columnNm: (inout) => `ID`,
    uiDisplayType: () => "UI Display",
  },
  E: {
    physFieldNm: () => "Physical Name",
    logFieldNm: () => "Logical Name",
    columnNm: () => "Table Column Name",
    columnType: () => "Data Type",
    autoIncrementYn: () => "Auto Increment",
    pkYn: () => "PK",
    requiredYn: () => "Required",
    uniqueYn: () => "Unique",
    readonlyYn: () => "ReadOnly",
    uiDisplayType: () => "UI Display",
    formula: () => "Field formula",
    fieldOption: () => "Other Options",
    alias: () => "Alias",
    insertDefault: () => "Input Default",
    updateDefault: () => "Update Default",
    virtualYn: () => "Virtual Column Status",
    insertableYn: () => "Insert Status",
    updatableYn: () => "Update Status",
  },
};

const EntityFieldProperties = ({ isTrd, ...props }) => {
  const [physFieldNm, setphysFieldNm] = useState("");
  const [logFieldNm, setLogFieldNm] = useState("");
  const [columnNm, setColumnNm] = useState("");
  const [columnType, setColumnType] = useState("");
  const [autoIncrementYn, setAutoIncrementYn] = useState("");
  const [pkYn, setPkYn] = useState("");
  const [requiredYn, setRequiredYn] = useState("");
  const [uniqueYn, setUniqueYn] = useState("");
  const [readonlyYn, setReadonlyYn] = useState("");
  const [uiDisplayType, setUiDisplayType] = useState("");
  const [formula, setFormula] = useState("");
  const [fieldOption, setFieldOption] = useState({});
  const [alias, setAlias] = useState("");
  const [insertDefault, setInsertDefault] = useState("");
  const [updateDefault, setUpdateDefault] = useState("");
  const [virtualYn, setVirtualYn] = useState("N");
  const [insertableYn, setInsertableYn] = useState("Y");
  const [updatableYn, setUpdatableYn] = useState("Y");

  const [isRefColumn, setIsRefColumn] = useState(false);

  const activedComponent = useSelector((state) => state.activedENTComponent);
  const workspace = useSelector((state) => state.workspace);

  const [isEditable, setIsEditable] = useState(true);

  const { dataModelType, dataModelEntities } = useSelector(
    (state) => state.outputENT.output
  );
  const output = useSelector((state) => state.outputENT.output);
  const parentsEntity = dataModelEntities?.find((_entity) =>
    _entity.dataModelEntityFields.find(
      (_field) => _field.compId === activedComponent.compId
    )
  );
  const serviceInout = parentsEntity?.serviceInout;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      StringUtils.equalsIgnoreCase(
        activedComponent.type,
        Enums.EntityComponentType.ENTITY_FIELD
      )
    ) {
      setphysFieldNm(activedComponent.physFieldNm);
      setLogFieldNm(activedComponent.logFieldNm);
      setColumnNm(activedComponent.columnNm);
      setColumnType(activedComponent.columnType);
      setAutoIncrementYn(activedComponent.autoIncrementYn);
      setPkYn(activedComponent.pkYn);
      setRequiredYn(activedComponent.requiredYn);
      setUniqueYn(activedComponent.uniqueYn);
      setReadonlyYn(activedComponent.readonlyYn);
      setUiDisplayType(activedComponent.uiDisplayType);
      setFormula(activedComponent.formula ? activedComponent.formula : "");
      //필드 옵션은 코드미러로 진행
      setFieldOption(
        StringUtils.isEmpty(activedComponent.fieldOption)
          ? {}
          : activedComponent.fieldOption
      );
      setAlias(activedComponent.alias);
      setInsertDefault(activedComponent.insertDefault);
      setUpdateDefault(activedComponent.updateDefault);
      setVirtualYn(StringUtils.defaultString(activedComponent.virtualYn, "Y"));
      setInsertableYn(
        StringUtils.defaultString(activedComponent.insertableYn, "Y")
      );
      setUpdatableYn(
        StringUtils.defaultString(activedComponent.updatableYn, "Y")
      );
      if (StringUtils.equalsIgnoreCase(activedComponent.virtualYn, "Y")) {
        setReadonlyYn("Y");
        setInsertableYn("N");
        setUpdatableYn("N");
      }

      // EAGER 연결로 인한 virtual 칼럼인가?
      setIsRefColumn(activedComponent.refEntityId ? true : false);

      //프로시져 또는 함수로 만들어진 필드는 수정 불가
      if (
        !StringUtils.equalsIgnoreCase(parentsEntity.entityType, "PROCEDURE") &&
        !StringUtils.equalsIgnoreCase(parentsEntity.entityType, "FUNCTION")
      ) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    }
  }, [activedComponent]);

  const UpdateFieldOption = (fo) => {
    setFieldOption(fo);
    debounceType({ target: { id: "fieldOption", value: fo } });
  };
  /**
   * Field Option (JSON) 입력기 오픈
   * @param {*} event
   */
  const onOpenFieldOption = (event) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <CodeMirrorPopup
        title={`${columnNm} Field Option Setting`}
        fieldOption={fieldOption}
        onOk={UpdateFieldOption}
      />,
      options
    );
    if (event) event.preventDefault();
  };

  /**
   * debounce type 설정
   */
  const debounceType = debounce((e) => {
    //output update
    let payload = {};
    if (e.target.id === "insertDefault") {
      payload = [
        {
          id: e.target.id,
          value: e.target.value,
          compId: activedComponent.compId,
        },
        {
          id: "autoNumberingType",
          value: e.target.autoNumberingType,
          compId: activedComponent.compId,
        },
        {
          id: "insertOption",
          value: null,
          compId: activedComponent.compId,
        },
      ];
    } else {
      payload = {
        id: e.target.id,
        value: e.target.value,
        compId: activedComponent.compId,
      };
    }
    EntityReduxHelper.updateFieldPropety(dispatch, output, payload);
    EntityReduxHelper.activateComponentProperty(dispatch, payload);
  }, 200);

  /**
   * UI display onChange 이벤트
   * @param {*} e
   */
  const onChangeUiDisplay = (e) => {
    setUiDisplayType(e.target.value);
    setInsertDefault("");
    setUpdateDefault("");
    const updateProps = [
      {
        id: "uiDisplayType",
        value: e.target.value,
      },
      {
        id: "insertDefault",
        value: "",
      },
      {
        id: "updateDefault",
        value: "",
      },
    ];
    updateProperties(updateProps);
  };

  const onChangeReadonly = (e) => {
    setReadonlyYn(e.target.value);
    const updateProps = [
      {
        id: "readonlyYn",
        value: e.target.value,
      },
    ];

    if (StringUtils.equalsIgnoreCase(e.target.value, "Y")) {
      updateProps.push({
        id: "updatableYn",
        value: "N",
      });
      setUpdatableYn("N");

      updateProps.push({
        id: "insertableYn",
        value: "N",
      });
      setInsertableYn("N");

      updateProps.push({
        id: "updateDefault",
        value: "",
      });
      setUpdateDefault("");

      updateProps.push({
        id: "insertDefault",
        value: "",
      });
      setInsertDefault("");
    }
    updateProperties(updateProps);
  };

  /**
   * 입력가능여부 onChange 이밴트
   * @param {*} e
   */
  const onChangeInputable = (e) => {
    setInsertableYn(e.target.value);
    const updateProps = [
      {
        id: "insertableYn",
        value: e.target.value,
      },
    ];
    if (StringUtils.equalsIgnoreCase(e.target.value, "N")) {
      setInsertDefault("");
      updateProps.push({
        id: "insertDefault",
        value: "",
      });
    }
    updateProperties(updateProps);
  };

  /**
   * 수정가능여부 onChange 이벤트
   * @param {*} e
   */
  const onChangeUpdatable = (e) => {
    setUpdatableYn(e.target.value);
    const updateProps = [
      {
        id: "updatableYn",
        value: e.target.value,
      },
    ];

    if (StringUtils.equalsIgnoreCase(e.target.value, "N")) {
      setUpdateDefault("");
      updateProps.push({
        id: "updateDefault",
        value: "",
      });
    }
    updateProperties(updateProps);
  };

  /**
   * Property 업데이트
   * @param {*} updateProps
   */
  const updateProperties = (updateProps) => {
    updateProps = updateProps.map((_prop) => {
      _prop.compId = activedComponent.compId;
      return _prop;
    });
    EntityReduxHelper.updateFieldPropety(dispatch, output, updateProps);
    EntityReduxHelper.activateComponentProperty(dispatch, updateProps);
  };

  /**
   * 기본값 작업 target.id, target.value로 데이터 넘김
   * @param {EventHandler} event
   * @param {function((e)=>{})} ChooseEvent
   */
  const openDefaultPopup = (event, id, ChooseEvent, autoNumbering) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%",
        },
      },
    };
    let DateYn = false;
    let columnLowerName = columnNm.toLowerCase();
    if (
      columnLowerName.indexOf("insrt_dt") > -1 ||
      columnLowerName.indexOf("insert_dt") > -1 ||
      columnLowerName.indexOf("updt_dt") > -1 ||
      columnLowerName.indexOf("update_dt") > -1
    ) {
      DateYn = true;
    }
    Popup.open(
      <DefaultInputPopup
        id={id}
        workspace={workspace}
        onChooseDefaultValue={ChooseEvent}
        dateYn={DateYn}
        autoNumbering={autoNumbering}
      />,
      options
    );
    if (event) event.preventDefault();
  };

  const onChangeInputDefault = (e) => {
    setInsertDefault(e.target.value);
    debounceType(e);
  };

  const onChangeUpdateDefault = (e) => {
    setUpdateDefault(e.target.value);
    debounceType(e);
  };

  const checkAPIType = (type) => {
    return StringUtils.equalsIgnoreCase(dataModelType, type);
  };

  const onOpenSettingPopup = (e, type) => {
    if (e) e.preventDefault();
    const options = {
      effect: Popup.ScaleUp,
      style: {
        content: {
          width: "500px",
        },
      },
    };

    if (StringUtils.equalsIgnoreCase(type, "S")) {
      options.style.content.width = "350px";
      Popup.open(
        <InsertOptionSPopup
          insertOption={JSON.parse(activedComponent?.insertOption) || {}}
          onSave={debounceType}
        />,
        options
      );
    } else if (StringUtils.equalsIgnoreCase(type, "U")) {
      Popup.open(
        <InsertOptionUPopup
          insertOption={JSON.parse(activedComponent?.insertOption) || {}}
          onSave={debounceType}
        />,
        options
      );
    }
  };

  /**
   * Field 정의 Rendering 부분
   * @returns
   */
  const renderFieldDefinition = () => {
    return (
      <Accordion.Item key={0} eventKey={0}>
        <Accordion.Header>Field Definition</Accordion.Header>
        <Accordion.Body>
          <PropertyLable>
            {contentsNameContext[dataModelType].columnNm(serviceInout)}
          </PropertyLable>
          <PropertyValue>
            <input
              className="form-control form-control-sm"
              defaultValue={columnNm}
              disabled={
                StringUtils.equalsIgnoreCase(virtualYn, "Y") ? false : true
              }
              onChange={(e) => {
                debounceType(e);
                setColumnNm(e.currentTarget.value);
              }}
            />
          </PropertyValue>
          <PropertyLable>
            {contentsNameContext[dataModelType].physFieldNm(serviceInout)}
          </PropertyLable>
          <PropertyValue>
            <input
              className="form-control form-control-sm"
              id={"physFieldNm"}
              value={physFieldNm}
              disabled={isRefColumn || checkAPIType("E")}
              onChange={(e) => {
                debounceType(e);
                setphysFieldNm(e.currentTarget.value);
              }}
            />
          </PropertyValue>

          <PropertyLable>
            {contentsNameContext[dataModelType].logFieldNm(serviceInout)}
          </PropertyLable>
          <PropertyValue>
            <input
              className="form-control form-control-sm"
              id={"logFieldNm"}
              value={logFieldNm}
              disabled={isRefColumn}
              onChange={(e) => {
                debounceType(e);
                setLogFieldNm(e.currentTarget.value);
              }}
            />
          </PropertyValue>
          <PropertyLable>
            {contentsNameContext[dataModelType].uiDisplayType(serviceInout)}
          </PropertyLable>
          <PropertyValue>
            <Form.Select
              size="sm"
              disabled={isRefColumn || checkAPIType("E")}
              id={"uiDisplayType"}
              onChange={onChangeUiDisplay}
              value={StringUtils.defaultString(uiDisplayType, "string")}
            >
              <option value={"string"}>String</option>
              <option value={"number"}>Number</option>
              <option value={"date"}>Date</option>
            </Form.Select>
          </PropertyValue>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  /*
   * 필드 상세 옵션 rendering 부분
   */
  const renderFieldDetailOption = () => {
    return (
      <Accordion.Item eventKey={1}>
        <Accordion.Header>Field Detail Option</Accordion.Header>
        <Accordion.Body>
          {!checkAPIType("E") && (
            <>
              <PropertyLable>
                {contentsNameContext[dataModelType].alias()}
              </PropertyLable>
              <PropertyValue>
                <input
                  className="form-control form-control-sm"
                  id={"alias"}
                  value={alias}
                  placeholder={"Please enter Alias."}
                  onChange={(e) => {
                    debounceType(e);
                    setAlias(e.currentTarget.value);
                  }}
                />
              </PropertyValue>
            </>
          )}

          {virtualYn === "N" && (
            <>
              <PropertyLable>
                {contentsNameContext[dataModelType].columnType()}
              </PropertyLable>
              <PropertyValue>
                <input
                  className="form-control form-control-sm"
                  id={"alias"}
                  value={columnType}
                  disabled
                />
              </PropertyValue>
              <PropertyLable>
                {contentsNameContext[dataModelType].autoIncrementYn()}
              </PropertyLable>
              <PropertyValue>
                <Form.Select
                  size="sm"
                  id={"autoIncrementYn"}
                  onChange={(e) => {
                    debounceType(e);
                    setAutoIncrementYn(e.target.value);
                  }}
                  value={StringUtils.defaultString(autoIncrementYn, "N")}
                  disabled={isTrd}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Use Auto Increment</option>
                  <option value={"N"}>No</option>
                </Form.Select>
              </PropertyValue>
              <PropertyLable>
                {contentsNameContext[dataModelType].pkYn()}
              </PropertyLable>
              <PropertyValue>
                <Form.Select
                  size="sm"
                  id={"pkYn"}
                  value={StringUtils.defaultString(pkYn, "N")}
                  onChange={(e) => {
                    debounceType(e);
                    setPkYn(e.target.value);
                  }}
                  disabled={isTrd}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>PK </option>
                  <option value={"N"}>Not PK </option>
                </Form.Select>
              </PropertyValue>
              <PropertyLable>
                {contentsNameContext[dataModelType].requiredYn()}
              </PropertyLable>
              <PropertyValue>
                <Form.Select
                  size={"sm"}
                  id={"requiredYn"}
                  onChange={(e) => {
                    debounceType(e);
                    setRequiredYn(e.target.value);
                  }}
                  value={StringUtils.defaultString(requiredYn, "N")}
                  disabled={isTrd}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Required</option>
                  <option value={"N"}>Not Required</option>
                </Form.Select>
              </PropertyValue>
              <PropertyLable>
                {contentsNameContext[dataModelType].uniqueYn()}
              </PropertyLable>
              <PropertyValue>
                <Form.Select
                  size={"sm"}
                  id={"uniqueYn"}
                  onChange={(e) => {
                    debounceType(e);
                    setUniqueYn(e.target.value);
                  }}
                  value={StringUtils.defaultString(uniqueYn, "N")}
                  disabled={isTrd}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Yes</option>
                  <option value={"N"}>No</option>
                </Form.Select>
              </PropertyValue>
              <PropertyLable>
                {contentsNameContext[dataModelType].readonlyYn()}
              </PropertyLable>
              <PropertyValue>
                <Form.Select
                  size="sm"
                  id={"readonlyYn"}
                  onChange={onChangeReadonly}
                  value={StringUtils.defaultString(readonlyYn, "N")}
                  disabled={
                    StringUtils.equalsIgnoreCase(virtualYn, "Y") ? true : false
                  }
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Yes</option>
                  <option value={"N"}>No</option>
                </Form.Select>
              </PropertyValue>
            </>
          )}
          {!isRefColumn && (
            <>
              <PropertyLable>
                {contentsNameContext[dataModelType].formula()}
              </PropertyLable>
              <PropertyValue>
                <input
                  className="form-control form-control-sm"
                  id={"formula"}
                  value={formula}
                  disabled={checkAPIType("E")}
                  onChange={(e) => {
                    debounceType(e);
                    setFormula(e.currentTarget.value);
                  }}
                />
              </PropertyValue>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  /**
   * 기본값 입력부분 렌더링
   */
  const renderFieldDefaultInput = () => {
    return (
      <Accordion.Item eventKey={2}>
        <Accordion.Header>Field Default</Accordion.Header>
        <Accordion.Body>
          <PropertyLable>
            {contentsNameContext[dataModelType].insertableYn()}
          </PropertyLable>
          <PropertyValue>
            <Form.Select
              size={"sm"}
              id={"insertableYn"}
              onChange={onChangeInputable}
              value={StringUtils.defaultString(insertableYn, "Y")}
            >
              <option value={""}>Select</option>
              <option value={"Y"}>Yes</option>
              <option value={"N"}>No</option>
            </Form.Select>
          </PropertyValue>
          {StringUtils.equalsIgnoreCase(insertableYn, "Y") && (
            <>
              <PropertyLable>
                {contentsNameContext[dataModelType].insertDefault()}
              </PropertyLable>
              <PropertyValue>
                {StringUtils.equalsIgnoreCase(
                  StringUtils.defaultString(activedComponent.autoNumberingType),
                  "S"
                ) ||
                StringUtils.equalsIgnoreCase(
                  StringUtils.defaultString(activedComponent.autoNumberingType),
                  "U"
                ) ? (
                  <div style={{ display: "flex", gap: "5px" }}>
                    <EInputPopup
                      id="insertDefault"
                      value={StringUtils.defaultString(insertDefault)}
                      onClick={(e) =>
                        openDefaultPopup(
                          e,
                          "insertDefault",
                          onChangeInputDefault,
                          true
                        )
                      }
                      onChange={onChangeInputDefault}
                    />
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="light-font-color"
                      onClick={(e) =>
                        onOpenSettingPopup(
                          e,
                          activedComponent.autoNumberingType
                        )
                      }
                    >
                      Setting
                    </Button>
                  </div>
                ) : (
                  <EInputPopup
                    id="insertDefault"
                    value={StringUtils.defaultString(insertDefault)}
                    onClick={(e) =>
                      openDefaultPopup(
                        e,
                        "insertDefault",
                        onChangeInputDefault,
                        true
                      )
                    }
                    onChange={onChangeInputDefault}
                  />
                )}
              </PropertyValue>
            </>
          )}

          <PropertyLable>
            {contentsNameContext[dataModelType].updatableYn()}
          </PropertyLable>
          <PropertyValue>
            <Form.Select
              size="sm"
              id={"updatableYn"}
              onChange={onChangeUpdatable}
              value={StringUtils.defaultString(updatableYn, "Y")}
            >
              <option value={""}>Select</option>
              <option value={"Y"}>Yes</option>
              <option value={"N"}>No</option>
            </Form.Select>
          </PropertyValue>
          {StringUtils.equalsIgnoreCase(updatableYn, "Y") && (
            <>
              <PropertyLable>
                {contentsNameContext[dataModelType].updateDefault()}
              </PropertyLable>
              <PropertyValue>
                <EInputPopup
                  id="updateDefault"
                  value={StringUtils.defaultString(updateDefault)}
                  onClick={(e) =>
                    openDefaultPopup(e, "updateDefault", onChangeUpdateDefault)
                  }
                  onChange={onChangeUpdateDefault}
                />
              </PropertyValue>
            </>
          )}

          <PropertyLable>
            {contentsNameContext[dataModelType].fieldOption()}
          </PropertyLable>
          <PropertyValue>
            <Button
              variant="outline-secondary"
              className="form-control"
              onClick={onOpenFieldOption}
            >
              Open Input Popup
            </Button>
          </PropertyValue>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  return (
    <div className="entity-sidebar">
      <PropertiesHeader>
        {physFieldNm}
        {checkAPIType("D") &&
          StringUtils.equalsIgnoreCase(virtualYn, "Y") &&
          `( Virtual Column )`}
      </PropertiesHeader>
      <Form>
        <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>
          {renderFieldDefinition()}
          {isEditable && !checkAPIType("S") && (
            <>
              {renderFieldDetailOption()}
              {StringUtils.equalsIgnoreCase(readonlyYn, "N") &&
                renderFieldDefaultInput()}
            </>
          )}
        </Accordion>
      </Form>
    </div>
  );
};

export default Object.assign(EntityField, {
  Editor: EntityFieldEditor,
  Properties: EntityFieldProperties,
});
