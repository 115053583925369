import React, { useRef } from "react";

import loadable from "@loadable/component";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import { generateNodeTreeIds } from "components/builder/ui/tree/treeUtils";
import { AppContext } from "components/common/AppContextProvider";
import CommonUtils, {
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import produce from "immer";
import { useContext } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

const UIEditorAMChart3 = ({ data, path, rootLocation, style }) => {
  const ref = useRef(null);
  const componentRef = useRef();
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const components = useContext(AppContext).component.getComponentList("B");
  const output = useSelector((state) => state.outputUI.output);
  const dispatch = useDispatch();
  let theme = CommonUtils.getTheme();

  if (!componentRef.current) {
    componentRef.current = loadable(() =>
      import(
        `components/builder/ui/editor/theme/${theme}/widget/chart/AMChart3Component`
      )
    );
  }

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const editorAttr = ObjectUtils.isEmpty(item.editorAttr)
    ? {}
    : item.editorAttr;

  /**
   * Onclick event
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    const nodeIds = generateNodeTreeIds(output.page, item.compId);
    dispatch(actions.setBuilderTreeNodeIds(nodeIds));
    Event.clickComponent(e, item, components, activedComponent, dispatch);
  };

  return (
    <div className="editor-component-wrap" style={{ ...style }} ref={ref}>
      <UIComponentSection
        item={item}
        style={{ opacity }}
        className="editor-component editor-base draggable"
        focusDelegation={true}
        title={editorAttr.title}
      >
        <componentRef.current
          componentInfo={item}
          event="renderEditor"
          isFocus={activedComponent.compId === item.compId ? true : false}
          onClick={onClick}
        ></componentRef.current>
      </UIComponentSection>
    </div>
  );
};

export default React.memo(UIEditorAMChart3);
