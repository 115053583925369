import { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import styled from "styled-components";

import {
  PropertyButtonGroup,
  PropertyLable,
} from "components/builder/ui/editor/theme/common/UIComponentStyle";
import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { Button } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import UElementDropContainer from "./UElementDropContainer";
import UElementItem from "./UElementItem";

/** 참고: Button.js > ElementList(#targetList) */
const UElementStyled = styled.div`
  .non-multi #btnAdd,
  .non-multi #btnRemove,
  .non-multi .all-chk,
  .non-multi .all-chk input {
    display: none;
  }

  //버튼 관련
  .el-list-btn {
    display: inline-flex;
    align-items: center;
    height: 26px;
    padding: 0 4px;
    background: #fff;
    // border: 1px solid #b0b0b0;
    color: #1b1b1b;
    cursor: pointer;
    box-shadow: none;
    text-align: center;
    border-radius: 0.2rem;
    display: inline-block;
  }
  .el-list-btn:hover {
    // border: 1px solid #454545;
    background: #e8e8e8;
  }
  .btn-group {
    justify-content: space-between;
  }

  .btn-group .btn-add {
    color: #fff;
    position: absolute;
    right: 38px;
  }
  .btn-group .btn-remove {
    color: #fff;
    position: absolute;
    right: 10px;
  }

  // element 관련
  .el-header {
    height: 21px;
    display: flex;
    gap: 2px;
  }
  .el-header div:not(.all-chk) {
    float: left;
    padding-left: 5px;
    width: 30%;
    box-sizing: border-box;
  }
  .el-body {
    overflow-y: auto;
  }
  .el-column {
    height: 34px;
    display: flex;
    gap: 2px;
  }

  //전체 선택 버튼
  .all-chk {
    float: left;
    width: 21px !important;
    text-align: center;
    box-sizing: border-box;
    margin-top: 3px;
  }
  .w-10 {
    width: 10px !important;
  }
  .propertyValue {
    width: 75%;
    padding: 4px 0px 4px 4px;
  }
`;

/**
 *  history: 22.04.28 init
 * @param {Object} props
 * @param {*} props.id
 * @param {*} props.label
 * @param {*} props.cols
 * @param {*} props.data
 * @param {*} props.required
 * @param {*} props.bodyStyle
 * @param {*} props.onBlur
 * @param {*} props.options
 * @param {*} props.isDisplay
 * @param {*} props.className
 * @param {*} props.enableEmpty
 * @param {*} props.isMobileEditor
 */
const UElementList = (props) => {
  const {
    id,
    label,
    cols,
    data,
    required,
    bodyStyle,
    onBlur,
    options,
    isDisplay,
    className,
    enableEmpty = false,
    isMobileEditor,
  } = props;
  const [allChk, setAllChk] = useState(false);
  const [elItems, setElItems] = useState(data || [{}]);

  const initElementList = () => {
    if (!ObjectUtils.isEmpty(options) && options["defaultParams"]) {
      if (
        data &&
        options["defaultParams"]["useYn"] !== null &&
        options["defaultParams"]["useYn"] !== undefined
      ) {
        const newData = [];
        data.map((item) => {
          const newItem = { ...item };
          if (newItem.useYn === null || newItem.useYn === undefined) {
            newItem.useYn = options["defaultParams"]["useYn"];
          }
          newData.push(newItem);
        });
        newData.length ? setElItems(newData) : setElItems(data || [{}]);
        return;
      }
    }
    setElItems(data || [{}]);
  };

  /* Row 추가 */
  const fnClickAdd = (e) => {
    let newItems = elItems.slice();
    if (!ObjectUtils.isEmpty(options)) {
      newItems.push(options["defaultParams"] || {});
    } else {
      newItems.push({});
    }
    setPropValue(newItems);
  };

  /* Row 삭제 */
  const fnClickRemove = (e) => {
    let changedItems = elItems.filter((data) => !data.isChecked);
    if (ArrayUtils.isEmpty(changedItems)) {
      if (enableEmpty) changedItems = [];
      else changedItems = [{}];
      setAllChk(false);
    }
    setPropValue(changedItems);
  };

  /**
   * Data 변경
   * @param {*} e
   * @param {*} comboItem  //USelectbox에서 넘겨오는 combo 선택 item
   */
  const onChange = (e, comboItem) => {
    const id = StringUtils.substringBefore(e.target.id, "_");
    const index = StringUtils.substringAfter(e.target.id, "_");

    let items = elItems.slice();
    let item = items[index];
    let value = e.target.value;

    if (id === "isChecked") {
      value = e.target.checked;
    }

    item = { ...item, [id]: value };

    //column에 onChange를 가지고 있을 때,
    let col = cols.filter((data) => data.id === id)[0];
    if (!ObjectUtils.isEmpty(col) && !ObjectUtils.isEmpty(col.onChange)) {
      item = col.onChange.call(this, item, e.target.value, comboItem, e);
    }

    items[index] = item;

    setPropValue(items);
  };

  /* 전체 선택 Click event */
  const setAllChkFn = (e) => {
    let items = elItems.slice();
    let checked = e.target.checked;

    items.map((item, index) => {
      item = { ...item, isChecked: checked };
      items[index] = item;
    });

    setPropValue(items);
    setAllChk(checked);
  };

  /* propertyValue에 값 저장 */
  const setPropValue = (items) => {
    // data = items.slice();
    setElItems(items);
    onBlur(id, items);
  };

  /**
   * 드래그 시 item 끼리 자리를 바꿀 때
   * @param {*} fromIndex
   * @param {*} toIndex
   */
  const moveItem = (fromIndex, toIndex) => {
    const newElItems = [...elItems];

    const changeItem = newElItems[toIndex];

    newElItems[toIndex] = newElItems[fromIndex];
    newElItems[fromIndex] = changeItem;

    setPropValue(newElItems);
  };

  /**
   * 드래그 시 비어있는 공간에 item이 들어갈 때
   * @param {*} fromIndex
   * @param {*} toIndex
   */
  const moveBetweenItem = (fromIndex, toIndex) => {
    let newElItems = [...elItems];

    const draggedItem = newElItems[fromIndex];

    newElItems.splice(toIndex, 0, draggedItem);

    if (fromIndex > toIndex) {
      newElItems.splice(fromIndex + 1, 1);
    } else {
      newElItems.splice(fromIndex, 1);
    }
    setPropValue(newElItems);
  };

  useEffect(() => {
    initElementList();
  }, [data, cols]);

  return (
    <UElementStyled style={{ width: "100%" }}>
      <div
        className={`w-full ${StringUtils.defaultString(className)} ${
          ObjectUtils.isEmpty(options) ||
          ObjectUtils.isEmpty(options.isMulti) ||
          options.isMulti
            ? ""
            : "non-multi"
        } ${isDisplay ? "" : "hide"}`}
        id={id}
      >
        <div className="btn-group el-title w-full pt-2 pb-2">
          <PropertyLable $requried={required}>{label}</PropertyLable>

          {/* <PropertyLable className="all-chk">
            <input
              type="checkbox"
              id="allChk"
              value="true"
              onChange={setAllChkFn}
              checked={StringUtils.defaultString(allChk, false)}
            />
          </PropertyLable> */}
          <PropertyButtonGroup>
            <Button
              id="btnAdd"
              onClick={fnClickAdd}
              variant={"outline-secondary"}
              className={"light-font-color"}
              size="sm"
            >
              <FaPlus size="14" />
            </Button>
            <Button
              id="btnRemove"
              onClick={fnClickRemove}
              variant={"outline-secondary"}
              className={"light-font-color"}
              size="sm"
            >
              <FaMinus size="14" />
            </Button>
          </PropertyButtonGroup>
        </div>

        <div
          className={`w-full el-header ${
            ObjectUtils.isEmpty(options)
              ? ""
              : ObjectUtils.isEmpty(options.isHeader)
              ? ""
              : options.isHeader
              ? ""
              : "hide"
          }`}
        >
          <PropertyLable className="all-chk">
            <input
              type="checkbox"
              id="allChk"
              value="true"
              onChange={setAllChkFn}
              checked={StringUtils.defaultString(allChk, false)}
            />
          </PropertyLable>

          {!ArrayUtils.isEmpty(cols)
            ? cols.map((col, colIndex) => (
                <PropertyLable
                  //style={{ width: "auto" }}
                  key={StringUtils.getUuid()}
                  className={`${col.className ? col.className : ""}`}
                  $requried={StringUtils.defaultString(col.required, false)}
                >
                  {col.label}
                </PropertyLable>
              ))
            : ""}
        </div>

        <div className="w-full el-body" style={bodyStyle}>
          <DndProvider backend={HTML5Backend}>
            <UElementDropContainer
              index={0}
              moveBetweenItem={moveBetweenItem}
            ></UElementDropContainer>
            {!ArrayUtils.isEmpty(elItems)
              ? elItems.map((item, index) => {
                  return (
                    <div key={index}>
                      <UElementItem
                        elItem={item}
                        index={index}
                        onChange={onChange}
                        cols={cols}
                        moveItem={moveItem}
                      />
                      <UElementDropContainer
                        index={index + 1}
                        moveBetweenItem={moveBetweenItem}
                      ></UElementDropContainer>
                    </div>
                  );
                })
              : ""}
          </DndProvider>
        </div>
      </div>
    </UElementStyled>
  );
};

export default UElementList;
