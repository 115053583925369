import { Enums } from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";

import DraggableTreeItem from "components/builder/ui/tree/DraggableTreeItem";
import { getLabel } from "components/builder/ui/tree/treeUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";

function DraggableTree({
  onClickTreeItem = () => {},
  moveColumn = () => {},
  onClickGridChild = () => {},
  moveItem = () => {},
  data = {},
  ...props
}) {
  /**
   * 트리뷰 노드 그리는 함수
   * @param {Array} childList
   * @returns
   */
  const renderTreeItems = (_component, path, step) => {
    const childList = _component.child;
    const { type } = _component;
    if (StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID)) {
      const {
        compId,
        propertyValue: {
          gridOptions: { columns: gridColumns, gridId },
        },
      } = _component;
      return gridColumns.map((col, cPath) => {
        //drag 이벤트 때문에 col에 gridId 추가
        const column = { ...col };
        column.gridCompId = compId;
        const cellHeader = {};
        cellHeader.propertyValue = { ...col };
        cellHeader.type = Enums.ComponentType.GRID_HEADER;
        cellHeader.gridId = gridId;
        cellHeader.compId = "Header-" + col.compId;
        cellHeader.componentClass = "grid/GridHeader";
        cellHeader.gridOptions = _component.propertyValue.gridOptions;
        cellHeader.path = path + "-" + cPath;

        const cellColumn = {};

        cellColumn.propertyValue = { ...col };
        cellColumn.type = Enums.ComponentType.GRID_CELL;
        cellColumn.gridId = gridId;
        cellColumn.compId = "Cell-" + col.compId;
        cellColumn.componentClass = "grid/GridCell";
        cellColumn.gridOptions = _component.propertyValue.gridOptions;
        cellColumn.path = path + "-" + cPath;
        return (
          <DraggableTreeItem
            node={column}
            key={`${column.compId}`}
            label={`${column.title}(column)`}
            onClick={(e) => onClickTreeItem(e, column)}
            step={step + 1}
            nodeType={Enums.ComponentType.GRID_COLUMN}
            gridId={gridId}
            moveItem={moveColumn}
          >
            <DraggableTreeItem
              node={cellHeader}
              key={`${cellHeader.compId}`}
              label={"Header"}
              onClick={(e) => onClickGridChild(e, cellHeader, column.compId)}
              nodeType={cellHeader.type}
              step={step + 2}
            />
            <DraggableTreeItem
              node={cellColumn}
              key={`${cellColumn.compId}`}
              label={"Column"}
              onClick={(e) => onClickGridChild(e, cellColumn, column.compId)}
              nodeType={cellColumn.type}
              step={step + 2}
            />
          </DraggableTreeItem>
        );
      });
    } else {
      return childList.map((component, cPath) => {
        const newPath = path + "-" + cPath;
        return (
          <DraggableTreeItem
            node={component}
            key={component.compId}
            label={getLabel(component)}
            onClick={(e) => onClickTreeItem(e, component)}
            step={step + 1}
            moveItem={moveItem}
            nodeType={component.type}
          >
            {component.child && renderTreeItems(component, newPath, step + 1)}
          </DraggableTreeItem>
        );
      });
    }
  };

  return !ObjectUtils.isEmpty(data) ? (
    <div className="builderTree-body">
      <div className="builderTree">
        <DraggableTreeItem
          key={data.compId}
          moveItem={moveItem}
          node={data}
          label={data?.propertyValue?.programName || "New Page"}
          step={0}
          nodeType={Enums.ComponentType.PAGE}
        >
          {renderTreeItems(data, 0, 0)}
        </DraggableTreeItem>
        {!ObjectUtils.isEmpty(data.footer) && (
          <DraggableTreeItem
            key={data.compId + "Footer"}
            moveItem={moveItem}
            node={data.footer}
            treeId={data.compId + "Footer"}
            label={
              data?.propertyValue?.programName + " Footer " || "New Page Footer"
            }
            step={0}
            nodeType={Enums.ComponentType.FOOTER}
          >
            {renderTreeItems(data.footer, 0, 0)}
          </DraggableTreeItem>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default DraggableTree;
