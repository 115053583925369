import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import React from "react";
import MobileTopNavSetting from "./MobileTopNavSetting";
import MobileMenuTypeSetting from "./MobileMenuTypeSetting";
import MobileBottomNavSetting from "./MobileBottomNavSetting";
import MobileThemeSetting from "./MobileThemeSetting";
import LoginSetting from "../LoginSetting";
import MainBoardSetting from "../MainBoardSetting";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { MobileLayoutContext } from ".";
import Message from "components/common/Message";
import { useNavigate } from "react-router-dom";
import { Enums } from "components/builder/BuilderEnum";
import { setMobileApp } from "components/builder/mobile/reducers/MobileAction";
import { AppContext } from "components/common/AppContextProvider";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";

const InitialAppConfiguraion = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const workspace = useSelector((state) => state.workspace);

  const { mobileAppMst } = useContext(MobileLayoutContext);
  const appContext = useContext(AppContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nextCallback = (cbData) => {
    let step = currentStep + 1;
    setCurrentStep(step);
  };

  const prevCallback = (cbData) => {
    let step = currentStep - 1;
    if (step < 0) step = 0;
    setCurrentStep(step);
  };

  const getStepComponent = (index) => {
    const stepComponent = [
      MobileTopNavSetting,
      MobileMenuTypeSetting,
      MobileBottomNavSetting,
      MobileThemeSetting,
      // LoginSetting,
      // MainBoardSetting,
    ];
    //마지막까지 세팅 전부 한 경우 UI Editor로 보낸다.
    if (index >= stepComponent.length) {
      Message.confirm(
        {
          title: "Congratulations!",
          desc: "You have just completed the app's layout settings. Redirecting to the Mobile UI Editor.",
        },
        () => {
          //그전까지 세팅하던 state는 리덕스에 저장한다.
          dispatch(setMobileApp(mobileAppMst));
          //Mobile UI Editor에 new Page 효과를 준다.
          const componentInfo = appContext.component.getPageComponent(); //Page Component
          componentInfo.builderType = Enums.BuilderType.MOBILE;
          componentInfo.filter = {
            page: {
              pageType: Enums.ComponentType.FILTER,
              child: [],
            },
          };
          UIReduxHelper.createPage(dispatch, componentInfo);
          UIReduxHelper.updateInformation(dispatch, null);
          dispatch(updateEventWorkspace({}));
          dispatch(updateEventOutput(""));
          navigate(
            Enums.BuilderPath.MOBILE.MAIN +
              "/" +
              Enums.BuilderPath.MOBILE.EDITOR
          );
        }
      );

      return <></>;
    } else {
      const Component = stepComponent[index];
      const isFinish = stepComponent.length - 1 === index;
      return (
        <Component
          initial={true}
          nextCallback={nextCallback}
          prevCallback={prevCallback}
          isFinish={isFinish}
        />
      );
    }
  };

  return (
    <>
      <Stepper activeStep={currentStep} alternativeLabel className="mb-3">
        <Step>
          <StepLabel>{"Set Top Nav"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Set Menu Template"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Set Bottom Navigation"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Set CSS Theme"}</StepLabel>
        </Step>
        {/* <Step>
          <StepLabel>{"Set Login Template"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Set Main Template"}</StepLabel>
        </Step> */}
      </Stepper>
      {getStepComponent(currentStep)}
    </>
  );
};

export default InitialAppConfiguraion;
