import React, { useEffect, useRef, useState } from "react";
import Modal from "components/common/modal/UModal";
import ProgramService from "services/ui/ProgramService";
import styled from "styled-components";
import { InputLabel, Select, Tooltip } from "@mui/material";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import MuiConfig from "components/common/config/MuiConfig";
import User from "components/common/utils/UserUtils";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import CodeService from "services/common/CodeService";
import Popup from "components/common/Popup";
import LocalStorageService from "services/common/LocalService";
import { RxOpenInNewWindow } from "react-icons/rx";

import WijmoGrid from "components/common/element/WijmoGrid";
import MSelectbox from "components/common/element/MSelectbox";
import USelectbox from "components/common/element/USelectbox";
import AppService from "services/common/AppService";

const TemplatePopupStyle = styled.div`
  .title {
    font-weight: bold;
  }
`;
/**
 * 불러오기 팝업
 * dataList => ProgramSerive.getProgramList
 * **/
function ProgramListPopup({
  title,
  callbackFnc,
  params,
  workspace,
  isSharedCall = "N",
  ...props
}) {
  const [moduleCd, setModuleCd] = useState();
  const [programTypeList, setProgramTypeList] = useState([]);
  const [moduleCdList, setModuleCdList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [showProgramList, setShowProgramList] = useState([]);
  const [SelectedProgram, setSelectedProgram] = useState();
  const [programId, setProgramId] = useState(
    params && params.programId
      ? StringUtils.defaultString(params.programId.value)
      : ""
  );
  const [programNm, setProgramNm] = useState(
    params && params.programNm
      ? StringUtils.defaultString(params.programNm.value)
      : ""
  );
  const [programType, setProgramType] = useState(
    params && params.programType
      ? StringUtils.defaultString(params.programType.value)
      : ""
  );

  //UI Builder냐 모바일 Builder냐 체크
  const [builderType, setBuilderType] = useState(
    params && params.builderType
      ? StringUtils.defaultString(params.builderType.value)
      : Enums.BuilderType.UI
  );
  const [workerId, setWorkerId] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const CalledPage = useRef([]);

  const columns = [
    {
      field: "moduleNm",
      headerName: "Module Name",
      width: 130,
    },
    {
      field: "tenantId",
      headerName: "Tenant Code",
      width: 130,
    },
    {
      field: "coCd",
      headerName: "CoCd",
      width: 120,
    },
    { field: "programId", headerName: "Program ID", width: 150 },

    {
      field: "programNm",
      headerName: "Program Name",
      width: 200,
    },
    {
      field: "programTypeNm",
      headerName: "Program Type",
      width: 130,
    },

    {
      field: "updtUserId",
      headerName: "Update User",
      width: 150,
      // valueGetter: (params) => `${params.row.updtUserId}`,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
      // valueGetter: (params) =>
      //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
    },
    {
      field: "newTab",
      headerName: "New Tab",
      headerAlign: "center",
      width: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="Open New Tab">
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => onOpenNewTab(params)}
            >
              <RxOpenInNewWindow />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (ObjectUtils.isEmpty(workspace)) {
      Popup.close();
      return Message.alert(
        "Workspace has not been set.",
        Enums.MessageType.INFO
      );
    } else {
      if (ArrayUtils.isEmpty(programTypeList)) {
        let comboParams = { codeMstCd: "Z0028" };
        if (params?.builderType === Enums.BuilderType.MOBILE) {
          comboParams.codeMstCd = "Z0054";
        }
        CodeService.getCodeCombo(comboParams, (res) => {
          if (res) {
            setProgramTypeList(res.data);
          }
        });
      }
      if (ArrayUtils.isEmpty(moduleCdList)) {
        AppService.getAppModuleList({ appId: workspace.appId }, (res) => {
          setModuleCdList(res.data);
        });
      }

      getProgramList();
    }
  }, []);

  useEffect(() => {
    if (!StringUtils.isEmpty(moduleCd)) {
      let list = programList.filter((t) =>
        StringUtils.equalsIgnoreCase(t.moduleCd, moduleCd)
      );
      setShowProgramList(list);
    }
  }, [moduleCd]);

  /**새탭에서 열기 */
  const onOpenNewTab = (program) => {
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/program/${program.programUid}`,
      "_blank"
    );
  };

  const getProgramList = () => {
    setIsLoading(true);
    const body = {
      moduleCd: moduleCd === "*" ? "" : moduleCd,
      appId: workspace.appId,
      // moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      workerId,
      programId,
      programNm,
      pageSize,
      programType,
      includeShared: isSharedCall,
      builderType,
    };
    ProgramService.getProgramList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            body.tenantId,
            body.coCd
          );
          let filteredList = [...list];
          if (!ObjectUtils.isEmpty(params)) {
            if (params.builderType === Enums.BuilderType.UI) {
              filteredList = list.filter(
                (item) => item.builderType === Enums.BuilderType.UI
              );
            } else if (params.builderType === Enums.BuilderType.MOBILE) {
              filteredList = list.filter(
                (item) => item.builderType === Enums.BuilderType.MOBILE
              );
              CodeService.getCodeCombo({ codeMstCd: "Z0054" }, (res) => {
                const mobileCodeList = res.data;
                for (let item of filteredList) {
                  for (let code of mobileCodeList) {
                    if (item.programType === code.id)
                      item.programTypeNm = code.text;
                  }
                }
              });
            }
          } else {
            filteredList = list.filter(
              (item) => item.builderType === Enums.BuilderType.UI
            );
          }

          setProgramList(filteredList);
          setShowProgramList(filteredList);
          setSelectedProgram();
          setIsLoading(false);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onSelectProgram = (program) => {
    setSelectedProgram(program);
  };

  const onDblClickSelectProgram = (program) => {
    setSelectedProgram(program);
    callbackFnc(program);
  };

  const onLoadModule = (v) => {
    console.log(SelectedProgram);
    if (SelectedProgram) {
      callbackFnc(SelectedProgram);
    } else {
      Message.alert("Please select Module.", Enums.MessageType.WARN);
    }
  };
  const onSearch = () => {
    CalledPage.current.length = 0;
    getProgramList(true);
  };

  const onEnterKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  return (
    <TemplatePopupStyle>
      <Modal>
        <Modal.Header title={title}></Modal.Header>
        <Modal.Body>
          <UmodalTemplate>
            <Row className="pb-3 pt-3">
              {!ObjectUtils.isEmpty(params) ? (
                <Col xs={2}>
                  <USelectbox
                    id="size"
                    name="size"
                    size="default"
                    onChange={(e) => setModuleCd(e.target.value)}
                    value={StringUtils.defaultString(moduleCd, "")}
                    items={moduleCdList}
                    options={{ matchCd: "moduleCd", matchNm: "moduleNm" }}
                  />
                </Col>
              ) : (
                ""
              )}
              <Col xs={2}>
                <Form.Control
                  label="Program ID"
                  placeholder="Search Program ID..."
                  value={programId}
                  fullWidth={true}
                  onChange={(e) => setProgramId(e.currentTarget.value)}
                  onKeyDown={onEnterKeyDown}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  label="Program Name"
                  placeholder="Search Program Name..."
                  value={programNm}
                  fullWidth={true}
                  onChange={(e) => setProgramNm(e.currentTarget.value)}
                  onKeyDown={onEnterKeyDown}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  label="Update User ID"
                  placeholder="Update User ID..."
                  value={workerId}
                  fullWidth={true}
                  onChange={(e) => setWorkerId(e.currentTarget.value)}
                  onKeyDown={onEnterKeyDown}
                />
              </Col>
              <Col xs={2}>
                <MSelectbox
                  size="small"
                  fullWidth={true}
                  data={programTypeList}
                  options={{ matchCd: "id", matchNm: "text" }}
                >
                  <InputLabel id="module-label">Program Type</InputLabel>
                  <Select
                    name="programType"
                    labelId="module-label"
                    label="Program Type"
                    fullWidth
                    color="primary"
                    value={programType}
                    onChange={(e) => setProgramType(e.target.value)}
                  ></Select>
                </MSelectbox>
              </Col>
              <Col xs={2}>
                <Button variant="primary" onClick={onSearch}>
                  Search <BsSearch size="14" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col style={{}}>
                <WijmoGrid
                  isLoading={isLoading}
                  columns={columns}
                  rows={showProgramList}
                  getRowId={(row) => row.programUid}
                  onRowClick={(param) => {
                    onSelectProgram(param);
                  }}
                  onRowDoubleClick={onDblClickSelectProgram}
                  {...MuiConfig.grid.options}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  style={{ height: "50vh" }}
                />
              </Col>
            </Row>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={() => onLoadModule()}>
            Load Program
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </TemplatePopupStyle>
  );
}

export default ProgramListPopup;
