import ArrayUtils from "components/common/utils/ArrayUtils";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import defaultTheme from "page/mobile/appConfiguration/defaultTheme.png";
import pcTheme from "page/mobile/appConfiguration/pctheme.png";
import { useContext } from "react";
import { MobileLayoutContext } from "page/mobile/appConfiguration";
import produce from "immer";

function MobileMenuTypeSetting({
  initial,
  nextCallback,
  prevCallback,
  theme,
  ...props
}) {
  const { saveLayout, mnuTmplList, mnu, setMnu } =
    useContext(MobileLayoutContext);

  const onChangeMenuType = (tmp) => {
    //아직 Custom이 없기 떄문에 P 고정
    setMnu(
      produce(mnu || {}, (draft) => {
        draft.layoutPresetId = tmp.layoutPresetId;
        draft.tmplType = "P";
        draft.layoutType = tmp.layoutType;
      })
    );
  };

  const onClickMenuSave = () => {
    saveLayout(mnu);
    if (initial) {
      nextCallback();
    }
  };

  return (
    <>
      <Row className="ls-container">
        <Col sm={2}>
          <div className="ls-header">Set Menu Template</div>
          <div className="ls-description">
            Select the menu type for the mobile application.
          </div>
        </Col>
        <Col sm={10}>
          <div className="ls-content">
            <Stack gap={3} direction="horizontal" className="mb-3">
              {mnuTmplList.map((tmp, idx) => {
                return (
                  <div
                    className={`menu-selector-wrapper ${
                      tmp.layoutPresetId === mnu?.layoutPresetId
                        ? "selected"
                        : ""
                    }`}
                    key={tmp.layoutPresetId}
                  >
                    <img
                      src={idx === 0 ? defaultTheme : pcTheme}
                      alt={tmp.presetNm}
                      onClick={(e) => onChangeMenuType(tmp)}
                    />
                    <Form.Check
                      type="radio"
                      name="menu-type"
                      id={tmp.layoutPresetId}
                      onChange={(e) => onChangeMenuType(tmp)}
                      label={tmp.presetNm}
                      checked={tmp.layoutPresetId === mnu?.layoutPresetId}
                    />
                  </div>
                );
              })}
            </Stack>
            <Row>
              {initial ? (
                <>
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button variant="link" onClick={prevCallback}>
                      Prev
                    </Button>
                    <Button variant="link" onClick={onClickMenuSave}>
                      Save & Next
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onClickMenuSave}>Save</Button>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default MobileMenuTypeSetting;
