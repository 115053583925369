import React from "react";
import BuilderHeader from "components/builder/BuilderHeader";
import HOCRouterProps from "components/common/HOCRouterProps";
import BuilderSidebar from "components/builder/BuilderSidebar";
import BuilderContent from "components/builder/BuilderContent";
import BuilderDraggableContainer from "components/builder/BuilderDraggableContainer";
import AppContextProvider from "components/common/AppContextProvider";
import AppThemeProvider from "components/common/AppThemeProvider";
import SearchResultComponent from "./SearchResultComponent";

class BuilderLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AppThemeProvider>
        <AppContextProvider>
          <HOCRouterProps Component={BuilderSidebar} applyLoader={true} />
          <HOCRouterProps Component={BuilderContent} applyLoader={false} />
          <SearchResultComponent />
        </AppContextProvider>
        <BuilderDraggableContainer id="builder-draggable" />
      </AppThemeProvider>
    );
  }
}

export default BuilderLayout;
