import React, { Suspense, useRef } from "react";

import loadable from "@loadable/component";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import { AppContext } from "components/common/AppContextProvider";
import produce from "immer";
import { useContext } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import CommonUtils, {
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { useCallback } from "react";
import { generateNodeTreeIds } from "../../tree/treeUtils";

const UIEditorComponent = ({ data, path, rootLocation, style }) => {
  const ref = useRef(null);
  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  const { isMobileEditor } = useSelector((state) => state.mobile);
  const components = useContext(AppContext).component.getComponentList("B");
  const output = useSelector((state) => state.outputUI.output);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const itemRef = useRef();

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    componentType: item.componentType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  const editorAttr = ObjectUtils.isEmpty(item.editorAttr)
    ? {}
    : item.editorAttr;

  /**
   * Onclick event
   * @param {Event} e
   * @returns
   */
  const onClick = (e) => {
    const nodeIds = generateNodeTreeIds(output.page, item.compId);
    dispatch(actions.setBuilderTreeNodeIds(nodeIds));
    Event.clickComponent(e, item, components, activedComponent, dispatch);
  };

  /**
   * Editor에서 Property속성 변경시 propertyValue 값 update
   * @param {*} propertyValue
   */
  const updateProperty = (propertyValue) => {
    const compId = item.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    UIReduxHelper.updateOutput(changedOutput, dispatch);
    dispatch(
      actions.updateActivateProps({
        ...activedComponent.propertyValue,
        ...propertyValue,
      })
    );
  };

  const getComponent = useCallback(() => {
    let mustRerender = false;
    if (JSON.stringify(item) !== JSON.stringify(itemRef.current)) {
      itemRef.current = item;
      mustRerender = true;
    }
    let theme = CommonUtils.getTheme();
    if (!componentRef.current || mustRerender) {
      if (isMobileEditor) {
        componentRef.current = loadable(() =>
          import(
            "components/builder/mobile/mobileComponents/" +
              editorAttr.componentClass
          )
        );
      } else {
        componentRef.current = loadable(() =>
          import(
            `components/builder/ui/editor/theme/${theme}/${editorAttr.componentClass}`
          )
        );
      }
      return componentRef.current;
    } else return componentRef.current;
  }, [item]);

  /**
   * render component items
   * @returns
   */
  const renderComponentItem = () => {
    if (!StringUtils.isEmpty(editorAttr.componentClass)) {
      const DynamicComponent = getComponent();
      return (
        <Suspense fallback={<div></div>}>
          <DynamicComponent
            componentInfo={item}
            event="renderEditor"
            isFocus={activedComponent.compId === item.compId ? true : false}
            onClick={onClick}
            fn={{ updateProperty: updateProperty }}
          />
        </Suspense>
      );
    } else {
      return <div>Please check componentClass in editor_attr !!</div>;
    }
  };

  if (
    editorAttr.componentClass === "form/Input" &&
    !StringUtils.isEmpty(item.propertyValue.style?.width)
  ) {
    let compStyle = { ...style, width: item.propertyValue.style.width };
    return (
      <div className="editor-component-wrap" style={compStyle} ref={ref}>
        {renderComponentItem()}
      </div>
    );
  } else {
    return (
      <div className="editor-component-wrap" style={{ ...style }} ref={ref}>
        {renderComponentItem()}
      </div>
    );
  }
};

export default React.memo(UIEditorComponent);
