import React from "react";
import MobilePropertiesTab from "./MobilePropertiesTab";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { IoClose } from "react-icons/io5";
import { setShowPropertiesTab } from "./reducers/MobileAction";

/**
 * 모바일의 Props 설정 부분이 둥둥 떠나니게 만드는 Wrapper
 * @param {*} props
 * @returns
 */
function MobileFloatingTab(props) {
  const { showPropTab } = useSelector((state) => state.mobile);
  const navigate = useNavigate();
  const tabRef = useRef();
  const tabHeaderRef = useRef();
  const positionRef = useRef();
  const dragStartRef = useRef();
  const dispatch = useDispatch();

  /**
   * 드래그 이벤트
   * 드래그 결과 위치에서 시작위치를 뺀다.
   * @param {*} e
   * @returns
   */
  const onDragComponent = (e) => {
    e.stopPropagation();

    //드래그 결과 포지션
    const newPosition = {
      top: e.pageY,
      left: e.pageX,
    };
    if (!positionRef.current) {
      positionRef.current = newPosition;
    }
    if (newPosition.left === 0 && newPosition.top === 0) {
      return false;
    }
    if (
      positionRef.current.top === newPosition.top &&
      positionRef.current.left === newPosition.left
    ) {
      return false;
    } else {
      positionRef.current = newPosition;
      if (!ObjectUtils.isEmpty(dragStartRef.current)) {
        tabRef.current.style = `top:${
          newPosition.top - dragStartRef.current.top
        }px; left:${newPosition.left - dragStartRef.current.left}px`;
      }
    }
  };

  /**
   * 드래그 시작할 때 포지션을 알아서 드래그시 이질감이 없도록 한다.
   *
   * @param {*} event
   * @returns
   */
  const onSetDragStartPosition = (event) => {
    event.stopPropagation();
    const element = tabHeaderRef.current;
    if (!element) return;
    const img = new Image();
    img.src = "";
    event.dataTransfer.setDragImage(img, 0, 0);
    const rect = element.getBoundingClientRect();
    const offsetX = event.clientX - rect.left; // 클릭한 X 위치
    const offsetY = event.clientY - rect.top; // 클릭한 Y 위치
    dragStartRef.current = {
      top: offsetY,
      left: offsetX,
    };
  };

  const handleDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let top = e.clientY - dragStartRef.current.top;
    let left = e.clientX - dragStartRef.current.left;
    let minTop = 60;
    let minLeft = 450;
    let maxTop = 850;
    let maxLeft = 1650;
    if (top < minTop) {
      top = minTop;
    } else if (top > maxTop) {
      top = maxTop;
    }

    if (left < minLeft) {
      left = minLeft;
    } else if (left > maxLeft) {
      left = maxLeft;
    }
    tabRef.current.style = `top:${top}px; left:${left}px`;
  };

  return (
    <div
      className={`floating-property-tab mobile ${
        showPropTab ? "show" : "hidden"
      }`}
      ref={tabRef}
    >
      <div
        className="floating-tab-header"
        ref={tabHeaderRef}
        draggable
        onDragStart={onSetDragStartPosition}
        onDrag={onDragComponent}
        onDragEnd={handleDragEnd}
      >
        <div onClick={(e) => dispatch(setShowPropertiesTab(false))}>
          <IoClose />
        </div>
      </div>
      <div className="floating-tab-body control-sidebar ">
        <MobilePropertiesTab navigate={navigate} />
      </div>
    </div>
  );
}

export default MobileFloatingTab;
