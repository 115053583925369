import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import ObjectUtils from "../utils/ObjectUtils";
import StringUtils from "../utils/StringUtils";
import Message from "../Message";
import { Enums } from "components/builder/BuilderEnum";
import CodeMirrorWrapper from "../element/CodeMirrorWrapper";
import styled from "@emotion/styled";
import { BuilderWrapper, Button, ButtonWrapper } from "./Builder";
import { debounce } from "../utils/InputUtils";

const EditorButton = styled(Button)``;

const Editor = ({
  value,
  onChange,
  callback,
  isViewModeChangeButton = false,
  setViewMode,
  show,
  ...props
}) => {
  const [viewSource, setViewSource] = useState("");

  useEffect(() => {
    if (ObjectUtils.isEmpty(value)) {
      setViewSource("");
    } else {
      const _pValueSource = JSON.stringify(value, null, 2);
      if (JSON.stringify(_pValueSource) !== viewSource) {
        setViewSource(JSON.stringify(value, null, 2));
      }
    }
  }, [value]);

  useEffect(() => {
    if (!show) {
      try {
        if (StringUtils.isEmpty(viewSource)) {
        } else {
          JSON.parse(viewSource);
        }
        Message.alert("This is Valid Data", Enums.MessageType.SUCCESS);
      } catch (error) {
        Message.alert("Data Format is Invalid.", Enums.MessageType.WARN);
      }
    }
  }, [show]);

  const _validate = (source) => {
    try {
      if (StringUtils.isEmpty(source)) {
        onChange({});
      } else {
        onChange(JSON.parse(source));
      }
      Message.alert("This is Valid Data", Enums.MessageType.SUCCESS);
    } catch (error) {
      Message.alert("Data Format is Invalid.", Enums.MessageType.WARN);
    }
  };

  const changeViewSource = (source) => {
    setViewSource(source);
    try {
      if (StringUtils.isEmpty(source)) {
        onChange({});
      } else {
        onChange(JSON.parse(source));
      }
    } catch (error) {}
  };
  return (
    <BuilderWrapper
      style={{ ...props.style, display: show ? "block" : "none" }}
    >
      <ButtonWrapper>
        <EditorButton onClick={(e) => _validate(viewSource)}>
          Validate JSON
        </EditorButton>
        {isViewModeChangeButton && (
          <EditorButton
            onClick={(e) => setViewMode("Builder")}
            style={{ color: "green" }}
          >
            Builder Viewer
          </EditorButton>
        )}
      </ButtonWrapper>

      <code>
        {`Make sure to enclose the key in double quotes (" "). 
After writing, please check Validation.
    Valid : {"sampleKey":"sampleValue"}
    InValid : {sampleKey: "sampleValue"}`}
      </code>
      <div className="codeMirror-area">
        <CodeMirrorWrapper>
          <CodeMirror
            value={viewSource}
            className="source-container"
            height="400px"
            extensions={[json(true)]}
            autoFocus={false}
            onChange={(value, viewUpdate) =>
              changeViewSource(value, viewUpdate)
            }
            {...props.editorConfig}
          />
        </CodeMirrorWrapper>
      </div>
    </BuilderWrapper>
  );
};

export default Editor;
