import React from "react";

const NormalIcon = (props) => {
  return <i {...props}></i>;
};
const PathIcon = ({ className, style, pathCount }) => {
  var elements = [];
  for (let i = 1; i <= pathCount; i++) {
    elements.push(
      React.createElement("span", { key: i, className: "path" + i })
    );
  }
  let mainContainer = React.createElement(
    "i",
    { className: className, style: { ...style } },
    elements
  );
  return mainContainer;
};

const Path3 = (props) => {
  return <PathIcon pathCount={3} {...props} />;
};
const Path4 = (props) => {
  return <PathIcon pathCount={4} {...props} />;
};
const Path5 = (props) => {
  return <PathIcon pathCount={5} {...props} />;
};
const Path6 = (props) => {
  return <PathIcon pathCount={6} {...props} />;
};
const Path10 = (props) => {
  return <PathIcon pathCount={10} {...props} />;
};
const Path11 = (props) => {
  return <PathIcon pathCount={11} {...props} />;
};
const Path15 = (props) => {
  return <PathIcon pathCount={15} {...props} />;
};
const Path17 = (props) => {
  return <PathIcon pathCount={17} {...props} />;
};

export const SearchFormLayoutTemplate = NormalIcon;
export const FilterFormLayoutTemplate = NormalIcon;
export const GridLayoutTemplate = NormalIcon;
export const FormLayoutTemplate = NormalIcon;
export const Tree = NormalIcon;
export const Container = NormalIcon;
export const Row = NormalIcon;
export const Column = NormalIcon;
export const Block = NormalIcon;
export const Form = NormalIcon;
export const Input = NormalIcon;
export const Select = NormalIcon;
export const Textarea = NormalIcon;
export const Text = NormalIcon;
export const Heading = NormalIcon;
export const Hyperlink = NormalIcon;
export const HtmlTag = NormalIcon;
export const Grid = NormalIcon;
export const Table = NormalIcon;
export const StepWizardTemplate = NormalIcon;
export const StepWizardContainer = NormalIcon;

export const Button = Path3;
export const InputGroupTemplate = Path4;
export const InputGroup = Path4;
export const RadioButton = Path5;
export const Checkbox = Path5;
export const FileUpload = Path5;
export const FileDownload = Path5;
export const Tab = Path5;
export const TabTemplate = Path5;
export const TabContainer = Path5;
export const Image = Path6;
export const PopupLayoutTemplate = Path10;
export const SingleDatePicker = Path10;
export const Excel = Path11;
export const AMChart3WidgetTemplate = Path15;
export const RangeDatePicker = Path17;
export const WidgetContainer = Path17;
export const NumericMetricTemplate = Path6;
export const FormWidgetTemplate = NormalIcon;
export const TextWidgetTemplate = Path3;
export const ListWidgetTemplate = NormalIcon;
export const TextEditor = Path4;
